const COMMON_PHL_BANK_CODES = [
  { value: 'ISLAM', label: 'Al-Amanah Islamic Bank' },
  { value: 'AUB', label: 'Asia United Bank' },
  { value: 'ANZ', label: 'Australia & New Zealand Bank' },
  { value: 'BDO', label: 'Banco De Oro Unibank' },
  { value: 'BANGK', label: 'Bangkok Bank Public Co.' },
  { value: 'BCH', label: 'Bank of China' },
  { value: 'BOC', label: 'Bank of Commerce' },
  { value: 'BPI', label: 'Bank of The Philippine Islands' },
  { value: 'ONB', label: 'BDO Network Bank' },
  { value: 'CBS', label: 'China Bank Savings' },
  { value: 'CB', label: 'China Banking Corporation' },
  { value: 'CIMB', label: 'Cimb Bank Philippines' },
  { value: 'CITI', label: 'Citibank' },
  { value: 'CRBR', label: 'Community Rural Bank of Romblon' },
  { value: 'CTB', label: 'CTBC Bank Corporation' },
  { value: 'DB', label: 'Deutsche Bank' },
  { value: 'DBP', label: 'Development Bank of The Philippines' },
  { value: 'EWB', label: 'East West Rural Bank' },
  { value: 'EQSB', label: 'Equicom Savings Bank' },
  { value: 'FCB', label: 'First Consolidated Bank' },
  { value: 'GSB', label: 'Guagua Rural Bank' },
  { value: 'HSBC', label: 'HSBC' },
  { value: 'HSBCS', label: 'HSBC Savings Bank Philippines' },
  { value: 'ING', label: 'ING Bank' },
  { value: 'LBP', label: 'Land Bank of the Philippines' },
  { value: 'MAL', label: 'Malayan Bank' },
  { value: 'MAY', label: 'Maybank Philippines' },
  { value: 'MBTC', label: 'Metropolitan Bank and Trust Co.' },
  { value: 'PBB', label: 'Philippine Business Bank' },
  { value: 'PNB', label: 'Philippine National Bank' },
  { value: 'PSB', label: 'Philippine Savings Bank' },
  { value: 'PTB', label: 'Philippine Trust Company' },
  { value: 'PVB', label: 'Philippine Veterans Bank' },
  { value: 'PBCOM', label: 'Philippines Bank of Communications' },
  { value: 'PRODS', label: 'Producers Savings Bank' },
  { value: 'RCBC', label: 'Rizal Commercial Banking Corporation' },
  { value: 'SEA', label: 'Seabank Philippines Inc' },
  { value: 'SB', label: 'Security Bank Corporation' },
  { value: 'STRBK', label: 'Sterling Bank of Asia' },
  { value: 'SCB', label: 'The Standard Chartered Bank' },
  { value: 'UB', label: 'Union Bank of The Philippines' },
  { value: 'UCPB', label: 'United Coconut Planters Bank' },
  { value: 'UOB', label: 'United Overseas Bank Philippines' },
];

export const PHL_WISE_SUPPORTED_BANK_CODES = [
  ...COMMON_PHL_BANK_CODES,
  { value: 'AB', label: 'Allied Bank' },
  { value: 'ASB', label: 'Allied Savings Bank' },
  { value: 'AMA', label: 'AMA Bank' },
  { value: 'BOT', label: 'Bank of Tokyo' },
  { value: 'BONE', label: 'Bank One Savings and Trust Corporation' },
  { value: 'CITIS', label: 'Citibank Savings' },
  { value: 'CITE', label: 'City Estate Savings Bank' },
  { value: 'CITY', label: 'City Savings Bank' },
  { value: 'FEB', label: 'Far Eastern Bank' },
  { value: 'PILSB', label: 'Filipino Savers Bank' },
  { value: 'FAB', label: 'First Allied Bank' },
  { value: 'FMB', label: 'First Macro Bank' },
  { value: 'IBANK', label: 'International Exchange Bank' },
  { value: 'LDB', label: 'Luzon Development Bank' },
  { value: 'PBANK', label: 'Pen Bank' },
  { value: 'PLB', label: 'Planters Bank' },
  { value: 'POB', label: 'Postal Bank' },
  { value: 'PRB', label: 'Premiere Bank' },
  { value: 'RCBCS', label: 'RCBC Savings' },
  { value: 'RSB', label: 'Robinson Savings Bank' },
  { value: 'SBS', label: 'Security Bank Savings' },
  { value: 'TYB', label: 'Tong Yang Savings Bank' },
  { value: 'UCPBS', label: 'UCPB Savings Bank' },
  { value: 'USB', label: 'University Savings Bank' },
].sort((a, b) => (a.label < b.label ? -1 : 1)); // we want them sorted alphabetically

export const PHL_HSBC_SUPPORTED_BANK_CODES = [
  ...COMMON_PHL_BANK_CODES,
  { value: 'ALKB', label: 'Allbank, Inc.' },
  { value: 'KARU', label: 'Bangko Kabayan' },
  { value: 'MRTC', label: 'Bangko Mabuhay' },
  { value: 'NSPR', label: 'Bangko Nuestra Senora Del Pila' },
  { value: 'BOFA', label: 'Bank Of America' },
  { value: 'BORR', label: 'Bank of Florida' },
  { value: 'MKRU', label: 'Bank of Makati' },
  { value: 'BIUR', label: 'Biñan Rural Bank' },
  { value: 'BPDI', label: 'BPI Direct Banko' },
  { value: 'RUCA', label: 'Camalig Bank' },
  { value: 'CNRL', label: 'Cantilan Bank' },
  { value: 'UWCB', label: 'Cathay United Bank Co.' },
  { value: 'CELR', label: 'Cebuana Lhuillier Rural Bank' },
  { value: 'CIPH', label: 'CIMB Bank Philippines' },
  { value: 'CQBB', label: 'Cooperative Bank of Quezon Province' },
  { value: 'COUK', label: 'Country Builders Bank' },
  { value: 'DCPH', label: 'DCPay Philippines' },
  { value: 'DCDE', label: 'Dumaguete City Development Bank' },
  { value: 'DUMT', label: 'Dungganon Bank' },
  { value: 'EWBC', label: 'East-West Banking Corporation' },
  { value: 'GOTY', label: 'Gotyme Bank Corporation' },
  { value: 'GXCH', label: 'G-Xchange' },
  { value: 'HSBCS', label: 'HSBC Savings Bank Philippines' },
  { value: 'HBPH', label: 'HSBC Savings bank' },
  { value: 'ICBK', label: 'Industrial And Commercial Bank of China' },
  { value: 'IBKO', label: 'Industrial Bank Of Korea - Manila' },
  { value: 'IORU', label: 'Innovative Bank' },
  { value: 'CHAS', label: 'JPMorgan Chase Bank' },
  { value: 'KOEX', label: 'Keb Hana Bank' },
  { value: 'LPCR', label: 'Laguna Prestige Bank' },
  { value: 'LFSH', label: 'LuLu Financial Services' },
  { value: 'MLRU', label: 'Malarayat Rural Bank' },
  { value: 'MYYA', label: 'Maya Bank' },
  { value: 'ICBC', label: 'Mega Intl Comml Bank Co.' },
  { value: 'MHCB', label: 'Mizuho Bank' },
  { value: 'MOML', label: 'Money Mall Rural Bank' },
  { value: 'BOTK', label: 'Mufg Bank' },
  { value: 'MVRS', label: 'Mvsm Bank' },
  { value: 'NRSL', label: 'New Rural Bank of San Leonardo (Nueva Ecija)' },
  { value: 'PAPH', label: 'Paymaya Philippines' },
  { value: 'PAEY', label: 'Paymongo Payments' },
  { value: 'QCDF', label: 'Queen City Development Bank' },
  { value: 'RARL', label: 'Rang-Ay Bank' },
  { value: 'RBRU', label: 'RBT Bank' },
  { value: 'ROBP', label: 'Robinsons Bank Corporation' },
  { value: 'RUBC', label: 'Rural Bank of Bacolod City' },
  { value: 'RUBU', label: 'Rural Bank of Bauang' },
  { value: 'RUDI', label: 'Rural Bank of Digos' },
  { value: 'RUGU', label: 'Rural Bank of Guinobatan' },
  { value: 'RUPZ', label: 'Rural Bank of La Paz' },
  { value: 'RLSK', label: 'Rural Bank of Lebak' },
  { value: 'RUMT', label: 'Rural Bank of Montalban' },
  { value: 'RUPP', label: 'Rural Bank of Porac' },
  { value: 'RURU', label: 'Rural Bank of Rosario' },
  { value: 'RUSY', label: 'Rural Bank of Sagay' },
  { value: 'RUSG', label: 'Rural Bank of Sta. Ignacia' },
  { value: 'LAUI', label: 'Seabank Philippines' },
  { value: 'SHBK', label: 'Shinhan Bank' },
  { value: 'SMBC', label: 'Sumitomo Mitsui Banking Corporation' },
  { value: 'TAYO', label: 'Tayocash' },
  { value: 'TODG', label: 'Tonik Digital Bank' },
  { value: 'UNOB', label: 'Uno Bank' },
  { value: 'UNOD', label: 'Union Digital Bank' },
  { value: 'USME', label: 'USSC Money Services' },
  { value: 'WEDV', label: 'Wealth Development Bank' },
  { value: 'TYBK', label: 'Yuanta Savings Bank' },
  { value: 'ZBTE', label: 'Zybi Tech' },
].sort((a, b) => (a.label < b.label ? -1 : 1)); // we want them sorted alphabetically
