import { Box } from '@remote-com/norma';
import styled, { css } from 'styled-components';

import { ButtonIcon } from '@/src/components/Button';

export const ToolbarWrapper = styled(Box)`
  display: flex;
`;

export const ToolbarContainer = styled(Box)`
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  border-width: 1px 0;
  background-color: ${({ theme }) => theme.colors.blank};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`;

export const VerticalSpacer = styled(Box)`
  height: 16px;
  border-left: ${({ theme }) => `1px solid ${theme.colors.grey[300]}`};
`;

export const ToggleButton = styled(ButtonIcon).attrs({
  variant: 'ghost',
  size: 'sm',
})`
  color: ${({ theme }) => theme.colors.grey[600]};
  ${({ $active, theme }) =>
    $active &&
    css`
      & {
        background: ${theme.colors.grey[100]};
      }
    `}
`;
