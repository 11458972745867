import { Pill, Stack, Text } from '@remote-com/norma';
import { IconV2OutlineAlertTriangle } from '@remote-com/norma/icons/IconV2OutlineAlertTriangle';
import { IconV2OutlineCheck } from '@remote-com/norma/icons/IconV2OutlineCheck';
import { add, isPast, format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { encodeUrlParams } from 'react-url-modal';

import type { Currency } from '@/src/api/config/employ/shared.types';
import { FlagIcon } from '@/src/components/Svg/sprites/FlagIcon';
import { PAYMENT_METHODS_ROUTE } from '@/src/constants/routes';
import type { StripePlatform } from '@/src/domains/payments/constants';
import { stripePlatforms } from '@/src/domains/payments/constants';
import { getPayInTimesForPaymentMethod } from '@/src/domains/payments/shared/paymentCompletionEstimations';
import type { OutstandingPayment } from '@/src/domains/payments/types';
import { convertToCents, formatMoneyWithoutCurrency } from '@/src/helpers/currency';
import type { $TSFixMe } from '@/types';

import type {
  CreditAndDebitCardPaymentMethod,
  PaymentMethod,
  StripeAchPaymentMethod,
  StripeBacsPaymentMethod,
  StripePaymentMethod,
  PaymentMethodUseCategory,
} from '../types';

import type { ResourceType, StripeCardType, WireChargeType } from './constants';
import {
  resourceTypes,
  paymentMethodTypeLabels,
  stripeCardBrands,
  wireChargeTypeLabels,
  wireTransferPaymentMethod,
} from './constants';

export function isCardExpired(expMonth: number, expYear: number) {
  const cardExpireDate = new Date(expYear, expMonth, 0);
  return isPast(cardExpireDate);
}

export function isCreditCard(type: string) {
  return type === resourceTypes.STRIPE_CARD;
}

export const isCreditCardPaymentMethod = (
  paymentMethod: PaymentMethod
): paymentMethod is CreditAndDebitCardPaymentMethod => {
  return paymentMethod.resourceType === resourceTypes.STRIPE_CARD;
};

export const isDirectDebitPaymentMethod = (resourceType: ResourceType): boolean => {
  switch (resourceType) {
    case resourceTypes.STRIPE_ACH_DIRECT_DEBIT:
    case resourceTypes.STRIPE_SEPA_DIRECT_DEBIT:
    case resourceTypes.STRIPE_BACS_DIRECT_DEBIT:
      return true;
    case resourceTypes.STRIPE_CARD:
    case resourceTypes.WIRE_TRANSFER:
    case resourceTypes.STRIPE_BANK_TRANSFER:
    case resourceTypes.PREFUNDING_CREDIT:
      return false;
  }
};

export function isBacs(type: string) {
  return type === resourceTypes.STRIPE_BACS_DIRECT_DEBIT;
}

export const isBacsPaymentMethod = (
  paymentMethod: StripePaymentMethod
): paymentMethod is StripeBacsPaymentMethod => {
  return paymentMethod.resourceType === resourceTypes.STRIPE_BACS_DIRECT_DEBIT;
};

export function isAch(type: string) {
  return type === resourceTypes.STRIPE_ACH_DIRECT_DEBIT;
}

export function isAchPaymentMethod(
  paymentMethod: StripePaymentMethod
): paymentMethod is StripeAchPaymentMethod {
  return paymentMethod.resourceType === resourceTypes.STRIPE_ACH_DIRECT_DEBIT;
}

export function isWireTransfer(type: string | null | undefined) {
  return type === resourceTypes.WIRE_TRANSFER;
}

export function getStatusBadge(expMonth: number, expYear: number) {
  const isExpired = isCardExpired(expMonth, expYear);
  return {
    type: isExpired ? 'inactive' : 'active',
    label: isExpired ? 'Expired' : 'Active',
  };
}

export function isPaymentMethodSupported(
  resourceType: ResourceType,
  employerDesiredCurrency?: Currency,
  stripePlatformCountryCode?: StripePlatform,
  ignoreStripePlatform?: boolean // optional flag till we enable multi-entity
) {
  const { code: currencyCode } = employerDesiredCurrency || {};
  switch (resourceType) {
    case resourceTypes.STRIPE_ACH_DIRECT_DEBIT:
      return currencyCode === 'USD';
    case resourceTypes.STRIPE_SEPA_DIRECT_DEBIT:
      return currencyCode === 'EUR';
    case resourceTypes.STRIPE_BACS_DIRECT_DEBIT:
      return ignoreStripePlatform
        ? currencyCode === 'GBP'
        : currencyCode === 'GBP' && stripePlatformCountryCode === stripePlatforms.UK;
    default:
      return true;
  }
}
/**
 *  Wire transfer is not a PaymentMethod entity that can be attached to a customer's account, so, unlike other methods, it's not returned by the API. However, all customers by default have the option to pay via wire transfer, so we append it to enable this.
 *
 * @param {Array} paymentMethodsFromAPI - array of user-added payment methods e.g. credit card
 * @returns {Array} - list of all payment methods, with wire transfer option added in
 */
export function addWireTransferPaymentMethod(paymentMethodsFromAPI: PaymentMethod[] = []) {
  return [...(paymentMethodsFromAPI || []), wireTransferPaymentMethod];
}

export function filterAvailablePaymentMethods<T extends StripePaymentMethod | PaymentMethod>(
  paymentMethods: T[],
  employerDesiredCurrency?: Currency,
  stripePlatformCountryCode?: StripePlatform
): T[] {
  return paymentMethods?.filter(({ resourceType }) =>
    isPaymentMethodSupported(resourceType, employerDesiredCurrency, stripePlatformCountryCode)
  );
}

/**
 * Separate the expired date to the MM/YYYY format.
 * For now, we're prepending '20' to get the full year
 * @param {string} expireDate expired date in MM/YY format
 */
export function splitExpireDate(expireDate: string) {
  if (!expireDate) {
    return [];
  }

  // format = MM/YY
  const splitDate = expireDate.split('/');
  const fullYear = splitDate[1].length > 2 ? splitDate[1] : `20${splitDate[1]}`;
  const formattedDate = format(
    new Date(parseInt(fullYear, 10), parseInt(splitDate[0], 10), 0),
    'MM/yyyy'
  );
  return formattedDate.split('/');
}

/**
 * The expired date must be in specific format for the masked field
 * @param {Object} card object with card information
 */
export function formatExpireDateInitialValue(expMonth: number, expYear: number) {
  return format(new Date(expYear, expMonth, 0), 'MM/yy');
}

export function validateExpireDate(date: string) {
  let result;
  try {
    result = splitExpireDate(date);
  } catch {
    return false;
  }

  const [month, year] = result;
  return !isCardExpired(parseInt(month, 10), parseInt(year, 10));
}

const wireTransferPaymentInfoLabel = 'Pay direct from your local bank';

const paymentInfo = {
  [resourceTypes.STRIPE_CARD]: (data: $TSFixMe) => data.stripeCard.name,
  [resourceTypes.STRIPE_SEPA_DIRECT_DEBIT]: (data: $TSFixMe) => data.stripeSepa.name,
  [resourceTypes.STRIPE_ACH_DIRECT_DEBIT]: (data: $TSFixMe) => data.stripeAch.name,
  [resourceTypes.STRIPE_BACS_DIRECT_DEBIT]: (data: $TSFixMe) => data?.stripeBacs?.name,
  [resourceTypes.WIRE_TRANSFER]: () => wireTransferPaymentInfoLabel,
  [resourceTypes.STRIPE_BANK_TRANSFER]: () => wireTransferPaymentInfoLabel,
};

export const benefitsPaymentInfo = {
  [resourceTypes.STRIPE_CARD]: 'Simple, one-click payment with fastest processing time',
  [resourceTypes.STRIPE_ACH_DIRECT_DEBIT]: 'Reduce operational burden with automated payments',
  [resourceTypes.STRIPE_BACS_DIRECT_DEBIT]: 'Reduce operational burden with automated payments',
  [resourceTypes.STRIPE_SEPA_DIRECT_DEBIT]: 'Reduce operational burden with automated payments',
};

const getCardBrand = (brand: StripeCardType) => stripeCardBrands[brand] || brand;

export const getCardLabel = ({ last4, brand }: { last4: string | number; brand: StripeCardType }) =>
  last4 ? `${getCardBrand(brand)} **${last4}` : getCardBrand(brand);

/**
 * Map API paymentMethod string to resourceType
 * @param {OutstandingPayment} outstandingPayment
 * @param {Object?} outstandingPayment.wireCharge
 * @param {StripeCharge?} outstandingPayment.stripeCharge
 */
export function getPaymentMethodResourceType(
  outstandingPayment?: OutstandingPayment
): ResourceType | null {
  if (!outstandingPayment) {
    return null;
  }

  if (outstandingPayment?.paymentMethod?.resourceType) {
    return outstandingPayment.paymentMethod.resourceType;
  }

  const { wireCharge, stripeCharge } = outstandingPayment;
  if (wireCharge != null) {
    return resourceTypes.WIRE_TRANSFER;
  }

  return stripeCharge?.paymentMethod?.resourceType ?? null;
}

/**
 * Map paymentMethod constant to a friendly name.
 * Almost like getPaymentMethodLabel, but when you only have paymentMethod
 * key
 */
export function getPaymentMethodTypeLabel(resourceType: ResourceType) {
  return paymentMethodTypeLabels[resourceType];
}

export function getWireChargeTypeLabel(wireChargeType?: WireChargeType) {
  return wireChargeType ? wireChargeTypeLabels[wireChargeType] : null;
}

const getLabelWithLast4 = (label: string, last4: string | number) => `${label} **${last4}`;

const getLabelWithMaybeLast4 = <T extends StripePaymentMethod>(
  stripePropertyName: keyof T,
  paymentMethod: T
) => {
  const { resourceType } = paymentMethod;
  const label = getPaymentMethodTypeLabel(resourceType);

  const last4 = (paymentMethod?.[stripePropertyName] as $TSFixMe)?.last4;

  if (last4) {
    return getLabelWithLast4(label, last4);
  }

  return label;
};

/**
 *
 * @param {Object} params
 * @param {PaymentMethod} params.paymentMethod - Stripe payment methods or mocked wire transfer payment method
 * @param {String | null} params.wireChargeType - optional extra info, manual or ach credit transfer
 * @returns {String}
 */
export const getPaymentMethodLabel = ({
  paymentMethod,
  wireChargeType,
}: {
  paymentMethod: PaymentMethod;
  wireChargeType?: WireChargeType;
}) => {
  const { resourceType } = paymentMethod;
  const defaultLabel = getPaymentMethodTypeLabel(resourceType);

  switch (resourceType) {
    case resourceTypes.STRIPE_CARD: {
      const { stripeCard } = paymentMethod;
      return getCardLabel({ last4: stripeCard.last4, brand: stripeCard.brand as StripeCardType });
    }

    case resourceTypes.STRIPE_ACH_DIRECT_DEBIT: {
      return getLabelWithMaybeLast4('stripeAch', paymentMethod);
    }

    case resourceTypes.STRIPE_SEPA_DIRECT_DEBIT: {
      return getLabelWithMaybeLast4('stripeSepa', paymentMethod);
    }

    case resourceTypes.STRIPE_BACS_DIRECT_DEBIT: {
      return getLabelWithMaybeLast4('stripeBacs', paymentMethod);
    }

    case resourceTypes.WIRE_TRANSFER: {
      // with details of manual or ach_credit_transfer wire charge when present or default to 'Bank Transfer'
      return getWireChargeTypeLabel(wireChargeType) ?? defaultLabel;
    }

    case resourceTypes.PREFUNDING_CREDIT: {
      const { balance } = paymentMethod.prefundingCredit;
      const amount = `${formatMoneyWithoutCurrency(convertToCents(balance?.amount))} ${
        balance?.currency?.code
      }`;

      if (paymentMethod.prefundingCredit.productType === 'contractor_management') {
        return `CM Prepaid funds: ${amount}`;
      }

      if (paymentMethod.prefundingCredit.productType === 'contractor_of_record') {
        return `CoR Prepaid funds: ${amount}`;
      }

      return `Prepaid funds: ${amount}`;
    }

    default:
      return defaultLabel;
  }
};

function getPaymentMethodDescription(paymentMethod: StripePaymentMethod) {
  const { resourceType } = paymentMethod;

  switch (resourceType) {
    case resourceTypes.STRIPE_ACH_DIRECT_DEBIT: {
      return paymentMethod.stripeAch.institution;
    }
    default:
      return paymentInfo[resourceType]?.(paymentMethod);
  }
}

export function getPaymentMethodUseConfig(paymentMethod: StripePaymentMethod) {
  const config: Record<PaymentMethodUseCategory, boolean> = {
    remoteInvoices: false,
    contractorInvoices: false,
    remotePayroll: false,
  };

  switch (paymentMethod.resourceType) {
    case resourceTypes.STRIPE_ACH_DIRECT_DEBIT:
    case resourceTypes.STRIPE_SEPA_DIRECT_DEBIT:
    case resourceTypes.STRIPE_BACS_DIRECT_DEBIT:
    case resourceTypes.STRIPE_CARD:
      config.remoteInvoices = true;
      config.contractorInvoices = true;
      break;
    default:
      break;
  }

  return config;
}

/**
 * For non-wire-transfer payment methods
 * @param {Object} managedPaymentMethod - e.g. Stripe card, direct debit
 * @returns {{providerType: String, name: String, description: String | null}}
 */
export function getManagedPaymentMethodFriendly(managedPaymentMethod: StripePaymentMethod) {
  return {
    providerType: isCreditCardPaymentMethod(managedPaymentMethod)
      ? managedPaymentMethod.stripeCard.brand
      : managedPaymentMethod.resourceType,
    name: getPaymentMethodLabel({ paymentMethod: managedPaymentMethod }),
    description: getPaymentMethodDescription(managedPaymentMethod),
    useConfig: getPaymentMethodUseConfig(managedPaymentMethod),
    processingTime: getPayInTimesForPaymentMethod(managedPaymentMethod.resourceType),
  };
}

export function getGustoDataFriendly({ gustoPaymentMethod, resourceType }: $TSFixMe) {
  return {
    name: getPaymentMethodLabel({
      paymentMethod: {
        resourceType,
        stripeAch: {
          last4: gustoPaymentMethod.last4,
        },
      } as $TSFixMe,
    }),
    description: gustoPaymentMethod.legalEntity.name,
  };
}

export function getPaymentMethodNameFromOutstandingPayment(
  outstandingPayment?: OutstandingPayment
) {
  const resourceType = getPaymentMethodResourceType(outstandingPayment);
  if (!resourceType) return '';

  return getPaymentMethodLabel({
    paymentMethod: isWireTransfer(resourceType)
      ? wireTransferPaymentMethod
      : (outstandingPayment?.stripeCharge?.paymentMethod as PaymentMethod),
    wireChargeType: outstandingPayment?.wireCharge?.type,
  });
}

/**
 *
 * @param {Object} params
 * @param {Object} params.paymentMethod - either customer-added or a default wire transfer method
 * @param {String} [params.wireChargeType] - optional extra info about type of wire transfer (manual or ach credit transfer) available after payment initiation
 * @param {boolean} [params.showProcessingTime] - optional feature showing expected processing times for different payment methods
 * @param {String} [params.surchargeInfo]
 * @returns {{name: String, providerType: String, description: String | null, surchargeWarning: String | null }}
 */
export function getAppliedPaymentMethodFriendly({
  paymentMethod,
  wireChargeType,
  showProcessingTime = false,
  surchargeInfo,
}: {
  paymentMethod: PaymentMethod;
  wireChargeType?: WireChargeType;
  showProcessingTime: boolean;
  surchargeInfo?: string;
}) {
  const { resourceType } = paymentMethod;

  const description = () => {
    switch (resourceType) {
      case resourceTypes.WIRE_TRANSFER:
        return `Initiate a payment from your bank. Arrives in ${getPayInTimesForPaymentMethod(
          resourceType
        )}.`;
      default:
        return `Arrives in ${getPayInTimesForPaymentMethod(resourceType)}.`;
    }
  };

  return {
    providerType: isCreditCardPaymentMethod(paymentMethod)
      ? paymentMethod.stripeCard.brand
      : resourceType,
    name: getPaymentMethodLabel({
      paymentMethod,
      wireChargeType,
    }),
    description: showProcessingTime ? description() : null,
    surchargeWarning: showProcessingTime && surchargeInfo ? null : surchargeInfo,
  };
}

export function getEstimatedPaymentArrivalDateRange(
  paymentType: ResourceType | undefined,
  startDate: string
) {
  const fromDate = new Date(startDate);

  switch (paymentType) {
    case resourceTypes.STRIPE_ACH_DIRECT_DEBIT:
    case resourceTypes.STRIPE_SEPA_DIRECT_DEBIT:
    case resourceTypes.STRIPE_BACS_DIRECT_DEBIT:
      return [add(fromDate, { days: 6 }), add(fromDate, { days: 9 })];
    case resourceTypes.STRIPE_CARD:
      return [add(fromDate, { days: 2 }), add(fromDate, { days: 4 })];
    case resourceTypes.WIRE_TRANSFER:
      return [add(fromDate, { days: 2 }), add(fromDate, { days: 6 })];
    default:
      return [];
  }
}

export const FlagBadge = ({ flagName, textValue }: { flagName?: string; textValue: string }) => (
  <Stack direction="row" alignItems="center">
    {flagName && <FlagIcon name={flagName} />}
    <Text ml={flagName ? 3 : 0}>{textValue}</Text>
  </Stack>
);

export const getCurrencyBadgeForPaymentMethod = (paymentMethod: StripePaymentMethod) => {
  switch (paymentMethod.resourceType) {
    case resourceTypes.STRIPE_ACH_DIRECT_DEBIT:
      return <FlagBadge flagName="United States" textValue="USD" />;
    case resourceTypes.STRIPE_SEPA_DIRECT_DEBIT:
      return <FlagBadge flagName="EUR" textValue="EUR" />;
    case resourceTypes.STRIPE_BACS_DIRECT_DEBIT:
      return <FlagBadge flagName="United Kingdom" textValue="GBP" />;
    case resourceTypes.STRIPE_CARD:
      return <FlagBadge flagName="United Nations" textValue="Worldwide" />;
    default:
      return '';
  }
};

export const getOwnerBadgeForPaymentMethod = (paymentMethod: StripePaymentMethod) => {
  return (
    <FlagBadge
      flagName={paymentMethod.legalEntity?.address?.country?.code}
      textValue={paymentMethod.legalEntity?.name}
    />
  );
};

export const getAutoPayBadgeForPaymentMethod = (paymentMethod: StripePaymentMethod) => {
  const useConfig = getPaymentMethodUseConfig(paymentMethod);

  const allInvoices = useConfig.remoteInvoices && useConfig.contractorInvoices;
  const allInvoicesAndPayroll = allInvoices && useConfig.remotePayroll;

  switch (true) {
    case allInvoices:
      return (
        <Pill tone="warning" Icon={IconV2OutlineAlertTriangle}>
          Invoices only
        </Pill>
      );

    case allInvoicesAndPayroll:
      return (
        <Pill tone="primary" Icon={IconV2OutlineCheck}>
          All invoices & payroll
        </Pill>
      );

    default:
      return '';
  }
};

export function getPayConfirmMessageParams(paymentMethod: StripePaymentMethod) {
  let label = '';
  let last4account = '';
  switch (paymentMethod.resourceType) {
    case resourceTypes.STRIPE_ACH_DIRECT_DEBIT: {
      label = `${paymentMethodTypeLabels.stripe_ach} Account`;
      last4account = paymentMethod.stripeAch.last4;
      break;
    }
    case resourceTypes.STRIPE_SEPA_DIRECT_DEBIT: {
      label = `${paymentMethodTypeLabels.stripe_sepa} Account`;
      last4account = paymentMethod.stripeSepa.last4;
      break;
    }
    case resourceTypes.STRIPE_CARD: {
      label = capitalize(paymentMethod.stripeCard.brand);
      last4account = paymentMethod.stripeCard.last4;
      break;
    }
    case resourceTypes.STRIPE_BACS_DIRECT_DEBIT: {
      label = `${paymentMethodTypeLabels.stripe_bacs} Account`;
      last4account = paymentMethod.stripeBacs.last4;
      break;
    }
  }
  return { label, last4account };
}

export const getAddNewPaymentMethodRoute = (returnPath?: string) =>
  returnPath
    ? {
        pathname: PAYMENT_METHODS_ROUTE,
        query: {
          modal: 'new-payment-method',
          returnPath,
        },
      }
    : {
        pathname: PAYMENT_METHODS_ROUTE,
        query: {
          modal: 'new-payment-method',
        },
      };

export const getVerifyACHRoute = ({ slug, isGusto }: { slug: string; isGusto?: boolean }) => {
  return {
    pathname: PAYMENT_METHODS_ROUTE,
    query: {
      modal: 'verify-deposits',
      params: encodeUrlParams({ slug, isGusto }),
    },
  };
};

export const getAuthorizeACHRoute = ({ slug }: { slug: string }) => {
  return {
    pathname: PAYMENT_METHODS_ROUTE,
    query: {
      modal: 'authorize-payment-method',
      params: encodeUrlParams({ slug }),
    },
  };
};

export const getPaymentMethodIfOnlyOne = (paymentMethods: Array<PaymentMethod> = []) => {
  const activeMethods = paymentMethods.filter((method) => !method.isDisabled);
  return activeMethods.length === 1 ? activeMethods[0] : null;
};
