const qs = require('qs');

// The axios default of paramsSerializer.indexes=false doesn't apply to nested arrays
// Check relevant discussion: https://github.com/axios/axios/issues/5094#issuecomment-1274863322
const serializeWithBracketsArray = (params) => qs.stringify(params, { arrayFormat: 'brackets' });

const SLUG_REGEX =
  /^(?:[a-z0-9_-]+-)?[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

const importJobsSlugTransformResponseOptions = {
  // mapping contains dot notation keys that should not be transformed to camelCase
  stopPaths: ['data.column_mapping.mapping'],
  // a side effect of the above is that the `sample_data` key will not be transformed to camelCase
  // when used inside the specified paths. For consistency, we are also preventing it from being
  // transformed in other paths.
  // eslint-disable-next-line sort-keys/sort-keys-fix
  exclude: ['sample_data'],
};

/* eslint-disable remote/disallow-data-layer-endpoint-assignment */
module.exports = {
  '/api/v1/[userType]/contract-documents/[slug]/comments': {},
  '/api/v1/account': {},
  '/api/v1/account/administrative-details': {},
  '/api/v1/account/administrative-details/skipped-fields': {},
  '/api/v1/account/bank-account': {},
  '/api/v1/account/bank-accounts': {},
  '/api/v1/account/business-info': {},
  '/api/v1/account/contracts/[contractSlug]/employment-eligibility': {},
  '/api/v1/account/contracts/[contractSlug]/tax-administrative-details/[taxAdministrativeDetailsSlug]':
    {},
  '/api/v1/account/contracts/[contractSlug]/tax-tasks': {},
  '/api/v1/account/contracts/[contractSlug]/tax-tasks/[taxTaskSlug]': {},
  '/api/v1/account/contracts/[contractSlug]/tax-tasks/[taxTaskSlug]/pdf-template': {},
  '/api/v1/account/emergency-contact': {},
  '/api/v1/account/home-address': {},
  '/api/v1/account/logins': {},
  '/api/v1/account/move-profile/summary': {},
  '/api/v1/account/move-profile/validate-token/[token]': {},
  '/api/v1/account/nium/available-currencies': {},
  '/api/v1/account/nium/beneficiaries/[slug]': {},
  '/api/v1/account/nium/beneficiary-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/account/nium/payout-methods': {},
  '/api/v1/account/nium/supported-countries': {},
  '/api/v1/account/notification-preferences': {},
  '/api/v1/account/payment-preferences': {},
  '/api/v1/account/personal-details': {},
  '/api/v1/account/settings/favorites': {},
  '/api/v1/account/wise/manual-countries': {},
  '/api/v1/account/withdrawal-methods': {},
  '/api/v1/account/withdrawal-methods/[slug]': {},
  '/api/v1/account/withdrawal-methods/[slug]/eligible-invoices-count-for-withdrawal-method': {},
  '/api/v1/account/withdrawal-methods/[slug]/payout-retryable-contractor-invoices': {},
  '/api/v1/ai-knowledge-bot/smooch/attachments/[attachmentPath]': {},
  '/api/v1/ai-knowledge-bot/sources/notion-pages/[slug]': {},
  '/api/v1/bank-account-support-settings': {
    serviceOptions: {
      // we don't want the country codes to be transformed
      transformResponseOptions: {
        exclude: [/\b[A-Z]{3}\b/],
      },
    },
  },
  '/api/v1/billing-platform/employer/billing-documents': {},
  '/api/v1/billing-platform/employer/billing-documents/[billingDocumentSlug]/itemized-report-csv/download':
    {},
  '/api/v1/billing-platform/employer/billing-documents/[slug]': {},
  '/api/v1/billing-platform/employer/billing-documents/[slug]/origin': {},
  '/api/v1/billing-platform/employer/billing-documents/[slug]/risk-reserve': {},
  '/api/v1/billing-platform/employer/billing-documents/dashboard': {},
  '/api/v1/billing-platform/employer/billing-documents/from-resource-identifier/[slug]': {},
  '/api/v1/billing-platform/employer/billing-documents/payment-reversed-packages': {},
  '/api/v1/billing-platform/employer/companies/[companySlug]': {},
  '/api/v1/billing-platform/rivendell/billable-items': {},
  '/api/v1/billing-platform/rivendell/billable-items/[billableSlug]': {},
  '/api/v1/billing-platform/rivendell/billable-items/corrections/[billableItemSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/billing-platform/rivendell/billable-items/filters/options': {},
  '/api/v1/billing-platform/rivendell/billing-documents': {},
  '/api/v1/billing-platform/rivendell/billing-documents/[billingDocumentSlug]/itemized-report-csv/download':
    {},
  '/api/v1/billing-platform/rivendell/billing-documents/[slug]': {},
  '/api/v1/billing-platform/rivendell/billing-documents/[slug]/origin': {},
  '/api/v1/billing-platform/rivendell/billing-documents/from-resource-identifier/[slug]': {},
  '/api/v1/billing-platform/rivendell/companies/[companySlug]': {},
  '/api/v1/billing-platform/rivendell/remote-entities': {},
  '/api/v1/billing-platform/rivendell/remote-entities/[slug]': {},
  '/api/v1/career-development/journal/feedback': {},
  '/api/v1/career-development/journal/feedback/list-recipients': {},
  '/api/v1/career-development/journal/notes': {},
  '/api/v1/companies': {},
  '/api/v1/company-currencies': {},
  '/api/v1/company-feedback/[slug]': {},
  '/api/v1/compliance-hub/change-records': {},
  '/api/v1/compliance-hub/change-records/[slug]': {},
  '/api/v1/config': {},
  '/api/v1/contractor-currencies': {},
  '/api/v1/contractor-payments/legal-entity/[legalEntitySlug]/payments-eligibility': {},
  '/api/v1/contractor-payments/payments-eligibility': {},
  '/api/v1/contractor-payments/supported-payment-rails': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/contracts/[contractSlug]/payroll-calendars': {},
  '/api/v1/cost-calculator/countries': {},
  '/api/v1/cost-calculator/currency-conversion': {},
  '/api/v1/cost-calculator/regions/[regionSlug]/fields': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries': {},
  // TODO refactor these 2 from [code] to [countryCode] and type them.
  '/api/v1/countries/[code]/contract-details/direct_employee': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[code]/direct-employee-administrative-details': {
    serviceOptions: { transformJsonSchema: true },
  },
  '/api/v1/countries/[countryCode]/address-details/global_payroll': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/administrative-details/legal_entity': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/bank-account-details/legal_entity': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/basic-information/global_payroll': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/basic-information/legal_entity': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/contract-amendments/employments/[employmentSlug]': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/contract-amendments/preview': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/contract-details': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/contract-details/contractor': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/contract-details/global_payroll': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/contract-pension-details/global_payroll': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/contractor-administrative-details': {
    serviceOptions: { transformJsonSchema: true },
  },
  '/api/v1/countries/[countryCode]/employee-administrative-details': {
    serviceOptions: { transformJsonSchema: true },
  },
  '/api/v1/countries/[countryCode]/employee-administrative-details/global_payroll': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/employment-basic-information': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/employment-eligibility': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/fillable_documents': {},
  '/api/v1/countries/[countryCode]/fillable_documents/[documentSlug]/show': {},
  '/api/v1/countries/[countryCode]/global_payroll': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/offboarding-details/global-payroll/[offboardingType]': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/offboarding/global-payroll/payment-details/[offboardingType]': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/pay-schedule-details/legal_entity': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/personal-details': {
    serviceOptions: { transformJsonSchema: true },
  },
  '/api/v1/countries/[countryCode]/personal-details/global_payroll': {
    serviceOptions: { transformJsonSchema: true },
  },
  '/api/v1/countries/[countryCode]/signatory-details/legal_entity': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/countries/[countryCode]/tax-and-compliance/[taxAndComplianceName]': {
    serviceOptions: { transformJsonSchema: true },
  },
  '/api/v1/countries/[slug]': {},
  '/api/v1/countries/employee-nationality': {},
  '/api/v1/countries/employer-onboarding': {},
  '/api/v1/countries/feature-list': {},
  '/api/v1/countries/regions': {},
  // As opposed to `/api/v1/countries` this endpoint does not require auth and can be used during user sign-up
  '/api/v1/country-list': {},
  '/api/v1/currencies': {},
  '/api/v1/currency-conversion': {},
  '/api/v1/ebury/beneficiary-account-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employee/activity-check-requirements': {},
  '/api/v1/employee/benefit-details': {},
  '/api/v1/employee/benefits': {},
  '/api/v1/employee/benefits/integrations': {},
  '/api/v1/employee/benefits/safety-wing/membership': {},
  '/api/v1/employee/benefits/safety-wing/plans': {},
  '/api/v1/employee/calendars': {},
  '/api/v1/employee/calendars/[slug]': {},
  '/api/v1/employee/career-development/career-goals': {},
  '/api/v1/employee/career-development/performance-cycles': {},
  '/api/v1/employee/career-development/performance-cycles/[cycleSlug]/performance-snapshot-instances':
    {},
  '/api/v1/employee/career-development/performance-cycles/[cycleSlug]/performance-snapshot-instances/[snapshotInstanceSlug]/performance-snapshot-instance-assessments/[assessmentSlug]':
    {
      serviceOptions: {
        transformResponseOptions: {
          // Don't transform the json-schema form
          stopPaths: ['data.performance_assessment_form'],
        },
      },
    },
  '/api/v1/employee/clients': {},
  '/api/v1/employee/clients/[clientSlug]': {},
  '/api/v1/employee/contract-documents': {},
  '/api/v1/employee/contract-documents/[contractDocumentSlug]': {},
  '/api/v1/employee/contract-documents/[contractDocumentSlug]/form': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employee/contract-documents/[contractDocumentSlug]/form-json-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employee/contract-templates': {},
  '/api/v1/employee/contractor-invoices': {},
  '/api/v1/employee/contractor-invoices/[slug]': {},
  '/api/v1/employee/contractor-invoices/[slug]/payment-timeline': {},
  '/api/v1/employee/contractor-invoices/[slug]/receipt': {
    serviceOptions: {
      ignoreTransformResponse: true,
      responseType: 'blob',
    },
  },
  '/api/v1/employee/contractor-invoices/can-create': {},
  '/api/v1/employee/contractor-invoices/eligible-expenses': {},
  '/api/v1/employee/contractor-invoices/excluded-currency-list': {},
  '/api/v1/employee/contractor-invoices/payout-quote-preview': {
    serviceOptions: {
      // we don't want the slugs which come in keys to be transformed
      transformResponseOptions: {
        exclude: [
          /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/, // slugs
          /slug-.*/, // for testing
          /.*-slug/, // for testing
        ],
      },
    },
  },
  '/api/v1/employee/contractor-invoices/quotes/[slug]': {},
  '/api/v1/employee/contractor-invoices/recurring': {},
  '/api/v1/employee/contractor-invoices/recurring/[slug]': {},
  '/api/v1/employee/contractor-invoices/stripe-exchange-rate': {},
  '/api/v1/employee/contractor-invoices/time-tracking/generated': {},
  '/api/v1/employee/contractor-invoices/time-tracking/line-items': {},
  '/api/v1/employee/countries/[slug]/country-subdivisions': {},
  '/api/v1/employee/document-requests': {},
  '/api/v1/employee/employment-requests': {},
  '/api/v1/employee/employment-requests/[employmentRequestSlug]': {},
  '/api/v1/employee/employment-verification-letter': {},
  '/api/v1/employee/employments/[employmentSlug]': {},
  '/api/v1/employee/employments/[employmentSlug]/assigned-projects': {},
  '/api/v1/employee/employments/[employmentSlug]/attendance-policies': {},
  '/api/v1/employee/employments/[employmentSlug]/available-projects': {},
  '/api/v1/employee/employments/[employmentSlug]/bank-account-countries': {},
  '/api/v1/employee/employments/[employmentSlug]/beneficiary-accounts': {},
  '/api/v1/employee/employments/[employmentSlug]/beneficiary-accounts/[beneficiarySlug]': {},
  '/api/v1/employee/employments/[employmentSlug]/company-structures': {},
  '/api/v1/employee/employments/[employmentSlug]/contracts': {},
  '/api/v1/employee/employments/[employmentSlug]/custom-fields': {},
  '/api/v1/employee/employments/[employmentSlug]/deposits': {},
  '/api/v1/employee/employments/[employmentSlug]/documents': {},
  '/api/v1/employee/employments/[employmentSlug]/leave-policies/offboarding-summary': {},
  '/api/v1/employee/employments/[employmentSlug]/nium-beneficiaries': {},
  '/api/v1/employee/employments/[employmentSlug]/nium-beneficiaries/[beneficiarySlug]': {},
  '/api/v1/employee/employments/[employmentSlug]/nium-beneficiary-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employee/employments/[employmentSlug]/payment-split-eligibility': {},
  '/api/v1/employee/employments/[employmentSlug]/payment-splits': {
    serviceOptions: {
      // we don't want the slugs which come in keys to be transformed
      transformResponseOptions: {
        exclude: [/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/],
      },
    },
  },
  '/api/v1/employee/employments/[employmentSlug]/payment-splits/[currencyCode]': {},
  '/api/v1/employee/employments/[employmentSlug]/time-preferences': {},
  '/api/v1/employee/employments/[employmentSlug]/time-trackings/current': {},
  '/api/v1/employee/employments/[employmentSlug]/timeoff-leave-policies': {},
  '/api/v1/employee/employments/[employmentSlug]/timeoff-leave-policies-summary': {},
  '/api/v1/employee/employments/[slug]/payslip-files': {},
  '/api/v1/employee/expenses': {},
  '/api/v1/employee/expenses/[slug]': {},
  '/api/v1/employee/expenses/templates/[expenseTemplate]/[countryCode]': {
    serviceOptions: {
      transformJsonSchema: {
        jsonSchemaPath: 'data',
      },
    },
  },
  '/api/v1/employee/expiring-documents/[expiringDocumentSlug]': {},
  '/api/v1/employee/incentives': {},
  '/api/v1/employee/information-requests/[informationRequestSlug]': {},
  '/api/v1/employee/integrations/features': {},
  '/api/v1/employee/notifications': {},
  '/api/v1/employee/offboarding-paid-timeoff-days': {},
  '/api/v1/employee/onboarding/tasks': {},
  '/api/v1/employee/org-chart': {},
  '/api/v1/employee/paid-timeoff-days': {},
  '/api/v1/employee/payroll-outputs/[slug]': {},
  '/api/v1/employee/pdf-filler/[pdfSlug]/fields': {
    serviceOptions: { transformResponseOptions: { stopPaths: ['data.schema'] } },
  },
  '/api/v1/employee/pdf-filler/[pdfSlug]/values': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employee/remote-equity/portal-access-status': {},
  '/api/v1/employee/remote-payments-settings': {},
  '/api/v1/employee/remote-work-request': {},
  '/api/v1/employee/remote-work-request/[slug]': {},
  '/api/v1/employee/scheduled-contractor-invoices': {},
  '/api/v1/employee/scheduled-contractor-invoices/[slug]': {},
  '/api/v1/employee/time-tracking/daily-summary': {},
  '/api/v1/employee/time-tracking/has-entries': {},
  '/api/v1/employee/timeoff': {},
  '/api/v1/employee/timeoff/[timeOffSlug]': {},
  '/api/v1/employee/timesheets': {},
  '/api/v1/employee/timesheets/[timesheetSlug]': {},
  '/api/v1/employee/travel-letter-request': {},
  '/api/v1/employee/travel-letter-request/[slug]': {},
  '/api/v1/employer/1099-tax-forms': {},
  '/api/v1/employer/annual-plans/[planSlug]': {},
  '/api/v1/employer/background-checks': {},
  '/api/v1/employer/background-checks/[slug]': {},
  '/api/v1/employer/background-checks/available-checks': {},
  '/api/v1/employer/beneficiaries-for/[resourceType]/[resourceSlug]': {},
  '/api/v1/employer/benefit-offers': {},
  '/api/v1/employer/benefit-offers/by-employment': {},
  '/api/v1/employer/benefit-renewal-requests': {},
  '/api/v1/employer/benefit-renewal-requests/[slug]/schema': {
    serviceOptions: {
      transformJsonSchema: { excludedFields: [SLUG_REGEX, 'health-group-slug', 'life-group-slug'] },
    },
  },
  '/api/v1/employer/benefit-tiers': {},
  '/api/v1/employer/benefits': {},
  '/api/v1/employer/benefits/employment/[slug]/benefit-offers/schema': {
    serviceOptions: {
      transformJsonSchema: {
        excludedFields: [SLUG_REGEX, 'health-group-slug', 'life-group-slug'],
      },
    },
  },
  '/api/v1/employer/benefits/employment/[slug]/v2/benefit-offers': {},
  '/api/v1/employer/benefits/integrations/partners': {},
  '/api/v1/employer/benefits/integrations/supported-partners': {},
  '/api/v1/employer/billable-contractor-activities': {},
  '/api/v1/employer/billing/dashboard': {},
  '/api/v1/employer/bulk-create-contractors/check-progress': {},
  '/api/v1/employer/bulk-create/check-progress': {},
  '/api/v1/employer/bulk-create/employee-of-record/countries': {},
  '/api/v1/employer/bulk-create/employee-of-record/countries/[countryCode]/template': {
    serviceOptions: {
      ignoreTransformResponse: true,
      responseType: 'blob',
    },
  },
  '/api/v1/employer/bulk-create/global-payroll/countries/[countryCode]/template': {
    serviceOptions: {
      ignoreTransformResponse: true,
      responseType: 'blob',
    },
  },
  '/api/v1/employer/bulk-employment-changes/bulk-amendments': {},
  '/api/v1/employer/bulk-employment-changes/bulk-amendments/[bulkAmendmentSlug]/bulk-amendment-contract-amendments':
    {},
  '/api/v1/employer/bulk-employment-changes/bulk-amendments/imports': {},
  '/api/v1/employer/bulk-scheduled-contractor-invoices/check-progress': {},
  '/api/v1/employer/calendars': {},
  '/api/v1/employer/calendars/[slug]': {},
  '/api/v1/employer/career-development/career-goals/[teamMembershipSlug]': {},
  '/api/v1/employer/career-development/company-employments': {},
  '/api/v1/employer/career-development/feedback': {},
  '/api/v1/employer/career-development/performance-cycles': {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]': {
    serviceOptions: {
      transformResponseOptions: {
        stopPaths: ['data.performance_assessment_forms'],
      },
    },
  },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/calibrator/groups':
    {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/calibrator/groups/[calibrationGroupSlug]':
    {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups':
    {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups/[calibrationGroupSlug]':
    {
      serviceOptions: {
        transformResponseOptions: {
          // Don't transform the filters because they contain the custom filter slug
          stopPaths: ['data.calibratee_filters'],
        },
      },
    },
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups/[calibrationGroupSlug]/calibratees/[calibrateeTeamMembershipSlug]/comments':
    {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups/[calibrationGroupSlug]/calibrators/available':
    {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/calibration-events/[calibrationEventSlug]/groups/[calibrationGroupSlug]/preview-calibratees':
    {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/performance-snapshots/[snapshotSlug]/overall-ratings':
    {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/performance-snapshots/[snapshotSlug]/performance-snapshot-instances':
    {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/performance-snapshots/[snapshotSlug]/performance-snapshot-instances-by-report-type':
    {},
  '/api/v1/employer/career-development/performance-cycles/[cycleSlug]/performance-snapshots/[snapshotSlug]/performance-snapshot-instances/[snapshotInstanceSlug]/performance-snapshot-instance-assessments/[assessmentSlug]':
    {
      serviceOptions: {
        transformResponseOptions: {
          // Don't transform the json-schema form
          stopPaths: ['data.performance_assessment_form'],
        },
      },
    },
  '/api/v1/employer/company': {},
  '/api/v1/employer/company-feedback': {},
  '/api/v1/employer/company-product-tiers': {},
  '/api/v1/employer/company-product-tiers/[companyProductTierSlug]': {},
  '/api/v1/employer/company-structures': {},
  '/api/v1/employer/company-structures/[slug]/nodes': {},
  '/api/v1/employer/company-structures/nodes/[slug]': {},
  '/api/v1/employer/company/account-team': {},
  '/api/v1/employer/company/bank-account': {},
  '/api/v1/employer/company/departments': {},
  '/api/v1/employer/company/domains': {},
  '/api/v1/employer/company/domains/verification-record': {},
  '/api/v1/employer/company/employees': {},
  '/api/v1/employer/company/location': {},
  '/api/v1/employer/company/payment-settings': {},
  '/api/v1/employer/company/privacy-notice/preview': {},
  '/api/v1/employer/company/sso': {},
  '/api/v1/employer/company/stats': {},
  '/api/v1/employer/company/stats/countries': {},
  '/api/v1/employer/company/team-members': {},
  '/api/v1/employer/company/team-members/[companyAdminSlug]': {},
  '/api/v1/employer/company/time-settings': {},
  '/api/v1/employer/compliance-profile': {},
  '/api/v1/employer/compliance-profile/credit-risk-status': {},
  '/api/v1/employer/compliance-profile/onboarding-reserves-status': {},
  '/api/v1/employer/compliance-profiles': {},
  '/api/v1/employer/compliance-requests': {},
  '/api/v1/employer/compliance-settings': {},
  '/api/v1/employer/compliance/industry-categories': {},
  '/api/v1/employer/config': {},
  '/api/v1/employer/consolidated-service-invoices/[invoiceReportSlug]': {},
  '/api/v1/employer/contract-documents': {},
  '/api/v1/employer/contract-documents/[contractDocumentSlug]/form': {},
  '/api/v1/employer/contract-documents/[slug]': {
    serviceOptions: {
      transformResponseOptions: { stopPaths: ['data.input'] },
    },
  },
  '/api/v1/employer/contract-documents/[slug]/review': {},
  '/api/v1/employer/contract-documents/bulk-create/[batchSlug]/check-progress': {},
  '/api/v1/employer/contract-documents/company-signatories': {},
  '/api/v1/employer/contract-eligibility/[slug]': {},
  '/api/v1/employer/contract-templates': {},
  '/api/v1/employer/contract-templates/[slug]': {},
  '/api/v1/employer/contract-templates/available': {},
  '/api/v1/employer/contractor-currency': {},
  '/api/v1/employer/contractor-invoices': {},
  '/api/v1/employer/contractor-invoices/[slug]/payment-timeline': {},
  '/api/v1/employer/contractor-invoices/automatic-payments': {},
  '/api/v1/employer/contractor-invoices/stats': {},
  '/api/v1/employer/contractor-payments/pay-in-eligibility': {},
  '/api/v1/employer/contractor-subscriptions': {},
  '/api/v1/employer/contracts/[slug]': {},
  '/api/v1/employer/contracts/[slug]/tax-tasks': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employer/cost-approval-requests/[costApprovalRequestSlug]': {},
  '/api/v1/employer/countries': {},
  '/api/v1/employer/countries/[countryCode]/global-payroll-offboardings/forms/[formStepId]': {
    serviceOptions: { transformJsonSchema: true },
  },
  '/api/v1/employer/countries/[countrySlug]/statutory-blueprints': {},
  '/api/v1/employer/countries/[slug]/country-subdivisions': {},
  '/api/v1/employer/custom-field-definitions': {},
  '/api/v1/employer/custom-field-definitions/[slug]': {},
  '/api/v1/employer/custom-field-values': {},
  '/api/v1/employer/custom-fields': {},
  '/api/v1/employer/customer-product-onboarding/checklist': {},
  '/api/v1/employer/dashboard/direct-offboardings': {},
  '/api/v1/employer/dashboard/employees': {
    serviceOptions: {
      transformParamsOptions: {
        // Do not snake_case slugs
        exclude: [/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/],
      },
    },
  },
  '/api/v1/employer/dashboard/offboardings': {},
  '/api/v1/employer/dashboard/offboardings/insights': {},
  '/api/v1/employer/dashboard/offboardings/insights/enabled': {},
  '/api/v1/employer/data-exports': {},
  '/api/v1/employer/data-exports/[slug]/download': {
    serviceOptions: {
      // eslint-disable-next-line
      deepTransformResponse: false,
      ignoreTransformResponse: true,
      responseType: 'blob',
    },
  },
  '/api/v1/employer/data-exports/templates': {},
  '/api/v1/employer/data-exports/templates/[slug]': {},
  '/api/v1/employer/data-exports/templates/[slug]/preview-using-template-slug': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false }, // The response keys are field labels
  },
  '/api/v1/employer/data-exports/templates/[slug]/template-building-blocks': {},
  '/api/v1/employer/device-management': {},
  '/api/v1/employer/device-management/catalog': {},
  '/api/v1/employer/device-management/orders': {},
  '/api/v1/employer/documents': {},
  '/api/v1/employer/eligible-managers': {},
  '/api/v1/employer/employee-pay-elements': {},
  '/api/v1/employer/employee-pay-elements/[payElementSlug]': {},
  '/api/v1/employer/employee-pay-elements/[payElementSlug]/occurrences': {},
  '/api/v1/employer/employment-requests': {},
  '/api/v1/employer/employment-requests/[employmentRequestSlug]': {},
  '/api/v1/employer/employments/[employmentSlug]': {},
  '/api/v1/employer/employments/[employmentSlug]/bill-to-entity/[billToEntitySlug]/pricing-plans':
    {},
  '/api/v1/employer/employments/[employmentSlug]/company-structures': {
    serviceOptions: {
      // eslint-disable-next-line
      deepTransformResponse: false,
    },
  },
  '/api/v1/employer/employments/[employmentSlug]/contract-change-custom-fields': {},
  '/api/v1/employer/employments/[employmentSlug]/contract-document-form-details': {},
  '/api/v1/employer/employments/[employmentSlug]/contracts': {},
  '/api/v1/employer/employments/[employmentSlug]/direct-offboarding': {},
  '/api/v1/employer/employments/[employmentSlug]/federal-tax-details': {},
  '/api/v1/employer/employments/[employmentSlug]/files': {},
  '/api/v1/employer/employments/[employmentSlug]/leave-policies/offboarding-summary': {},
  '/api/v1/employer/employments/[employmentSlug]/nium-beneficiaries': {},
  '/api/v1/employer/employments/[employmentSlug]/nium-beneficiaries/[beneficiarySlug]': {},
  '/api/v1/employer/employments/[employmentSlug]/nium-beneficiary-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/offboardings': {},
  '/api/v1/employer/employments/[employmentSlug]/pricing-plan': {},
  '/api/v1/employer/employments/[employmentSlug]/salaries': {},
  '/api/v1/employer/employments/[employmentSlug]/salaries/cutoff-dates': {},
  '/api/v1/employer/employments/[employmentSlug]/salaries/latest': {},
  '/api/v1/employer/employments/[employmentSlug]/severance-reserve': {},
  '/api/v1/employer/employments/[employmentSlug]/timeoff-leave-policies': {},
  '/api/v1/employer/employments/[employmentSlug]/timeoff-leave-policies-summary': {},
  '/api/v1/employer/employments/[employmentSlug]/used-timeoff-leave-policies': {},
  '/api/v1/employer/employments/[employmentSlug]/work-calendars': {},
  '/api/v1/employer/employments/[slug]/payslip-files': {},
  '/api/v1/employer/employments/[teamMembershipSlug]/timeline-events': {},
  '/api/v1/employer/equity/employees': {},
  '/api/v1/employer/equity/summary': {},
  '/api/v1/employer/expenses': {},
  '/api/v1/employer/expenses/[slug]': {},
  '/api/v1/employer/expenses/stats': {},
  '/api/v1/employer/garnishment-order-types': {},
  '/api/v1/employer/garnishments': {},
  '/api/v1/employer/garnishments/[slug]': {},
  '/api/v1/employer/hris/company-admins': {},
  '/api/v1/employer/hris/company-admins/linked': {},
  '/api/v1/employer/hris/reporting-lines/assigned-managers': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employer/hris/team-members': {},
  '/api/v1/employer/implementation-fees/[resourceSlug]': {},
  '/api/v1/employer/import-job-types/[name]/json-schema': {
    serviceOptions: {
      // eslint-disable-next-line
      deepTransformResponse: false,
    },
  },
  '/api/v1/employer/import-job-types/[name]/template': {
    serviceOptions: {
      ignoreTransformResponse: true,
      responseType: 'blob',
    },
  },
  '/api/v1/employer/import-jobs': {},
  '/api/v1/employer/import-jobs/[slug]': {
    serviceOptions: {
      transformResponseOptions: importJobsSlugTransformResponseOptions,
    },
  },
  '/api/v1/employer/import-jobs/[slug]/export-rows-csv': {},
  '/api/v1/employer/import-jobs/[slug]/rows': {
    serviceOptions: {
      paramsSerializer: {
        serialize: serializeWithBracketsArray,
      },
    },
  },
  '/api/v1/employer/import-jobs/[slug]/rows/find-and-replace': {},
  '/api/v1/employer/import-jobs/[slug]/value-corrections-summaries': {},
  '/api/v1/employer/import-jobs/[slug]/value_corrections/[fieldName]': {},
  '/api/v1/employer/incentives': {},
  '/api/v1/employer/integration/[integrationName]': {},
  '/api/v1/employer/integration/[integrationName]/access': {},
  '/api/v1/employer/integration/[integrationName]/account-mapping': {},
  '/api/v1/employer/integration/[integrationName]/account-mapping/contractors/inactive': {},
  '/api/v1/employer/integration/[integrationName]/account-mapping/contractors/per-account': {},
  '/api/v1/employer/integration/[integrationName]/account-mapping/contractors/unmapped': {},
  '/api/v1/employer/integration/[integrationName]/account-mapping/default': {},
  '/api/v1/employer/integration/[integrationName]/account-mapping/line-items': {},
  '/api/v1/employer/integration/[integrationName]/admins': {},
  '/api/v1/employer/integration/[integrationName]/availability': {},
  '/api/v1/employer/integration/[integrationName]/features': {},
  '/api/v1/employer/integration/[integrationName]/hris-custom-fields': {},
  '/api/v1/employer/integration/[integrationName]/last-sync': {},
  '/api/v1/employer/integration/[integrationName]/list-accounts': {},
  '/api/v1/employer/integration/[integrationName]/list-employment-types': {},
  '/api/v1/employer/integration/[integrationName]/list-timeoff-types': {},
  '/api/v1/employer/integration/[integrationName]/sync-mapping': {},
  '/api/v1/employer/integration/[integrationName]/sync-summary': {},
  '/api/v1/employer/integration/[integrationName]/sync-summary/[billingDocumentSlug]': {},
  '/api/v1/employer/integration/[integrationName]/synced-contractors': {},
  '/api/v1/employer/integration/[integrationName]/tenants': {},
  '/api/v1/employer/integration/[integrationName]/tenants/[operatingUnit]/account-mapping': {},
  '/api/v1/employer/integration/[integrationName]/tenants/external': {},
  '/api/v1/employer/integration/[integrationName]/tenants/operating-units': {},
  '/api/v1/employer/integration/[integrationName]/timeoff': {},
  '/api/v1/employer/integration/available-countries-for-gp': {},
  '/api/v1/employer/integration/remote-mappable-fields/[countrySlug]': {},
  '/api/v1/employer/integration/remote_api/tokens': {},
  '/api/v1/employer/integrations': {},
  '/api/v1/employer/integrations/oauth/[integrationName]': {},
  '/api/v1/employer/integrations/oauth/[integrationName]/callback': {},
  '/api/v1/employer/invoice-reports': {},
  '/api/v1/employer/invoice-reports/[invoiceReportSlug]/[validatorName]/summarize_results': {},
  '/api/v1/employer/invoice-reports/[invoiceReportSlug]/breakdown-csv': {},
  '/api/v1/employer/invoice-reports/[slug]': {},
  '/api/v1/employer/job-catalog/custom-field-definitions': {},
  '/api/v1/employer/job-catalog/custom-field-definitions/[slug]': {},
  '/api/v1/employer/job-catalog/job-families': {},
  '/api/v1/employer/job-catalog/job-families/[slug]': {},
  '/api/v1/employer/job-catalog/job-sub-families/[slug]': {},
  '/api/v1/employer/job-catalog/jobs': {},
  '/api/v1/employer/job-catalog/jobs/[slug]': {
    serviceOptions: {
      transformResponseOptions: {
        stopPaths: ['data.custom_fields'], // because the keys are the field names, eg {"Someone's stuff": "Foo"}
      },
    },
  },
  '/api/v1/employer/job-catalog/schema': {
    serviceOptions: {
      transformJsonSchema: true,
    },
  },
  '/api/v1/employer/leave-policies': {},
  '/api/v1/employer/leave-policies/[policySlug]': {},
  '/api/v1/employer/leave-policies/[policySlug]/variants': {},
  '/api/v1/employer/leave-policies/[policySlug]/variants/[variantSlug]': {},
  '/api/v1/employer/legal-entities': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/administrative-details': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/auto-pay-configs': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/bank-account': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/bank-accounts': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/beneficiary-accounts': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/chart-of-accounts': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/chart-of-accounts/[slug]': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/chart-of-accounts/download': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/compliance-settings': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/contractor-countries': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/contractor-payments/pay-in-eligibility': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/document-categories': {
    serviceOptions: {},
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/documents/gusto': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/ebury-customer': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/implementation-fee': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/mosey/account-setup/schema': {
    serviceOptions: {
      transformJsonSchema: true,
    },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/nium-beneficiaries': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/nium-beneficiary-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/nium-eligibility': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/pay-schedule': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/pay-schedule-calendar': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/payment-methods': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/payment-methods/[paymentMethodSlug]/scheduled-invoices':
    {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/payroll-funding-currencies': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/pension-details-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/pension-schemes': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/prefunding-orders/available-amounts': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/prefunding-orders/orders-and-usages': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/remote-payments-settings': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/settings': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/signatory-details': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/state-tax-requirements/[state]': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/state-tax-requirements/status': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/stripe-platform/[stripePaymentMethodType]': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/trusted-beneficiaries': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/trusted-beneficiaries/[beneficiarySlug]': {},
  '/api/v1/employer/legal-entities/compliance-settings': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employer/legal-entities/payment-methods': {},
  '/api/v1/employer/legal-entities/payment-methods/gusto-ach': {},
  '/api/v1/employer/legal-entity-pay-elements': {},
  '/api/v1/employer/managers': {},
  '/api/v1/employer/marketplace/token': {},
  '/api/v1/employer/mobility/[contractEligibilitySlug]/mobility-assistance-status': {},
  '/api/v1/employer/mobility/remote-work-request': {},
  '/api/v1/employer/mobility/remote-work-request/[slug]': {},
  '/api/v1/employer/mobility/travel-letter-request': {},
  '/api/v1/employer/mobility/travel-letter-request/[slug]': {},
  '/api/v1/employer/nium-kyb/entity-onboarding/applicant-details': {},
  '/api/v1/employer/nium-kyb/entity-onboarding/business-details': {},
  '/api/v1/employer/nium-kyb/entity-onboarding/compliance-details': {},
  '/api/v1/employer/nium-kyb/entity-onboarding/region': {},
  '/api/v1/employer/nium-kyb/entity-onboarding/stakeholders': {},
  '/api/v1/employer/nium-kyb/fetch-corporate-constants': {},
  '/api/v1/employer/nium-kyb/onboarding-case': {},
  '/api/v1/employer/nium-kyb/public-company-details': {},
  '/api/v1/employer/nium-kyb/rfis': {},
  '/api/v1/employer/notifications': {},
  '/api/v1/employer/onboarding-notes': {},
  '/api/v1/employer/onboarding-reserves/[slug]': {},
  '/api/v1/employer/onboarding/company/team-members': {},
  '/api/v1/employer/onboarding/eligibility-questionnaire': {},
  '/api/v1/employer/onboarding/eligibility-questionnaire/schema': {
    serviceOptions: { transformJsonSchema: true },
  },
  '/api/v1/employer/onboarding/employments/[employmentSlug]/custom-fields': {},
  '/api/v1/employer/onboarding/employments/[employmentSlug]/pricing-plan/eor': {},
  '/api/v1/employer/onboarding/employments/[productEmploymentType]/[countryCode]/schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employer/onboarding/employments/[slug]': {},
  '/api/v1/employer/onboarding/employments/[slug]/contract-details': {},
  '/api/v1/employer/onboarding/employments/[slug]/eligible-discounts': {},
  '/api/v1/employer/onboarding/employments/[slug]/onboarding-status': {},
  '/api/v1/employer/onboarding/pre-hire-check/[employmentSlug]': {},
  '/api/v1/employer/org-chart': {},
  '/api/v1/employer/org-chart/account-link-suggestions': {},
  '/api/v1/employer/outstanding-payments-and-refunds': {},
  '/api/v1/employer/outstanding-payments/[outstandingPaymentSlug]/compatible-payment-methods': {},
  '/api/v1/employer/outstanding-payments/[slug]': {},
  '/api/v1/employer/outstanding-payments/[slug]/payment-timeline': {},
  '/api/v1/employer/outstanding-refunds/[slug]': {},
  '/api/v1/employer/paid-timeoff-days': {},
  '/api/v1/employer/payment-methods': {},
  '/api/v1/employer/payment-methods/gusto-ach': {},
  '/api/v1/employer/payroll-inputs': {},
  '/api/v1/employer/payroll-inputs/totals': {},
  '/api/v1/employer/payroll-outputs': {},
  '/api/v1/employer/payroll-outputs/[slug]/payslip-files': {},
  '/api/v1/employer/payroll-reports': {},
  '/api/v1/employer/payroll-runs': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/change-events': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/contracts/[contractSlug]/output-pay-elements-breakdown':
    {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/contracts/[contractSlug]/pay-details-totals': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/employee-pay-details/aggregates/[contractSlug]':
    {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/failed-payouts': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/pay-in-details': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payment-file/download': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payment-status': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payment-summary': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payouts': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payroll-outputs/pay-elements/[contractSlug]': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payroll-receipt-summary': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payroll-run-comments': {},
  '/api/v1/employer/payroll-runs/[payrollRunSlug]/payroll-run-files': {},
  '/api/v1/employer/payroll-runs/[slug]': {},
  '/api/v1/employer/payroll-runs/[slug]/contracts': {},
  '/api/v1/employer/payroll-runs/[slug]/expenses': {},
  '/api/v1/employer/payroll-runs/[slug]/incentives': {},
  '/api/v1/employer/payroll-runs/[slug]/payroll-receipt-line-items': {},
  '/api/v1/employer/payroll-runs/[slug]/timeoff': {},
  '/api/v1/employer/payroll/expected-payments': {},
  '/api/v1/employer/permissions': {
    serviceOptions: { transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/employer/potential-managers': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/prices/global-payroll': {},
  '/api/v1/employer/pricing-plans/[planSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/pricing/product-catalog/product-prices': {},
  '/api/v1/employer/pricing/products': {},
  '/api/v1/employer/pricing/subscriptions': {},
  '/api/v1/employer/probation-extension/calculate': {},
  '/api/v1/employer/projects': {},
  '/api/v1/employer/projects/[projectSlug]': {},
  '/api/v1/employer/projects/eligible-leads': {},
  '/api/v1/employer/remote-equity/employees': {},
  '/api/v1/employer/remote-equity/employments/[slug]': {},
  '/api/v1/employer/remote-equity/manager-summary': {},
  '/api/v1/employer/remote-equity/owner-summary': {},
  '/api/v1/employer/remote-equity/status': {},

  /**
   * @deprecated this endpoint will be removed in favour of '/api/v1/employer/legal-entities/[legalEntitySlug]/remote-payments-settings'
   */
  '/api/v1/employer/remote-payments-settings': {},
  '/api/v1/employer/reporting-lines/account-link-suggestions': {},
  '/api/v1/employer/risk-reserves/[slug]/proof-of-payment': {},
  '/api/v1/employer/roles': {},
  '/api/v1/employer/roles/[slug]': {
    serviceOptions: {
      transformResponseOptions: { stopPaths: ['data.permissions'] },
    },
  },
  '/api/v1/employer/scheduled-contractor-invoices': {},
  '/api/v1/employer/scheduled-contractor-invoices/[slug]': {},
  '/api/v1/employer/smartfield-definitions': {},
  '/api/v1/employer/talent-insights/countries': {},
  '/api/v1/employer/talent-insights/currencies': {},
  '/api/v1/employer/talent-insights/search': {},
  '/api/v1/employer/talent-insights/search/[slug]': {},
  '/api/v1/employer/tce': {},
  '/api/v1/employer/team-plans': {},
  '/api/v1/employer/team-plans/[slug]': {},
  '/api/v1/employer/team-plans/[slug]/seats': {},
  '/api/v1/employer/timeoff': {},
  '/api/v1/employer/timeoff/[timeOffSlug]': {},
  '/api/v1/employer/timeoff/dashboard': {},
  '/api/v1/employer/timeoff/offboarding-paid-timeoff-days': {},
  '/api/v1/employer/timeoff/timeline': {
    serviceOptions: {
      // we don't want dates nor country codes which come in keys to be transformed;
      // we will manually transform the keys at the custom hook level
      // eslint-disable-next-line
      deepTransformResponse: false,
    },
  },
  '/api/v1/employer/timesheets': {},
  '/api/v1/employer/timesheets/[timesheetSlug]': {},
  '/api/v1/employer/timesheets/stats': {},
  '/api/v1/employer/workflow_features': {},
  '/api/v1/employer/workflows': {},
  '/api/v1/employer/workflows/[slug]': {},
  '/api/v1/employer/workflows/[slug]/output-schemas': {},
  '/api/v1/employer/workflows/apps': {},
  '/api/v1/employer/workflows/conditional-suggestions': {},
  '/api/v1/employer/workflows/file-suggestions': {},
  '/api/v1/employer/workflows/output-schemas': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/employer/workflows/slack/channels': {},
  '/api/v1/files/[slug]': {},
  '/api/v1/form_draft/[formName]': {},
  '/api/v1/freelancer/clients': {},
  '/api/v1/holidays': {},
  '/api/v1/identity-verification': {},
  '/api/v1/identity-verification/[inquiryId]': {},
  '/api/v1/identity-verification/inquiry-with-token/[inquiryId]': {},
  '/api/v1/insights': { serviceOptions: {} },
  '/api/v1/insights/upcoming-dates': {},
  '/api/v1/insights/year-to-date-earnings': {},
  '/api/v1/integration/status/[integrationName]': {},
  '/api/v1/knowledge-database/countries': {},
  '/api/v1/knowledge-database/facts/[name]': {},
  '/api/v1/knowledge-database/regions/[regionSlug]': {},
  '/api/v1/legal-entities/[legalEntitySlug]/payments-eligibility': {},
  '/api/v1/legal-entities/payments-eligibility': {},
  '/api/v1/mobility-services': {},
  '/api/v1/mobility-services/[processType]/education-and-job/schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/mobility-services/[processType]/permit-or-visa-details/schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/mobility-services/employee-details/schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/onboarding/invite-info/[token]': {},
  '/api/v1/pay-in-method-fees': {},
  '/api/v1/payments-eligibility/[employmentSlug]': {},
  '/api/v1/product-features': {
    serviceOptions: {
      transformResponseOptions: {
        stopPaths: ['data.access'],
      },
    },
  },
  '/api/v1/prompts': {},
  '/api/v1/referral-link': {},
  '/api/v1/reports': {},
  '/api/v1/reports/billing/[slug]': {},
  '/api/v1/reports/payroll/[slug]': {},
  '/api/v1/reports/people/[slug]': {},
  '/api/v1/reports/people/employees': {},
  '/api/v1/reports/people/headcount-over-time': {},
  '/api/v1/reports/people/leave-and-absence': {},
  '/api/v1/reports/people/span-of-control': {},
  '/api/v1/rivendell/adjustments/review': {},
  '/api/v1/rivendell/annual-plans/[planSlug]': {},
  '/api/v1/rivendell/background-checks': {},
  '/api/v1/rivendell/background-jobs-status/[tag]': {},
  '/api/v1/rivendell/background-jobs/[tag]': {},
  '/api/v1/rivendell/beneficiaries-for/[resourceType]/[resourceSlug]': {},
  '/api/v1/rivendell/benefit-costs': {},
  '/api/v1/rivendell/benefit-costs/external': {},
  '/api/v1/rivendell/benefit-details': {},
  '/api/v1/rivendell/benefit-details/[countrySlug]': {},
  '/api/v1/rivendell/benefit-groups': {},
  '/api/v1/rivendell/benefit-groups/[slug]/benefit-offers/stats': {},
  '/api/v1/rivendell/benefit-offers': {},
  '/api/v1/rivendell/benefit-offers/by-employment': {},
  '/api/v1/rivendell/benefit-offers/by-offer': {},
  '/api/v1/rivendell/benefit-offers/dashboard/missing-benefit-offers': {},
  '/api/v1/rivendell/benefit-offers/dashboard/missing-benefit-offers/companies': {},
  '/api/v1/rivendell/benefit-offers/dashboard/missing-benefit-offers/employments': {},
  '/api/v1/rivendell/benefit-plans': {},
  '/api/v1/rivendell/benefit-plans/[slug]': {},
  '/api/v1/rivendell/benefit-tiers': {},
  '/api/v1/rivendell/benefits': {},
  '/api/v1/rivendell/benefits/external': {},
  '/api/v1/rivendell/benefits/package-manager/benefit-groups/[slug]': {},
  '/api/v1/rivendell/billable-contractor-activities': {},
  '/api/v1/rivendell/billing-documents/[slug]/risk-reserve': {},
  '/api/v1/rivendell/blocked-domains': {},
  '/api/v1/rivendell/bulk-employment-changes/bulk-amendments': {},
  '/api/v1/rivendell/bulk-employment-changes/bulk-amendments/[slug]/bulk-amendment-contract-amendments':
    {},
  '/api/v1/rivendell/carriers': {},
  '/api/v1/rivendell/cash-app/settled-transactions/[currencyCode]': {},
  '/api/v1/rivendell/cash-app/settled-transactions/[currencyCode]/sources': {},
  '/api/v1/rivendell/cash-app/settled-transactions/[currencyCode]/stats': {},
  '/api/v1/rivendell/cash-app/settled-transactions/[settledTransactionSlug]/matches': {},
  '/api/v1/rivendell/companies': {},
  '/api/v1/rivendell/companies/[companySlug]/bulk-create/check-progress': {},
  '/api/v1/rivendell/companies/[companySlug]/bulk-create/employee-of-record/countries': {},
  '/api/v1/rivendell/companies/[companySlug]/bulk-create/employee-of-record/countries/[countryCode]/template':
    {
      serviceOptions: {
        ignoreTransformResponse: true,
        responseType: 'blob',
      },
    },
  '/api/v1/rivendell/companies/[companySlug]/bulk-create/global-payroll/countries/[countryCode]/template':
    {
      serviceOptions: {
        ignoreTransformResponse: true,
        responseType: 'blob',
      },
    },
  '/api/v1/rivendell/companies/[companySlug]/collections-settings': {},
  '/api/v1/rivendell/companies/[companySlug]/company-product-tiers': {},
  '/api/v1/rivendell/companies/[companySlug]/compliance-profile': {},
  '/api/v1/rivendell/companies/[companySlug]/compliance-profiles': {},
  '/api/v1/rivendell/companies/[companySlug]/compliance-requests': {},
  '/api/v1/rivendell/companies/[companySlug]/contractor-subscriptions': {},
  '/api/v1/rivendell/companies/[companySlug]/countries/[countryCode]/default-benefit-offers': {},
  '/api/v1/rivendell/companies/[companySlug]/countries/[countryCode]/default-benefit-offers/scheduled':
    {},
  '/api/v1/rivendell/companies/[companySlug]/countries/[countryCode]/default-benefit-offers/scheduled/schema':
    {
      serviceOptions: {
        transformJsonSchema: {
          excludedFields: [SLUG_REGEX, 'health-group-slug', 'life-group-slug'],
        },
      },
    },
  '/api/v1/rivendell/companies/[companySlug]/countries/[countryCode]/default-benefit-offers/schema':
    {
      serviceOptions: {
        transformJsonSchema: {
          excludedFields: [SLUG_REGEX, 'health-group-slug', 'life-group-slug'],
        },
      },
    },
  '/api/v1/rivendell/companies/[companySlug]/hris/company-admins/linked': {},
  '/api/v1/rivendell/companies/[companySlug]/notes': {},
  '/api/v1/rivendell/companies/[companySlug]/payment-settings': {},
  '/api/v1/rivendell/companies/[companySlug]/severance-policy': {},
  '/api/v1/rivendell/companies/[companySlug]/severance-reserves': {},
  '/api/v1/rivendell/companies/[companySlug]/stats': {},
  '/api/v1/rivendell/companies/[companySlug]/team-members': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[companySlug]/team-members/[userSlug]': {},
  '/api/v1/rivendell/companies/[companySlug]/vat-validation': {},
  '/api/v1/rivendell/companies/[slug]': {},
  '/api/v1/rivendell/companies/[slug]/compliance-settings': {},
  '/api/v1/rivendell/companies/[slug]/documents': {},
  '/api/v1/rivendell/companies/[slug]/pricing-eligibility': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/rivendell/companies/[slug]/roles': {},
  '/api/v1/rivendell/companies/[slug]/salesforce': {},
  '/api/v1/rivendell/companies/[slug]/sso_config': {},
  '/api/v1/rivendell/companies/[slug]/tags': {},
  '/api/v1/rivendell/compliance-request-item-templates': {},
  '/api/v1/rivendell/compliance/industry-categories': {},
  '/api/v1/rivendell/compliance/onboarding-reserves/country-policies': {},
  '/api/v1/rivendell/compliance/onboarding-reserves/industry-sector-policies': {},
  '/api/v1/rivendell/consolidated-service-invoices/[invoiceReportSlug]': {},
  '/api/v1/rivendell/contract-documents': {},
  '/api/v1/rivendell/contract-documents/[slug]': {
    serviceOptions: { transformResponseOptions: { stopPaths: ['data.input'] } },
  },
  '/api/v1/rivendell/contract-documents/[slug]/comments': {},
  '/api/v1/rivendell/contract-documents/[slug]/reviewers': {},
  '/api/v1/rivendell/contract-documents/automation-events': {},
  '/api/v1/rivendell/contract-documents/signatories': {},
  '/api/v1/rivendell/contract-eligibilities': {},
  '/api/v1/rivendell/contract-eligibilities/e-verify': {},
  '/api/v1/rivendell/contract-eligibilities/expiring-documents': {},
  '/api/v1/rivendell/contract-eligibilities/metrics': {},
  '/api/v1/rivendell/contract-eligibilities/mobility-assistance': {},
  '/api/v1/rivendell/contract-eligibilities/pre-hire-assessment': {},
  '/api/v1/rivendell/contract-templates': {},
  '/api/v1/rivendell/contract-templates/[slug]': {},
  '/api/v1/rivendell/contract-templates/supported-automations': {},
  '/api/v1/rivendell/contract-templates/supported-automations/[automationType]/countries': {},
  '/api/v1/rivendell/contractor-invoices': {},
  '/api/v1/rivendell/contractor-invoices/[slug]': {},
  '/api/v1/rivendell/contractor-invoices/[slug]/payment-timeline': {},
  '/api/v1/rivendell/contractor-invoices/[slug]/receipt': {
    serviceOptions: {
      ignoreTransformResponse: true,
      responseType: 'blob',
    },
  },
  '/api/v1/rivendell/contractors/contractor-agreement-templates/[slug]': {},
  '/api/v1/rivendell/contracts': {},
  '/api/v1/rivendell/contracts/[amendedContractSlug]/salary-increase-validation': {},
  '/api/v1/rivendell/contracts/[slug]': {},
  '/api/v1/rivendell/contracts/[slug]/contract-details': {},
  '/api/v1/rivendell/contracts/[slug]/tax-tasks': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/rivendell/contracts/calculate-period-gross-salary': {},
  '/api/v1/rivendell/cost-approval-requests': {},
  '/api/v1/rivendell/cost-approval-requests/[costApprovalRequestSlug]': {},
  '/api/v1/rivendell/countries': {},
  '/api/v1/rivendell/countries/[countrySlug]/automation-contract-template': {},
  '/api/v1/rivendell/countries/[countrySlug]/prices': {},
  '/api/v1/rivendell/countries/[countrySlug]/severance-policy': {},
  '/api/v1/rivendell/countries/[countrySlug]/statutory-blueprints': {},
  '/api/v1/rivendell/country-payroll-statuses': {},
  '/api/v1/rivendell/custom-field-definitions': {},
  '/api/v1/rivendell/custom-field-definitions/[slug]': {},
  '/api/v1/rivendell/data-exports': {},
  '/api/v1/rivendell/data-exports/[slug]/download': {
    serviceOptions: {
      // eslint-disable-next-line
      deepTransformResponse: false,
      ignoreTransformResponse: true,
      responseType: 'blob',
    },
  },
  '/api/v1/rivendell/data-exports/[slug]/preview': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/rivendell/data-exports/templates': {},
  '/api/v1/rivendell/data-exports/templates/[slug]': {},
  '/api/v1/rivendell/data-exports/templates/[slug]/preview': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/rivendell/data-exports/templates/[slug]/preview-using-template-slug': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false }, // The response keys are field labels
  },
  '/api/v1/rivendell/data-exports/templates/[slug]/template-building-blocks': {},
  '/api/v1/rivendell/default-benefit-offers': {},
  '/api/v1/rivendell/demo-accounts': {},
  '/api/v1/rivendell/demo-accounts/[slug]': {},
  '/api/v1/rivendell/direct-offboardings': {},
  '/api/v1/rivendell/direct-offboardings/[employmentSlug]': {},
  '/api/v1/rivendell/document-comments': {},
  '/api/v1/rivendell/document-requests': {},
  '/api/v1/rivendell/employee-data-elements': {},
  '/api/v1/rivendell/employee-data-elements/[dataElementSlug]': {},
  '/api/v1/rivendell/employee-data-elements/review': {},
  '/api/v1/rivendell/employee-pay-elements': {},
  '/api/v1/rivendell/employee-pay-elements-mapping-probabilities': {},
  '/api/v1/rivendell/employee-pay-elements/[payElementSlug]': {},
  '/api/v1/rivendell/employee-pay-elements/[payElementSlug]/occurrences': {},
  '/api/v1/rivendell/employee-pay-elements/review': {},
  '/api/v1/rivendell/employee-payment-installments': {},
  '/api/v1/rivendell/employee-payment-installments/[slug]/bank-account': {},
  '/api/v1/rivendell/employee-payment-installments/[slug]/notes': {},
  '/api/v1/rivendell/employees': {},
  '/api/v1/rivendell/employees/[userSlug]/paid-timeoff-days': {},
  '/api/v1/rivendell/employment-requests': {},
  '/api/v1/rivendell/employments/[employmentSlug]': {},
  '/api/v1/rivendell/employments/[employmentSlug]/administrative-details': {
    serviceOptions: {
      transformJsonSchema: true,
    },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/available-product-prices': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/beneficiary-accounts': {},
  '/api/v1/rivendell/employments/[employmentSlug]/beneficiary-accounts/[beneficiarySlug]': {},
  '/api/v1/rivendell/employments/[employmentSlug]/benefit-offers': {},
  '/api/v1/rivendell/employments/[employmentSlug]/benefit-offers/scheduled': {},
  '/api/v1/rivendell/employments/[employmentSlug]/benefit-offers/scheduled/schema': {
    serviceOptions: {
      transformJsonSchema: { excludedFields: [SLUG_REGEX, 'health-group-slug', 'life-group-slug'] },
    },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/benefit-offers/schema': {
    serviceOptions: {
      transformJsonSchema: { excludedFields: [SLUG_REGEX, 'health-group-slug', 'life-group-slug'] },
    },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/bill-to-entity/[billToEntitySlug]/pricing-plans':
    {},
  '/api/v1/rivendell/employments/[employmentSlug]/contracts': {},
  '/api/v1/rivendell/employments/[employmentSlug]/federal-tax-details': {},
  '/api/v1/rivendell/employments/[employmentSlug]/nium-beneficiaries': {},
  '/api/v1/rivendell/employments/[employmentSlug]/nium-beneficiaries/[beneficiarySlug]': {},
  '/api/v1/rivendell/employments/[employmentSlug]/nium-beneficiary-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/payment-split': {
    serviceOptions: {
      // we don't want the slugs which come in keys to be transformed
      transformResponseOptions: { stopPaths: ['data.employment_payment_splits'] },
    },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/payment-split-eligibility': {},
  '/api/v1/rivendell/employments/[employmentSlug]/reporting-line': {
    serviceOptions: {
      // eslint-disable-next-line
      deepTransformResponse: false,
    },
  },
  '/api/v1/rivendell/employments/[employmentSlug]/risk-reserves/estimate': {},
  '/api/v1/rivendell/employments/[employmentSlug]/salaries/cutoff-dates': {},
  '/api/v1/rivendell/employments/[employmentSlug]/salary': {},
  '/api/v1/rivendell/employments/[employmentSlug]/severance-reserve': {},
  '/api/v1/rivendell/employments/[employmentSlug]/timeoff-leave-policies': {},
  '/api/v1/rivendell/employments/[employmentSlug]/timeoff-leave-policies-summary': {},
  '/api/v1/rivendell/employments/[employmentSlug]/timeoff-leave-policies-summary/offboarding': {},
  '/api/v1/rivendell/employments/[employmentSlug]/used-timeoff-leave-policies': {},
  '/api/v1/rivendell/employments/[employmentSlug]/work-calendars': {},
  '/api/v1/rivendell/employments/[slug]/pay-elements-summary': {},
  '/api/v1/rivendell/employments/[slug]/pre-hire-check': {},
  '/api/v1/rivendell/expenses': {},
  '/api/v1/rivendell/expenses/[slug]': {},
  '/api/v1/rivendell/expenses/review': {},
  '/api/v1/rivendell/expenses/templates/[expenseTemplate]/[countryCode]': {
    serviceOptions: { transformJsonSchema: { jsonSchemaPath: 'data' } },
  },
  '/api/v1/rivendell/expiring-documents': {},
  '/api/v1/rivendell/expiring-documents/[slug]': {},
  '/api/v1/rivendell/extra-entitlements': {},
  '/api/v1/rivendell/extra-entitlements/[slug]': {},
  '/api/v1/rivendell/files/[slug]': {},
  '/api/v1/rivendell/freelancers': {},
  '/api/v1/rivendell/garnishment-order-types': {},
  '/api/v1/rivendell/garnishments': {},
  '/api/v1/rivendell/garnishments/[slug]': {},
  '/api/v1/rivendell/global-payroll-offboardings/[offboardingSlug]/pay-elements': {},
  '/api/v1/rivendell/health-checks': {},
  '/api/v1/rivendell/holidays': {},
  '/api/v1/rivendell/holidays/[holidaySlug]': {},
  '/api/v1/rivendell/implementation-fees': {},
  '/api/v1/rivendell/implementation-fees/[resourceSlug]': {},
  '/api/v1/rivendell/import-job-types/[name]/json-schema': {
    serviceOptions: {
      // eslint-disable-next-line
      deepTransformResponse: false,
    },
  },
  '/api/v1/rivendell/import-job-types/[name]/template': {
    serviceOptions: {
      ignoreTransformResponse: true,
      responseType: 'blob',
    },
  },
  '/api/v1/rivendell/import-jobs': {},
  '/api/v1/rivendell/import-jobs/[slug]': {
    serviceOptions: {
      transformResponseOptions: importJobsSlugTransformResponseOptions,
    },
  },
  '/api/v1/rivendell/import-jobs/[slug]/errors': {},
  '/api/v1/rivendell/import-jobs/[slug]/errors-report': {},
  '/api/v1/rivendell/import-jobs/[slug]/export-rows-csv': {},
  '/api/v1/rivendell/import-jobs/[slug]/rows': {
    serviceOptions: {
      paramsSerializer: {
        serialize: serializeWithBracketsArray,
      },
    },
  },
  '/api/v1/rivendell/import-jobs/[slug]/rows/find-and-replace': {},
  '/api/v1/rivendell/import-jobs/[slug]/value-corrections-summaries': {},
  '/api/v1/rivendell/import-jobs/[slug]/value-corrections/[fieldName]': {
    serviceOptions: {
      // eslint-disable-next-line
      deepTransformResponse: false,
    },
  },
  '/api/v1/rivendell/incentives': {},
  '/api/v1/rivendell/incentives/[slug]': {},
  '/api/v1/rivendell/incentives/recurring': {},
  '/api/v1/rivendell/incentives/review': {},
  '/api/v1/rivendell/information-requests': {},
  '/api/v1/rivendell/integration-template-pay-elements': {},
  '/api/v1/rivendell/integration-template-pay-elements/[slug]': {},
  '/api/v1/rivendell/inter-company-invoices': {},
  '/api/v1/rivendell/inter-company-invoices/[interCompanyInvoiceSlug]/inter-company-invoice-entry-groups':
    {},
  '/api/v1/rivendell/inter-company-invoices/[interCompanyInvoiceSlug]/inter-company-invoice-entry-groups/[interCompanyInvoiceEntryGroupSlug]/inter-company-invoice-entries':
    {},
  '/api/v1/rivendell/inter-company-invoices/[slug]': {},
  '/api/v1/rivendell/inter-company-remoter-markup': {},
  '/api/v1/rivendell/inter-company-transactions': {},
  '/api/v1/rivendell/inter-company-transactions/[slug]': {},
  '/api/v1/rivendell/invoice-reports': {},
  '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/breakdown-csv': {},
  '/api/v1/rivendell/invoice-reports/[slug]': {},
  '/api/v1/rivendell/invoice-reports/[slug]/partial-items/service-invoices/creditable-data': {},
  '/api/v1/rivendell/invoice-reports/[slug]/risk-reserve': {},
  '/api/v1/rivendell/invoice-reports/[slug]/validators': {},
  '/api/v1/rivendell/knowledge-database/attendance-policies': {},
  '/api/v1/rivendell/knowledge-database/attendance-policies/[policySlug]': {},
  '/api/v1/rivendell/knowledge-database/core-benefits': {},
  '/api/v1/rivendell/knowledge-database/country-salary-configurations': {},
  '/api/v1/rivendell/knowledge-database/country-salary-configurations/[slug]': {},
  '/api/v1/rivendell/knowledge-database/employer-contributions': {},
  '/api/v1/rivendell/knowledge-database/fact-schemas': {},
  '/api/v1/rivendell/knowledge-database/fact-schemas/[name]': {
    serviceOptions: {
      transformJsonSchema: { jsonSchemaPath: 'data.rendered_schema.schema' },
    },
  },
  '/api/v1/rivendell/knowledge-database/facts/[factSlug]': {
    // To be deprecated by /facts/[name]/[slug]. Read Linear task WEB-22
  },
  '/api/v1/rivendell/knowledge-database/timeoff-policies': {},
  '/api/v1/rivendell/knowledge-database/timeoff-policies/[policySlug]': {},
  '/api/v1/rivendell/kyb': {},
  '/api/v1/rivendell/kyb-specialists': {},
  '/api/v1/rivendell/leave-policies': {},
  '/api/v1/rivendell/leave-policies/[policySlug]/variants': {},
  '/api/v1/rivendell/leave-policy-variants': {},
  '/api/v1/rivendell/leave-policy-variants/[leavePolicyVariantSlug]': {},
  '/api/v1/rivendell/legal-entities': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/administrative-details': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/auto-pay-configs': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/bank-account': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/collections-settings': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/compliance-profile': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/compliance-requests': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/compliance-settings': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/document-categories': {
    serviceOptions: {},
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/documents/gusto': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/nium-beneficiaries': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/notes': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/pay-schedule': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/pay-schedule-calendar': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/payments-eligibility': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/payroll-config': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/pension-details-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/pension-schemes': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/prefunding-orders/available-amounts': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/prefunding-orders/orders-and-usages': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/remote-payments-settings': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/settings': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/signatory-details': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/state-tax-requirements/[state]': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/state-tax-requirements/status': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/vat-validation': {},
  '/api/v1/rivendell/legal-entities/[slug]': {},
  '/api/v1/rivendell/legal-entities/[slug]/tags': {},
  '/api/v1/rivendell/legal-entities/kyb': {},
  '/api/v1/rivendell/legal-entity-pay-element-data-source': {},
  '/api/v1/rivendell/legal-entity-pay-element-data-source/[payElementDataSourceSlug]': {},
  '/api/v1/rivendell/legal-entity-pay-element-data-source/details': {},
  '/api/v1/rivendell/legal-entity-pay-element-integration-configuration-schema/[countryCode]': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/rivendell/legal-entity-pay-element-template-collections': {},
  '/api/v1/rivendell/legal-entity-pay-element-template-collections/[slug]': {},
  '/api/v1/rivendell/legal-entity-pay-element-templates': {},
  '/api/v1/rivendell/legal-entity-pay-element-templates/[slug]': {},
  '/api/v1/rivendell/legal-entity-pay-elements': {},
  '/api/v1/rivendell/legal-entity-pay-elements/[payElementSlug]': {},
  '/api/v1/rivendell/marketing/campaigns': {},
  '/api/v1/rivendell/mobility-specialists': {},
  '/api/v1/rivendell/mobility/[contractEligibilitySlug]/mobility-assistance-status': {},
  '/api/v1/rivendell/nium-kyb/onboarding-info/[companySlug]': {},
  '/api/v1/rivendell/nium-kyb/rfis': {},
  '/api/v1/rivendell/nium/contractor-beneficiary-schema': {
    // eslint-disable-next-line
    serviceOptions: { deepTransformResponse: false },
  },
  '/api/v1/rivendell/notifications/custom': {},
  '/api/v1/rivendell/notifications/custom/tags': {},
  '/api/v1/rivendell/off_payslip_costs': {},
  '/api/v1/rivendell/off_payslip_costs/[slug]': {},
  '/api/v1/rivendell/offboardings': {},
  '/api/v1/rivendell/offboardings/[offboardingSlug]': {},
  '/api/v1/rivendell/onboarding-notes': {},
  '/api/v1/rivendell/onboarding/employments/[slug]/administrative-details/skipped-fields': {},
  '/api/v1/rivendell/outstanding-payments/[slug]': {},
  '/api/v1/rivendell/outstanding-payments/creators': {},
  '/api/v1/rivendell/outstanding-refunds/[slug]': {},
  '/api/v1/rivendell/payroll-calendars': {},
  '/api/v1/rivendell/payroll-calendars/[slug]': {},
  '/api/v1/rivendell/payroll-inputs/review-totals': {},
  '/api/v1/rivendell/payroll-outputs': {},
  '/api/v1/rivendell/payroll-outputs/[slug]': {},
  '/api/v1/rivendell/payroll-outputs/[slug]/summary': {},
  '/api/v1/rivendell/payroll-runs': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/contracts/[contractSlug]/output-pay-elements-breakdown':
    {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/contracts/[contractSlug]/pay-details-totals': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/employee-pay-details/aggregates/[contractSlug]':
    {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/employee-payroll-overviews': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/employee-payroll-overviews/[slug]/reconciliation-data':
    {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/employees-direct-offboarding-status': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/failed-requests': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/gross-to-net-files/[fileSlug]': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/notes': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payments-overview': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-outputs/pay-elements/[contractSlug]': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-run-comments': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/reconciliation': {
    serviceOptions: {
      transformResponseOptions: {
        stopPaths: ['data.overviews.comments'], // comment keys should preserve snakeCase
      },
    },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/reconciliation/legal-entity-pay-elements': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/review-offboarding-pay-elements': {},
  '/api/v1/rivendell/payroll-runs/[slug]': {
    serviceOptions: { transformResponseOptions: { stopPaths: ['data.validations'] } },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/beneficiary-totals': {},
  '/api/v1/rivendell/payroll-runs/[slug]/billing/breakdown': {},
  '/api/v1/rivendell/payroll-runs/[slug]/billing/employee-breakdown': {},
  '/api/v1/rivendell/payroll-runs/[slug]/billing/memos': {},
  '/api/v1/rivendell/payroll-runs/[slug]/billing/memos/[memoSlug]': {},
  '/api/v1/rivendell/payroll-runs/[slug]/billing/overview': {
    serviceOptions: { transformResponseOptions: { stopPaths: ['data.validations'] } },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/billing/total-payroll-cost': {},
  '/api/v1/rivendell/payroll-runs/[slug]/payroll-run-files': {},
  '/api/v1/rivendell/payroll-runs/[slug]/totals': {},
  '/api/v1/rivendell/payroll-runs/monthly-input-reconciliation-rate': {},
  '/api/v1/rivendell/payslips': {},
  '/api/v1/rivendell/payslips/[slug]': {},
  '/api/v1/rivendell/payslips/[slug]/evaluate': {},
  '/api/v1/rivendell/payslips/[slug]/preview-match': {},
  '/api/v1/rivendell/payslips/parsing-rules-options': {},
  '/api/v1/rivendell/permissions': {
    serviceOptions: { transformResponseOptions: { stopPaths: ['data'] } },
  },
  '/api/v1/rivendell/persisted-config-settings': {},
  '/api/v1/rivendell/persisted-config-settings/[persistedConfigSettingSlug]/schema': {
    serviceOptions: {
      transformJsonSchema: true,
    },
  },
  '/api/v1/rivendell/persisted-config-settings/[slug]': {},
  '/api/v1/rivendell/persisted-config-settings/domains': {},
  '/api/v1/rivendell/prefunding-orders': {},
  '/api/v1/rivendell/prefunding-orders-and-usages': {},
  '/api/v1/rivendell/pricing/[companySlug]/subscriptions': {},
  '/api/v1/rivendell/pricing/country-product-prices': {},
  '/api/v1/rivendell/pricing/discounts': {},
  '/api/v1/rivendell/pricing/pricing-revisions': {},
  '/api/v1/rivendell/pricing/product-prices': {},
  '/api/v1/rivendell/pricing/products': {},
  '/api/v1/rivendell/pricing/team-plan-fees': {},
  '/api/v1/rivendell/recurring-contractor-invoices': {},
  '/api/v1/rivendell/recurring-contractor-invoices/[slug]': {},
  '/api/v1/rivendell/remote-paid-services': {},
  '/api/v1/rivendell/remote-paid-services/[serviceCostSlug]': {},
  '/api/v1/rivendell/remote-work-request': {},
  '/api/v1/rivendell/remote-work-request/[requestSlug]': {},
  '/api/v1/rivendell/reports/vendor-report-config': {
    serviceOptions: {
      // eslint-disable-next-line
      deepTransformResponse: false,
    },
  },
  '/api/v1/rivendell/risk-reserves/[slug]/proof-of-payment': {},
  '/api/v1/rivendell/roles': {},
  '/api/v1/rivendell/roles/[slug]': {
    serviceOptions: {
      transformResponseOptions: { stopPaths: ['data.permissions'] },
    },
  },
  '/api/v1/rivendell/scheduled-contractor-invoices': {},
  '/api/v1/rivendell/scheduled-contractor-invoices/[slug]': {},
  '/api/v1/rivendell/severance-reserves/countries': {},
  '/api/v1/rivendell/smartfield-definitions': {},
  '/api/v1/rivendell/stop-work-orders/employments': {},
  '/api/v1/rivendell/tags': {},
  '/api/v1/rivendell/talent-insights/countries': {},
  '/api/v1/rivendell/talent-insights/currencies': {},
  '/api/v1/rivendell/talent-insights/plans': {},
  '/api/v1/rivendell/talent-insights/search': {},
  '/api/v1/rivendell/talent-insights/search/[slug]': {},
  '/api/v1/rivendell/task-assignments': {},
  '/api/v1/rivendell/tax-notes': {},
  '/api/v1/rivendell/tce': {},
  '/api/v1/rivendell/team-memberships/[teamMembershipSlug]/timeline-events': {},
  '/api/v1/rivendell/team-plans': {},
  '/api/v1/rivendell/team-plans/[slug]': {},
  '/api/v1/rivendell/team-plans/[slug]/seats': {},
  '/api/v1/rivendell/time-trackings/pay-element-mappings': {},
  '/api/v1/rivendell/timeoff': {},
  '/api/v1/rivendell/timeoff/[timeOffSlug]': {},
  '/api/v1/rivendell/timeoffs/review': {},
  '/api/v1/rivendell/timesheets': {},
  '/api/v1/rivendell/timesheets/[timesheetSlug]': {},
  '/api/v1/rivendell/transaction-receipts': {},
  '/api/v1/rivendell/travel-letter-request': {},
  '/api/v1/rivendell/travel-letter-request/[requestSlug]': {},
  '/api/v1/rivendell/users': {},
  '/api/v1/rivendell/users/[slug]': {},
  '/api/v1/rivendell/users/[slug]/employments': {},
  '/api/v1/rivendell/users/[slug]/employments/documents': {},
  '/api/v1/rivendell/users/[slug]/employments/onboarding-documents': {},
  '/api/v1/rivendell/users/[slug]/tags': {},
  '/api/v1/rivendell/users/[slug]/team-member-companies': {},
  '/api/v1/rivendell/users/[userSlug]/notes': {},
  '/api/v1/rivendell/users/[userSlug]/payment-preferences': {},
  '/api/v1/rivendell/users/[userSlug]/potential-duplicates': {},
  '/api/v1/rivendell/users/[userSlug]/withdrawal-methods': {},
  '/api/v1/rivendell/vat-groups': {},
  '/api/v1/rivendell/vat-groups/[slug]': {},
  '/api/v1/session/accounts': {},
  '/api/v1/session/current-user': {},
  '/api/v1/session/ws/token': {},
  '/api/v1/standard-job-titles-with-options': {
    serviceOptions: {
      service: 'GROND',
      transformResponseOptions: { stopPaths: ['data.standard_job_titles'] },
    },
  },
  '/api/v1/stripe-connect/account': {},
  '/api/v1/stripe-connect/balances': {},
  '/api/v1/stripe-connect/dashboard-link': {},
  '/api/v1/stripe-connect/eligibility': {},
  '/api/v1/stripe-connect/payout-settings': {},
  '/api/v1/stripe/current-platform': {},
  '/api/v1/support-interactions/zendesk/tickets': {},
  '/api/v1/support-interactions/zendesk/tickets/comments': {},
  '/api/v1/table-views': {},
  '/api/v1/table-views/[tableViewSlug]': {},
  '/api/v1/timeline': {},
  '/api/v1/updates': {},
  '/api/v1/updates/notifications': {},
  '/api/v1/updates/todos': {},
  '/api/v1/work-calendars': {},
  '/api/v2/employee/company-settings': {},
  '/api/v2/employer/[countryCode]/employment-eligibility-basic-information': {},
  '/api/v2/employer/[countryCode]/pre-hire-assessment-controls': {},
  '/api/v2/employer/company-settings': {},
  '/api/v2/employer/hris/departments': {},
  '/api/v2/employer/hris/departments/[slug]': {},
  '/api/v2/employer/hris/me/reports': {},
  '/api/v2/employer/legal-entities/[legalEntitySlug]/documents': {
    serviceOptions: { asFormData: false },
  },
  '/api/v2/employer/onboarding/job-title-eligibility': {},
  '/api/v2/rivendell/hris/company-admin/email/registry': {
    serviceOptions: { asFormData: false },
  },
  '/api/v2/rivendell/knowledge-database/facts': {
    serviceOptions: {
      transformResponseOptions: { stopPaths: ['data.dimensions'] },
    },
  },
  '/api/v2/rivendell/knowledge-database/facts/[identifier]': {
    serviceOptions: {
      transformResponseOptions: { stopPaths: ['data.metaschema', 'data.dimensions'] },
    },
  },
  '/api/v2/rivendell/knowledge-database/facts/[identifier]/fact-values': {
    serviceOptions: {
      transformParamsOptions: {
        deep: false,
        exclude: ['dimensions', /value/],
      },
      transformResponseOptions: {
        stopPaths: [
          'data.data.fact_value.dimensions',
          'data.data.fact_value.value_raw',
          'data.data.fact_value.dimension_slugs',
        ],
      },
    },
  },
  '/api/v2/rivendell/knowledge-database/facts/[identifier]/fact-values/[slug]': {
    serviceOptions: {
      transformResponseOptions: {
        stopPaths: [
          'data.fact_value.dimensions',
          'data.fact_value.value_raw',
          'data.fact_value.dimension_slugs',
        ],
      },
    },
  },
  '/api/v2/rivendell/knowledge-database/facts/[identifier]/fact-values/[slug]/timeline': {
    serviceOptions: {
      transformResponseOptions: {
        stopPaths: [
          'data.fact_value.dimensions',
          'data.fact_value.value_raw',
          'data.fact_value.dimension_slugs',
        ],
      },
    },
  },
  '/api/v2/rivendell/knowledge-database/facts/[identifier]/fact-values/[slug]/version/[versionSlug]':
    {
      serviceOptions: {
        transformResponseOptions: {
          stopPaths: [
            'data.fact_value.dimensions',
            'data.fact_value.value_raw',
            'data.fact_value.dimension_slugs',
          ],
        },
      },
    },
  '/api/v2/rivendell/legal-entities/[legalEntitySlug]/documents': {
    serviceOptions: { asFormData: false },
  },
  '/api/v2/user-profiles': {},
  '/boba/api/v1/admin/companies': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/admin/companies/[companySlug]/jobs': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/admin/companies/[companySlug]/profiles': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/admin/jobs': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/admin/jobs/[jobSlug]': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/admin/metrics/companies/[companySlug]/jobs': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/company-profiles': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/departments': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/hiring-managers': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/[jobSlug]/review': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/[slug]': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/metrics': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects/[prospectSlug]': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects/[prospectSlug]/interview': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects/[prospectSlug]/resume': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects/metrics': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/profiles/available-languages': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/sourcing/autocomplete': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/sourcing/bookmarked': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/sourcing/previous-searches': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/sourcing/prospects': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/sourcing/search': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/subscriptions': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/subscriptions/plans': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/public/countries': {
    serviceOptions: {},
  },
  '/boba/api/v1/public/countries/regions': {
    serviceOptions: {},
  },
  '/boba/api/v1/public/countries/regions/timezones': {
    serviceOptions: {},
  },
  '/boba/api/v1/public/currencies': {
    serviceOptions: {},
  },
  '/dev/feature-flags-tooling': {},
  '/dev/legacy-feature-flags': {},
};
