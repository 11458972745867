import { Content, Overlay, Root, Portal } from '@radix-ui/react-dialog';
import { SROnly, themeV2 } from '@remote-com/norma';
import { IconTimes } from '@remote-com/norma/icons/IconTimes';
import { AnimatePresence } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';
import type { PropsWithChildren, ReactNode, MouseEvent, ComponentProps } from 'react';
import type { CSSObject } from 'styled-components';

import { Button } from '@/src/components/Button';
import { useIsFormDirty } from '@/src/components/Form/hooks';
import {
  AnimatedWrapper,
  ModalContent,
  ModalOverlay,
  CloseButton,
  ModalInnerContent,
  Title,
  Description,
  Body,
  Footer,
} from '@/src/components/ImmersiveModal/ImmersiveModal.styled';
import { ConfirmCloseModal } from '@/src/components/Modal';
import { isTest } from '@/src/helpers/general';
import { useIsMounted } from '@/src/hooks/useIsMounted';

export const fadeAnimationProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.25, ease: [0.17, 0.67, 0.96, 0.61] },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.25, ease: [0.17, 0.67, 0.96, 0.61] },
  },
};

export const slideAnimationProps = {
  initial: {
    opacity: 0,
    translateY: '20%',
    scale: 0.98,
  },
  animate: {
    opacity: 1,
    translateY: '0%',
    scale: 1,
    transition: { duration: 0.4, ease: [0.25, 0.75, 0.5, 1] },
  },
  exit: {
    opacity: 0,
    translateY: '20%',
    scale: 0.98,
    transition: { duration: 0.25, ease: [0.25, 0.75, 0.5, 1] },
  },
};

type ImmersiveProps = {
  'data-testid'?: string;
  /** Custom text for the cancel button */
  cancelButtonText?: string;
  /** When true, the submit button displays as loading */
  confirmLoading?: boolean;
  description?: ReactNode;
  /** When true, the submit button is shown disabled */
  disableSubmitBtn?: boolean;
  /** Override the modal footer with a custom element */
  footer?: ReactNode;
  /** Passed to submit button if 'shouldSubmit' is true */
  formName?: string;
  /** Required for aria-label, and used for display when showHeaderTitle is true */
  headerTitle: string | ReactNode;
  onCancel: () => void;
  /**
   * Callback function executed right before the modal begins the dismissal process.
   * Useful for performing actions like redirects or clean-ups immediately before
   * the modal closes.
   */
  onBeforeDismiss?: () => Promise<void>;
  /**
   * Function to invoke a modal, toast or any information that has to be shown before closing the modal,
   * if an APIcall has to be done then use onBeforeDismiss prop.
   */
  onClose?: (handleStartDismiss: () => void) => void;
  onCancelButtonClick?: () => void;
  onSave?: () => void;
  /** Custom text for the submit button */
  saveButtonText?: string;
  shouldShowConfirmCloseModal?: boolean;
  /** When true, the submit button turns into type="submit" */
  shouldSubmit?: boolean;
  /** When true, and default footer is visible, shows cancel button (default to true) */
  showCancelButton?: boolean;
  showFooter?: boolean;
  /** Controls visibility (if false it is not rendered) */
  visible?: boolean;
  /** For passing down styled-component classes */
  className?: string;
  maxWidth?: string;
  additionalStyle?: {
    title?: CSSObject;
    description?: CSSObject;
    body?: CSSObject;
    wrapper?: CSSObject;
    content?: CSSObject;
    innerContent?: CSSObject;
    footer?: CSSObject;
  };
  /**
   * For customized styling
   *
   * Technical note: backgroundColor is used at "bg" prop of ModalContent,
   * which is based on styled-system. However, it also supports raw values
   * (such as #FFF), thus the `(string & {})` part.
   */
  backgroundColor?: ComponentProps<typeof ModalContent>['bg'] | (string & {});
  canDismissViaBackdrop?: () => boolean;
  canDismissViaEscape?: () => boolean;
  showCloseButton?: boolean;
  showHeaderTitle?: boolean;
};

export const ImmersiveModal = ({
  'data-testid': dataTestid = 'modal',
  cancelButtonText = 'Cancel',
  children,
  confirmLoading = false,
  description,
  disableSubmitBtn = false,
  footer,
  formName,
  headerTitle,
  onCancel,
  onCancelButtonClick,
  onBeforeDismiss,
  onClose,
  onSave,
  saveButtonText = 'Save',
  shouldShowConfirmCloseModal = true,
  shouldSubmit = true,
  showCancelButton = true,
  showFooter = true,
  visible = false,
  className, // pass styled component classes down
  maxWidth = '642px',
  additionalStyle,
  backgroundColor,
  canDismissViaBackdrop = () => true,
  canDismissViaEscape = () => true,
  showCloseButton = true,
  showHeaderTitle = true,
}: PropsWithChildren<ImmersiveProps>) => {
  // NOTE: When using `react-url-modal`, the component will be removed when `onCancel()`` is executed.
  // This prevents the exit animation from playing. To counter this, we have to handle state internally
  // and execute `onCancel()` only after the animation has completed.
  const [isOpen, setIsOpen] = useState(visible);
  const isMounted = useIsMounted();
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const isFormDirty = useIsFormDirty({ active: visible });

  const triggerElement = useRef<HTMLElement | null>(null);
  const focusTimeoutRef = useRef<number>();

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      triggerElement.current = document.activeElement as HTMLElement;
    }
  }, []);

  // When the modal is closed, we focus back on the element that triggered it.
  // Radix-ui's Dialog would implement this by default, but it only does so if we use the Dialog.Trigger component, which we don't.
  const focusTriggerElement = () => {
    if (typeof document === 'undefined') return;

    if (focusTimeoutRef.current) {
      clearTimeout(focusTimeoutRef.current);
    }
    // Explanation: While a value of 0 might be enough (targeting the next render cycle), on some browsers this
    // was not enough. A value of 10ms seems to work to ensure that the modal is fully closed before focusing
    // back on the trigger element.
    focusTimeoutRef.current = window.setTimeout(() => {
      if (triggerElement.current) {
        triggerElement.current.focus();
      }
    }, 10);
  };

  const handleAnimationComplete = () => {
    focusTriggerElement();
    onCancel();
  };

  const handleStartDismiss = async () => {
    if (onBeforeDismiss) await onBeforeDismiss();
    if (isMounted()) setIsOpen(false);
  };

  const closeModalHandler = (
    event: Event | MouseEvent<HTMLButtonElement>,
    cb: () => void = handleStartDismiss
  ) => {
    // If there's a dirty form in the modal, we show a confirmation dialog before closing it,
    // otherwise we close the modal immediately
    if (isFormDirty && shouldShowConfirmCloseModal) {
      event.preventDefault();
      event.stopPropagation();
      setShowConfirmCloseModal(true);
    } else {
      cb();
    }
  };

  const TitleTag = showHeaderTitle ? Title : SROnly;

  return (
    <>
      <AnimatePresence onExitComplete={handleAnimationComplete}>
        {isOpen && (
          <Root open={isOpen} modal>
            <Portal>
              <Overlay asChild>
                <ModalOverlay
                  data-testid={dataTestid}
                  className={className}
                  {...(isTest() ? {} : fadeAnimationProps)}
                >
                  <Content
                    asChild
                    onEscapeKeyDown={(event) => canDismissViaEscape() && closeModalHandler(event)}
                    onPointerDownOutside={(event) => {
                      if (event.currentTarget instanceof Element) {
                        // Prevent dismissing the modal when clicking on toast messages
                        if (event.currentTarget.closest('.toast')) return;
                      }

                      return canDismissViaBackdrop() && closeModalHandler(event);
                    }}
                    tabIndex={undefined} // by default, radix dialog sets a tabindex of -1, which traps the focus so we need to disable this. No a11y is harmed.
                  >
                    <AnimatedWrapper
                      aria-modal="true"
                      aria-labelledby="immersive-modal-header-title"
                      additionalStyle={
                        additionalStyle?.wrapper || {
                          backgroundColor: themeV2.colors.blank,
                        }
                      }
                      {...(isTest() ? {} : slideAnimationProps)}
                    >
                      <ModalContent
                        bg={backgroundColor}
                        pb={11}
                        additionalStyle={additionalStyle?.content}
                      >
                        {showCloseButton && (
                          <CloseButton
                            Icon={IconTimes}
                            aria-label="Close modal"
                            data-testid="modal-close-button"
                            tone="secondary"
                            variant="ghost"
                            onClick={
                              onClose ? () => onClose(handleStartDismiss) : closeModalHandler
                            }
                          />
                        )}

                        <ModalInnerContent
                          maxWidth={maxWidth}
                          additionalStyle={additionalStyle?.innerContent}
                        >
                          <TitleTag
                            id="immersive-modal-header-title"
                            $hasDescription={!!description}
                            $titleStyle={additionalStyle?.title || {}}
                          >
                            {headerTitle}
                          </TitleTag>

                          {description && (
                            <Description $descriptionStyle={additionalStyle?.description || {}}>
                              {description}
                            </Description>
                          )}
                          <Body
                            additionalStyle={
                              additionalStyle?.body || { padding: `${themeV2.space[7]}px` }
                            }
                          >
                            {children}
                          </Body>
                          {showFooter && (
                            <Footer additionalStyle={additionalStyle?.footer}>
                              {footer || (
                                <>
                                  {showCancelButton && (
                                    <Button
                                      data-testid="modal-cancel-button"
                                      type="button"
                                      size="lg"
                                      variant="outline"
                                      onClick={onCancelButtonClick || closeModalHandler}
                                    >
                                      {cancelButtonText}
                                    </Button>
                                  )}
                                  <Button
                                    data-testid="modal-save-button"
                                    isLoading={confirmLoading}
                                    disabled={confirmLoading || disableSubmitBtn}
                                    tone="primary"
                                    size="lg"
                                    {...(shouldSubmit
                                      ? {
                                          type: 'submit',
                                          form: formName,
                                        }
                                      : {
                                          onClick: onSave,
                                          type: 'button',
                                        })}
                                  >
                                    {saveButtonText}
                                  </Button>
                                </>
                              )}
                            </Footer>
                          )}
                        </ModalInnerContent>
                      </ModalContent>
                    </AnimatedWrapper>
                  </Content>
                </ModalOverlay>
              </Overlay>
            </Portal>
          </Root>
        )}
      </AnimatePresence>
      <ConfirmCloseModal
        visible={showConfirmCloseModal}
        onSave={() => {
          setShowConfirmCloseModal(false);
          handleStartDismiss();
        }}
        onCancel={() => {
          setShowConfirmCloseModal(false);
        }}
      />
    </>
  );
};
