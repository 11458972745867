import Tippy from '@tippyjs/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/svg-arrow.css';
import { rem } from 'polished';
import type { CSSObject, FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { Box } from '../../layout';

import type { TooltipProps } from './Tooltip';

export const Label = styled(Box)<{ $styles: FlattenSimpleInterpolation | CSSObject }>`
  text-align: center;
  line-height: 1.5;

  ${({ $styles }) => $styles}
`;

export const StyledTippy = styled(Tippy)<{
  $styles: FlattenSimpleInterpolation | CSSObject;
  $type: TooltipProps['type'];
}>(
  ({ theme, $type, $styles }) => css`
    ${theme.typography.xs}
    border-radius: ${theme.borderRadius['2xs']};
    padding: ${theme.space[3]}px ${theme.space[4]}px;
    border: 1px solid;
    box-shadow: ${theme.shadows.small};
    font-size: ${rem('12px')};
    white-space: pre-line;

    .tippy-content {
      /* Avoids tippy arrow container overlapping the content */
      position: relative;
      z-index: 1;
      max-height: 300px;
      overflow: auto;
    }

    &[data-animation='fade'] {
      opacity: 0;

      &[data-state='visible'] {
        opacity: 1;
      }
    }

    &[data-placement='top'] {
      .tippy-svg-arrow {
        bottom: 1px;
      }
    }

    &[data-placement='bottom'] {
      .tippy-svg-arrow {
        top: 1px;
      }
    }

    &[data-placement='right'] {
      .tippy-svg-arrow {
        left: 0.5px;
      }
    }

    &[data-placement='left'] {
      .tippy-svg-arrow {
        right: 0.5px;
      }
    }

    &[data-placement='top-start'] .tippy-svg-arrow,
    &[data-placement='top-end'] .tippy-svg-arrow {
      bottom: ${$type === 'caption' ? '1.3px' : '1px'};
    }

    &[data-placement='bottom-start'] .tippy-svg-arrow,
    &[data-placement='bottom-end'] .tippy-svg-arrow {
      top: ${$type === 'caption' ? '1.3px' : '1px'};
    }

    ${() => {
      switch ($type) {
        case 'caption':
          return css`
            border-radius: ${theme.borderRadius.full};
            background-color: ${theme.colors.grey[800]};
            color: ${theme.colors.blank};
            border-color: ${theme.colors.grey[800]};
            padding: ${theme.space[1]}px ${theme.space[3]}px;

            .tippy-svg-arrow {
              path:first-child {
                fill: ${theme.colors.grey[800]};
              }

              path:last-child {
                fill: ${theme.colors.grey[800]};
              }
            }
          `;
        case 'default':
        default:
          return css`
            background-color: ${theme.colors.blank};
            color: ${theme.colors.grey[800]};
            border-color: ${theme.colors.grey[200]};

            .tippy-svg-arrow {
              path:first-child {
                fill: ${theme.colors.blank};
              }

              path:last-child {
                fill: ${theme.colors.grey[200]};
              }
            }
          `;
      }
    }}

    ${$styles}
  `
);
