import { useField } from 'formik';
import type { ComponentProps } from 'react';
import type { UseQueryOptions } from 'react-query';
import { useQueries } from 'react-query';

import type { LegalEntityPayElementApi } from '@/src/api/config/employ/legalEntityPayElement.types';
import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import { useUserCan } from '@/src/components/UserCan';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import { makeGet } from '@/src/services/ApiClient/functions/makeRequest';

import { LepeOption } from './LegalEntityPayElementSelect';

type Props = ComponentProps<typeof PaginatedSearchSelectField> & {
  filters: LegalEntityPayElementApi.Admin.LegalEntityPayElementsRequest['queryParams'];
};

export function useLEPEs(lepeSlugs: string[] = [], options: UseQueryOptions<unknown> = {}) {
  const { userCan } = useUserCan();

  const results = useQueries(
    lepeSlugs.map((slug) => ({
      queryKey: [
        '/api/v1/rivendell/legal-entity-pay-elements/[payElementSlug]',
        { pathParams: { slug } },
      ],
      queryFn: async () =>
        (
          await makeGet('/api/v1/rivendell/legal-entity-pay-elements/[payElementSlug]', {
            pathParams: {
              payElementSlug: slug,
            },
          })
        ).data,
      enabled:
        userCan('read', Resources.legal_entity_pay_elements) &&
        // If options.enabled was sent use it, if it was not sent we assume enabled=true
        (options?.enabled ?? true),
    }))
  );

  return results;
}

export function LegalEntityPayElementSelectMultiselect({
  filters = {},
  ...selectFieldProps
}: Props) {
  const [{ value }] = useField(selectFieldProps.name);
  const lepeSlugs = value?.map((option: string) => option).filter(Boolean) ?? [];
  const lepeQueries = useLEPEs(lepeSlugs, {
    enabled: Array.isArray(lepeSlugs) && lepeSlugs.length > 0,
  });
  const isLoading = lepeQueries.some((query) => query.isLoading);
  const defaultOptions =
    lepeQueries
      .map((query) => query.data)
      .filter(Boolean)
      .map((option) => ({
        ...option,
        label: (option as LegalEntityPayElementApi.LegalEntityPayElement).name,
        value: (option as LegalEntityPayElementApi.LegalEntityPayElement).slug,
      })) || [];
  const lepeSlugSet = new Set<string>(lepeSlugs);

  return (
    <PaginatedSearchSelectField
      query={{
        path: '/api/v1/rivendell/legal-entity-pay-elements',
        params: { queryParams: { ...filters } },
        options: {
          select: (res: LegalEntityPayElementApi.Admin.LegalEntityPayElementsResponse) => {
            const options = getDataWithRenamedProperty(res, 'legalEntityPayElements')
              .data.map((option: LegalEntityPayElementApi.LegalEntityPayElement) => ({
                ...option,
                label: option.name,
                value: option.slug,
              }))
              .filter(
                // here we filter out the options that are already set as defaultOptions
                (option: { label: string; value: string }) => !lepeSlugSet.has(option.value)
              );

            return {
              ...res,
              data: options,
            };
          },
        },
      }}
      label="Pay item"
      placeholder="Select a pay item"
      loadingMessage={() => 'Loading pay items…'}
      description="Search by pay item name"
      data-testid="pay-elements-select-field"
      isControlled
      components={{
        Option: LepeOption,
      }}
      isLoadingDefaultOptions={isLoading}
      defaultOptions={defaultOptions}
      multiple
      {...selectFieldProps}
    />
  );
}
