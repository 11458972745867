import { Box } from '@remote-com/norma';
import { useEffect, useRef } from 'react';

import SearchField, { trimInputValue } from '@/src/components/Table/Components/SearchField';
import { useSavedSearchValue } from '@/src/hooks/useSavedSearchValue';

export const GlobalFilter = ({
  setGlobalFilter,
  globalFilterPlaceholder,
  globalFilterProps,
  state: value,
}) => {
  const searchFieldRef = useRef();
  const searchInputRef = useRef();
  const searchTerm = useSavedSearchValue((state) => state.searchTerm);
  const setSearchTerm = useSavedSearchValue((state) => state.setSearchTerm);
  const persistSearch = !!globalFilterProps?.persistSearch;
  const minCharsForSearch = globalFilterProps?.minCharsForSearch ?? 1;

  // Run this once to ensure the search applies when the user changes tables
  useEffect(() => {
    if (!persistSearch) return;

    if (value) setSearchTerm(value);
    else setGlobalFilter(searchTerm);

    // DO NOT ADD DEPENDENCIES HERE. Only run on mount!!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If the value is set from the react-table state (so it's not reflected
    // in the search input), we need to update the search input value manually
    const trimmedValue = trimInputValue(value);
    if (
      !persistSearch &&
      trimmedValue &&
      trimInputValue(searchInputRef.current?.value) !== trimmedValue
    ) {
      searchInputRef.current.value = trimmedValue;
    }
  }, [value, searchInputRef, persistSearch]);

  return (
    <Box ref={searchFieldRef} display="inline">
      <SearchField
        id="table-search"
        ref={searchInputRef}
        defaultValue={(persistSearch && searchTerm) || value}
        onChange={(fieldValue) => {
          if (fieldValue && fieldValue.length < minCharsForSearch) return;
          if (persistSearch) setSearchTerm(fieldValue);
          setGlobalFilter(fieldValue);
        }}
        label={globalFilterPlaceholder}
        placeholder="Search"
        width="160px"
        {...globalFilterProps}
      />
    </Box>
  );
};
