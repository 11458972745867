import type { LiteralUnion } from 'type-fest';

type BorderRadiusKey = LiteralUnion<
  'none' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full',
  string | number
>;

/**
 * The purpose of `BorderRadiusKey` is to provide Intellisense for the `borderRadius` prop.
 * It will show the available values in the editor while allowing regular numbers and strings to be used.
 */
export const borderRadius: Record<BorderRadiusKey, string> = {
  none: '0px',
  '2xs': '4px',
  xs: '6px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '24px',
  '2xl': '32px',
  full: '9999px',
};
