import type { ButtonIconProps } from '@remote-com/norma';
import { IconV2SolidArrowUp } from '@remote-com/norma/icons/IconV2SolidArrowUp';
import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import { ButtonIcon } from '@/src/components/Button';
import { isOnMobileDevice } from '@/src/helpers/general';

const InputWrapper = styled.form<{ isHighlighted: boolean }>`
  display: flex;
  padding: 0px 11px; /* Reduce padding to fit within 50px */
  min-height: 50px;
  max-height: 200px;
  border-radius: 25px;
  border: 1px solid
    ${({ isHighlighted, theme }) =>
      isHighlighted ? theme.colors.grey[900] : theme.colors.grey[300]};
  align-items: flex-start;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  position: relative;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.white};
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TextInput = styled.textarea<{ isMobile: boolean }>`
  border-radius: 25px;
  flex: 1;
  padding: 6px 11px; /* Reduce padding to fit within 50px */
  border: none;
  font-size: ${({ isMobile }) =>
    isMobile
      ? '16px'
      : '14px'}; /* set it to 16px on mobile to avoid auto zoom upon textarea highlight */
  resize: none; /* Prevent manual resizing */
  overflow: auto; /* Hide scrollbars until max-height is reached */
  height: 50px;
  max-height: 200px;
  line-height: 18px; /* Set line-height to match the 18px per line requirement */
  box-sizing: border-box; /* Include padding in height calculation */
  line-height: 18px; /* Set line-height to match the 18px per line requirement */
  :focus {
    outline: none;
  }
  align-content: center;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ActionButton = styled(ButtonIcon)`
  border: none;
  cursor: pointer;
  width: 26px;
  height: 26px;
  align-self: end;
  margin-bottom: 11px;
  animation: ${fadeIn} 0.3s ease-in forwards;
`;

type Props = {
  ctaProps?: ButtonIconProps;
  inputProps: {
    onSubmit: (message: string) => void;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder: string;
  };
  /**
   * Whether to override default textarea behaviour, submitting forms when Enter is pressed.
   * Holding shift follows default behaviour in this mode (add a new line).
   * Recommended ON when short content is expected, OFF when longer form content is expected.
   */
  submitOnEnter: boolean;
};

export const TextareaWithCta = ({ inputProps, ctaProps, ...config }: Props): JSX.Element => {
  const { onSubmit, value, onChange, placeholder } = inputProps;
  const { submitOnEnter } = config;
  const isMobile = isOnMobileDevice();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    // Reset the height first (to shrink if needed)
    textarea.style.height = 'auto';
    // Calculate the new height based on scrollHeight, with a max height of 84px
    const MAX_MESSAGE_TEXT_AREA_HEIGHT = 200;
    const newHeight = Math.min(textarea.scrollHeight, MAX_MESSAGE_TEXT_AREA_HEIGHT);
    // Set the new height
    textarea.style.height = `${newHeight}px`;
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
    autoResizeTextarea();
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    autoResizeTextarea();
    onSubmit(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Submit on cmd/ctrl + enter
    if (event.key === 'Enter' && event.metaKey) {
      if (!ctaProps?.disabled) {
        handleSubmit(event);
        return;
      }
    }

    // Submit on enter if enabled for short-form content
    if (submitOnEnter) {
      if (event.key === 'Enter' && !event.shiftKey) {
        // Prevent the default behavior of adding a new line
        event.preventDefault();
        if (!ctaProps?.disabled) {
          handleSubmit(event);
        }
      }
    }
  };

  // useEffect to auto-adjust textarea height when the component first renders
  useEffect(() => {
    autoResizeTextarea();
  }, [value]);

  return (
    <InputWrapper onSubmit={handleSubmit} isHighlighted={!!value}>
      <TextInput
        ref={textareaRef}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        isMobile={isMobile}
      />
      <ActionButton
        variant="solid"
        size="xs"
        type="submit"
        {...(ctaProps || {})}
        Icon={IconV2SolidArrowUp}
      />
    </InputWrapper>
  );
};
