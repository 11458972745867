import { themeV2, themeV2Dark } from '@remote-com/norma';
import type { ThemeProviderProps } from 'next-themes';
import { ThemeProvider as UserThemeProvider, useTheme as useUserTheme } from 'next-themes';
import type { ReactNode } from 'react';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';

import { useIsFeatureFlagEnabled } from '../feature-flag/context';

/**
 * Return the complete theme object,
 * based on user preference and feature flag.
 */
function useResolvedTheme(): typeof themeV2 {
  const { resolvedTheme } = useUserTheme();

  // Heads up: This is very important,
  // as it's the _only_ gate of dark mode feature flag!
  // Conditional hooks are not simple,
  // and we want to launch with "system" as default.
  //
  // Because of this, we don't want to set the user preference to "light",
  // as part of our feature flag gate.
  // Instead, what we really gate is the _resolved_ value itself!
  const isEnabled = useIsFeatureFlagEnabled('dark_mode');
  if (!isEnabled) return themeV2;

  if (resolvedTheme !== 'dark') return themeV2;

  /**
   * The typing here is a bit tricky.
   *
   * "themeV2Dark" has the same shape as "themeV2",
   * but we have set Styled Components to use "themeV2" as the type,
   * _and_ "themeV2" is over-typed with "as const",
   * so not only the keys but also the values are frozen.
   *
   * This means we can't use "themeV2Dark" directly,
   * as its values are not assignable to "themeV2".
   * So we need to force the typing here.
   *
   * The alternative is to fix the type of "themeV2",
   * e.g., to _not_ cover values,
   * but that's too big a change now.
   */
  const themeV2DarkAsThemeV2 = themeV2Dark as unknown as typeof themeV2;

  return themeV2DarkAsThemeV2;
}

/**
 * Why extract to Inner:
 * We need the user's theme preference here,
 * which is _provided_ by next-themes,
 * so this component must be a child of UserThemeProvider,
 * which is defined at "Outer".
 */
function Inner(props: { children: ReactNode }): JSX.Element {
  const { children } = props;

  const theme = useResolvedTheme();

  return <StyledComponentThemeProvider theme={theme}>{children}</StyledComponentThemeProvider>;
}

/**
 * Outer wrapper to provider UserThemeProvider,
 * aka user's preference for theme.
 * See Inner for the actual theme usage.
 */
function Outer(props: { children: ReactNode }): JSX.Element {
  const { children } = props;

  const isEnabled = useIsFeatureFlagEnabled('dark_mode');

  /**
   * This strange usage, along with the TypeScript mute, is necessary.
   * It is mainly because React in next-themes has a conflict with
   * React in Dragon.
   *
   * It is expected that the provider wraps the app,
   * (i.e., it expects a "children" prop),
   * but due to the typing, "children" is not accepted.
   *
   * See:
   * - https://github.com/pacocoursey/next-themes?tab=readme-ov-file#with-app
   */
  const providerProps: ThemeProviderProps = {
    // @ts-expect-error
    children: <Inner>{children}</Inner>,
    /**
     * This does _not_ disable the theming at all.
     * Instead, this only controls the color scheme of built-in elements,
     * like the scrollbar.
     * We want to disable this when the feature flag is disabled,
     * so that the scrollbar is not dark ("system" theme is dark)
     * while the rest of the app is light (theme when flag is off).
     */
    enableColorScheme: !!isEnabled,
  };

  return <UserThemeProvider {...providerProps} />;
}

export const ThemeProvider = Outer;
