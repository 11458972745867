import { Badge, Box, FormGroupProvider, InputSelectComponents } from '@remote-com/norma';
import styled from 'styled-components';

import { CheckboxField, InputCurrencyField, InputField } from '@/src/components/Ui/Form';
import { DatePickerField } from '@/src/components/Ui/Form/DatePickerField';
import { DateRangePickerField } from '@/src/components/Ui/Form/DateRangePickerField';
import { SelectField } from '@/src/components/Ui/Form/formikIntegration/SelectField';
import { Label } from '@/src/components/Ui/Form/inputs/InputCheckbox/InputCheckbox.styled';
import { SearchField } from '@/src/components/Ui/Form/SearchField';
import { CompanySelectField } from '@/src/components/Ui/Select/admin/CompanySelectField';
import { EmployeeSelectField } from '@/src/components/Ui/Select/admin/EmployeeSelect';
import { LegalEntityMultiselect as AdminLegalEntityMultiSelectField } from '@/src/components/Ui/Select/admin/LegalEntityMultiselect';
import { LegalEntityPayElementCodeSelect as AdminLegalEntityPayElementCodeSelect } from '@/src/components/Ui/Select/admin/LegalEntityPayElementCodeSelect';
import { LegalEntityPayElementSelectMultiselect } from '@/src/components/Ui/Select/admin/LegalEntityPayElementMultiselect';
import { LegalEntityPayElementSelectField } from '@/src/components/Ui/Select/admin/LegalEntityPayElementSelect';
import { LegalEntitySelectField as AdminLegalEntitySelectField } from '@/src/components/Ui/Select/admin/LegalEntitySelect';
import AdminPayrollRunSelect from '@/src/components/Ui/Select/admin/PayrollRunSelect';
import { UserSelectField } from '@/src/components/Ui/Select/admin/UserSelect';
import {
  CountryMultiSelectField,
  CountrySelectField,
} from '@/src/components/Ui/Select/CountrySelect';
import { LegalEntityMultiSelect as EmployerLegalEntityMultiSelect } from '@/src/components/Ui/Select/employer/LegalEntityMultiSelect';
import { LegalEntityPayElementCodeSelect as EmployerLegalEntityPayElementCodeSelect } from '@/src/components/Ui/Select/employer/LegalEntityPayElementCodeSelect';
import { LegalEntitySelectField as EmployerLegalEntitySelectField } from '@/src/components/Ui/Select/employer/LegalEntitySelect';
import EmployerPayrollRunSelect from '@/src/components/Ui/Select/employer/PayrollRunSelect';
import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import { useUser } from '@/src/components/UserProvider/context';
import {
  employmentStatus,
  employmentStatusLabels,
  employmentStatusOptions,
  employmentTypeOptions,
} from '@/src/domains/employment/constants';
import EmployeeContractSelectorFilter from '@/src/domains/payroll/components/EmployeeContractSelector';
import { ACTIVE_PAYROLL_RUN_FILTER_STATUS_IDS } from '@/src/domains/payroll/constants';
import { PAYSLIP_STATUS_OPTIONS } from '@/src/domains/payslips/constants';
import { buildProjectName } from '@/src/domains/projects/helpers';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import { dateToISO8601, getYearOptions } from '@/src/helpers/date';
import useCompanyCurrenciesData from '@/src/hooks/useCompanyCurrenciesData';
import useCountriesData, { useCompanyCountriesData } from '@/src/hooks/useCountriesData';
import useCurrencies from '@/src/hooks/useCurrencies';
import { InvoiceSelector } from '@/src/views/rivendell/invoices/InvoiceSelector';

const CheckboxBlock = styled(Box).attrs({
  mt: 2,
  mb: 2,
})`
  align-items: center;
  display: flex;
  height: 100%;

  ${Label} {
    color: ${({ theme }) => theme.colors.grey[600]};
    font-size: ${({ theme }) => theme.typography.sm};
  }
`;

// https://formik.org/docs/guides/arrays#avoid-nesting
export const makeFilterIdThatAvoidsNesting = (id) => `['${id}']`;

export function CountrySelectFilter({ column, valueField, ...props }) {
  const { id } = column;
  const { data: countries } = useCountriesData();

  return (
    <CountrySelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      placeholder="Select country"
      noOptionsMessage={() => 'Loading countries...'}
      countries={countries || []}
      transformValue={(option) => option[valueField]}
      {...props}
    />
  );
}

CountrySelectFilter.defaultProps = {
  valueField: 'name',
};

export function EmployerCountrySelectFilter({ column, valueField, ...props }) {
  const { id } = column;
  const { data: countries } = useCompanyCountriesData();

  return (
    <CountrySelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      placeholder="Select country"
      noOptionsMessage={() => 'Loading countries...'}
      countries={countries || []}
      transformValue={(option) => option[valueField]}
      {...props}
    />
  );
}

export function CountryMultiSelectFilter({ column, valueField = 'slug', ...props }) {
  const { id } = column;
  const { data: countries } = useCountriesData();

  const countriesOptions =
    countries && countries.map((country) => ({ value: country[valueField], label: country.name }));

  return (
    <CountryMultiSelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      placeholder="Select country"
      countries={countriesOptions}
      noOptionsMessage={() => 'Loading countries...'}
      {...props}
    />
  );
}

export function EmployerCountryMultiSelectFilter({ column, valueField = 'slug', ...props }) {
  const { id } = column;
  const { data: countries } = useCompanyCountriesData();

  const countriesOptions =
    countries && countries.map((country) => ({ value: country[valueField], label: country.name }));

  return (
    <CountryMultiSelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      placeholder="Select country"
      countries={countriesOptions}
      noOptionsMessage={() => 'Loading countries...'}
      {...props}
    />
  );
}

export function CurrencySelectFilter({ column, valueField, ...props }) {
  const { id } = column;
  const { data: currencies } = useCurrencies();

  return (
    <SelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      options={currencies}
      getOptionLabel={(currency) => currency.code}
      noOptionsMessage={() => 'Loading currencies...'}
      placeholder="Select currency"
      {...props}
    />
  );
}

export function AdminEmployeeSelectField(params) {
  const { column, preFilteredRows, options, dispatch, ...props } = params;
  const { id } = column;

  return (
    <EmployeeSelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      transformValue={(option) => option.slug}
      withSearchIcon
      {...props}
    />
  );
}

const FETCH_CONTRACTS_QUERY = 'fetch-contracts-filter';
export function AdminContractSelectField(params) {
  const { column, preFilteredRows, options, slug, ...props } = params;
  const { id } = column;

  return (
    <EmployeeContractSelectorFilter
      queryKey={[FETCH_CONTRACTS_QUERY, slug]}
      filters={{
        payrollRunSlug: slug,
      }}
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      transformValue={(option) => option.slug}
      {...props}
    />
  );
}

export function AdminUserSelectField(params) {
  const { column, ...props } = params;
  const { id } = column;

  return (
    <UserSelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      transformValue={(user) => user?.slug}
      placeholder={column.placeholder ? column.render('placeholder') : column.render('Header')}
      withSearchIcon={false}
      {...props}
    />
  );
}

export function BasicTextFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;

  return (
    <InputField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      withSearchIcon
      {...props}
    />
  );
}

export function BasicSelectFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;

  const selectOptions =
    options ||
    preFilteredRows.map(({ values }) => ({
      value: values[id],
      label: values[id],
    }));

  const filterId = makeFilterIdThatAvoidsNesting(id);

  return (
    <FormGroupProvider>
      <SelectField
        components={{
          Input: ({ ...allProps }) => (
            <InputSelectComponents.Input {...allProps} data-testid={allProps.id} />
          ),
        }}
        id={`${id}-selector`}
        name={filterId}
        label={column.render('Header')}
        options={selectOptions}
        transformValue={(option) => option.value}
        withSearchIcon
        {...props}
      />
    </FormGroupProvider>
  );
}

export function MultiSelectFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;

  const { id } = column;
  const preFilteredOptions = preFilteredRows.map(({ values }) => ({
    value: values[id],
    label: values[id],
  }));

  const selectOptions = options || preFilteredOptions;

  return (
    <SelectField
      multiple
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      options={selectOptions}
      transformValue={(selectedOptions) => {
        if (Array.isArray(selectedOptions)) {
          return selectedOptions.map((option) => ({
            value: option.value,
            label: option.label,
          }));
        }
        // No options selected
        return {};
      }}
      {...props}
    />
  );
}

export function TextFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;
  return (
    <SearchField
      label={column.render('Header')}
      name={makeFilterIdThatAvoidsNesting(id)}
      {...props}
    />
  );
}

const CheckboxFilterWrapper = ({ children }) => <CheckboxBlock>{children}</CheckboxBlock>;

export function CheckboxFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;

  return (
    <CheckboxFilterWrapper>
      <CheckboxField label={column.render('Header')} name={id} {...props} />
    </CheckboxFilterWrapper>
  );
}

export function CurrencyInputFilter(params) {
  const { column, currency, disabled, ...props } = params;
  const { id } = column;
  return (
    <InputCurrencyField
      label={column.render('Header')}
      name={makeFilterIdThatAvoidsNesting(id)}
      currency={currency}
      disabled={disabled}
      {...props}
    />
  );
}

export function DateFilter(params) {
  const { column, onAllRowsSelectableChange, onRowSelectableChange, ...props } = params;
  const { id } = column;

  return (
    <DatePickerField
      label={column.render('Header')}
      name={makeFilterIdThatAvoidsNesting(id)}
      $size="md"
      {...props}
    />
  );
}

export function MonthYearFilter(params) {
  const { column, ...props } = params;
  const { id } = column;
  const filterId = makeFilterIdThatAvoidsNesting(id);

  return (
    <DatePickerField
      label={column.render('Header')}
      name={filterId}
      $size="md"
      dateFormat="yyyy-MM"
      placeholder="YYYY-MM"
      showMonthYearPicker
      data-testid={`${filterId}-selector-input`}
      {...props}
    />
  );
}

export function YearFilter(props) {
  const yearOptions = getYearOptions({ includeBlankOption: false });
  return (
    <BasicSelectFilter
      {...props}
      name="year"
      label="Year"
      placeholder="Select a year"
      options={yearOptions}
      transformValue={(option) => option.value}
      withSearchIcon={false}
    />
  );
}

export function DateRangeFilter(params) {
  const { column, onAllRowsSelectableChange, onRowSelectableChange, ...props } = params;
  const { id } = column;

  return (
    <DateRangePickerField
      label={column.render('Header')}
      name={makeFilterIdThatAvoidsNesting(id)}
      $size="md"
      placeholder="Select date or range"
      {...props}
    />
  );
}

/**
 * MonthRangeFilter
 *
 * Renders a month year picker field for range of month selection
 *
 * @param {Omit<import('@/src/components/Form/DynamicForm/types').DateRangeField, 'type', 'name'> & {id?: string}} params
 * @return {JSX.Element}
 */
export function MonthRangeFilter(params) {
  const { column, onAllRowsSelectableChange, onRowSelectableChange, ...props } = params;
  const { id } = column;

  return (
    <DateRangePickerField
      label={column.render('Header')}
      name={makeFilterIdThatAvoidsNesting(id)}
      $size="md"
      transformValue={(option) => {
        const endDate = option.end ? new Date(option.end) : null;
        const endOfMonth = endDate
          ? new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth() + 1, 0))
          : null;

        return [option.start, endOfMonth ? dateToISO8601(endOfMonth) : null];
      }}
      showMonthYearPicker
      {...props}
    />
  );
}

export function ShowAllPagesFilter({ column }) {
  const { id } = column;

  return <CheckboxField label="Disable pagination" name={id} />;
}

export function AdminCompaniesFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;

  return (
    <CompanySelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      {...props}
    />
  );
}

export function AdminLegalEntityFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;
  return (
    <AdminLegalEntitySelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      withSearchIcon={false}
      placeholder="Select contract legal entity"
      {...props}
    />
  );
}

export function AdminLegalEntityMultiFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;
  return (
    <AdminLegalEntityMultiSelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      withSearchIcon={false}
      placeholder="Select contract legal entity"
      {...props}
    />
  );
}

export function EmployerLegalEntityFilter(params) {
  const { column, ...props } = params;
  const { id } = column;
  return (
    <EmployerLegalEntitySelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      withSearchIcon={false}
      isClearable
      placeholder="Select entity"
      {...props}
    />
  );
}

export function EmployerLegalEntityMultiFilter(params) {
  const { column, ...props } = params;
  const { id } = column;
  return (
    <EmployerLegalEntityMultiSelect
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      withSearchIcon={false}
      isClearable
      placeholder="Select entity"
      {...props}
    />
  );
}

export function LegalEntityFilter(props) {
  return (
    <BasicSelectFilter
      withSearchIcon={false}
      placeholder="Select contract Remote entity"
      {...props}
    />
  );
}

const getInitialOptions = (allowFilteringByNone) =>
  allowFilteringByNone
    ? [
        {
          label: 'Not in payroll run',
          value: 'none',
        },
      ]
    : [];

export function AdminPayrollRunFilter(params) {
  const {
    allowFilteringByNone = false,
    column,
    preFilteredRows,
    options,
    filters,
    ...props
  } = params;
  const { id } = column;

  const initialOptions = getInitialOptions(allowFilteringByNone);

  return (
    <AdminPayrollRunSelect
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      transformValue={(option) => option.slug || option.value}
      filters={{
        status: ACTIVE_PAYROLL_RUN_FILTER_STATUS_IDS,
        ...filters,
      }}
      initialOptions={initialOptions}
      {...props}
    />
  );
}

export function EmployerPayrollRunFilter(params) {
  const {
    allowFilteringByNone = false,
    column,
    preFilteredRows,
    options,
    filters,
    ...props
  } = params;
  const { id } = column;

  const initialOptions = getInitialOptions(allowFilteringByNone);

  return (
    <EmployerPayrollRunSelect
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      transformValue={(option) => option.slug || option.value}
      filters={{
        status: ACTIVE_PAYROLL_RUN_FILTER_STATUS_IDS,
        ...filters,
      }}
      initialOptions={initialOptions}
      {...props}
    />
  );
}

export function AdminInvoiceSelectField(params) {
  const { column, preFilteredRows, options, slug, ...props } = params;
  const { id } = column;

  return (
    <InvoiceSelector
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      label={column.render('Header')}
      transformValue={(option) => option.slug || option.value}
      {...props}
    />
  );
}

/**
 * @param props
 * @param {boolean} showGroupOptions Adds filter that groups several statuses
 * @returns Filter for employment status, as found in @see employmentStatus
 */
export function StatusFilter({ showGroupOptions = true, customStatusList, ...props }) {
  const adminTableEmploymentStatusFilterOptions = [
    ...(showGroupOptions
      ? [
          {
            value: employmentStatus.INCOMPLETE,
            label: employmentStatusLabels[employmentStatus.INCOMPLETE],
          },
          {
            value: employmentStatus.EMPLOYED,
            label: employmentStatusLabels[employmentStatus.EMPLOYED],
          },
        ]
      : []),
    ...(customStatusList || employmentStatusOptions),
  ];

  return (
    <BasicSelectFilter
      {...props}
      withSearchIcon={false}
      placeholder="Select status"
      options={adminTableEmploymentStatusFilterOptions}
    />
  );
}

export function EmploymentTypeFilter(props) {
  return (
    <MultiSelectFilter
      {...props}
      placeholder="Select employment type"
      options={employmentTypeOptions}
    />
  );
}

export const PayslipStatusFilter = (props) => {
  const options = Object.values(PAYSLIP_STATUS_OPTIONS).map(({ value, badge, label }) => ({
    value,
    label: <Badge type={badge} label={label} />,
  }));

  return <BasicSelectFilter {...props} withSearchIcon={false} options={options} />;
};

export function LegalEntityPayElementFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;

  return (
    <LegalEntityPayElementSelectField
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      description=""
      label="Legal entity pay item"
      {...props}
    />
  );
}

export function LegalEntityPayElementMultiFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;

  return (
    <LegalEntityPayElementSelectMultiselect
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      description=""
      label="Legal entity pay item"
      {...props}
    />
  );
}

export function AdminLegalEntityPayElementCodeFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;

  return (
    <AdminLegalEntityPayElementCodeSelect
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      description=""
      label="Legal entity pay item code"
      {...props}
    />
  );
}

export function EmployerLegalEntityPayElementCodeFilter(params) {
  const { column, preFilteredRows, options, ...props } = params;
  const { id } = column;

  return (
    <EmployerLegalEntityPayElementCodeSelect
      id={`${id}-selector`}
      name={makeFilterIdThatAvoidsNesting(id)}
      description=""
      label="Legal entity pay item code"
      {...props}
    />
  );
}

/**
 * Displays a select filter for deparments. You'll need to provide the departments with `useGetDepartments()`.
 * @param {Object} params
 * @param {(undefined | {name: string, slug: string}[])} params.departments
 * @returns {JSX.Element}
 */
export const DepartmentFilter = (params) => {
  const { departments, ...props } = params;
  const departmentsOptions = departments?.map((department) => ({
    label: department?.name,
    value: department?.slug,
  }));
  return (
    <MultiSelectFilter
      {...props}
      label="Department"
      placeholder="Select department"
      options={departmentsOptions}
    />
  );
};

const getManagerOptionsSorted = ({ currentUser, optionsList, sorted = true }) => {
  const currentManager = optionsList?.filter((option) => option.slug === currentUser.slug);
  const otherManagers = optionsList?.filter((option) => option.slug !== currentUser.slug);

  const managers =
    sorted && currentManager?.length ? [...currentManager, ...otherManagers] : optionsList;

  return managers?.map((manager) => {
    const name = manager?.name || manager?.user?.name;
    const slug = manager?.slug || manager?.user?.slug;

    return {
      label: name,
      value: slug,
    };
  });
};

export const ManagerFilter = (params) => {
  const { user } = useUser();

  const { assignedManagers, label, ...props } = params;
  const optionsList = assignedManagers;
  const managersOptions = getManagerOptionsSorted({ currentUser: user, optionsList }) || [];

  return (
    <MultiSelectFilter
      {...props}
      label={label ?? props.column.render('Header')}
      options={managersOptions}
    />
  );
};

export const DirectReportsOnlyFilter = (params) => {
  const { label, column, ...props } = params;
  const { id } = column;

  return (
    <CheckboxBlock>
      <CheckboxField label={label || column.render('Header')} name={id} {...props} />
    </CheckboxBlock>
  );
};

export const DateRelatedFilters = new Set([
  DatePickerField,
  DateRangeFilter,
  MonthYearFilter,
  YearFilter,
]);

export const InvoiceCurrencyFilter = (props) => {
  const { data = [] } = useCompanyCurrenciesData();

  const options = data.map(({ code }) => ({
    label: code,
    value: code,
  }));

  return <BasicSelectFilter {...props} options={options} />;
};

export const PaginatedProjectSelectFilter = ({ columnId, label }) => {
  return (
    <PaginatedSearchSelectField
      id={`${columnId}-selector`}
      name={makeFilterIdThatAvoidsNesting(columnId)}
      label={label}
      withSearchIcon={false}
      isClearable
      placeholder="Select project"
      query={{
        path: '/api/v1/employer/projects',
        params: {
          queryParams: {
            pageSize: 10,
          },
        },
        options: {
          select: (res) => getDataWithRenamedProperty(res, 'projects'),
        },
      }}
      loadingMessage={() => 'Loading projects…'}
      getOptionLabel={(option) => buildProjectName(option)}
      getOptionValue={(option) => option.slug}
      transformValue={(option) => option.slug}
    />
  );
};
