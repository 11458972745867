import { EXPENSES_ADMIN_QUERY } from '@/src/domains/expenses/constants';

import { ADJUSTMENTS_QUERY_NAME } from './queryNames';

/**
 * Invalidate above the fold content for payrolls like the list endpoint, and totals
 * @param {import('react-query').QueryClient} queryClient
 * @returns {Promise<void>}
 */
export async function invalidatePayrollRunMetadata(queryClient) {
  // First invalidate the run and the list of all runs
  await Promise.all([
    queryClient.invalidateQueries('/api/v1/rivendell/payroll-runs/[slug]'),
    queryClient.invalidateQueries('/api/v1/rivendell/payroll-runs'),
  ]);

  // Then invalidate the totals and validations
  await Promise.all([
    queryClient.invalidateQueries('/api/v1/rivendell/payroll-runs/[slug]/totals'),
    queryClient.invalidateQueries('/api/v1/rivendell/payroll-runs/[slug]/validate'),
  ]);
}

export async function invalidatePayrollOutputsQueries(queryClient, { keys } = {}) {
  return Promise.all([
    queryClient.invalidateQueries(['/api/v1/rivendell/payroll-outputs']),
    ...(keys
      ? keys.map((slug) =>
          queryClient.invalidateQueries([
            '/api/v1/rivendell/payroll-outputs/[slug]',
            { pathParams: { slug } },
          ])
        )
      : [queryClient.invalidateQueries(['/api/v1/rivendell/payroll-outputs/[slug]'])]),
  ]);
}

export async function invalidateUpsertPayrollRun(queryClient) {
  return Promise.all([
    invalidatePayrollRunMetadata(queryClient),
    invalidatePayrollOutputsQueries(queryClient),
    queryClient.invalidateQueries(ADJUSTMENTS_QUERY_NAME),
    queryClient.invalidateQueries(EXPENSES_ADMIN_QUERY),
    queryClient.invalidateQueries(['/api/v1/rivendell/expenses']),
    queryClient.invalidateQueries(['/api/v1/rivendell/incentives']),
    queryClient.invalidateQueries('/api/v1/rivendell/payslips'),
    queryClient.invalidateQueries('/api/v1/rivendell/payslips/[slug]'),
    queryClient.invalidateQueries('/api/v1/rivendell/employee-pay-elements'),
  ]);
}
