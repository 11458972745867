import { Box, Stack, Text } from '@remote-com/norma';
import type { StackProps } from '@remote-com/norma';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import type { CSSObject } from 'styled-components';
import styled, { css } from 'styled-components';

import { ButtonIcon } from '@/src/components/Button';

export const ModalOverlay = styled(motion(Box))`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: ${({ theme }) => transparentize(0.52, theme.colors.grey[700])};
  overflow: hidden;
  z-index: 999999;
`;

type AnimatedWrapperProps = {
  additionalStyle: CSSObject;
};

export const AnimatedWrapper = styled(motion(Box))<AnimatedWrapperProps>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100vw;
  /* Duplicating height rule to create a fallback for browser that do not support svh unit */
  height: calc(100vh - ${({ theme }) => theme.space[9]}px);
  height: calc(100svh - ${({ theme }) => theme.space[9]}px);
  margin: ${({ theme }) => theme.space[9]}px 0 0;
  border-top-left-radius: ${({ theme }) => theme.space[3]}px;
  border-top-right-radius: ${({ theme }) => theme.space[3]}px;
  ${({ additionalStyle }) => css`
    ${additionalStyle}
  `}
`;

type ModalContentProps = {
  additionalStyle?: CSSObject;
};

export const ModalContent = styled(Box)<ModalContentProps>`
  background: ${({ bg, theme }) => bg || theme.colors.background.surface};
  cursor: default;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[10]}px ${({ theme }) => theme.space[5]}px
    ${({ theme }) => theme.space[5]}px;
  @media screen and (min-width: ${({ theme }) => theme.breakpointValues.medium}) {
    padding: ${({ theme }) => theme.space[7]}px;
  }
  ${({ additionalStyle }) => css`
    ${additionalStyle}
  `}
`;

export const CloseButton = styled(ButtonIcon)`
  position: fixed;
  top: ${({ theme }) => theme.space[7]}px;
  right: ${({ theme }) => theme.space[7]}px;
`;

type ModalInnerContentProps = {
  additionalStyle?: CSSObject;
};

export const ModalInnerContent = styled(Box)<ModalInnerContentProps>`
  margin: 0 auto;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  --modal-body-max-width: ${({ maxWidth }) => maxWidth};

  ${({ additionalStyle }) => css`
    ${additionalStyle}
  `}
`;

type TitleProps = {
  $hasDescription: boolean;
  $titleStyle?: CSSObject;
};

export const Title = styled.h2<TitleProps>`
  ${({ theme }) => theme.typography['4xlMedium']};
  text-align: center;
  margin-bottom: ${({ $hasDescription, theme }) => ($hasDescription ? `${theme.space[4]}px` : 0)};
  color: ${({ theme }) => theme.colors.grey[900]};
  ${({ $titleStyle }) => css`
    ${$titleStyle}
  `}
`;

type DescriptionProps = {
  $descriptionStyle?: CSSObject;
};

export const Description = styled(Text)<DescriptionProps>`
  ${({ theme }) => theme.typography.sm};
  color: ${({ theme }) => theme.colors.grey[600]};
  text-align: center;
  ${({ $descriptionStyle }) => css`
    ${$descriptionStyle}
  `}
`;

type BodyProps = {
  additionalStyle: CSSObject;
};

export const Body = styled(Box)<BodyProps>`
  /* Remove vertical margins of existing child DynamicForm, because
   modal body already provides a generous margin. */
  --dynamicFormContainerMargin: 0;

  ${({ additionalStyle }) => css`
    ${additionalStyle}
  `}
  @media screen and (min-width: ${({ theme }) => theme.breakpointValues.medium}) {
    padding: ${({ theme }) => theme.space[7]}px;
  }
`;

type FooterProps = StackProps & {
  additionalStyle?: CSSObject;
};

export const Footer = styled(Stack)<FooterProps>`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[6]}px;
  padding-bottom: ${({ theme }) => theme.space[9]}px;

  @media screen and (min-width: ${({ theme }) => theme.breakpointValues.medium}) {
    padding-bottom: ${({ theme }) => theme.space[11]}px;
  }

  ${({ additionalStyle }) =>
    additionalStyle &&
    css`
      ${additionalStyle}
    `}
`;
