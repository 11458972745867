import type { BadgeType } from '@remote-com/norma';
import type { ValueOf } from 'type-fest';

import type { UserStatus } from '@/src/api/config/api.types';

export const USER_TYPE = {
  FREELANCER: 'freelancer',
  EMPLOYEE: 'employee',
  EMPLOYER: 'employer',
  DELETED: 'deleted',
  ADMIN: 'admin',
  SERVICE_PROVIDER: 'service_provider',
  // The candidate role is for usage with the Jobs application not Employ.
  // Candidates should only see any empty state when using Employ or be switched
  // to another user profile in their account with a different role.
  CANDIDATE: 'candidate',
} as const;

export type UserAdminOrEmployer = typeof USER_TYPE.ADMIN | typeof USER_TYPE.EMPLOYER;

// Note that UserType is actually users.role in the DB schema
export type UserType = ValueOf<typeof USER_TYPE>;

export const userTypeLabels = {
  [USER_TYPE.EMPLOYEE]: 'Employee',
  [USER_TYPE.FREELANCER]: 'Self-sign-up contractor',
  [USER_TYPE.EMPLOYER]: 'Employer',
  [USER_TYPE.DELETED]: 'Deleted',
  [USER_TYPE.ADMIN]: 'Remote admin',
  [USER_TYPE.SERVICE_PROVIDER]: 'Service provider',
  [USER_TYPE.CANDIDATE]: 'Candidate',
} as const;

export const userTypeOptions = (Object.keys(userTypeLabels) as UserType[]).map((value) => ({
  value,
  label: userTypeLabels[value],
}));

export const userStatus = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  CREATED: 'created',
  DELETED: 'deleted',
  INITIATED: 'initiated',
  INACTIVE: 'inactive',
} as const satisfies Record<string, UserStatus>;

export const userStatusLabels = {
  [userStatus.ACTIVE]: 'Active',
  [userStatus.CANCELLED]: 'Canceled',
  [userStatus.CREATED]: 'Created',
  [userStatus.DELETED]: 'Deleted',
  [userStatus.INITIATED]: 'Initiated',
  [userStatus.INACTIVE]: 'Inactive',
} satisfies Record<UserStatus, string>;

export const offboardedUserStatuses = new Set<UserStatus>([
  userStatus.CANCELLED,
  userStatus.DELETED,
  userStatus.INACTIVE,
]);

export const userStatusOptions = Object.entries(userStatusLabels).map(([value, label]) => ({
  value,
  label,
}));

export const userStatusBadges = {
  [userStatus.ACTIVE]: 'active',
  [userStatus.CANCELLED]: 'inactive',
  [userStatus.INACTIVE]: 'inactive',
  [userStatus.CREATED]: 'pending',
  [userStatus.DELETED]: 'inactive',
  [userStatus.INITIATED]: 'pending',
} as const satisfies Record<UserStatus, BadgeType>;

export const companyAdminValues = {
  VIEWER: 'viewer',
  ADMIN: 'admin',
  OWNER: 'owner',
  PEOPLE_MANAGER: 'people_manager',
  ONBOARDING_MANAGER: 'onboarding_manager',
};

export type CompanyAdminValue = ValueOf<typeof companyAdminValues>;

export const companyAdminLabels = {
  VIEWER: 'Viewer',
  ADMIN: 'Admin',
  OWNER: 'Owner',
  PEOPLE_MANAGER: 'People Manager',
  ONBOARDING_MANAGER: 'Onboarding Manager',
};

export const companyAdminRoles = [
  {
    label: companyAdminLabels.VIEWER,
    value: companyAdminValues.VIEWER,
  },
  {
    label: companyAdminLabels.PEOPLE_MANAGER,
    value: companyAdminValues.PEOPLE_MANAGER,
  },
  {
    label: companyAdminLabels.ONBOARDING_MANAGER,
    value: companyAdminValues.ONBOARDING_MANAGER,
  },
  {
    label: companyAdminLabels.ADMIN,
    value: companyAdminValues.ADMIN,
  },
  {
    label: companyAdminLabels.OWNER,
    value: companyAdminValues.OWNER,
  },
];

/**
 * We should not show the owner type when inviting a new company admin
 */
export const companyAdminRoleOptions = companyAdminRoles.filter(
  (companyAdmin) => companyAdmin.value !== companyAdminValues.OWNER
);

export function getCompanyAdminOption(value: CompanyAdminValue) {
  return companyAdminRoles.find((companyAdminOption) => companyAdminOption.value === value);
}

export const employeePersona = {
  EMPLOYEE: 'employee',
  CONTRACTOR: 'contractor',
  FREELANCER: 'freelancer',
  CANDIDATE: 'candidate',
};

export const employeePersonaLabels = {
  [employeePersona.EMPLOYEE]: 'Employee',
  [employeePersona.CONTRACTOR]: 'Contractor',
  [employeePersona.FREELANCER]: 'Freelancer',
};

export const registrationFlowType = {
  EMPLOYER: 'employer',
  FREELANCER: 'freelancer',
  GLOBAL_PAYROLL_EMPLOYER: 'global-payroll-employer',
  TALENT: 'talent',
};

export const registrationFlowTrackingNames = {
  MANAGE: 'manage',
  HIRE: 'hire',
  FREELANCER: 'freelancer',
  JOB_SEEKER: 'job-seeker',
  EMPLOYEE: 'employee',
};

export const signUpSourceType = {
  SELF_SIGN_UP: 'self_signup',
  INVITED_BY_EMPLOYER: 'invited_by_employer',
  SELF_SIGN_UP_GLOBAL_PAYROLL: 'self_signup_global_payroll',
  EOR_INTEGRATION_GUSTO: 'eor_integration_gusto',
  EOR_INTEGRATION_PERSONIO: 'eor_integration_personio_embedded',
  SELF_SIGN_UP_TALENT: 'self_signup_talent',
};

export const companyCohortType = {
  GUSTO_REFERRAL: 'gusto_referral',
  PERSONIO_REFERRAL: 'personio_embedded_referral',
  PERSONIO_EMBEDDED_REFERRAL: 'personio_embedded_embedded_eor',
};

export const signUpSourceTypeLabels = {
  [signUpSourceType.SELF_SIGN_UP]: 'Self sign up',
  [signUpSourceType.INVITED_BY_EMPLOYER]: 'Invited by employer',
  [signUpSourceType.SELF_SIGN_UP_GLOBAL_PAYROLL]: 'Self sign up - global payroll',
  [signUpSourceType.EOR_INTEGRATION_GUSTO]: 'Gusto',
  [signUpSourceType.EOR_INTEGRATION_PERSONIO]: 'Personio',
  [signUpSourceType.SELF_SIGN_UP_TALENT]: 'Self sign up - Talent',
};

export const signUpSourceByRegistrationFlow = {
  [registrationFlowType.GLOBAL_PAYROLL_EMPLOYER]: signUpSourceType.SELF_SIGN_UP_GLOBAL_PAYROLL,
  [registrationFlowType.TALENT]: signUpSourceType.SELF_SIGN_UP_TALENT,
  [registrationFlowType.FREELANCER]: signUpSourceType.SELF_SIGN_UP,
};

export const companyCohortTypeLabels = {
  [companyCohortType.GUSTO_REFERRAL]: 'Gusto referral',
  [companyCohortType.PERSONIO_REFERRAL]: 'Personio referral',
  [companyCohortType.PERSONIO_EMBEDDED_REFERRAL]: 'Personio embedded referral',
};

export const companySignUpSourceFilterOptions = [
  {
    label: signUpSourceTypeLabels[signUpSourceType.SELF_SIGN_UP],
    value: signUpSourceType.SELF_SIGN_UP,
  },
  {
    label: signUpSourceTypeLabels[signUpSourceType.EOR_INTEGRATION_GUSTO],
    value: signUpSourceType.EOR_INTEGRATION_GUSTO,
  },
  {
    label: signUpSourceTypeLabels[signUpSourceType.EOR_INTEGRATION_PERSONIO],
    value: signUpSourceType.EOR_INTEGRATION_PERSONIO,
  },
];

export const companyCohortFilterOptions = [
  {
    label: companyCohortTypeLabels[companyCohortType.GUSTO_REFERRAL],
    value: companyCohortType.GUSTO_REFERRAL,
  },
  {
    label: companyCohortTypeLabels[companyCohortType.PERSONIO_REFERRAL],
    value: companyCohortType.PERSONIO_REFERRAL,
  },
  {
    label: companyCohortTypeLabels[companyCohortType.PERSONIO_EMBEDDED_REFERRAL],
    value: companyCohortType.PERSONIO_EMBEDDED_REFERRAL,
  },
];

export const totpStatus = {
  ON: true,
  OFF: false,
};

export const totpStatusLabels = {
  // @ts-expect-error
  [totpStatus.ON]: 'On',
  // @ts-expect-error
  [totpStatus.OFF]: 'Off',
};

export const totpStatusBadges = {
  // @ts-expect-error
  [totpStatus.ON]: 'active',
  // @ts-expect-error
  [totpStatus.OFF]: 'inactive',
};

export const TWO_FACTOR_AUTHENTICATION_ENABLED = 'totp-required';

export const TWO_FACTOR_AUTH_ERRORS = {
  TWO_FACTOR_AUTH_REQUIRED: 'two_factor_auth_required',
  TWO_FACTOR_SETUP_REQUIRED: 'two_factor_setup_required',
  TWO_FACTOR_SESSION_TIMEOUT: 'two_factor_session_timeout',
};

export const SSO_ENABLED = 'sso_enabled';
export const SSO_REQUIRED_MESSAGE =
  'Your account uses Single Sign-On (SSO) instead of email and password. Please log in using SSO.';

export const INCORRECT_EMAIL_OR_PASSWORD = 'incorrect_email_or_password';
export const LOCKED_ACCOUNT = 'account_locked';

export const SSO_ERROR_KEYS = {
  SSO_CONFIG_NOT_FOUND: 'SSO_CONFIG_NOT_FOUND',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  LOGIN_NOT_FOUND: 'LOGIN_NOT_FOUND',
  SSO_INTEGRATION_FAILED: 'SSO_INTEGRATION_FAILED',
  SSO_EMAIL_NOT_FOUND: 'SSO_EMAIL_NOT_FOUND',
  SSO_EXPIRED_CERTIFICATE: 'SSO_EXPIRED_CERTIFICATE',
  INVALID_USER_INFO: 'INVALID_USER_INFO',
  GENERIC_SSO_ERROR: 'GENERIC_SSO_ERROR',
};

export const SSO_ERROR_MESSAGES = {
  [SSO_ERROR_KEYS.SSO_CONFIG_NOT_FOUND]:
    'Your company does not support login via SSO. Please contact your company administrator.',
  [SSO_ERROR_KEYS.USER_NOT_FOUND]: 'Login via SSO is only available for company managers.',
  [SSO_ERROR_KEYS.LOGIN_NOT_FOUND]: 'Login via SSO is only available for company managers.',
  [SSO_ERROR_KEYS.SSO_INTEGRATION_FAILED]:
    'Your company does not support login via SSO. Please contact your company administrator.',
  [SSO_ERROR_KEYS.SSO_EMAIL_NOT_FOUND]:
    'This email is not configured for SSO. Please contact your administrator.',
  [SSO_ERROR_KEYS.SSO_EXPIRED_CERTIFICATE]: 'Certificate has already expired. Please renew it.',
  [SSO_ERROR_KEYS.INVALID_USER_INFO]:
    'There was something wrong with your company SSO integration. Please contact your company administrator.',
  [SSO_ERROR_KEYS.GENERIC_SSO_ERROR]: 'Something went wrong with SSO sign in',
};

export const productTypeOptions = [
  {
    id: 'eor',
    label: "Hire employees in a country where we don't have an entity",
    value: 'eor',
  },
  {
    id: 'contractor',
    label: 'Manage and pay contractors',
    value: 'contractor',
  },
  {
    id: 'globalPayroll',
    label: "Pay employees using Remote's in-house payroll services",
    value: 'global_payroll',
  },
  {
    id: 'hris',
    label: 'Manage my global team on one platform',
    value: 'hris',
  },
];

export const projectedEmployeesOptions = [
  {
    id: 'one',
    label: '1',
    value: '1',
  },
  {
    id: 'twoToNine',
    label: '2-9',
    value: '2 - 9',
  },
  {
    id: 'moreThanTen',
    label: '10+',
    value: '10+',
  },
  {
    id: 'notSure',
    label: "I'm not sure yet",
    value: "I'm not sure yet",
  },
];

export const permissionsSignatureCookieName = 'tiger_permissions_signature';

export const LINKED_ACCOUNT_STATUS = {
  SIGNED_OUT: 'signed_out',
  SIGNED_IN: 'signed_in',
} as const;
