type BackgroundForStyledSystem = {
  'background.base': '#F3F4F8';
  'background.subtle': '#FAFCFF';
  'background.surface': '#FFFFFF';
};

type ForegroundForStyledSystem = {
  'foreground.onBrand': '#FFFFFF';
};

export const background = {
  base: '#F3F4F8',
  subtle: '#FAFCFF',
  surface: '#FFFFFF',
} as const;

export const foreground = {
  onBrand: '#FFFFFF',
} as const;

export const white = '#FFFFFF';

export const brand = {
  900: '#013D9E',
  800: '#0045B3',
  700: '#0053D9',
  600: '#0061FF',
  500: '#2496FF',
  400: '#63B4FF',
  300: '#93CBFF',
  200: '#BFDFFE',
  100: '#DBEDFE',
  50: '#EFF7FF',
} as const;

export const grey = {
  900: '#0F1419',
  800: '#202D39',
  700: '#364452',
  600: '#4B5865',
  500: '#697786',
  400: '#9AA6B2',
  300: '#CDD6DF',
  200: '#E3E9EF',
  100: '#EEF2F6',
  50: '#F8FAFC',
} as const;

export const red = {
  900: '#911818',
  800: '#B41818',
  700: '#D92020',
  600: '#F03838',
  500: '#F96666',
  400: '#FD9B9B',
  300: '#FECACA',
  200: '#FEE2E2',
  100: '#FEF2F2',
  50: '#FFFAFA',
} as const;

export const orange = {
  900: '#971F0C',
  800: '#BC2406',
  700: '#E63505',
  600: '#FF3F05',
  500: '#FF5F2E',
  400: '#FF9466',
  300: '#FFCFAE',
  200: '#FFE7D5',
  100: '#FFF5ED',
  50: '#FFF9F5',
} as const;

export const yellow = {
  900: '#933A0D',
  800: '#B54708',
  700: '#DC6503',
  600: '#F79009',
  500: '#FDAD22',
  400: '#FEC24B',
  300: '#FEDB89',
  200: '#FEEEC7',
  100: '#FFF9EB',
  50: '#FFFCF5',
} as const;

export const green = {
  900: '#056023',
  800: '#027A2A',
  700: '#039835',
  600: '#12B749',
  500: '#32D583',
  400: '#6CE996',
  300: '#A6F4C0',
  200: '#D1FADF',
  100: '#ECFDF3',
  50: '#F6FEF8',
} as const;

export const greenLight = {
  900: '#3A6212',
  800: '#3E7C0F',
  700: '#58A30D',
  600: '#71C61C',
  400: '#ABEF67',
  500: '#8DE13A',
  300: '#D1F8AB',
  200: '#E3FBCC',
  100: '#F3FEE7',
  50: '#FAFEF5',
} as const;

export const purple = {
  900: '#451FB8',
  800: '#5325DC',
  700: '#6638EF',
  600: '#7F5AF8',
  500: '#9D8AFB',
  400: '#C0B4FE',
  300: '#DCD6FE',
  200: '#ECE9FE',
  100: '#F5F3FF',
  50: '#FAFAFF',
} as const;

export const cyan = {
  900: '#156075',
  800: '#0E7290',
  700: '#088DB2',
  600: '#06ABD4',
  500: '#22CCEE',
  400: '#67E1F9',
  300: '#A5EDFC',
  200: '#CFF6FE',
  100: '#ECFDFF',
  50: '#F5FEFF',
} as const;

export const blueLight = {
  900: '#065586',
  800: '#0267A2',
  700: '#0086C9',
  600: '#0BA1EC',
  500: '#36B9FA',
  400: '#7CD2FD',
  300: '#B9E7FE',
  200: '#E0F4FE',
  100: '#F0FAFF',
  50: '#F5FCFF',
} as const;

export const fuchsia = {
  900: '#83198F',
  800: '#A01CAF',
  700: '#C026D3',
  600: '#D946EF',
  500: '#E879F9',
  400: '#F1ABFC',
  300: '#F6D0FE',
  200: '#FBE8FF',
  100: '#FDF4FF',
  50: '#FEFAFF',
} as const;

export const pink = {
  900: '#9E165F',
  800: '#C11579',
  700: '#DD2593',
  600: '#EE46B6',
  500: '#F670C5',
  400: '#FAA7DE',
  300: '#FCCEED',
  200: '#FCE7F5',
  100: '#FDF2F9',
  50: '#FEF6FB',
} as const;

type Background = typeof background;
type BackgroundColor = Background[keyof Background];
type Brand = typeof brand;
type BrandColor = Brand[keyof Brand];
type Grey = typeof grey;
type GreyColor = Grey[keyof Grey];
type Red = typeof red;
type RedColor = Red[keyof Red];
type Orange = typeof orange;
type OrangeColor = Orange[keyof Orange];
type Yellow = typeof yellow;
type YellowColor = Yellow[keyof Yellow];
type Green = typeof green;
type GreenColor = Green[keyof Green];
type GreenLight = typeof greenLight;
type GreenLightColor = GreenLight[keyof GreenLight];
type Purple = typeof purple;
type PurpleColor = Purple[keyof Purple];
type Cyan = typeof cyan;
type CyanColor = Cyan[keyof Cyan];
type BlueLight = typeof blueLight;
type BlueLightColor = BlueLight[keyof BlueLight];
type Fuchsia = typeof fuchsia;
type FuchsiaColor = Fuchsia[keyof Fuchsia];
type Pink = typeof pink;
type PinkColor = Pink[keyof Pink];

type Color =
  | BackgroundColor
  | BrandColor
  | GreyColor
  | RedColor
  | OrangeColor
  | YellowColor
  | GreenColor
  | GreenLightColor
  | PurpleColor
  | CyanColor
  | BlueLightColor
  | FuchsiaColor
  | PinkColor;

type Scale = '900' | '800' | '700' | '600' | '500' | '400' | '300' | '200' | '100' | '50';
type ColorForStyledSystem<Name extends string> = Record<`${Name}.${Scale}`, Color>;

export type PalettesForStyledSystem = BackgroundForStyledSystem &
  ForegroundForStyledSystem &
  ColorForStyledSystem<'brand'> &
  ColorForStyledSystem<'grey'> &
  ColorForStyledSystem<'red'> &
  ColorForStyledSystem<'orange'> &
  ColorForStyledSystem<'yellow'> &
  ColorForStyledSystem<'green'> &
  ColorForStyledSystem<'greenLight'> &
  ColorForStyledSystem<'purple'> &
  ColorForStyledSystem<'cyan'> &
  ColorForStyledSystem<'blueLight'> &
  ColorForStyledSystem<'fuchsia'> &
  ColorForStyledSystem<'pink'>;
