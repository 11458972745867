import { FeedbackMessage, FEEDBACK_MESSAGE_ERROR, Box } from '@remote-com/norma';
import { buildCompleteYupSchema } from '@remoteoss/json-schema-form';
import { Formik, useFormikContext } from 'formik';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { ErrorSummary } from '@/src/components/Form/ErrorSummary';
import FormButtonSubmit from '@/src/components/Form/FormButtonSubmit';
import { TrackForm } from '@/src/components/Form/TrackForm';
import {
  SubmitContainer,
  InputsContainer,
  FormContainer,
} from '@/src/domains/registration/onboarding/shared/styles/Form';
import { isDev, isTest, error } from '@/src/helpers/general';

import {
  checkFormIsEmpty,
  getInitialValues,
  setFieldsAsTouched,
  parseSubmitValues,
  getFieldsWithFlatFieldsets,
} from './helpers';
import RenderFields from './RenderFields';

const FormDraft = dynamic(() => import('./FormDraft'), { ssr: false });
const FormDraftModal = dynamic(() => import('./FormDraftModal'), { ssr: false });

function shouldShowDraftModal(fieldValues, draft) {
  const hasEmptyFieldValues = checkFormIsEmpty(fieldValues);
  const hasDraft = !checkFormIsEmpty(draft?.values);
  return (draft?.shouldShowDraftModal || hasEmptyFieldValues) && hasDraft;
}

const FormikValuesObserver = ({ onFieldChange }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    onFieldChange(values);
  }, [values, onFieldChange]);
  return null;
};

const DynamicForm = ({
  formSize,
  formName,
  showSubmitButton,
  fields,
  fieldValues,
  formError,
  formErrorTitle,
  showErrorSummary,
  validateOnMount,
  validateOnBlur,
  showFieldsDeprecated,
  isLoading,
  biggerMargin,
  handleOnSubmit,
  // eslint-disable-next-line camelcase
  TODO_MIGRATE_handleOnSubmit,
  onFilesRemoved,
  fieldsets,
  submitButtonLabel,
  submitButtonSubLabel,
  submitButtonProps,
  strictFieldNames,
  enableReinitialize,
  validationSchema: yupValidationSchema,
  validate,
  innerRef,
  renderFields,
  children,
  isSubmitButtonDisabled,
  draft,
  onFieldChange,
  fieldErrors,
  enableAIAssistant,
  aiAssistantConfig,
}) => {
  const [isDraftModalVisible, setDraftModalVisibility] = useState(() =>
    shouldShowDraftModal(fieldValues, draft)
  );

  const initialValues = renderFields
    ? fieldValues
    : getInitialValues(fields, fieldValues, { strictFieldNames });

  const validationSchema = yupValidationSchema || buildCompleteYupSchema(fields);

  const isMissingPropToRefactorSubmitValues = !['TODO', 'DONE'].includes(
    TODO_MIGRATE_handleOnSubmit
  );

  if (isMissingPropToRefactorSubmitValues) {
    const migrationErrorMsg = `🚨🚨 DynamicForm: TODO_MIGRATE_handleOnSubmit prop is missing in form ${formName} - See RMT-388`;
    const isDevOrTestMocked = isDev() || isTest() || isTest() === undefined;
    if (
      // this catches tests where the util is mocked, forcing the component
      // (and the test) to break in case the prop is missing
      isDevOrTestMocked
    ) {
      throw Error(migrationErrorMsg);
    } else {
      // Make it easier to catch on Staging/Production
      console.error(migrationErrorMsg);
      error(migrationErrorMsg);
    }
  }

  const handleInternalSubmit = async (formValues, formikBag) => {
    // NOTE: In !1569, code was added to prevent double submits of forms.
    // This code caused faulty submits for select fields, so we've removed it
    // but kept this as a safeguard.
    if (formikBag.isSubmitting || !handleOnSubmit) {
      return true;
    }

    // eslint-disable-next-line camelcase
    const refactorProp = TODO_MIGRATE_handleOnSubmit;

    if (refactorProp !== 'DONE') {
      // NOTE: Use old signature for forms not refactored yet (RMT-294).
      // Read docs "handling submit values" for more details.
      return handleOnSubmit(formValues, formikBag);
    }

    const parsedValues = parseSubmitValues(formValues, fields);

    return handleOnSubmit(parsedValues, formikBag, { originalValues: formValues });
  };

  const handleDeleteDraft = () => {
    setDraftModalVisibility(false);
  };

  const handleUseDraft = (setValues) => {
    setValues(getInitialValues(fields, draft?.values));
    setDraftModalVisibility(false);
  };

  const fieldsToRender = getFieldsWithFlatFieldsets({ fields, fieldsets });

  return (
    <Formik
      /**
       * Formik needs all fields to have initial value to get touched flag set (used for displaying errors) when firing onSubmit handler
       * read https://github.com/formium/formik/blob/34a11422bf1619236bc9fdb1b7c4f0d285638702/packages/formik/src/Formik.tsx#L98
       * For some use cases, fields list changes on rerender, so the initialValues;
       * but Formik doesn't take it into consideration if enableReinitialize is not set to true, and ends up not showing errors
       */
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      validationSchema={validate ? null : validationSchema} // avoid double validation
      validate={validate}
      onSubmit={handleInternalSubmit}
      innerRef={innerRef}
      initialTouched={validateOnMount ? setFieldsAsTouched(initialValues) : undefined}
      validateOnMount={validateOnMount}
      validateOnBlur={validateOnBlur}
    >
      {(formikBag) => {
        const { handleSubmit, isSubmitting, values, setFieldValue, setValues } = formikBag;
        return (
          <FormContainer id={formName} data-testid={formName} onSubmit={handleSubmit} noValidate>
            {draft?.id && <FormDraft draft={draft} fields={fields} />}
            {draft?.id && (
              <FormDraftModal
                isVisible={isDraftModalVisible}
                onCancel={handleDeleteDraft}
                onConfirm={() => handleUseDraft(setValues)}
                formName={formName}
                draft={draft}
              />
            )}
            {onFieldChange && <FormikValuesObserver onFieldChange={onFieldChange} />}
            <ThemeProvider theme={{ size: formSize }}>
              <TrackForm formName={formName} systemErrors={fieldErrors} />
              <InputsContainer>
                {renderFields ? (
                  renderFields({
                    values,
                    fields,
                    setFieldValue,
                    showFieldsDeprecated,
                    onFilesRemoved,
                    enableAIAssistant,
                    aiAssistantConfig,
                  })
                ) : (
                  <RenderFields
                    fields={fieldsToRender}
                    biggerMargin={biggerMargin}
                    onFilesRemoved={onFilesRemoved}
                    setFieldValue={setFieldValue}
                    formValues={values}
                    showFieldsDeprecated={showFieldsDeprecated}
                    enableAIAssistant={enableAIAssistant}
                    aiAssistantConfig={aiAssistantConfig}
                  />
                )}
              </InputsContainer>
              {showErrorSummary ? (
                <ErrorSummary title={formErrorTitle} errorMessage={formError} formName={formName} />
              ) : (
                formError && (
                  <FeedbackMessage my={7} variant={FEEDBACK_MESSAGE_ERROR} isAriaLiveRegion>
                    {formError}
                  </FeedbackMessage>
                )
              )}

              {/* // TODO refactor to use React Context https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/13517 */}
              {children && children(formikBag)}

              {showSubmitButton && (
                <SubmitContainer>
                  <FormButtonSubmit
                    isLoading={isLoading || isSubmitting}
                    data-testid={`${formName}-submit`}
                    disabled={isSubmitButtonDisabled}
                    {...submitButtonProps}
                  >
                    {submitButtonLabel}
                  </FormButtonSubmit>
                  {!!submitButtonSubLabel && <Box mt="16px">{submitButtonSubLabel}</Box>}
                </SubmitContainer>
              )}
            </ThemeProvider>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

DynamicForm.defaultProps = {
  formError: '',
  fieldValues: {},
  submitButtonLabel: 'Continue',
  biggerMargin: false,
  enableReinitialize: false,
  isSubmitButtonDisabled: false,
  validateOnBlur: true,
};

DynamicForm.propTypes = {
  formSize: PropTypes.string,
  /** Render function that passes down Formik children props */
  children: PropTypes.func,
  biggerMargin: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  /** List of fields configurations. Read /helpers.jsx for docs */
  fields: PropTypes.arrayOf(PropTypes.object),
  /**
   * Configures field groupings within the form.
   * Supports two modes:
   * 1. Standard: A nested object structure (commonly used)
   * 2. Flat: A flat object (less common, used when the API expects a flat structure)
   *
   * For an example of flat fieldsets, check the "Flat Fieldsets" story in Storybook.
   */
  fieldsets: PropTypes.object,
  /** function to render more complex RenderFields instance
   * Probably you don't need this unless there's a specific reason you cannot pass `fields` to DynamicForm directly
   *
   * renderFields={({values, setFieldValue}) => <ComponentThatUsesRenderFieldsInternally values={values} fields={fieldsFromElsewhere}/>}
   */
  renderFields: (props, propName, componentName) => {
    if (props.fields == null && props.renderFields == null) {
      return new Error(
        `Missing "fields" prop in "${componentName}". You should add "fields" or "${propName}".`
      );
    }
    return null;
  },
  /** Default field values */
  fieldValues: PropTypes.object,
  formError: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fieldErrors: PropTypes.object,
  /** When true, always renders deprecated fields, even if empty. Currently used for debugging purposes (aka Country Forms) */
  showFieldsDeprecated: PropTypes.bool,
  formErrorTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Wether to show the error summary at the bottom of the form */
  showErrorSummary: PropTypes.bool,
  /** When true, it renders the default submit button. If the submit button perfoms an
   * asynchronous action the onSubmit prop must be an async function or return a promise
   * so that the isSubmitting state can be reset once the asynchronous action has been
   * fulfilled. https://formik.org/docs/api/formik#onsubmit-values-values-formikbag-formikbag--void--promiseany
   */
  showSubmitButton: PropTypes.bool,
  /**
   * When enabled no transformation on keys will be performed (snake_case or kebab-case)
   * Useful when working on form with ie. slugs as a field names.
   */
  strictFieldNames: PropTypes.bool,
  handleOnSubmit: PropTypes.func,
  /**
   * The form submit is not yet refactored to use the parsed values.
   * Read more about it at DynamicForm.handleSubmit.stories.mdx.
   */
  TODO_MIGRATE_handleOnSubmit: PropTypes.oneOf(['TODO', 'DONE']),
  submitButtonLabel: PropTypes.string,
  submitButtonSubLabel: PropTypes.string,
  submitButtonProps: PropTypes.object,
  /** Formik prop - It forces form config reinitialization */
  enableReinitialize: PropTypes.bool,
  /** Formik prop - When true, sets all fields as touched and shows their errors on the initial render */
  validateOnMount: PropTypes.bool,
  /** Formik prop - Validates the field when it loses focus, providing immediate feedback */
  validateOnBlur: PropTypes.bool,
  validate: PropTypes.func,
  validationSchema: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isSubmitButtonDisabled: PropTypes.bool,
  /** used to read Formik's values; it will receive the forms values */
  onFieldChange: PropTypes.func,
  onFilesRemoved: PropTypes.func,
  /** used for form drafts */
  draft: PropTypes.shape({
    id: PropTypes.string,
    values: PropTypes.object,
    saveParsingFn: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    shouldShowDraftModal: PropTypes.bool,
  }),
  isLoading: PropTypes.bool,
  /** Whether to enable the AI Form Assistant for the fields */
  enableAIAssistant: PropTypes.bool,
  /** Configuration for the AI Form Assistant */
  aiAssistantConfig: PropTypes.shape({
    /** The title of the form */
    formTitle: PropTypes.string,
    /**  A description of the form, used to provide context to the AI */
    formContext: PropTypes.string,
    /** When set to true, the AI assistant will generate content as soon as it is opened, without asking for user input. */
    generateOnOpen: PropTypes.bool,
  }),
};

/**
 * DynamicFormFields is a helper that allows using
 * DynamicForm fields and a custom Formik instance.
 */
export const DynamicFormFields = ({
  formSize,
  fields,
  biggerMargin,
  onFilesRemoved,
  values,
  enableAIAssistant,
  aiAssistantConfig,
  ...props
}) => (
  <ThemeProvider theme={{ size: formSize }}>
    <InputsContainer>
      <RenderFields
        fields={fields}
        biggerMargin={biggerMargin}
        onFilesRemoved={onFilesRemoved}
        formValues={values}
        enableAIAssistant={enableAIAssistant}
        aiAssistantConfig={aiAssistantConfig}
        {...props}
      />
    </InputsContainer>
  </ThemeProvider>
);

DynamicFormFields.propTypes = {
  formSize: DynamicForm.propTypes.formSize,
  fields: DynamicForm.propTypes.fields,
  biggerMargin: DynamicForm.propTypes.biggerMargin,
  values: DynamicForm.propTypes.fieldValues,
  enableAIAssistant: DynamicForm.propTypes.enableAIAssistant,
  aiAssistantConfig: DynamicForm.propTypes.aiAssistantConfig,
};

export default DynamicForm;
