import type { DefaultTheme, ThemeProps } from 'styled-components';
import styled, { css } from 'styled-components';

import { Box } from '../../../foundations/layout/Box';

type FocusRingProps = ThemeProps<DefaultTheme> & {
  outerColor?: string;
};

export const focusRing = ({ theme, outerColor = theme.colors.brand[500] }: FocusRingProps) => css`
  box-shadow: 0 0 0 2px ${theme.colors.blank}, 0 0 0 4px ${outerColor};
`;

export const DatePickerCalendarBasicContainer = styled(Box).attrs({
  as: 'article', // For A11Y reasons. Demo at !22321
  'aria-label': 'Date picker widget',
})<{}>`
  ${({ theme }) => css`
    --date-picker-column-gap: 4px;
    --date-picker-circle-size: 40px;
    --date-picker-padding-size: ${theme.space[6]}px;
    --date-picker-month-padding-size: ${theme.space[5]}px;
    --date-picker-header-height: 56px;
    --date-picker-day-names-height: 56px;
    --date-picker-footer-height: 58px;
    --date-picker-z-index-disabled: 1;
    --date-picker-z-index-date: 2;
    --date-picker-width: 384px;
    --date-picker-number-of-days: 7;
    --date-picker-grid-cell-width: calc(
      (var(--date-picker-width) - var(--date-picker-padding-size) * 2) /
        var(--date-picker-number-of-days)
    );

    background-color: ${theme.colors.background.surface};
    border-radius: ${theme.borderRadius.md} var(--right-border-radius) ${theme.borderRadius.md}
      ${theme.borderRadius.md};
    box-shadow: 0px 0px ${theme.space[4]}px 0px #0f141914;

    user-select: none;
    width: var(--date-picker-width);

    .react-datepicker__day-names,
    .react-datepicker__week {
      display: grid;
      grid-template-columns: repeat(var(--date-picker-number-of-days), 1fr);
      place-items: center;
    }

    .react-datepicker__day-names {
      height: var(--date-picker-day-names-height);
      padding: var(--date-picker-month-padding-size) var(--date-picker-month-padding-size) 0;

      color: ${theme.colors.grey[900]};

      ${theme.typography.smMedium}
    }

    .react-datepicker__month {
      display: grid;
      gap: var(--date-picker-column-gap);
      grid-template-rows: repeat(6, 1fr);
      /* Use padding instead of margin to prevent the .focus-visible box-shadow from being clipped */
      padding: var(--date-picker-column-gap) var(--date-picker-month-padding-size)
        var(--date-picker-month-padding-size);

      /* We need the added classname to increase specificity */
      &--disabled.react-datepicker__month-text {
        color: ${theme.colors.grey[200]};
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .react-datepicker__month.react-datepicker__monthPicker {
      grid-template-rows: repeat(4, 1fr);
    }

    .react-datepicker__month-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .react-datepicker__month-text,
    .react-datepicker__day {
      position: relative;
      border-radius: ${theme.borderRadius.full};
      color: ${theme.colors.grey[900]};
      cursor: pointer;
      display: grid;
      place-items: center;

      height: var(--date-picker-circle-size);
      width: var(--date-picker-circle-size);

      ${theme.typography.sm}

      --date-picker-marker-color: ${theme.colors.brand[600]};

      &:hover {
        background-color: ${theme.colors.grey[100]};
      }

      &:focus-within:not(:where(&--selected, &--in-range)),
      &--keyboard-selected {
        background-color: ${theme.colors.grey[200]};
      }

      &.focus-visible {
        /* We don't want the focus ring to show with the "selecting" style */
        &:not(.react-datepicker__day--in-selecting-range) {
          ${focusRing({ theme, outerColor: theme.colors.grey[500] })};
        }

        &.react-datepicker__day--selected {
          ${focusRing};
        }
      }

      /* Styles for "today", when not selected */
      &.react-datepicker__day--today:not(:where(&--selected, &--in-selecting-range, &--in-range)) {
        color: ${theme.colors.brand[600]};
        border: 1px solid ${theme.colors.brand[600]};
        background-color: ${theme.colors.white};

        &:hover {
          background-color: ${theme.colors.brand[100]};
        }

        &:focus-visible {
          ${focusRing}
          background-color: ${theme.colors.brand[50]};
        }

        &.react-datepicker__day--disabled {
          color: ${theme.colors.grey[400]};
          background-color: ${theme.colors.white};
          border: 1px solid ${theme.colors.grey[400]};

          &:focus-visible {
            ${focusRing({ theme, outerColor: theme.colors.grey[500] })}
            background-color: ${theme.colors.grey[200]};
          }
        }
      }

      :focus-visible,
      &:focus:not(:focus-visible) {
        outline: 2px solid transparent;
      }

      &--outside-month {
        color: ${theme.colors.grey[500]};
      }

      &--selected {
        background-color: ${theme.colors.brand[600]};
        color: white;
        --date-picker-marker-color: ${theme.colors.white};

        &:hover {
          background-color: ${theme.colors.brand[700]};
        }
      }

      &--disabled {
        color: ${theme.colors.grey[400]};
        cursor: not-allowed;

        * {
          cursor: inherit;
        }
      }
    }
  `}
`;
