import getConfig from 'next/config';

import { SIGN_IN_ROUTE } from '@/src/constants/routes';
import { getRedirectPathFromUrl } from '@/src/domains/registration/auth/helpers/redirects';

const {
  publicRuntimeConfig: { ENVIRONMENT },
} = getConfig();

const isEnabled = ENVIRONMENT !== 'production';

function handle404MissingPermissions(response) {
  if (!isEnabled) {
    return;
  }

  const details = response.data.requiredPermissions.map((permission) => {
    const permissionDetails = {
      endpoint: `${response.config.url}`,
      method: `${response.config.method}`,
      permission: permission.name,
      accessLevel: permission.level,
    };

    return permissionDetails;
  });

  const existingFailedRequests = JSON.parse(sessionStorage.getItem('permission-denied-requests'));

  if (existingFailedRequests) {
    const found = details.filter((failedPermission) =>
      existingFailedRequests.some(
        (existingFailedPermission) =>
          existingFailedPermission.endpoint === failedPermission.endpoint &&
          existingFailedPermission.method === failedPermission.method
      )
    );

    if (!found || found.length === 0) {
      const mergedRequests = [...existingFailedRequests, details];
      sessionStorage.setItem('permission-denied-requests', JSON.stringify(mergedRequests.flat()));
    }
  } else {
    sessionStorage.setItem('permission-denied-requests', JSON.stringify(details));
  }

  window.dispatchEvent(new CustomEvent('permission-denied'));
}

// handler to axios response.interceptor error
export async function onAuthFail(error) {
  const { config: originalReq, response } = error || {};

  // Handling missing permission 404s
  if (
    typeof window !== 'undefined' &&
    response?.status === 404 &&
    response.data?.requiredPermissions
  ) {
    handle404MissingPermissions(response);
    return Promise.reject(error);
  }

  const isUserNotAuthorized = response?.status === 401;
  // BE tracks the session inactivity and returns a 403 error when we try to access a API have sensitive action (like modifying Nium beneficiaries)
  // When this happens, BE also deletes the session so we need to redirect to sign in page for user to login again
  const isUserForbiddenAccessDueToInactivity =
    response?.status === 403 && response?.data?.code === 'sca_session_inactive';

  if (
    (isUserNotAuthorized || isUserForbiddenAccessDueToInactivity) &&
    !originalReq?.skipCustomAuthInterceptor
  ) {
    // If a 401 request occurs, redirect to sign in as it means the user is no longer authorized
    if (typeof window !== 'undefined') {
      window.location.href = getRedirectPathFromUrl({
        url: SIGN_IN_ROUTE,
        asPath: window.location.pathname,
      });

      // Simulate in progress request
      // Prevents error while redirecting
      return new Promise(() => {});
    }
  }

  // proceed as normal
  return Promise.reject(error);
}
