// @TODO: Remove this once this is enabled by default on all countries
import { useEffect } from 'react';
import { create } from 'zustand';

import { useLegalEntity } from '@/src/domains/legalEntities/hooks/useLegalEntitiesData';

type Store = {
  isEnabled: boolean;
  setIsEnabled: (isEnabled: boolean) => void;
};

export const timeTrackingIntegrationEnabledStore = create<Store>((set) => ({
  isEnabled: false,
  setIsEnabled: (isEnabled) => set({ isEnabled }),
}));

/**
 * Currently this is only used in the ADMIN UI, for the Payroll Run page.
 *
 * You might use this in more pags. Just stick the legalEntitySlug there and include the hook on the page, and also
 * take the loading state into consideration.
 */
export function useTimeTrackingIntegrationEnabler(legalEntitySlug?: string) {
  // Currently we only care about the ADMIN UI
  const { data: legalEntity, isLoading } = useLegalEntity(legalEntitySlug, {
    enabled: !!legalEntitySlug,
  });

  useEffect(() => {
    if (legalEntity?.timeTrackingIntegrationEnabled) {
      timeTrackingIntegrationEnabledStore.getState().setIsEnabled(true);
    }

    return () => {
      timeTrackingIntegrationEnabledStore.getState().setIsEnabled(false);
    };
  }, [legalEntity?.timeTrackingIntegrationEnabled]);

  return { isLoading };
}
