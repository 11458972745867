import { Resources } from '@/src/domains/registration/auth/constants/permissions';

export const enumToOptionFields = (constantList) =>
  Object.values(constantList).map(({ id: value, label, description, ...rest }) => ({
    value,
    label,
    description,
    suffix: description,
    ...rest,
  }));

/**
 * This is the whole list of payroll run statuses, either for EOR and global payroll.
 * You might find different filtered lists for each specific payroll type below.
 */
export const PAYROLL_RUN_STATUSES = /** @type {const} */ ({
  PREPARING: {
    id: 'preparing',
    label: 'Preparing',
    infoLabel: 'Preparing. Payroll inputs (like expenses) can be added.',
    badge: 'inactive',
    status: 'Preparing payroll',
    description:
      "We're gathering the required pay data for this payroll cycle. No action needed from your end!",
    stepTitle: 'Preparing payroll',
    overviewTitle: 'Payroll is being prepared',
    sortKey: 0,
  },
  PROCESSING: {
    id: 'processing',
    label: 'Processing',
    infoLabel: 'Processing. Payroll outputs and payslips can be uploaded.',
    badge: 'pending',
    status: 'Remote is processing',
    description:
      "Nothing to do here yet! We're simply validating and processing all the pay information.",
    stepTitle: 'Processing payroll',
    overviewTitle: 'Payroll is being processed',
    sortKey: 1,
  },
  WAITING_FOR_CUSTOMER_APPROVAL: {
    id: 'waiting_for_customer_approval',
    label: 'Waiting for customer approval',
    infoLabel: 'Waiting for customer approval.',
    badge: 'pending',
    status: 'Needs approval',
    description: 'Please, review and approve all the outcomes.',
    stepTitle: 'Customer approves payroll',
    overviewTitle: 'Payroll is waiting for customer approval',
    sortKey: 2,
  },
  FINALIZED: {
    id: 'finalized',
    label: 'Finalized',
    infoLabel: 'Finalized. Numbers are final.',
    badge: 'scheduled',
    status: 'Numbers are final',
    description:
      'Ready for payments to be processed, invoices to be prepared, and payslips to be uploaded.',
    stepTitle: 'Finalize outcomes',
    overviewTitle: 'Payroll is being finalized',
    sortKey: 3,
  },
  COMPLETED: {
    id: 'completed',
    label: 'Completed',
    infoLabel: 'Complete. This payroll run was approved.',
    badge: 'active',
    status: 'All done!',
    description: 'All done! Your hardworking team is paid their deserved compensation.',
    stepTitle: 'All done',
    overviewTitle: 'Payroll is completed',
    sortKey: 4,
  },
  REJECTED: {
    id: 'rejected',
    label: 'Canceled',
    infoLabel: 'Canceled. This payroll run was rejected and should not be used.',
    badge: 'error',
    status: 'Payroll canceled',
    description: 'Payroll was canceled by your team',
    overviewTitle: 'Payroll is canceled',
    sortKey: Infinity,
  },
});

// ALL
// Filters with all statuses id
export const ALL_PAYROLL_RUN_STATUSES_ID = Object.values(PAYROLL_RUN_STATUSES).map(
  (status) => status.id
);

/**
 * List of statuses with whole struture
 *
 * @type {Record<keyof typeof PAYROLL_RUN_STATUSES.id, import('type-fest').ValueOf<typeof PAYROLL_RUN_STATUSES>>}
 */
export const PAYROLL_RUN_STATUSES_BY_ID = Object.values(PAYROLL_RUN_STATUSES).reduce(
  (acc, status) => ({ ...acc, [status.id]: status }),
  {}
);

// Global payroll
// List of statuses with whole struture
export const GLOBAL_PAYROLL_RUN_STATUSES = Object.fromEntries(
  Object.entries({
    ...PAYROLL_RUN_STATUSES,
    COMPLETED: {
      id: 'completed',
      label: 'Completed',
      infoLabel: 'Complete. This payroll run was approved.',
      badge: 'active',
      status: 'Payment file is ready',
      description: 'Download the payment file before pay day to pay the employees on time.',
      stepTitle: 'You pay your team',
      overviewTitle: 'Payroll is completed',
    },
  })
);

export const ACTIVE_GLOBAL_PAYROLL_RUN_STATUSES = Object.fromEntries(
  Object.entries(GLOBAL_PAYROLL_RUN_STATUSES).filter(
    (status) => ![GLOBAL_PAYROLL_RUN_STATUSES.REJECTED.id].includes(status[1].id)
  )
);

// EOR
// Filters with all active payroll runs. Those that are rejected do not matter
export const ACTIVE_PAYROLL_RUN_FILTER_STATUS_IDS = Object.values(PAYROLL_RUN_STATUSES)
  .map((payrollRunStatus) => payrollRunStatus.id)
  .filter((payrollRunStatus) => payrollRunStatus !== PAYROLL_RUN_STATUSES.REJECTED.id);

export const ACTIVE_EOR_PAYROLL_RUN_STATUSES = Object.fromEntries(
  Object.entries(PAYROLL_RUN_STATUSES).filter(
    (status) =>
      ![
        PAYROLL_RUN_STATUSES.REJECTED.id,
        PAYROLL_RUN_STATUSES.WAITING_FOR_CUSTOMER_APPROVAL.id,
      ].includes(status[1].id)
  )
);

// List of statuses with whole struture
// We should not allow the user to change the status to reject, hence the need to remove it from the list
export const EOR_PAYROLL_RUN_STATUSES = Object.fromEntries(
  Object.entries(PAYROLL_RUN_STATUSES).filter(
    (status) => ![PAYROLL_RUN_STATUSES.WAITING_FOR_CUSTOMER_APPROVAL.id].includes(status[1].id)
  )
);

export const PAYROLL_RUN_TYPES = /** @type {const} */ ({
  MAIN: {
    id: 'main',
    label: 'Main',
  },
  ONEOFF: {
    id: 'one_off',
    label: 'Off-cycle',
  },
  PROFORMA: {
    id: 'pro_forma',
    label: 'Pro Forma',
  },
  TAX_DOCUMENTS: {
    id: 'tax_documents',
    label: 'Tax Documents',
  },
  EXPENSES: {
    id: 'expenses',
    label: 'Expenses',
  },
});
export const PAYROLL_RUN_TYPES_OPTIONS = enumToOptionFields(PAYROLL_RUN_TYPES);

export const PAYROLL_RUN_TYPES_BY_ID =
  /** @type {Record<keyof typeof PAYROLL_RUN_TYPES.id, import('type-fest').ValueOf<PAYROLL_RUN_TYPES>>} */ (
    Object.values(PAYROLL_RUN_TYPES).reduce((acc, type) => ({ ...acc, [type.id]: type }), {})
  );

export const ALL_PAYROLL_RUN_TYPES = Object.values(PAYROLL_RUN_TYPES).map((t) => t.id);

/**
 * Global cutoff date of Payroll Inputs.
 * */
export const GLOBAL_MONTHLY_CUTOFF_DATE = 11;

export const productTypes = {
  EOR: 'eor',
  EOR_TEAM_PLAN: 'eor_team_plan',
  EOR_ANNUAL_PLAN: 'eor_annual_plan',
  GLOBAL_PAYROLL: 'global_payroll',
  DIRECT: 'direct',
  PEO: 'peo',
};

export const productTypeLabels = {
  [productTypes.EOR]: 'EOR',
  [productTypes.EOR_TEAM_PLAN]: 'EOR',
  [productTypes.EOR_ANNUAL_PLAN]: 'EOR',
  [productTypes.GLOBAL_PAYROLL]: 'Global Payroll',
  [productTypes.PEO]: 'Co-employment',
};

export const productTypeOptions = [
  {
    id: productTypes.EOR,
    label: productTypeLabels[productTypes.EOR],
    value: productTypes.EOR,
  },
  {
    id: productTypes.GLOBAL_PAYROLL,
    label: productTypeLabels[productTypes.GLOBAL_PAYROLL],
    value: productTypes.GLOBAL_PAYROLL,
  },
  {
    id: productTypes.PEO,
    label: productTypeLabels[productTypes.PEO],
    value: productTypes.PEO,
  },
];

export const PAYMENT_PROVIDERS_OPTIONS = [
  {
    id: 'nium',
    label: 'Remote Payments (Nium)',
    value: 'remote_payments_nium',
  },
  {
    id: 'ebury',
    label: 'Remote Payments (Ebury)',
    value: 'remote_payments_ebury',
  },
];

export const exportTypes = {
  TIMEOFF_BALANCES: 'timeoff-balances',
  PAYROLL_RUN: 'payroll-run',
  TIME_OFF: 'timeoff',
  PAYROLL_INPUT: 'payroll-input',
};

// Filters
export const effectiveDateFilters = { start: 'effective_start_date', end: 'effective_end_date' };
export const insertedAtFilters = { start: 'inserted_at_from', end: 'inserted_at_to' };
export const reviewedAtFilters = { start: 'reviewed_at_from', end: 'reviewed_at_to' };
export const startDateFilters = { start: 'start_date_from', end: 'start_date_to' };
export const dateRangeFilters = { start: 'start_date', end: 'end_date' };
export const createdDateFilters = { start: 'created_start_date', end: 'created_end_date' };

// Operation types
export const PAYSLIP_OPERATION_TYPE = { type: 'payslip', subType: 'payslip' };
export const YEAR_END_OPERATION_TYPE = { type: 'payroll_run', subType: 'year_end_statement' };
export const COMPANY_DOCUMENT_TYPE = { type: 'payroll_run', subType: 'company_document' };
export const INTERNAL_DOCUMENT_TYPE = { type: 'payroll_run', subType: 'internal_document' };

// Payroll inputs
export const PAYROLL_INPUT_TYPES = /** @type {const} */ ({
  EMPLOYEE: 'employee',
  EXPENSE: 'expense',
  INCENTIVE: 'incentive',
  ADJUSTMENT: 'adjustment',
  TIMEOFF: 'timeoff',
  EMPLOYEE_PAY_ELEMENT: 'employee-pay-element',
  EMPLOYEE_DATA_ELEMENT: 'employee-data-element',
  OFFBOARDING: 'offboarding',
});

// Permissions for the suggestions related to each input type
// Supports multiple permissions per key
export const PAYROLL_INPUT_SUGGESTION_PERMISSIONS = /** @type {any} */ ({
  [PAYROLL_INPUT_TYPES.EMPLOYEE]: [['read', Resources.contracts]],
  [PAYROLL_INPUT_TYPES.EXPENSE]: [['update', Resources.expenses]],
  [PAYROLL_INPUT_TYPES.INCENTIVE]: [['update', Resources.incentives]],
  [PAYROLL_INPUT_TYPES.ADJUSTMENT]: [['update', Resources.adjustments]],
  [PAYROLL_INPUT_TYPES.TIMEOFF]: [['update', Resources.time_off]],
  [PAYROLL_INPUT_TYPES.EMPLOYEE_PAY_ELEMENT]: [
    ['read', Resources.employee_pay_elements],
    ['update', Resources.employee_pay_elements],
  ],
  [PAYROLL_INPUT_TYPES.EMPLOYEE_DATA_ELEMENT]: [
    ['read', Resources.employee_data_elements],
    ['update', Resources.employee_data_elements],
  ],
  [PAYROLL_INPUT_TYPES.OFFBOARDING]: [['update', Resources.offboarding_payroll]],
});

// Turns a payroll run status into a simpler message aimed at employers and non-payroll ops users
export const PAYROLL_RUN_STATUS_FRIENDLY_COPY = /** @type {const} */ ({
  [PAYROLL_RUN_STATUSES.PREPARING.id]: 'In progress',
  [PAYROLL_RUN_STATUSES.PROCESSING.id]: 'In progress',
  [PAYROLL_RUN_STATUSES.WAITING_FOR_CUSTOMER_APPROVAL.id]: 'Needs approval',
  [PAYROLL_RUN_STATUSES.FINALIZED.id]: 'Finalized',
  [PAYROLL_RUN_STATUSES.COMPLETED.id]: 'Completed',
  [PAYROLL_RUN_STATUSES.REJECTED.id]: 'Rejected',
});

// Some friendly labels map to more than one payroll run status.
// The select filters don't do well with options that have arrays as values,
// so we use the friendly label also for the option value.
// Before sending the filter to the endpoint, we transform the friendly label into the actual payroll run statuses.
export const PAYROLL_RUN_STATUS_FRIENDLY_COPY_OPTIONS = [
  {
    label: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.PREPARING.id],
    value: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.PREPARING.id],
    statuses: [PAYROLL_RUN_STATUSES.PREPARING.id, PAYROLL_RUN_STATUSES.PROCESSING.id],
  },
  {
    label: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.WAITING_FOR_CUSTOMER_APPROVAL.id],
    value: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.WAITING_FOR_CUSTOMER_APPROVAL.id],
    statuses: [PAYROLL_RUN_STATUSES.WAITING_FOR_CUSTOMER_APPROVAL.id],
  },
  {
    label: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.FINALIZED.id],
    value: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.FINALIZED.id],
    statuses: [PAYROLL_RUN_STATUSES.FINALIZED.id],
  },
  {
    label: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.COMPLETED.id],
    value: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.COMPLETED.id],
    statuses: [PAYROLL_RUN_STATUSES.COMPLETED.id],
  },
  {
    label: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.REJECTED.id],
    value: PAYROLL_RUN_STATUS_FRIENDLY_COPY[PAYROLL_RUN_STATUSES.REJECTED.id],
    statuses: [PAYROLL_RUN_STATUSES.REJECTED.id],
  },
];
