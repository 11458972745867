import { DropdownMenu, Toggle, Tooltip } from '@remote-com/norma';
import { IconV2OutlineRefreshCw } from '@remote-com/norma/icons/IconV2OutlineRefreshCw';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { Button } from '@/src/components/Button';
import { DropdownMenuButtonIcon, MenuItem } from '@/src/components/DropdownMenu';
import {
  Toolbar,
  ToolbarControls,
  ToolbarActions,
} from '@/src/components/Table/Components/TableComponents.styled';
import { useTableUserSettingsContext } from '@/src/components/Table/Components/TableUserSettingsProvider';
import BulkSelection from '@/src/components/Table/Components/Toolbar/BulkSelection';
import { EditColumnsButton } from '@/src/components/Table/Components/Toolbar/EditColumnsButton';
import { ExportCSVButton } from '@/src/components/Table/Components/Toolbar/ExportCSVButton';
import { GlobalFilter } from '@/src/components/Table/Components/Toolbar/GlobalFilter';
import FilterManagerDrawer from '@/src/components/Table/Components/Toolbar/SaveFilters/FilterManagerDrawer';
import { SaveFilterContextProvider } from '@/src/components/Table/Components/Toolbar/SaveFilters/SaveFilterContext';
import SmartSearchInput from '@/src/components/Table/Components/Toolbar/SmartSearch/SmartSearchInput';
import { FiltersPopoverTable } from '@/src/components/Table/Components/Toolbar/TableFilters';
import { TableViewFloatingActions } from '@/src/components/Table/Components/Toolbar/TableViewFloatingActions';
import { TableViewMenu } from '@/src/components/Table/Components/Toolbar/TableViewMenu';
import { trackCompactTablesEvent } from '@/src/components/Table/helpers/tableTrackingEvents';
import { useUser } from '@/src/components/UserProvider/context';
import useComponentDidUpdate from '@/src/hooks/useDidUpdate';

export const TableToolbar = ({
  columnsStateProps,
  setGlobalFilter,
  state,
  globalFilter,
  globalFilterPlaceholder,
  globalFilterProps,
  FilterToolbarComponent,
  hideToolbarFilterButton,
  toolbarSupportingText,
  isBulkEditActivated,
  filters,
  bulkEditOnClick,
  BulkEditComponent,
  exportCSV,
  exportWithExcelSupport,
  exportFooter,
  addRecordAction,
  onRowSelectionChange,
  selectedFlatRows,
  isCheckboxDisabled,
  pageCount,
  totalCount,
  columnConfig,
  hideToolbar,
  name: tableName,
  data,
  metadata,
  queryFn,
  onReloadClick,
  refetchData,
  menuActionItems = [],
  shouldFetchAllPagesOnSelectAll,
  shouldRenderSelectAll,
  onExportData,
  saveFiltersConfig,
  isAllPageRowsSelected,
  toggleAllRowsSelected,
  tableState,
  exportCSVDisplayText,
  selectAllLabel,
  selectionSupportingText,
  maxBulkSelectRows,
  clientSidePagination,
  allowConfigureAllColumns,
  showExportButton,
  useCursorPaginationForExports,
  tableViews,
  /**
   * The query key for the table, coming from the `useGetForTable` hook.
   * It contains an array where the first element is the API path used for the request.
   */
  queryKey = [],
  smartSearch,
  ...rest
}) => {
  const isAPIPathAvailable = queryKey.length > 0;
  const canSmartSearchBeUsed =
    isAPIPathAvailable && smartSearch.enabled && (globalFilter || filters);
  const selectedRows = useMemo(
    () => selectedFlatRows.map(({ original }) => original),
    [selectedFlatRows]
  );

  useComponentDidUpdate(() => {
    if (isFunction(onRowSelectionChange)) {
      const selectedRowsChecked = isFunction(isCheckboxDisabled)
        ? selectedFlatRows.filter((row) => !isCheckboxDisabled(row))
        : selectedFlatRows;

      onRowSelectionChange(selectedRowsChecked);
    }
  }, [onRowSelectionChange, selectedRows]);

  const [showFilterManager, setShowFilterManager] = useState(false);
  const { isCompactTable, setCompactView } = useTableUserSettingsContext();

  const showToolbar =
    !hideToolbar &&
    (addRecordAction ||
      globalFilter ||
      filters ||
      exportCSV ||
      columnConfig ||
      isBulkEditActivated);

  const bulkSelectionProps = {
    state,
    queryFn,
    pageCount,
    totalCount,
    isCheckboxDisabled,
    bulkEditOnClick,
    selectedFlatRows,
    BulkEditComponent,
    shouldFetchAllPagesOnSelectAll,
    shouldRenderSelectAll,
    isAllPageRowsSelected,
    toggleAllRowsSelected,
    selectAllLabel,
    selectionSupportingText,
    maxBulkSelectRows,
  };

  const showBulkSelection = isBulkEditActivated && selectedRows.length > 0;

  // override save filters enabled value to enable for admins by default
  const { userIsAdmin } = useUser();
  saveFiltersConfig.enabled = saveFiltersConfig.enabled || userIsAdmin;

  const handleReload = () => {
    onReloadClick();
  };

  if (!showToolbar) return null;

  // Determine if any actions exist
  const hasDropdownMenu = exportCSV || menuActionItems.length > 0;

  const ExportButton = ({ renderOutsideOfMenu }) => (
    <ExportCSVButton
      totalCount={totalCount}
      {...columnsStateProps}
      clientSidePagination={clientSidePagination}
      exportCSV={exportCSV}
      exportWithExcelSupport={exportWithExcelSupport}
      exportFooter={exportFooter}
      pageCount={pageCount}
      queryFn={queryFn}
      data={data}
      metadata={metadata}
      reactTableState={state}
      onExportData={onExportData}
      exportCSVDisplayText={exportCSVDisplayText}
      allowConfigureAllColumns={allowConfigureAllColumns}
      renderOutsideOfMenu={renderOutsideOfMenu}
      useCursorPaginationForExports={useCursorPaginationForExports}
    />
  );

  return (
    <>
      <TableViewFloatingActions tableViews={tableViews} setGlobalFilter={setGlobalFilter} />
      <Toolbar data-testid="table-toolbar">
        <ToolbarControls>
          {!showBulkSelection ? (
            <>
              {globalFilter && (
                <GlobalFilter
                  state={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  globalFilterPlaceholder={globalFilterPlaceholder}
                  globalFilterProps={globalFilterProps}
                />
              )}
              {filters && (
                <SaveFilterContextProvider
                  {...saveFiltersConfig}
                  showFilterManager={showFilterManager}
                  setShowFilterManager={setShowFilterManager}
                >
                  {canSmartSearchBeUsed ? (
                    <SmartSearchInput
                      apiPath={queryKey[0]}
                      transformFilters={rest.transformFilters}
                      setAllFilters={rest.setAllFilters}
                      setGlobalFilter={setGlobalFilter}
                      columns={rest.columns}
                      currentFilters={state.filters}
                      tableName={tableName}
                      placeholder={smartSearch.placeholder}
                      globalSearchFields={smartSearch.globalSearchFields}
                    />
                  ) : null}
                  {!hideToolbarFilterButton && <FiltersPopoverTable {...rest} state={state} />}
                  {saveFiltersConfig.enabled && filters && (
                    <FilterManagerDrawer
                      isVisible={showFilterManager}
                      onClose={() => setShowFilterManager(false)}
                    />
                  )}
                  {FilterToolbarComponent && <FilterToolbarComponent {...rest} state={state} />}
                </SaveFilterContextProvider>
              )}
              {toolbarSupportingText && toolbarSupportingText}
            </>
          ) : (
            <BulkSelection {...bulkSelectionProps} />
          )}
        </ToolbarControls>
        <ToolbarActions>
          {onReloadClick && (
            <Button
              tone="secondary"
              variant="outline"
              size="sm"
              IconBefore={IconV2OutlineRefreshCw}
              onClick={handleReload}
            >
              Reload
            </Button>
          )}
          {addRecordAction}
          {showExportButton && <ExportButton renderOutsideOfMenu />}
          <TableViewMenu tableViews={tableViews} />
          <DropdownMenu
            trigger={
              <DropdownMenuButtonIcon data-testid="table-more-actions" label="Table Actions" />
            }
          >
            {columnConfig && <EditColumnsButton />}
            {exportCSV && <ExportButton />}
            {menuActionItems.map(({ action: menuItemAction, label, actionIcon }) => (
              <MenuItem key={label} label={label} onSelect={menuItemAction} Icon={actionIcon}>
                {label}
              </MenuItem>
            ))}
            <MenuItem
              hasDivider={!!hasDropdownMenu}
              onSelect={() => {
                trackCompactTablesEvent({
                  isCompactTable,
                  tableName,
                  countResults: data?.length,
                });
                setCompactView(!isCompactTable);
              }}
              Toggle={
                <Toggle
                  data-testid="compact-tables-button"
                  onClick={() => {}}
                  label="Compact tables"
                  checked={isCompactTable}
                />
              }
            >
              <Tooltip
                position="left"
                label={
                  isCompactTable
                    ? 'Switch to the default view for a more spacious layout'
                    : 'Switch to the compact view for a condensed layout'
                }
              >
                Compact tables
              </Tooltip>
            </MenuItem>
          </DropdownMenu>
        </ToolbarActions>
      </Toolbar>
    </>
  );
};

TableToolbar.propTypes = {
  /**
   * React component for "add record" button
   *
   * Preferably use `AddTableRecordButton` which comes preconfigured with the correct variant and size.
   */
  addRecordAction: PropTypes.element,
  selectAllLabel: PropTypes.string,
  selectionSupportingText: PropTypes.string,
  /**
   * Smart search configuration consisting of a boolean flag to enable/disable the feature
   * and an array of objects with label and value properties to pass as additional context to Remote AI service.
   */
  smartSearch: PropTypes.shape({
    enabled: PropTypes.bool,
    placeholder: PropTypes.string,
  }),
};
