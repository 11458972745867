import { Box, InputTextarea } from '@remote-com/norma';
import { Field, useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import AIFormAssistant, {
  AI_FORM_ASSISTANT_HEIGHT,
} from '@/src/components/Ui/Form/AIFormAssistant';

/**
 * @typedef {import('@/src/domains/aiPoweredForms').AIFormAssistantConfig} AIFormAssistantConfig
 * @typedef {Omit<import('@/src/components/Form/DynamicForm/types').TextAreaField, 'type'>} TextAreaField
 */

/**
 * TextareaField
 *
 * Renders a textarea field, containing label, description and field error
 *
 * @export
 * @param {TextAreaField & {id?: string, enableAIAssistant?: boolean, aiAssistantConfig?: AIFormAssistantConfig)}} props
 * @return {JSX.Element}
 */
export const TextareaField = ({ enableAIAssistant, aiAssistantConfig, ...props }) => {
  const { values, setValues } = useFormikContext(); // preserve formik integration with new Textarea component
  const { name, onChange, onBlur } = props;

  function handleOnChange(e, nativeOnChange) {
    if (onChange) onChange(e, values, setValues);
    nativeOnChange(e);
  }

  const inputRef = useRef(null);

  const [isAIVisible, setIsAIVisible] = useState(false);

  const handleFocus = () => {
    if (enableAIAssistant) {
      setIsAIVisible(true);
    }
  };

  const handleBlur = (event, nativeOnBlur) => {
    if (onBlur) onBlur(event);

    if (!enableAIAssistant) {
      nativeOnBlur(event);
      return;
    }

    // Check if the related target is not a child of the input field
    if (!inputRef.current?.parentNode?.parentNode?.contains(event?.relatedTarget)) {
      setIsAIVisible(false);
      nativeOnBlur(event);
    }
  };

  const handleAIAssistantBlur = (event, nativeOnBlur) => {
    // If the related target is the textarea itself, blur should not be triggered
    if (event.relatedTarget instanceof HTMLElement && event.relatedTarget.name === name) {
      return;
    }

    setIsAIVisible(false);
    nativeOnBlur({ ...event, target: { ...event.target, name } });
  };

  return (
    <Field name={name}>
      {({ field, meta: { error, touched } }) => (
        <StyledInputWrapper $isAIVisible={isAIVisible}>
          <InputTextarea
            {...field}
            errorText={error && touched ? error : ''}
            value={field.value}
            {...props}
            // Put custom `onChange` handler after the `props` spread,
            // so that it's not overriden by `props.onChange`.
            onChange={(e) => handleOnChange(e, field.onChange)}
            ref={inputRef}
            onFocus={handleFocus}
            onBlur={(e) => handleBlur(e, field.onBlur)}
          >
            {isAIVisible && (
              <AIFormAssistant
                {...(aiAssistantConfig || {})}
                onBlur={(e) => handleAIAssistantBlur(e, field.onBlur)}
              />
            )}
          </InputTextarea>
        </StyledInputWrapper>
      )}
    </Field>
  );
};

const StyledInputWrapper = styled(Box)`
  textarea {
    margin-bottom: 0;
    transition: margin-bottom 0.25s ease;
  }

  ${({ theme, $isAIVisible }) =>
    $isAIVisible &&
    css`
      textarea {
        margin-bottom: ${theme.space[3] + AI_FORM_ASSISTANT_HEIGHT}px;
      }
    `};
`;
