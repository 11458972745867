import { Box, Stack, StyledArrayFieldset } from '@remote-com/norma';
import styled, { css } from 'styled-components';

import { ButtonIcon } from '@/src/components/Button';
import { InputController } from '@/src/components/Ui/Form/Input';

export const ParentFieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border: none;
`;

/**
 * Does not need to change anything.
 * Norma's StyledArrayFieldset is actually extracted from here in the past.
 * Technically, FieldGroupArray can use Norma directly,
 * but we intentionally keep all styling in one place here.
 */
export const ChildWrapper = StyledArrayFieldset;

export const LegendLabel = styled.span`
  ${({ theme }) => theme.typography.lgMedium};
  padding-left: 8px;
  display: flex;
  align-items: center;
  flex-grow: 1;

  &::after {
    content: '';
    height: 2px;
    background-color: ${({ theme }) => theme.colors.grey[300]};
    flex-grow: 1;
    margin-left: 8px;
  }
`;

const CircleWrapperGroup = styled(Box).attrs({ pl: '2px' })``;

export const Legend = styled.legend`
  ${({ hasRemoveAction, shouldHideRemoveAction }) =>
    hasRemoveAction &&
    !shouldHideRemoveAction &&
    css`
      padding-right: 2px;
    `}
  width: 100%;

  + {
    /*
      NOTE: There's some magic happening with the positioning of the native
      legend element within a fieldset. These magic numbers fix the extra
      white space that is being created at the top.
    */

    * {
      margin-top: -13px;
    }
  }

  /* Required to allow tooltip in fieldset legend */
  position: relative;
`;

export const LegendInnerContainer = styled(Stack).attrs({
  direction: 'row',
  alignItems: 'center',
  width: '100%',
})``;

export const Body = styled(Box)`
  ${LegendLabel} {
    ${({ theme }) => theme.typography.lgMedium};
  }
`;

export const CircleWrapperSingle = styled(Stack).attrs({
  direction: 'row',
  justifyContent: 'space-between',
})``;

const ConditionalWrapper = ({ condition, Wrapper, children }) =>
  condition ? <Wrapper>{children}</Wrapper> : children;

export const CircleIconButton = ({ Icon, onClick, index, group }) => (
  <ConditionalWrapper condition={group} Wrapper={CircleWrapperGroup}>
    <ButtonIcon
      type="button"
      tone="secondary"
      variant="ghost"
      size="sm"
      label={`Remove field details of ${index + 1}`}
      onClick={onClick}
      Icon={Icon}
    />
  </ConditionalWrapper>
);

export const StyledInputController = styled(InputController)`
  width: 100%;
  padding-top: 24px;
`;

export const BoxWithMargin = styled(Box).attrs({ mt: 5 })``;
