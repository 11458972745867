/* eslint-disable remote/disallow-data-layer-endpoint-assignment */
module.exports = {
  '/api/v1/account/bank-accounts/[bankAccountSlug]': {},
  '/api/v1/account/settings/favorites/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/totp': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/account/withdrawal-methods/[slug]': {},
  '/api/v1/career-development/journal/notes/[slug]': {},
  '/api/v1/employee/calendars/[slug]': {},
  '/api/v1/employee/contractor-invoices/[slug]': {},
  '/api/v1/employee/contractor-invoices/recurring/[slug]': {},
  '/api/v1/employee/employments/[employmentSlug]/beneficiary-accounts/[beneficiarySlug]': {},
  '/api/v1/employee/employments/[employmentSlug]/nium-beneficiaries/[beneficiarySlug]': {},
  '/api/v1/employee/employments/[employmentSlug]/payment-splits/[currencyCode]': {},
  '/api/v1/employee/time-trackings/[timeTrackingSlug]': {},
  '/api/v1/employer/calendars/[slug]': {},
  '/api/v1/employer/checklists/[checklistSlug]': {},
  '/api/v1/employer/checklists/[checklistSlug]/tasks/[taskSlug]': {},
  '/api/v1/employer/company/business-validation-document/[documentSlug]': {
    serviceOptions: { asFormData: true },
  },
  '/api/v1/employer/company/domains/[name]': {},
  '/api/v1/employer/company/team-members/[slug]': {},
  '/api/v1/employer/custom-field-definitions/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employee-pay-elements/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/aor-subscription-intent': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/employments/[employmentSlug]/contract-amendments/[contractSlug]': {},
  '/api/v1/employer/employments/[employmentSlug]/department': {},
  '/api/v1/employer/employments/[employmentSlug]/direct-offboardings/[offboardingSlug]': {},
  '/api/v1/employer/employments/[employmentSlug]/files/[fileSlug]': {},
  '/api/v1/employer/employments/[employmentSlug]/user-relationship': {},
  '/api/v1/employer/garnishments/[slug]': {},
  '/api/v1/employer/import-jobs/[slug]': {},
  '/api/v1/employer/import-jobs/[slug]/rows/[rowNumber]': {},
  '/api/v1/employer/integration/[integrationName]': {},
  '/api/v1/employer/integration/[integrationName]/account-mapping/[mappingSlug]/contractors': {},
  '/api/v1/employer/integration/remote_api/tokens/[id]': {},
  '/api/v1/employer/job-catalog': {},
  '/api/v1/employer/job-catalog/custom-field-definitions/[slug]': {},
  '/api/v1/employer/job-catalog/job-sub-families/[slug]': {},
  '/api/v1/employer/job-catalog/jobs/[slug]': {},
  '/api/v1/employer/leave-policies/[policySlug]': {},
  '/api/v1/employer/leave-policies/[policySlug]/variants/[variantSlug]': {},
  '/api/v1/employer/legal-entities/[legalEntitySlug]/chart-of-accounts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/chart-of-accounts/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/legal-entities/[legalEntitySlug]/documents/bulk-delete': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/outstanding-payments/[slug]/reset': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/employer/payment-methods/[paymentMethodSlug]': {},
  '/api/v1/employer/projects/[projectSlug]': {},
  '/api/v1/employer/roles/[slug]': {},
  '/api/v1/form_draft/[formName]': {},
  '/api/v1/freelancer/client-invitations/[invitationSlug]': {},
  '/api/v1/identity-verification/[inquiryId]': {},
  '/api/v1/impersonation': {},
  '/api/v1/rivendell/benefit-groups/[slug]': {},
  '/api/v1/rivendell/benefits/package-manager/yearly-mappings/[slug]': {},
  '/api/v1/rivendell/companies/[companySlug]/company-product-tiers/[companyProductTierSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[slug]/sso_config': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/companies/[slug]/tags/[tagId]': {},
  '/api/v1/rivendell/compliance-requests/[slug]': {},
  '/api/v1/rivendell/contract-templates/supported-automations/[automationSlug]': {},
  '/api/v1/rivendell/custom-field-definitions/[slug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/data-exports/templates/[slug]': {},
  '/api/v1/rivendell/default-benefit-offers/[slug]': {},
  '/api/v1/rivendell/employments/[employmentSlug]/mobility-assignments': {},
  '/api/v1/rivendell/employments/[employmentSlug]/pre-hire-check': {},
  '/api/v1/rivendell/employments/[employmentSlug]/user-relationship': {},
  '/api/v1/rivendell/extra-entitlements/[slug]': {},
  '/api/v1/rivendell/files/[slug]': {},
  '/api/v1/rivendell/files/delete-multiple': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/garnishments/[slug]': {},
  '/api/v1/rivendell/global-payroll-offboardings/[offboardingSlug]': {},
  '/api/v1/rivendell/holidays/[holidaySlug]': {},
  '/api/v1/rivendell/import-jobs/[slug]/rows/[rowNumber]': {},
  '/api/v1/rivendell/incentives/[slug]': {},
  '/api/v1/rivendell/integration-template-pay-elements/[slug]': {},
  '/api/v1/rivendell/invoice-reports/[invoiceReportSlug]/partial-items/[partialItemSlug]': {},
  '/api/v1/rivendell/knowledge-database/attendance-policies/[policySlug]': {},
  '/api/v1/rivendell/knowledge-database/fact-schemas/[name]': {},
  '/api/v1/rivendell/knowledge-database/facts/[factSlug]': {},
  '/api/v1/rivendell/knowledge-database/regions/[regionSlug]': {},
  '/api/v1/rivendell/knowledge-database/timeoff-policies/[policySlug]': {},
  '/api/v1/rivendell/leave-policies/[policySlug]': {},
  '/api/v1/rivendell/leave-policies/[policySlug]/variants/[variantSlug]': {},
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/documents/[legalEntityDocumentSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[legalEntitySlug]/documents/bulk-delete': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[slug]/notes/[noteSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/legal-entities/[slug]/tags/[tagId]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/legal-entity-pay-element-data-source/[payElementDataSourceSlug]': {},
  '/api/v1/rivendell/legal-entity-pay-element-template-collections/[slug]': {},
  '/api/v1/rivendell/legal-entity-pay-element-templates/[slug]': {},
  '/api/v1/rivendell/legal-entity-pay-elements/[slug]': {},
  '/api/v1/rivendell/notifications/custom/[slug]': {},
  '/api/v1/rivendell/offboardings/[offboardingSlug]': {},
  '/api/v1/rivendell/onboarding-notes': {},
  '/api/v1/rivendell/outstanding-payments/[slug]/reset': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/outstanding-refunds/[refundSlug]/reimbursement': {},
  '/api/v1/rivendell/outstanding-refunds/mark_applied_off_platform/[refundSlug]': {},
  '/api/v1/rivendell/payroll-calendars/[slug]': {},
  '/api/v1/rivendell/payroll-outputs/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payroll-outputs/batch-delete': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/employee-pay-elements/batch': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payments': {},
  '/api/v1/rivendell/payroll-runs/[payrollRunSlug]/payroll-run-contracts': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]': {},
  '/api/v1/rivendell/payroll-runs/[slug]/billing/memos/[memoSlug]': {
    serviceOptions: { asFormData: false },
  },
  '/api/v1/rivendell/payroll-runs/[slug]/payroll-outputs/batch-delete-all': {},
  '/api/v1/rivendell/payroll-runs/[slug]/payslips/batch-delete-all': {},
  '/api/v1/rivendell/payslips/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/payslips/batch-delete': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/pricing/team-plan-fees/[slug]': {},
  '/api/v1/rivendell/recurring-incentives/batch': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/roles/[slug]': {},
  '/api/v1/rivendell/task-assignments': {},
  '/api/v1/rivendell/task-assignments/[slug]': {},
  '/api/v1/rivendell/tax-notes/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/rivendell/time-trackings/pay-element-mappings/[mappingSlug]': {},
  '/api/v1/rivendell/users/[slug]/tags/[tagId]': {},
  '/api/v1/rivendell/vat-groups/[slug]': {},
  '/api/v1/session': {},
  '/api/v1/session/accounts/[slug]': { serviceOptions: { asFormData: false } },
  '/api/v1/table-views/[tableViewSlug]': {},
  '/api/v2/employer/hris/departments/[departmentSlug]': {},
  '/api/v2/employer/hris/departments/[slug]/assignments': {
    serviceOptions: { asFormData: false },
  },
  '/api/v2/employer/hris/employee/[slug]/linked-company-admin': {},
  '/api/v2/rivendell/hris/employee/[slug]/linked-company-admin': {},
  '/api/v2/rivendell/knowledge-database/facts/[factIdentifier]/fact-values/[slug]/version/[versionSlug]':
    {},
  '/boba/api/v1/admin/jobs/[jobSlug]': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/company-profiles/company-logo': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/[jobSlug]': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/jobs/prospects/[prospectSlug]/bookmark': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/sourcing/[personId]/bookmark': {
    serviceOptions: { useAuthentication: true },
  },
  '/boba/api/v1/employer/sourcing/previous-searches/[previousSearchSlug]': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
  '/boba/api/v1/employer/sourcing/prospects/[prospectSlug]': {
    serviceOptions: { asFormData: false, useAuthentication: true },
  },
};
