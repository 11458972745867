import {
  addBusinessDays,
  addMonths,
  endOfDay,
  endOfMonth,
  lastDayOfMonth,
  setDate,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import getConfig from 'next/config';
import snakecaseKeys from 'snakecase-keys';

import { COMPENSATION_WAGE_TYPES } from '@/src/api/config/employ/compensation.types';
import { yesNoValues } from '@/src/components/Form/DynamicForm/constants';
import { dateRangeFilterLabel } from '@/src/components/Table/helpers';
import { useUser } from '@/src/components/UserProvider/context';
import { ADMIN_PAYROLL_RUN_ROUTE, PAYROLL_RUN_DETAILS_ROUTE } from '@/src/constants/routes';
import { contractStatusLabels } from '@/src/domains/employment/constants';
import { getActiveCompensation } from '@/src/domains/employment/helpers';
import { getPayrollCycleLabel } from '@/src/domains/pay/helpers';
import {
  createdDateFilters,
  dateRangeFilters,
  effectiveDateFilters,
  EOR_PAYROLL_RUN_STATUSES,
  exportTypes,
  GLOBAL_MONTHLY_CUTOFF_DATE,
  GLOBAL_PAYROLL_RUN_STATUSES,
  insertedAtFilters,
  PAYROLL_INPUT_TYPES,
  PAYROLL_RUN_STATUSES,
  PAYROLL_RUN_STATUSES_BY_ID,
  PAYMENT_PROVIDERS_OPTIONS,
  PAYROLL_RUN_TYPES,
  productTypes,
  reviewedAtFilters,
  startDateFilters,
} from '@/src/domains/payroll/constants';
import { buildPayrollRunViewHref } from '@/src/domains/payroll/employer/helpers';
import { formatPayElementDurationValue } from '@/src/domains/payroll/shared/payElements/helpers';
import { productEmploymentTypes } from '@/src/domains/pricing/constants';
import { hasCompanyProduct, isEmployer } from '@/src/domains/registration/auth/helpers';
import { DEFAULT_WORK_HOURS } from '@/src/domains/timeoff/constants';
import { userCacheKeys } from '@/src/domains/userCache/constants';
import { getFromUserCache } from '@/src/domains/userCache/helpers';
import { friendlyMoneyWithCurrencyCode } from '@/src/helpers/currency';
import { dateToISO8601, formatYearMonthDay } from '@/src/helpers/date';
import { friendlyPlaceholderDash } from '@/src/helpers/general';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

export function getDaysWorked(hours) {
  if (hours == null) {
    return null;
  }

  const days = hours / DEFAULT_WORK_HOURS;
  const roundedDays = Math.round(days * 10) / 10;

  return roundedDays;
}

export function getHoursWorked(days) {
  if (days == null) {
    return null;
  }

  const hours = days * DEFAULT_WORK_HOURS;
  return Math.round(hours);
}

export function getFormattedHoursWorked(hours) {
  if (hours == null) {
    return '—';
  }

  const days = getDaysWorked(hours);

  return `${hours} (${getSingularPluralUnit(days, 'day', 'days', false)})`;
}

export const getPayrollRunStatus = (status) => {
  const result = Object.values(PAYROLL_RUN_STATUSES).find(({ id }) => id === status);

  return result || null;
};

export const getPayrollType = (status) => {
  const result = Object.values(PAYROLL_RUN_TYPES).find(({ id }) => id === status);

  return result || null;
};

export const getPaymentProvider = (value) => {
  return PAYMENT_PROVIDERS_OPTIONS.find((option) => option.value === value)?.label || 'unknown';
};

export const getPayrollTypeLabel = (type) => {
  return getPayrollType(type)?.label;
};

function buildReportDownloadParams({ type, values }) {
  const params = new URLSearchParams({});

  if (type === exportTypes.TIMEOFF_BALANCES && !values?.date) {
    const today = dateToISO8601();
    params.append('date', today);
  }

  Object.keys(values).forEach((valueKey) => params.append(snakeCase(valueKey), values[valueKey]));
  return params.toString();
}

export function makeReportDownloadURL({ type, values = {} }) {
  const {
    publicRuntimeConfig: { API_BASE_URL_CLIENT },
  } = getConfig();

  return `${API_BASE_URL_CLIENT}/api/v1/rivendell/reports/${type}/download?${buildReportDownloadParams(
    {
      type,
      values,
    }
  )}`;
}

export function makeBulkExpensesReceiptsDownloadURL(payrollRunSlug) {
  const {
    publicRuntimeConfig: { API_BASE_URL_CLIENT },
  } = getConfig();

  return `${API_BASE_URL_CLIENT}/api/v1/rivendell/payroll-runs/${payrollRunSlug}/expense-receipts`;
}

export function makeP45DownloadURL(payrollRunSlug) {
  const {
    publicRuntimeConfig: { API_BASE_URL_CLIENT },
  } = getConfig();

  return `${API_BASE_URL_CLIENT}/api/v1/rivendell/payroll-runs/${payrollRunSlug}/p45-documents`;
}

/**
 * @typedef {import('@/src/types').Country}
 */

/**
 * Formats a date to 'yyyy-MM-dd' in local time.
 *
 * @param {Date} datetime - The date object to format.
 * @returns {string} The formatted date string.
 */
export const formatYearMonthDayInLocalTime = (datetime) =>
  formatYearMonthDay(datetime, { isSourceInUtc: false, formatInLocalTime: true });

/*
  The following variables are used in createMonthlyPayrollRunConditions and in several functions in
  usePayrollRunFormFields file (where we auto-fill values when payroll run type or month is updated by the user)
  to make sure that if some logic is updated in the initial values it they will also be updated there
*/

export const formattedStartOfTheMonth = (/** @type {Date | number} */ date = Date.now()) =>
  formatYearMonthDayInLocalTime(startOfMonth(date));

export const formattedEndOfTheMonth = (/** @type {Date | number} */ date = Date.now()) =>
  formatYearMonthDayInLocalTime(endOfMonth(date));

export const formattedApprovalEndDate = (/** @type {Date | number} */ date = Date.now()) =>
  formatYearMonthDayInLocalTime(endOfDay(addBusinessDays(setDate(date, 11), 8)));

export const formattedExpectedPayoutDate = (/** @type {Date | number} */ date = Date.now()) =>
  formatYearMonthDayInLocalTime(startOfDay(setDate(date, 25)));

export const formattedCutoffDate = (/** @type {Date | number} */ date = Date.now()) =>
  formatYearMonthDayInLocalTime(endOfDay(setDate(date, 11)));

export const createMonthlyPayrollRunConditions = () => {
  // PayrollRun initial monthly values are set based on the current month,
  // since today is the default date param to inputsStartDate and others we don't need to send it

  const initialValues = {
    type: PAYROLL_RUN_TYPES.MAIN.id,
    template: '',
    month: '',
    remoteEntity: '',
    periodRange: ['', ''],
    includeEmployeesUpTo: '',
    cutoffDate: '',
    expectedPayoutDate: '',
    approvalDate: '',
    shortLabel: '',
    name: '',
    includeEmployees: true,
    includeExpenses: true,
    includeIncentives: true,
    includeAdjustments: true,
    includeTimeoff: true,
    summarizeAutomatically: true,
    summarizeDisabledNote: '',
    includePayElements: true,
    force: false,
    note: '',
  };

  return initialValues;
};

/**
 * Extracts payroll run conditions from
 * @param { PayrollRun } payrollRun
 */
export function extractPayrollRunConditions(payrollRun) {
  if (!payrollRun) {
    return {};
  }

  return {
    ...payrollRun,
    country: payrollRun.legalEntity.address.country.slug,
    periodRange: [payrollRun.periodStart, payrollRun.periodEnd],
    approvalDate: payrollRun.approvalDate || '',
    cutoffDate: payrollRun.cutoffDate || '',
  };
}

export const getPayrollRunBadgeProps = (payrollRun) => ({
  type: PAYROLL_RUN_STATUSES_BY_ID[payrollRun?.status]?.badge,
  label: payrollRun?.name,
  labelExtra: `${
    payrollRun?.periodStart && payrollRun?.periodEnd
      ? `${dateRangeFilterLabel([payrollRun?.periodStart, payrollRun?.periodEnd])} - `
      : ``
  }${PAYROLL_RUN_STATUSES_BY_ID[payrollRun?.status]?.label}`,
});

/**
 * Given a date, determines if it's before or at the monthly payroll cutoff date.
 * @param {Date} date
 * @returns {boolean}
 */
export const isDateBeforeOrAtMonthlyCutoffDate = (
  date,
  cutoffDate = GLOBAL_MONTHLY_CUTOFF_DATE
) => {
  if (date.getDate() <= cutoffDate) {
    return true;
  }

  return false;
};

/**
 * Given a date, returns the date for the closest end of month's payroll cycle
 * @param {Date} date
 * @returns {Date}
 */
export const getClosestEndOfMonthPayrollCycleDate = (
  date,
  cutoffDate = GLOBAL_MONTHLY_CUTOFF_DATE
) =>
  isDateBeforeOrAtMonthlyCutoffDate(date, cutoffDate)
    ? lastDayOfMonth(date)
    : lastDayOfMonth(addMonths(date, 1));

export function convertFriendlyBooleanFilter(filterValue) {
  return filterValue ? filterValue === yesNoValues.YES : undefined;
}

// Given an add contract to run response error, check if the error
// is because the contract is already on the run
export function alreadyInRunError(error) {
  const errorKeys = Object.entries(error.response?.data?.errors || {});
  const alreadyInRun =
    errorKeys.length === 1 &&
    errorKeys.find(
      ([key, value]) =>
        camelCase(key).startsWith('associateContract') &&
        value?.contractSlug?.[0] === 'has already been taken'
    );

  return alreadyInRun;
}

export async function moveContractsToPayrollRun(
  {
    addContractToPayrollRunMutation,
    removeContractFromPayrollRunMutation,
    contractSlugs,
    targetPayrollRunSlug,
    currentPayrollRunSlug,
  },
  reactQueryCallbacks
) {
  try {
    await addContractToPayrollRunMutation.mutateAsync({
      pathParams: { slug: targetPayrollRunSlug },
      bodyParams: { contractSlugs },
    });
  } catch (error) {
    if (alreadyInRunError(error)) {
      // Throw an axios-like error object so the getExceptionInfo utility catches the correct error message
      // eslint-disable-next-line no-throw-literal
      throw {
        response: {
          data: {
            message: `${getSingularPluralUnit(
              contractSlugs.length,
              'Employee is',
              'Some of these employees are',
              true,
              false
            )} already in the selected payroll run.`,
          },
        },
      };
    }

    throw error;
  }
  await removeContractFromPayrollRunMutation.mutateAsync(
    {
      bodyParams: { contractSlugs },
      pathParams: {
        payrollRunSlug: currentPayrollRunSlug,
      },
    },
    reactQueryCallbacks
  );
}

export function getPayrollStatusesByProductType(productType) {
  return productType === productTypes.EOR ? EOR_PAYROLL_RUN_STATUSES : GLOBAL_PAYROLL_RUN_STATUSES;
}

export function generateStatusesOptions(statuses) {
  return Object.values(statuses).map(({ id, label, badge }) => ({
    value: id,
    label,
    badge,
  }));
}

// The `rejected` option will not be shown in the status dropdown, since we don't want users to cancel a payroll run from this dropdown.
// But when a payroll run is already in `rejected` status, we will add the option to the dropdown, to display what the current status is.
export function getStatusesOptions(payrollRun) {
  const payrollRunStatusesOptions = getPayrollStatusesByProductType(payrollRun?.productType);
  const statusesOptions = generateStatusesOptions(payrollRunStatusesOptions);

  if (payrollRun?.status === PAYROLL_RUN_STATUSES.REJECTED.id) {
    return statusesOptions;
  }

  return statusesOptions.filter(
    (statusOption) => statusOption.value !== PAYROLL_RUN_STATUSES.REJECTED.id
  );
}

const endDateIds = [
  effectiveDateFilters.end,
  dateRangeFilters.end,
  reviewedAtFilters.end,
  createdDateFilters.end,
  insertedAtFilters.end,
  startDateFilters.end,
];

const mapFilterForAdjustments =
  (list) =>
  ([id, value]) =>
    ({
      [createdDateFilters.start]: {
        id: 'insertedAt',
        value: [list.createdStartDate, list.createdEndDate],
      },
      [effectiveDateFilters.start]: {
        id: 'effectiveDate',
        value: [list.effectiveStartDate, list.effectiveEndDate],
      },
    }[id] || { id, value });

const mapFilterForExpenses =
  (list) =>
  ([id, value]) =>
    ({
      [dateRangeFilters.start]: {
        id: 'expenseDate',
        value: [list.startDate, list.endDate],
      },
    }[id] || { id, value });

const mapFilterForIncentives =
  (list) =>
  ([id, value]) =>
    ({
      [dateRangeFilters.start]: {
        id: 'payrollMonth',
        value: [list.startDate, list.endDate],
      },
    }[id] || { id, value });

const mapFilterForTimeoff =
  (list) =>
  ([id, value]) =>
    ({
      [insertedAtFilters.start]: {
        id: 'insertedAt',
        value: [list.insertedAtFrom, list.insertedAtTo],
      },
      [startDateFilters.start]: {
        id: 'startDateRange',
        value: [list.startDateFrom, list.startDateTo],
      },
    }[id] || { id, value });

const mapFilterByInputType = (inputType, list) =>
  ({
    [PAYROLL_INPUT_TYPES.ADJUSTMENT]: mapFilterForAdjustments(list),
    [PAYROLL_INPUT_TYPES.EXPENSE]: mapFilterForExpenses(list),
    [PAYROLL_INPUT_TYPES.INCENTIVE]: mapFilterForIncentives(list),
    [PAYROLL_INPUT_TYPES.TIMEOFF]: mapFilterForTimeoff(list),
  }[inputType]);

// Helper to transform filters list from { filter_id: value } into [ { id: filterId, value: value } ].
export function generateMissingPayrollInputsFiltersArray({ list, inputType }) {
  return Object.entries(snakecaseKeys(list))
    .filter(([id]) => {
      // Date filters are requested to the BE split into start and end date entries (both with an id and a value)
      // but for the UI we use date range filters, so we just need one id and the value to be an array
      // so here we remove end date and then set the value as [start, end] below
      const isEndDateFilter = endDateIds.includes(id);

      return !isEndDateFilter;
    })
    .filter(([id]) => {
      // We don't want to add 'status' filter in incentives input type since that is not a filter users can use
      // in Incentives page, status are filtered by the tabs - so we just send 'selectedTab' query param for that
      const isIncentiveStatusFilter =
        inputType === PAYROLL_INPUT_TYPES.INCENTIVE && id === 'status';

      return !isIncentiveStatusFilter;
    })
    .map(mapFilterByInputType(inputType, list));
}

export const isGlobalPayrollUser = (user) =>
  isEmployer(user) &&
  hasCompanyProduct(
    getFromUserCache(user, userCacheKeys.COMPANY_DATA),
    productEmploymentTypes.GLOBAL_PAYROLL
  );

/*
 * Returns `true` if the user's company has at least one EOR employee.
 *
 * This does not tell anything about whether the company has GP employees or not,
 * i.e for a company that has both EOR and GP employees, this function will return `true`.
 *
 * For context https://www.notion.so/Employer-of-Record-vs-Global-Payroll-24b52fa1ece34845886f82cb6baf8a88?d=ecfeba4850174e7fbf9fdf8158d6dd37#01e826bc1e00490ea01cda5de962535d
 */
export const isEorUser = (user) =>
  isEmployer(user) && getFromUserCache(user, userCacheKeys.COMPANY_DATA)?.hasEorEmployees;

export const hasContractorEmployees = (user) =>
  isEmployer(user) && getFromUserCache(user, userCacheKeys.COMPANY_DATA)?.hasContractorEmployees;

export const hasDirectEmployees = (user) =>
  isEmployer(user) && getFromUserCache(user, userCacheKeys.COMPANY_DATA)?.hasDirectEmployees;

export const hasGlobalPayrollEmployees = (user) =>
  isEmployer(user) && getFromUserCache(user, userCacheKeys.COMPANY_DATA)?.hasGlobalPayrollEmployees;

export const hasHadTalentSubscription = (user) =>
  isEmployer(user) && getFromUserCache(user, userCacheKeys.COMPANY_DATA)?.hasHadTalentSubscription;

// Should return true if a payroll run is in a state that blocks any kind of update
export function isPayrollRunStatusBlocked(payrollRunStatus) {
  return [
    PAYROLL_RUN_STATUSES.COMPLETED.id,
    PAYROLL_RUN_STATUSES.FINALIZED.id,
    PAYROLL_RUN_STATUSES.REJECTED.id,
  ].includes(payrollRunStatus);
}

export function getAmountCurrencyPair(amount) {
  return {
    amount: amount?.convertedAmount,
    currency: amount?.convertedCurrency,
  };
}

export const getAnnualBaseSalary = (contract) =>
  getAmountCurrencyPair(contract?.compensations?.[0]?.amount || contract?.compensation?.amount);
export const getGrossRecurringSalary = (contract) =>
  getAmountCurrencyPair(
    contract?.compensations?.[0]?.grossRecurringSalary ||
      contract?.compensation?.grossRecurringSalary
  );
export const getPeriodGrossSalary = (contract) =>
  getAmountCurrencyPair(
    contract?.compensations?.[0]?.periodGrossSalary || contract?.compensation?.periodGrossSalary
  );

export const getGrossHourlyRate = (contract) =>
  getAmountCurrencyPair(
    contract?.compensations?.[0]?.grossRegularHourlyRate ||
      contract?.compensation?.grossRegularHourlyRate
  );

export const getInputsGrossPay = ({ contract: { grossPaySum: amount }, currency }) => {
  if (amount === null) return null;
  return { amount, currency };
};

function getLabel({ employment, jobTitle, status }) {
  return `${employment?.shortSlug} - ${jobTitle} - ${employment?.company?.name} - ${contractStatusLabels[status]}`;
}

export const getEmployeeContractLabel = ({ employment, jobTitle, status }) =>
  `${employment?.user?.name} - ${getLabel({ employment, jobTitle, status })}`;

export function getPayrollInputTypeSingularAndPlural(inputType) {
  switch (inputType) {
    case PAYROLL_INPUT_TYPES.EMPLOYEE:
      return ['employee', 'employees'];
    case PAYROLL_INPUT_TYPES.EXPENSE:
      return ['expense', 'expenses'];
    case PAYROLL_INPUT_TYPES.INCENTIVE:
      return ['incentive', 'incentives'];
    case PAYROLL_INPUT_TYPES.ADJUSTMENT:
      return ['adjustment', 'adjustments'];
    case PAYROLL_INPUT_TYPES.TIMEOFF:
      return ['time off', 'time offs'];
    case PAYROLL_INPUT_TYPES.EMPLOYEE_PAY_ELEMENT:
      return ['employee pay item', 'employee pay items'];
    default:
      return ['input', 'inputs'];
  }
}

export const getPayrollRunViewHrefByUserType = ({ payrollRun, userIsAdmin }) => {
  if (!payrollRun?.slug) {
    return null;
  }

  // Admin
  if (userIsAdmin) {
    return {
      pathname: userIsAdmin ? ADMIN_PAYROLL_RUN_ROUTE : PAYROLL_RUN_DETAILS_ROUTE,
      query: {
        slug: payrollRun.slug,
      },
    };
  }

  // Employer
  return buildPayrollRunViewHref(payrollRun);
};

/**
 * Returns the base endpoint for the current user.
 *
 * If they are an admin, return the rivendell namespace, otherwise return the employer namespace.
 *
 * If they are any other role, this throws for now, as the unified vision for payroll only contemplates
 * admins and employers.
 *
 * @returns {{ baseEndpoint: '/api/v1/rivendell' | '/api/v1/employer', userIsAdmin: boolean }}
 */
export function useBasePayrollEndpoint() {
  const { userIsAdmin } = useUser();

  return {
    baseEndpoint: userIsAdmin ? '/api/v1/rivendell' : '/api/v1/employer',
    userIsAdmin,
  };
}

/**
 * Checks if there are any hourly employees in the given list of employees
 * @param {Array<import('@/src/api/config/employ/contract.types').AdminContract> | Array<import('@/src/api/config/employ/payrollRun.types').PayrollRun.EmployeeContractType> | null} employees - List of employees
 * @returns {boolean} True if any employee has an hourly compensation, false otherwise
 */
export function areThereAnyHourlyEmployees(employees) {
  if (employees == null || employees?.length === 0) return false;
  return employees.some((employee) =>
    employee.compensations?.some(
      (compensation) => compensation?.wageType === COMPENSATION_WAGE_TYPES.HOURLY
    )
  );
}

/**
 * Checks if there are any salaried employees in the given list of employees
 * @param {Array<import('@/src/api/config/employ/contract.types').AdminContract> | Array<import('@/src/api/config/employ/payrollRun.types').PayrollRun.EmployeeContractType> | null} employees - List of employees
 * @returns {boolean} True if any employee has a salary compensation, false otherwise
 */
export function areThereAnySalariedEmployees(employees) {
  if (employees == null || employees?.length === 0) return false;
  return employees.some((employee) =>
    employee.compensations?.some(
      (compensation) => compensation?.wageType === COMPENSATION_WAGE_TYPES.SALARY
    )
  );
}

export function areThereAnyOvertimeEligibleEmployees(employees) {
  if (employees == null || employees?.length === 0) return false;
  return employees.some((employee) => {
    const activeCompensation = getActiveCompensation(employee.compensations);
    if (activeCompensation == null) return false;
    return activeCompensation.overtimeEligible;
  });
}

/**
 * Extracts the gross hourly rate from a list of compensations
 *
 * Extract the active compensation and return the hourly gross rate in the supported format for the
 * MoneyCell component
 * @param {Array<import('@/src/api/config/employ/compensation.types').Compensation>} compensations - List of compensations for an employee
 * @returns {Object} Object containing amount and currency of the hourly rate
 */
export function getGrossHourlyRateMoneyObject(compensations) {
  const activeCompensation = getActiveCompensation(compensations);
  return {
    amount: activeCompensation?.grossRegularHourlyRate?.convertedAmount,
    currency: activeCompensation?.grossRegularHourlyRate?.convertedCurrency,
  };
}

export function getSumRegularHours(row) {
  const { compensations } = row;
  const activeCompensation = getActiveCompensation(compensations);
  if (activeCompensation.wageType === COMPENSATION_WAGE_TYPES.HOURLY) {
    return `${formatPayElementDurationValue(row.sumRegularHours)}`;
  }
  return friendlyPlaceholderDash;
}

export function getSumOvertimeHours(row) {
  const { sumOvertimeHours, compensations } = row;
  const activeCompensation = getActiveCompensation(compensations);
  if (sumOvertimeHours == null || !activeCompensation.overtimeEligible) {
    return friendlyPlaceholderDash;
  }

  return `${formatPayElementDurationValue(row.sumOvertimeHours)}`;
}

export const formatSalaryWithCycle = (salary, cycle) => {
  if (!salary) return '—';
  const formattedSalary = friendlyMoneyWithCurrencyCode(salary.amount, salary.currency, 'right');
  const cycleLabel = getPayrollCycleLabel(cycle) ?? '-';
  return `${formattedSalary} (${cycleLabel})`;
};
