// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconTextBold = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M6 3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h9a5 5 0 0 0 2.44-9.37l.1-.1A5 5 0 0 0 14 3H6Zm8 8a3 3 0 0 0 0-6H7v6h7Zm-7 2v6h8a3 3 0 0 0 0-6H7Z" /></svg>;
const ForwardRef = forwardRef(IconTextBold);
export { ForwardRef as IconTextBold };