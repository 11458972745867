import format from 'date-fns/format';
import get from 'lodash/get';
import last from 'lodash/last';
import PropTypes from 'prop-types';
import { array, date, mixed, number, object, string } from 'yup';

import { DEFAULT_DATE_FORMAT } from '@/src/constants/dates';
import { contractStatus } from '@/src/domains/employment/constants';
import {
  DEFAULT_SUPPORTED_FORMATS,
  DOCUMENT_SUPPORTED_FORMATS,
  INVOICE_SUPPORTED_FORMATS,
  MAX_FILE_SIZE_BYTES,
  MAX_MB_FILE_SIZE,
  PAYROLL_OUTPUT_SUPPORTED_FORMATS,
  PAYSLIP_SUPPORTED_FORMATS,
  SPREADSHEETS_MIME_TYPES,
  SPREADSHEET_EXTENSIONS,
  UPLOAD_DOCUMENT_SUPPORTED_FORMATS,
  WORD_DOCUMENTS,
  WORD_DOCUMENTS_EXTENSIONS,
  WORD_DOCUMENTS_MIME_TYPES,
} from '@/src/domains/files/constants';
import { validateFileSize } from '@/src/domains/files/helpers';
import { USER_TYPE } from '@/src/domains/registration/auth/constants';
import { isValidDate } from '@/src/helpers/date';
import { error } from '@/src/helpers/general';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

import { benefitPlanCostTypes } from '../domains/benefits/constants';

import {
  isValidCost,
  isValidIntegerOnlyCost,
  isValidIntegerOnlyNegativeCost,
  isValidNegativeCost,
} from './currency';

export const genericRequiredLabel = 'Required field';
export const goalOptionRequiredLabel = 'Please select one of the options above';
export const validHoursLabel = 'Valid hours: 0-8';

export const baseUrl = string().url('Please enter a valid URL (e.g. https://www.remote.com)');

export const baseString = string().trim().nullable();

export const nonEmptyArray = array().min(1);

export const domainName = baseString.matches(
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/gim,
  'Please enter a valid domain name (e.g remote.com)'
);

export const domainNamesListCommaSeparated = baseString.matches(
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+(?:\s*,\s*[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+)*$/gim,
  'Please enter a valid domain name (e.g remote.com) or a list of comma-separated domains (e.g. remote.com, alternative-domain.com)'
);

export const maxLengthBaseString = baseString.max(
  255,
  (message) => `Must be at most ${message.max} characters`
);

export const baseUrlMax255 = baseUrl.max(
  255,
  (message) => `Must be at most ${message.max} characters`
);

export const buildUuidSchema = (message = 'Invalid ID') => {
  return baseString.matches(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/,
    message
  );
};

export const passwordSchema = baseString;
export const requiredPasswordSchema = passwordSchema.required('Password is required');
export const passwordConfirmationSchema = baseString.required(genericRequiredLabel);
export const passwordConfirmationMessage = 'Confirm password must match password';

export const totpValidationSchema = string()
  .required('6-digit code is a required field')
  .matches(/^[0-9]{6}$/, 'Must be a 6-digit code');

export const totpBackupValidationSchema = string()
  .required('8 characters code is a required field')
  .matches(/^[A-Z0-9]{8}$/, 'Must be a 8 characters code');

export const emailSchema = baseString.email('Please enter a valid email address.');

export const todayDateHint = new Date().toISOString().substring(0, 10); // get just date

export const dateFormatterPattern = DEFAULT_DATE_FORMAT;
const dateTypeErrorMsg = `Must be a valid date in ${dateFormatterPattern.toLocaleLowerCase()} format. e.g. ${todayDateHint}`;
const dateRegex = /(?:\d){4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9]|3[0-1])/;

// Use baseString instead of dateSchema because it's a tuple [start, end]
export const dateRangeSchema = baseString.matches(dateRegex, dateTypeErrorMsg);

export const dateSchema = date()
  .transform((valAsDate, valOriginal) => (valOriginal === '' ? null : valAsDate))
  .nullable()
  .test('is-yyyy-mm-dd', dateTypeErrorMsg, (_, context) => {
    const value = context.originalValue;
    if (!value) {
      return true; // Empty field
    }
    return dateRegex.test(context.originalValue);
  });

export const timeSchema = baseString.matches(
  /^(?:\d|[01]\d|2[0-3]):[0-5]\d/,
  `Must be a valid time. e.g. 22:00`
);
export const dateSchemaLoading = dateSchema.required('Field is still loading.');

function joinSchemas(schemas) {
  const [first, ...rest] = schemas;
  return rest.reduce((merged, part) => merged.concat(part), first);
}

/**
 * @typedef {Object} GenerateDateSchema
 * @property {String} params.name
 * @property {Bool} [params.required]
 * @property {Bool} [params.requiredMsg]
 * @property {Date | String} [params.minDate] - Date obj | String in format yyyy-MM-dd
 * @property {Date | String} [params.maxDate] - Date obj | String in format yyyy-MM-dd
 * @property {String} [params.maxDateMsg] - maxDate Error message
 * @property {String} [params.minDateMsg] - minDate Error message
 */

/**
 * @param {GenerateDateSchema} params
 * @returns {YupSchema}
 */
export function generateDateSchema({
  name,
  required,
  requiredMsg,
  minDate,
  maxDate,
  minDateMsg,
  maxDateMsg,
}) {
  const base = required ? dateSchema.required(requiredMsg || genericRequiredLabel) : dateSchema;

  const parts = [base];

  if (minDate && !isValidDate(minDate)) {
    error(`Field "${name}" with invalid "minDate": ${minDate}. Ignored from Yup validations.`);
    return base;
  }

  if (maxDate && !isValidDate(maxDate)) {
    error(`Field "${name}" with invalid "maxDate": ${maxDate}. Ignored from Yup validations.`);
    return base;
  }

  if (minDate) {
    // Convert to a String so that Yup parses it at midnight. Check unit tests for examples.
    const minDateString = format(new Date(minDate), DEFAULT_DATE_FORMAT);
    parts.push(
      dateSchema.min(minDateString, ({ min }) => {
        return (
          minDateMsg || `The date must be ${format(new Date(min), DEFAULT_DATE_FORMAT)} or after.`
        );
      })
    );
  }
  if (maxDate) {
    parts.push(
      // No need to convert to a string
      // because `maxDate` (Date) will always be later than value (String)
      dateSchema.max(maxDate, ({ max }) => {
        return (
          maxDateMsg || `The date must be ${format(new Date(max), DEFAULT_DATE_FORMAT)} or before.`
        );
      })
    );
  }

  return joinSchemas(parts);
}

export const yearMonthSchema = baseString.matches(
  /(?:\d){4}-(?:0[1-9]|1[0-2])/,
  `Must be a valid month in yyyy-mm format. e.g. ${todayDateHint.substring(0, 7)}`
);

export const integerSchema = number().integer(`Can't have decimals`);

export const generateDecimalRangeSchema = (minimum, maximum) =>
  number()
    .typeError('The value must be a number')
    .min(minimum, `Must be ${minimum} or greater`)
    .max(maximum, `Must be ${maximum} or lower`);

export const generateIntegerRangeSchema = (minimum, maximum) =>
  number()
    .typeError('The value must be a number')
    .integer(`Can't have decimals`)
    .min(minimum, `Must be ${minimum} or greater`)
    .max(maximum, `Must be ${maximum} or lower`);

export const generateDecimalGreaterThanSchema = (minimum) =>
  number().typeError('The value must be a number').min(minimum, `Must be ${minimum} or greater`);

export const numberWithMaxTwoDecimalPlacesSchema = number().test(
  'maxTwoDecimalPlaces',
  'Enter an amount with at most 2 decimal places',
  (value) => /^-?\d+(\.\d{1,2})?$/.test(value?.toString() || '')
);

export const positiveNumberWithMaxTwoDecimalPlacesSchema = (min, max) =>
  number()
    .test('maxTwoDecimalPlaces', 'Enter an amount with at most 2 decimal places', (value) =>
      /^\d+(\.\d{1,2})?$/.test(value?.toString() || '')
    )
    .min(min, `Must be ${min} or greater`)
    .max(max, `Must be ${max} or lower`);

export const ninSchema = string()
  // Remove spaces in the number
  .transform((value) => value.replace(/ /g, ''))
  .matches(
    /^[A-CEGHJ-NOPR-TW-Z]{2}[0-9]{6}[ABCD\s]{1}$/g,
    'Please enter a valid NIN number in this format: AB123456C'
  )
  .required(genericRequiredLabel);

export const countrySlugsSchema = mixed().test(
  'is-valid-country-slug',
  genericRequiredLabel,
  (value) => {
    return (
      string().defined().isValidSync(value) ||
      (Array.isArray(value) && value.every((val) => baseString.isValidSync(val)))
    );
  }
);

export const countrySlugsArraySchema = mixed().test(
  'is-valid-country-slug-array',
  genericRequiredLabel,
  (value) => {
    return nonEmptyArray.of(baseString).isValidSync(value);
  }
);

export const multiSelect = array()
  .of(
    object().shape({
      label: baseString,
      value: baseString,
    })
  )
  .typeError('Requires a set of options');

export const singleSelect = object().shape({
  label: baseString,
  value: baseString,
});

export const countrySchema = object().shape({
  code: baseString,
  features: array().of(baseString),
  name: baseString,
  slug: baseString,
});

export const requiredCountrySchema = countrySchema
  .typeError('Country required')
  .test('countrySlug', `Please select a country`, function validateCountry(country) {
    return !!country.slug;
  });

export const countryPropType = PropTypes.shape({
  code: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  slug: PropTypes.string,
});

export const employeePropType = PropTypes.shape({
  name: PropTypes.string,
  slug: PropTypes.string,
});

export const contractStatusSchema = baseString
  .required(genericRequiredLabel)
  .oneOf(Object.values(contractStatus).filter((status) => status !== contractStatus.DELETED));

export const juroContractIdSchema = baseString
  // Remove spaces
  .transform((value) => value.replace(/ /g, ''))
  // Allows to insert the URL sending but only using the ID
  .transform((value) => value.replace('https://app.juro.com/view/', ''))
  .length(24, 'Juro Contract IDs are 24 characters long');

export const currencyAmountSchema = baseString
  .test(
    'is-positive-amount',
    'Negative amounts are not valid',
    (value) => !isValidNegativeCost(value)
  )
  .test(
    'is-valid-currency-amount',
    'Please use US standard currency format. Ex: 1,024.12',
    (value) => !value || isValidCost(value)
  );

export const integerCurrencyOnlyAmountSchema = baseString
  .test(
    'is-positive-amount',
    'Negative amounts are not valid',
    (value) => !isValidNegativeCost(value)
  )
  .test(
    'is-valid-integer-currency-amount',
    'Please enter a whole number. Ex: 1024',
    (value) => !value || isValidIntegerOnlyCost(value)
  );

export const getCurrencyAmountSchema = (integerCurrencyOnly) =>
  integerCurrencyOnly ? integerCurrencyOnlyAmountSchema : currencyAmountSchema;

export const currencyWithNegativeAmountSchema = baseString.test(
  'is-valid-currency-with-negative-amount',
  'Please use US standard currency format. Ex: 1024.12',
  (value) => !value || isValidCost(value) || isValidNegativeCost(value)
);

export const integerCurrencyOnlyWithNegativeAmountSchema = baseString.test(
  'is-valid-integer-currency-with-negative-amount',
  'Please enter a whole number. Ex: 1024',
  (value) => !value || isValidIntegerOnlyCost(value) || isValidIntegerOnlyNegativeCost(value)
);

export const getCurrencyWithNegativeAmountSchema = (integerCurrencyOnly) =>
  integerCurrencyOnly
    ? integerCurrencyOnlyWithNegativeAmountSchema
    : currencyWithNegativeAmountSchema;

export const generateCurrencyWithMinimumAmountSchema = (minimum, message) =>
  currencyAmountSchema
    .required(genericRequiredLabel)
    .test('is-correct-minimum-amount', message, (value) => value && parseFloat(value) >= minimum);

export const mobileNumberValidation = baseString
  .required(genericRequiredLabel)
  .max(30, 'Must be at most 30 digits')
  .matches(
    /^(\+|00)[0-9]{6,}$/,
    'Please insert only the country code and phone number, without letters or spaces'
  );

export const regexPhone = /^(\+|00)[0-9]{6,}$/; // Make sure to be the same as in JSON schemas

export const phoneNumberValidationOptional = baseString
  .max(30, 'Must be at most 30 digits')
  .matches(
    regexPhone,
    'Please insert only the country code and phone number, without letters or spaces'
  );

export const vatNumberValidation = baseString
  .required(genericRequiredLabel)
  .matches(/^\w*$/, "Please don't include spaces or dashes");

export const integerPositiveOrZeroSchema = number()
  .integer(`Can't have decimals`)
  .min(0, 'Must be 0 or greater')
  .required(genericRequiredLabel);

export const daysOffSchema = number().min(0, 'Must be 0 or greater').required(genericRequiredLabel);

export const integerGreaterThanZero = number()
  .typeError('The value must be a number')
  .integer(`Can't have decimals`)
  .min(1, 'Must be greater than 0');

export const roleDescriptionValidation = string()
  .min(100, 'Must be at least 100 characters')
  .max(10000, 'Must be at most 10000 characters')
  .required(genericRequiredLabel);

// FILES
function getFileExtensionFromMimeType(file) {
  // Spreadsheets and docs do not contain the file extension in the MIME type
  // and therefore require special handling.
  if (SPREADSHEETS_MIME_TYPES.includes(file.type)) {
    return SPREADSHEET_EXTENSIONS[file.type];
  }
  if (WORD_DOCUMENTS_MIME_TYPES.includes(file.type)) {
    return WORD_DOCUMENTS_EXTENSIONS[file.type];
  }

  // Strips the extension from the MIME type and adds a "." at the beginning.
  const typeAsArray = file.type.split('/');
  return typeAsArray.length > 0 ? `.${typeAsArray[1]}` : file.type;
}

function generateFileValidation({ formats, parseFileExtension = false }) {
  return mixed().test('fileFormat', 'Unsupported format', (value) => {
    // this is the File object from the API and it's an edit form
    if (!value || (!(value instanceof File) && value?.name?.length > 0)) {
      return true;
    }

    const fileExtension = getFileExtensionFromMimeType(value);
    return formats.includes(parseFileExtension ? fileExtension : value.type);
  });
}

export const requiredFile = mixed().required('File is required');

export const fileSizeValidation = mixed().test(
  'fileSize',
  `File size too large. The limit is ${MAX_MB_FILE_SIZE} MB.`,
  (value) => {
    // this is the File object from the API and it's an edit form
    if (!value || (!(value instanceof File) && value?.name?.length > 0)) {
      return true;
    }

    return value && validateFileSize(value.size, MAX_FILE_SIZE_BYTES);
  }
);

export const requiredFileValidation = mixed()
  .required('File is required')
  .test(
    'fileSize',
    `File size too large. The limit is ${MAX_MB_FILE_SIZE} MB.`,
    (value) => value && validateFileSize(value.size, MAX_FILE_SIZE_BYTES)
  );

export const requiredFileValidationWithCustomMessage = (message) =>
  mixed()
    .required(message)
    .test(
      'fileSize',
      `File size too large. The limit is ${MAX_MB_FILE_SIZE} MB.`,
      (value) => value && validateFileSize(value.size, MAX_FILE_SIZE_BYTES)
    );

export const requiredFileArrayValidation = array()
  .required('File is required')
  .test(
    'fileSize',
    `File size too large. The limit is ${MAX_MB_FILE_SIZE} MB.`,
    (validationFiles) =>
      // Files pre-filled from the API have no size and are valid
      validationFiles &&
      validationFiles.every(
        (file) => !file.size || validateFileSize(file.size, MAX_FILE_SIZE_BYTES)
      )
  );

export const fileArrayValidation = array().test(
  'fileSize',
  `File size too large. The limit is ${MAX_MB_FILE_SIZE} MB.`,
  (validationFiles) =>
    // Files pre-filled from the API have no size and are valid
    !validationFiles ||
    (validationFiles &&
      validationFiles.every(
        (file) => !file.size || validateFileSize(file.size, MAX_FILE_SIZE_BYTES)
      ))
);

export const totalSizeFileArrayValidation = array().test(
  'fileSize',
  `Total file size too large. The limit is ${MAX_MB_FILE_SIZE} MB.`,
  (validationFiles) => {
    // Files pre-filled from the API have no size and are valid
    if (!validationFiles) return true;

    const totalFileSize = validationFiles.reduce(
      (acc, cur) => (cur.size ? acc + cur.size : acc),
      0
    );
    return validateFileSize(totalFileSize, MAX_FILE_SIZE_BYTES);
  }
);

// This assumes other ID cards are optional if there is the main doc ic (personalId)
export const extraIDdocsValidation = fileArrayValidation.when(
  'personalId-blocker',
  (personalIdBlocker, schema) =>
    personalIdBlocker
      ? schema.required('Required because you did not upload a List A document.')
      : schema
);

export const mainIdDocValidation = fileArrayValidation.when(
  ['governmentIssuedId-blocker', 'socialSecurityCard-blocker'],
  (governmentIssuedIdBlocker, socialSecurityCardBlocker, schema) => {
    return governmentIssuedIdBlocker || socialSecurityCardBlocker
      ? schema.required('Required because you did not provide a List B and C documents')
      : schema;
  }
);

export const mainNationalIdDocValidation = fileArrayValidation.when(
  ['passport-blocker'],
  (passportBlocker, schema) => {
    return passportBlocker
      ? schema.required('Required because you did not provide a passport')
      : schema;
  }
);

export const mainPassportDocValidation = fileArrayValidation.when(
  ['national-id-blocker'],
  (nationalIdBlocker, schema) => {
    return nationalIdBlocker
      ? schema.required('Required because you did not provide a national id')
      : schema;
  }
);

export const fileValidation = (supportedFormats = []) =>
  requiredFile.concat(fileSizeValidation).concat(
    generateFileValidation({
      formats: supportedFormats.length > 0 ? supportedFormats : DEFAULT_SUPPORTED_FORMATS,
      parseFileExtension: supportedFormats.length > 0,
    })
  );

export const payslipFileValidation = mixed()
  .concat(fileSizeValidation)
  .concat(generateFileValidation({ formats: PAYSLIP_SUPPORTED_FORMATS }))
  .required(genericRequiredLabel);

export const payrollOutputFileValidation = mixed()
  .concat(fileSizeValidation)
  .concat(generateFileValidation({ formats: PAYROLL_OUTPUT_SUPPORTED_FORMATS }));

export const payrollRunFilesValidation = mixed()
  .concat(fileSizeValidation)
  .concat(generateFileValidation({ formats: PAYROLL_OUTPUT_SUPPORTED_FORMATS }));

export const optionaPayslipFileValidation = mixed()
  .concat(fileSizeValidation)
  .concat(generateFileValidation({ formats: PAYSLIP_SUPPORTED_FORMATS }));

export const invoiceFileValidationOptional = fileSizeValidation.concat(
  generateFileValidation({ formats: INVOICE_SUPPORTED_FORMATS })
);

export const documentValidation = mixed()
  .concat(fileSizeValidation)
  .concat(generateFileValidation({ formats: DOCUMENT_SUPPORTED_FORMATS }));

export const uploadDocumentValidation = mixed()
  .concat(fileSizeValidation)
  .concat(generateFileValidation({ formats: UPLOAD_DOCUMENT_SUPPORTED_FORMATS }));

export const wordDocumentValidation = mixed()
  .concat(fileSizeValidation)
  .concat(generateFileValidation({ formats: WORD_DOCUMENTS }));

export const nicNumberPakSchema = baseString
  .required(genericRequiredLabel)
  .max(13, 'Must be at most 13 digits')
  .matches(/^[0-9]+$/, 'Please enter a valid number (digits only, no hyphens)');

export const currencySchema = object().shape({
  code: baseString,
  slug: baseString,
  symbol: baseString,
});

export const requiredCurrencySchema = currencySchema
  .typeError('Requires a currency object')
  .test('code', `Please select a currency`, function validateCurrency(currency) {
    return !!currency.code;
  });

export const currencyPropType = PropTypes.shape({
  code: PropTypes.string,
  slug: PropTypes.string,
  symbol: PropTypes.string,
});

export const legalEntityAddressPropType = PropTypes.shape({
  address: PropTypes.string,
  country: countryPropType,
});

export const legalEntityPropType = PropTypes.shape({
  address: legalEntityAddressPropType,
  name: PropTypes.string,
  kvk: PropTypes.string,
  vat: PropTypes.string,
  slug: PropTypes.string,
});

export const targetAudienceRolePropType = PropTypes.oneOf([USER_TYPE.EMPLOYER, USER_TYPE.EMPLOYEE]);

export const validateIsNumber = number()
  .typeError('The value must be a number')
  .test('is-number', 'The value must be a number', (value) => !Number.isNaN(parseFloat(value)));

export const validateIsNumberOptional = number()
  .nullable(true)
  .typeError('The value must be a number');

export const validateIsPositiveIntegerOrZero = number()
  .nullable(true)
  .integer(`Can't have decimals`)
  .min(0, 'Must be 0 or greater')
  .typeError('The value must be a number');

export const validateIsPositiveNumber = number()
  .typeError('The value must be a number')
  .positive('The number must be greater than zero.')
  .required(genericRequiredLabel);

export const validateIsPositiveNumberOptional = number()
  .nullable(true)
  .typeError('The value must be a number')
  .positive('The number must be greater than zero.');

export const validateIsDigits = string()
  .trim()
  .matches(/^\d+$/, 'Value must be only digits')
  .required(genericRequiredLabel);

export const validateEINRequiredSchema = baseString
  .matches(/\d{2}-\d{7}/, `Must be a valid EIN in 00-0000000 format. e.g. 12–3484245`)
  .required(genericRequiredLabel);

export const validateAck = baseString.required('Please acknowledge this field');

export const benefitPlanCostType = mixed().oneOf(Object.values(benefitPlanCostTypes));

export const componentStylePropType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.arrayOf(PropTypes.string),
]);

/**
 * Compose schema for a string of digits with an exact length
 * @param {number} length - The length of the string
 */
export function composeSchemaDigitsLength(length) {
  return baseString
    .matches(/^[0-9]+$/, 'Must be only digits')
    .length(length, `Must be exactly ${length} digits`);
}

/**
 * Although we can target nested fields by using dot-notation,
 * in some contexts, we need to access only the innermost field name.
 * @param {string} fieldName - The field name (can include path described by dot-notation)
 * @example 'address.street.number' -> 'number'
 */
function getFieldNameWithoutPath(fieldName) {
  return last(fieldName.split('.'));
}

/**
 * Validation and visibility condition for a single field
 * @param {object} params - The parameters for this function
 * @param {string} params.name - The field name
 * @param {string} [params.value] - The field value
 * @param {function} params.validate - The validation for this field. Should return a Boolean.
 * @param {function} [params.visibilityCondition] - The visibility condition for this field. Should return a Boolean.
 * @param {object} params.schemaType - The schema that applies when this field is invisible
 * @param {object} [params.schemaTypeVisible] - The schema that applies when this field is visible
 * @param {boolean} [params.isNested] - If field is nested in a fieldset
 */
export function validationForField({
  name,
  validate,
  visibilityCondition,
  schemaType,
  schemaTypeVisible,
  isNested = false,
}) {
  // Nested fields need a "$" prefix to be interpreted properly by ".when" function
  // https://github.com/jquense/yup/issues/1213#issuecomment-1706934137
  const fieldNameWithoutPath = isNested ? `$${name}` : getFieldNameWithoutPath(name);

  return {
    visibilityCondition: (props) => {
      const existingFieldValue = get(props, name);

      return visibilityCondition
        ? visibilityCondition(existingFieldValue)
        : validate(existingFieldValue);
    },
    schema: schemaType.when(fieldNameWithoutPath, {
      is: (existingFieldValue) => validate(existingFieldValue),
      then: schemaTypeVisible || schemaType.required(genericRequiredLabel),
      otherwise: schemaType,
    }),
  };
}

/**
 * Validation and visibility conditions for one or multiple fields
 * @param {(object|object[])} fields - an array of field objects, or a single field object
 * @param {object} schemaType - The schema that applies when this field is invisible
 * @param {object} [schemaTypeVisible] - The schema that applies when this field is visible
 * @param {'some'|'all'} [validationType] - Validate some or all fields
 */
export function validationForFields(fields, schemaType, schemaTypeVisible, validationType) {
  // NOTE: When passed a single field instead of an array, we add it to an array.
  // This allows for a little more sensible syntax when there is only a single
  // visibility condition needed.
  const fieldsList = Array.isArray(fields) ? fields : [fields];

  const fieldNames = fieldsList.map((field) => getFieldNameWithoutPath(field.name));

  return {
    visibilityCondition: (props) => {
      const validationResults = fieldsList.map(({ name, visibilityCondition, validate }) => {
        // NOTE: This will get the field value, even if a nested field was provided
        const existingFieldValue = get(props, name);

        return visibilityCondition
          ? visibilityCondition(existingFieldValue, props)
          : validate(existingFieldValue, props);
      });

      const isValid =
        validationType === 'some'
          ? validationResults.some((result) => result === true)
          : validationResults.every((result) => result === true);

      return isValid;
    },
    schema: schemaType.when(fieldNames, {
      is: (...fieldValues) => {
        const fieldValuesByName = Object.fromEntries(
          fieldsList.map(({ name }, index) => [name, fieldValues[index]])
        );
        const validatedFields = fieldsList.map(({ validate }, index) =>
          validate(fieldValues[index], fieldValuesByName)
        );

        const isValid =
          validationType === 'some'
            ? validatedFields.some((validatedField) => validatedField === true)
            : validatedFields.every((validatedField) => validatedField === true);

        return isValid;
      },
      then: schemaTypeVisible || schemaType.required(genericRequiredLabel),
      otherwise: schemaType,
    }),
  };
}

export const makeVisibilityAndRequirementDependentOnFields = (
  fieldNamesToDependOn,
  conditionForVisibility
) => {
  // Allow for passing in single string instead of array
  fieldNamesToDependOn = Array.isArray(fieldNamesToDependOn)
    ? fieldNamesToDependOn
    : [fieldNamesToDependOn];

  return (
    schemaTypeVisible = mixed(),
    schemaTypeInvisible = schemaTypeVisible.nullable().notRequired()
  ) => ({
    visibilityCondition: (formSlice) =>
      conditionForVisibility(fieldNamesToDependOn.map((name) => get(formSlice, name))),
    schema: mixed().when(fieldNamesToDependOn, (...fieldValues) =>
      conditionForVisibility(fieldValues) ? schemaTypeVisible : schemaTypeInvisible
    ),
  });
};

const currentDate = new Date();
export const currentDateWith12Months = currentDate.setMonth(currentDate.getMonth() + 12);

export const maxDateNotExceed12MonthsSchema = date().max(
  new Date(currentDateWith12Months),
  'Short term contracts cannot exceed 12 months'
);

export const probationLengthSchema = (experienceLevelOptions) =>
  validateIsNumber.test('probationLength-match', null, function matchWithSibling() {
    const { path, parent, createError } = this;
    const level = experienceLevelOptions.find((x) => parent.experienceLevel?.startsWith(x.label));
    const expectedProbationLength = level?.attrs?.['data-probationlength'];
    const probationLengthErrorMessage = `Based on what you selected for job category/level, probation period must be exactly
      ${getSingularPluralUnit(expectedProbationLength, 'month', 'months', false)}`;
    if (expectedProbationLength !== parent.probationLength) {
      return createError({
        path,
        message: probationLengthErrorMessage,
      });
    }
    return true;
  });

export const ukPostalCodeSchema = baseString
  .required(genericRequiredLabel)
  .matches(/^([A-Z]{1,2}[0-9][A-Z0-9]?|[BFS]IQQ) ?[0-9][A-Z]{2}$/, 'Invalid UK postal code');

// Allows 9 digit US postal code (zip+4)
export const usZip4PostalCodeSchema = baseString
  .required(genericRequiredLabel)
  .matches(/^[0-9]{5}-[0-9]{4}$/, 'Invalid US postal code');

// Allows 5 (zip) or 9 digit US postal code (zip+4)
export const usPostalCodeSchema = baseString
  .required(genericRequiredLabel)
  .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid US postal code');

// TODO: This should be used to validate employee but not contracor bank number in localBankFields
export const idnBankNumberSchema = baseString
  .required(genericRequiredLabel)
  .max(3, 'Must be at most 3 digits')
  .matches(/^[0-9]+$/, 'Please enter a valid number (digits only, no hyphens)');

export const timeoffDaysSchema = array().of(
  object()
    .shape({
      hours: baseString.matches(/^[0-8]$/, validHoursLabel),
      day: baseString.required(genericRequiredLabel),
    })
    .test('is-not-zero-hours-total', null, function checkTotalHours() {
      const { parent, createError } = this;
      const totalHour = parent.reduce((prev, curr) => {
        return prev + (parseInt(curr.hours) || 0);
      }, 0);
      if (totalHour <= 0) {
        return createError({
          path: 'Total hours: ',
          message: 'The number of hours must be greater than zero. Please edit hours to continue',
        });
      }
      return true;
    })
);

// requires a string that is a valid city name
export const cityValidation = string().matches(/^[^\d]+$/, 'City cannot contain numbers');
