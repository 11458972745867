import { Text } from '@remote-com/norma';
import type { Editor as EditorCoreType } from '@tiptap/core';
import type { EditorOptions, HTMLContent, JSONContent } from '@tiptap/react';
import debounce from 'lodash/debounce';
import type { FunctionComponent, RefObject } from 'react';
import { forwardRef, useRef } from 'react';

import { Editor } from '@/src/domains/editor/Editor';
import type { EditorProps, EditorRef } from '@/src/domains/editor/Editor';
import { isContentBlockEmpty } from '@/src/domains/editor/helpers';
import { error, isTest } from '@/src/helpers/general';

export default function EditorField({
  setValue,
  valueFromApi,
  name,
  isLoading,
  hasError,
  placeholder,
  minHeight,
  maxWidth,
  onBlur,
  showToolbar = true,
  editorOptions = {},
  ToolbarAction,
  editorRef,
  variant,
}: {
  setValue: (arg: string, arg2: HTMLContent | undefined) => void;
  valueFromApi: HTMLContent | undefined | null;
  name: string;
  isLoading?: boolean;
  hasError?: boolean;
  placeholder?: string;
  showToolbar?: boolean;
  editorOptions?: Partial<EditorOptions>;
  onBlur?: EditorOptions['onBlur'];
  minHeight?: EditorProps['minHeight'];
  maxWidth?: EditorProps['maxWidth'];
  ToolbarAction?: FunctionComponent<{ editor: EditorCoreType }>;
  editorRef?: RefObject<EditorRef>;
  variant?: EditorProps['variant'];
}) {
  async function onUpdate(onUpdateEditor: EditorCoreType) {
    if (isContentBlockEmpty(onUpdateEditor.getJSON())) {
      setValue(name, '');
    } else {
      setValue(name, onUpdateEditor?.getHTML());
    }
  }

  const debouncedUpdate = useRef(debounce(onUpdate, 500)).current;

  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  return (
    <Editor
      showToolbar={showToolbar}
      editorOptions={{
        onUpdate: ({ editor: onUpdateEditor }) => {
          // Only running in tests
          // eslint-disable-next-line no-autofix/no-floating-promise/no-floating-promise
          if (isTest()) onUpdate(onUpdateEditor);
          else debouncedUpdate(onUpdateEditor);
        },
        content: valueFromApi,
        ...(onBlur ? { onBlur } : {}),
        ...editorOptions,
      }}
      ref={editorRef}
      hasError={hasError}
      placeholder={placeholder}
      minHeight={minHeight}
      maxWidth={maxWidth}
      ToolbarAction={ToolbarAction}
      variant={variant}
    />
  );
}

/**
 * Wrapper around the Editor that provides a field-like API, for TipTap editors exporting *JSON* content.
 * This was copied from `EditorField`, for `domains/careerDevelopment`.
 * To be explored: integrating functionality into existing EditorField / other editor domain components in a more useful way..
 */
export const EditorFieldJSON = forwardRef<
  EditorRef,
  EditorProps & {
    /**
     * Make the editor inherit height.
     * Use this to make the editor grow to fill space in your layout, by setting `height: 100%` on its parent element.
     */
    autoHeight?: boolean;
    isLoading?: boolean;
    name: string;
    onBlur?: EditorOptions['onBlur'];
    setValue: (fieldName: string, value: JSONContent | undefined, textValue: string) => void;
    valueFromApi: JSONContent | undefined | null;
  }
>(
  (
    {
      autoHeight = false,
      editorOptions = {},
      hasError,
      isLoading,
      maxWidth,
      minHeight,
      name,
      onBlur,
      placeholder,
      setValue,
      showToolbar = true,
      ToolbarAction,
      valueFromApi,
      variant,
    },
    ref
  ) => {
    async function onUpdate(onUpdateEditor: EditorCoreType) {
      setValue(name, onUpdateEditor?.getJSON(), onUpdateEditor?.getText());
    }

    const debouncedUpdate = useRef(debounce(onUpdate, 500)).current;

    if (isLoading) {
      return <Text>Loading...</Text>;
    }

    return (
      <Editor
        ref={ref}
        autoHeight={autoHeight}
        showToolbar={showToolbar}
        editorOptions={{
          onBlur: ({ editor }) => {
            onUpdate(editor).catch((err) => {
              error(`Error updating editor content on blur: ${err?.message}`);
            });
          },
          onUpdate: ({ editor: onUpdateEditor }) => {
            // Only running in tests
            // eslint-disable-next-line no-autofix/no-floating-promise/no-floating-promise
            if (isTest()) onUpdate(onUpdateEditor);
            else debouncedUpdate(onUpdateEditor);
          },
          content: valueFromApi,
          ...(onBlur ? { onBlur } : {}),
          ...editorOptions,
        }}
        hasError={hasError}
        placeholder={placeholder}
        maxWidth={maxWidth}
        minHeight={minHeight}
        ToolbarAction={ToolbarAction}
        variant={variant}
      />
    );
  }
);
