import { hoursToMinutes, secondsToHours, secondsToMinutes } from 'date-fns';
import type { ValueOf } from 'type-fest';

import {
  PAY_ELEMENT_FREQUENCY,
  PAY_ELEMENT_OCCURRENCES,
  PAY_ELEMENT_UNIT_TYPES,
  PAY_ELEMENT_UNIT_TYPES_LABELS,
} from '@/src/domains/payElements/constants';
import type { EmployeePayElement, TimesheetsMetaValue } from '@/src/domains/payElements/types';
import { EMPLOYEE_PAY_ELEMENT_OCCURRENCE_VARIATIONS } from '@/src/domains/team/shared/UserProfile/EmployeeProfilePayElements/constants';
import { convertFromCents, friendlyMoneyWithCurrencyCode } from '@/src/helpers/currency';
import { displayDuration, formatTime24, formatTimezone } from '@/src/helpers/date';
import { getFromConstObject } from '@/src/helpers/getFromConstObject';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';
import { timeTrackingIntegrationEnabledStore } from '@/src/views/rivendell/payrolls/PayrollRun/timeTrackingIntegrationEnabledStore';

export function getEmployeePayElementOccurrenceVariation(payElement: EmployeePayElement): {
  variation?: ValueOf<typeof EMPLOYEE_PAY_ELEMENT_OCCURRENCE_VARIATIONS>;
  targetPayElement: EmployeePayElement;
} {
  if (
    payElement.occurrence === PAY_ELEMENT_OCCURRENCES.TEMPORARY &&
    !payElement.fromPermanentEmployeePayElement
  ) {
    return {
      variation: EMPLOYEE_PAY_ELEMENT_OCCURRENCE_VARIATIONS.ONE_OFF_TEMPORARY_PAY_ELEMENT,
      targetPayElement: payElement,
    };
  }

  if (
    payElement.occurrence === PAY_ELEMENT_OCCURRENCES.TEMPORARY &&
    payElement.fromPermanentEmployeePayElement
  ) {
    return {
      variation:
        EMPLOYEE_PAY_ELEMENT_OCCURRENCE_VARIATIONS.TEMPORARY_PAY_ELEMENT_LINKED_TO_PERMANENT_PAY_ELEMENT,
      // The pay element to render the ocurrence from is the PEPE from this TEPE
      targetPayElement: payElement.fromPermanentEmployeePayElement,
    };
  }

  if (payElement.occurrence === PAY_ELEMENT_OCCURRENCES.PERMANENT && payElement.nextOccurrence) {
    return {
      variation:
        EMPLOYEE_PAY_ELEMENT_OCCURRENCE_VARIATIONS.NEXT_OCCURRENCE_OF_PERMANENT_PAY_ELEMENT,
      // The pay element to render the ocurrence from is this PEPE
      targetPayElement: payElement,
    };
  }

  return {
    // Fallbak to the pay element that originated this request
    targetPayElement: payElement,
  };
}

// Using regular labels for simplicity, as they differ from all the other ones
export function getEmployeePayElementOccurrenceLabel(payElement: EmployeePayElement) {
  const { variation, targetPayElement } = getEmployeePayElementOccurrenceVariation(payElement);

  if (variation === EMPLOYEE_PAY_ELEMENT_OCCURRENCE_VARIATIONS.ONE_OFF_TEMPORARY_PAY_ELEMENT) {
    return 'Once';
  }

  if (targetPayElement?.payElementFrequency === PAY_ELEMENT_FREQUENCY.BI_MONTHLY) {
    if (targetPayElement.payElementValueMethod === 'every_pay_element') {
      return 'Bi-monthly (identical)';
    }

    return 'Bi-monthly (pro-rata)';
  }

  if (targetPayElement?.payElementFrequency === PAY_ELEMENT_FREQUENCY.BI_WEEKLY) {
    return 'Bi-weekly';
  }

  return 'Monthly';
}

export const formatPayElementDurationValue = (initialSeconds: number) => {
  const isNegative = initialSeconds < 0;
  const seconds = Math.abs(initialSeconds);
  const hours = secondsToHours(seconds);
  const minutes = secondsToMinutes(seconds) - hoursToMinutes(hours);

  return isNegative
    ? `-${displayDuration({ hours, minutes })}`
    : displayDuration({ hours, minutes });
};

const formatPayElementDurationStartEnd = (
  seconds: number,
  start: string,
  end: string,
  timezone?: string
) => {
  const range = `${formatTime24(start, { timezone })} -> ${formatTime24(end, { timezone })}`;
  const totalTime = formatPayElementDurationValue(seconds);
  const formattedTimezone = timezone ? ` ${formatTimezone(end, { timezone })}` : '';

  return `${range} (${totalTime})${formattedTimezone}`;
};

export function formatPayElementDuration(value: number, meta?: TimesheetsMetaValue) {
  const start = meta?.timesheets?.start;
  const end = meta?.timesheets?.end;

  if (start && end && !timeTrackingIntegrationEnabledStore.getState().isEnabled) {
    return formatPayElementDurationStartEnd(value, start, end, meta?.timesheets?.timezone);
  }

  return formatPayElementDurationValue(value);
}

type Params = {
  type: string;
  value: number;
  currency?: { code: string };
  meta?: any;
};

export function getFormattedPayElementValue({ type, value, currency, meta }: Params): string {
  switch (type) {
    case PAY_ELEMENT_UNIT_TYPES.PERCENTAGE:
      return `${convertFromCents(value)}%`;
    case PAY_ELEMENT_UNIT_TYPES.UNIT:
      return getSingularPluralUnit(value, 'unit', 'units', false);
    case PAY_ELEMENT_UNIT_TYPES.AMOUNT:
      return friendlyMoneyWithCurrencyCode(value, currency!, 'right');
    case PAY_ELEMENT_UNIT_TYPES.DURATION:
      return formatPayElementDuration(value, meta);
    default:
      return String(value);
  }
}

export function getFormattedPayElementUnit({
  type,
  currency,
}: Pick<Params, 'type' | 'currency'>): string {
  switch (type) {
    case PAY_ELEMENT_UNIT_TYPES.PERCENTAGE:
      return '%';
    case PAY_ELEMENT_UNIT_TYPES.UNIT:
      return 'Units';
    case PAY_ELEMENT_UNIT_TYPES.AMOUNT:
      if (currency) {
        return currency.code;
      }
    // fall through
    default:
      return getFromConstObject(PAY_ELEMENT_UNIT_TYPES_LABELS, type, String(type));
  }
}
