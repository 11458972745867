import * as Palettes from './palettes';
import * as PalettesDark from './palettes-dark';
import { SEMANTIC_COLORS, SEMANTIC_COLORS_DARK } from './semantic';
import { v1ColorCompatibilities, v1ColorCompatibilitiesDark } from './v1ColorCompatibilities';

export const colors = {
  ...Palettes,
  primary: Palettes.brand[600],
  blank: Palettes.white,
  ...SEMANTIC_COLORS,

  // compatibility layers
  ...v1ColorCompatibilities,
};

export const colorsDark = {
  ...PalettesDark,
  primary: PalettesDark.brand[600],
  blank: PalettesDark.white,
  ...SEMANTIC_COLORS_DARK,
  ...v1ColorCompatibilitiesDark,
};
