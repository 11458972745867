// Add data types to window.navigator ambiently for implicit use in the entire project. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="user-agent-data-types" />

import { useGet } from '@remote-com/data-layer';
import { getRandomColorForInitialsImage } from '@remote-com/norma';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useContext, useState, useMemo, useEffect, useRef } from 'react';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery } from 'react-query';

import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import { SETUP_2FA_ROUTE, SIGN_IN_ROUTE } from '@/src/constants/routes';
import { transformPermissions } from '@/src/domains/account/helpers';
import { isSCA2FARequired, isUserOnboarded } from '@/src/domains/registration/auth/helpers';
import {
  getDashboardRedirect,
  getUserDestination,
} from '@/src/domains/registration/auth/helpers/redirects';
import { taskStatus } from '@/src/domains/tasks/constants';
import { fetchUserTasks } from '@/src/domains/tasks/services';
import { fetchUserCache } from '@/src/domains/userCache/services';
import { captureException } from '@/src/helpers/captureException';
import { isUserOnMacOS, redirectToPath } from '@/src/helpers/general';
import { pageExists } from '@/src/helpers/pageNotFoundHandler';
import { isZendeskURL } from '@/src/helpers/security';
import { getZendeskSSOUrl } from '@/src/helpers/zendesk';
import { useCustomMutation } from '@/src/hooks/useCustomMutation';
import { installAuthInterceptor, removeAuthInterceptor } from '@/src/services/ApiClient';
import { transformLoginsResponse, selectUserProfile } from '@/src/services/User';
import type { $TSFixMe } from '@/types';

import type { UserContextUser } from './context';
import UserContext from './context';
import {
  canAccessDashboardRoute,
  isUserWithTasks,
  isPrivateRoute,
  isDashboardRoute,
} from './utils';

export type UserAccountResponse = UserAccount;

type TUserProvider = {
  user?: UserContextUser;
  // to avoid fixing all the tests that pass a user through renderWithWrapper, we enable this props in tests
  isTestingMode: boolean;
  children: ReactNode;
};

/**
 * Redirects the user to a Zendesk url
 * @param {string} query - The query string to be used for generating the Zendesk SSO URL.
 */
async function redirectToZendesk(query: string) {
  try {
    const zendeskSSOUrl = await getZendeskSSOUrl(query);
    redirectToPath(zendeskSSOUrl);
    return;
  } catch (e) {
    console.error('Could not redirect to Zendesk');
  }
}

const UserProvider = ({ user: testUser, isTestingMode = false, children }: TUserProvider) => {
  const { pathname, push, query, asPath, replace } = useRouter();
  const [timeToSignOutRemaining, setTimeToSignOutRemaining] = useState<number | undefined>();
  const [canRenderPage, setCanRenderPage] = useState(true);
  const [channelReference, setChannelReference] = useState();
  const [userAuthData, setUserAuthenticationData] = useState<{
    email: string;
    password: string;
    isInternalUser: boolean;
    originPathname: string;
  }>();
  const userTasks = useRef<{ tasks: any[]; isFetched: boolean }>({ tasks: [], isFetched: false });

  const {
    data: account,
    refetch,
    isLoading: isLoadingUser,
    isFetched: isUserAccountFetched,
  } = useGet('/api/v1/account', {
    options: {
      select: transformPermissions, // permissions data must be formatted to snake_case as done in /services/User#getUser
      retry: false,
      enabled: !isTestingMode,
    },
  });

  const { data: accountCache, isLoading: isLoadingUserCache } = useQuery(
    'user-cache',
    () => fetchUserCache(account.data),
    {
      enabled: !!account,
    }
  );

  const { data: loginMethods, refetch: refetchLogins } = useGet('/api/v1/account/logins', {
    options: {
      enabled: !!account || !!testUser,
      select: transformLoginsResponse,
      retry: false,
    },
  });

  const user = useMemo(() => {
    if (isTestingMode) return testUser;
    if (!account) return undefined;

    return {
      ...account.data,
      ...(accountCache && { userCache: accountCache }),
      isOnMacOS: isUserOnMacOS(),
      initialsColor: getRandomColorForInitialsImage(account.data.name),
    };
  }, [isTestingMode, testUser, account, accountCache]);

  /**
   * When the user is not authenticated, redirect to sign in page when accessing a protected route.
   * @param shouldRedirectOnFail
   */
  async function handleSignedOutUser(shouldRedirectOnFail: boolean) {
    setCanRenderPage(true);

    if (query.oap) {
      await selectUserProfile({ bodyParams: { profileIndex: query.oap } });
    }

    // when user is not authenticated and tries to access a protected route, redirect to sign in
    if (shouldRedirectOnFail) {
      push(`${SIGN_IN_ROUTE}?redirect=${encodeURIComponent(asPath)}`);
    }
  }

  /**
   * Handle user redirection for signed in users.
   * @returns
   */
  async function handleSignedInUser() {
    const isTwoFactorAuthenticationRequired =
      !account.data?.totp?.enabled &&
      isUserOnboarded(account.data) &&
      isSCA2FARequired(account.data);

    if (isTwoFactorAuthenticationRequired) {
      push(SETUP_2FA_ROUTE);
      return;
    }

    if (isDashboardRoute() && !canAccessDashboardRoute(account.data)) {
      setCanRenderPage(false);
      // for non-admins: redirect to /dashboard when accessing /rivendell routes
      // for Remote admins: redirect to /rivendell when accessing /dashboard routes
      push(getDashboardRedirect(account?.data) || '/');
      return;
    }

    if (!userTasks.current.isFetched && isUserWithTasks(account.data)) {
      const { data: tasksData } = await fetchUserTasks({
        queryParams: { status: taskStatus.CREATED },
      });
      userTasks.current.tasks = tasksData;
      userTasks.current.isFetched = true;
    }

    const userDestination = await getUserDestination({
      user: account.data,
      context: { pathname },
      tasks: userTasks.current.tasks,
    });

    if (userDestination === null) {
      setCanRenderPage(true);
      return;
    }

    if (userDestination && userDestination !== pathname) {
      setCanRenderPage(false);
      push(userDestination);
    }
  }

  async function getUserRedirect(shouldRedirectOnFail: boolean) {
    if (process.env.NODE_ENV === 'production' && pathname === '/404') {
      const exists = await pageExists(asPath);
      if (exists) {
        replace(asPath);
        return;
      }
    }

    if (!account) {
      await handleSignedOutUser(shouldRedirectOnFail);
      return;
    }

    await handleSignedInUser();
  }

  /**
   * Redirect the user to Zendesk if the query param return_to is present.
   */
  useEffect(() => {
    if (isZendeskURL(query?.return_to)) {
      // eslint-disable-next-line no-autofix/no-floating-promise/no-floating-promise
      redirectToZendesk(query.return_to as string);
    }
  }, [asPath, query]);

  /**
   * Handle user redirection once account data is fetched.
   */
  useEffect(() => {
    const shouldRedirectOnFail = isPrivateRoute(asPath);

    // Ensure redirect to sign in if 401 occurs
    if (shouldRedirectOnFail) {
      installAuthInterceptor();
    } else {
      removeAuthInterceptor();
    }

    if (isUserAccountFetched) {
      // eslint-disable-next-line no-autofix/no-floating-promise/no-floating-promise
      getUserRedirect(shouldRedirectOnFail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, isUserAccountFetched]);

  /**
   * Refetch /account and /account/logins
   * @returns
   */
  async function refreshUser() {
    try {
      const [userData] = await Promise.all([refetch(), refetchLogins()]);
      return userData.data.data;
    } catch (e) {
      captureException(`error refreshing user: ${e}`);
      return {};
    }
  }

  function setInactivityTimeRemaining(time: number) {
    setTimeToSignOutRemaining(time);
  }

  function setChannelRef(ch: any) {
    setChannelReference(ch);
  }

  function setUserAuthData({
    email,
    password,
    isInternalUser,
    originPathname,
  }: {
    email: string;
    password: string;
    isInternalUser: boolean;
    originPathname: string;
  }) {
    setUserAuthenticationData({ email, password, isInternalUser, originPathname });
  }

  const value = {
    user,
    isLoading: isLoadingUser || isLoadingUserCache,
    loginMethods,
    refreshUser,
    setInactivityTimeRemaining,
    timeToSignOutRemaining,
    setChannelRef,
    channelReference,
    userAuthData,
    setUserAuthData,
  };

  // Note: This is not ideal but due to the way tests were set up, where a user is passed directly to UserContext Provider,
  // we don't need to go through the account fetching and therefore the logic bellow is not used in tests.
  // The solution would be to refactor all the tests and set user using through MSW.
  if (isTestingMode) {
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
  }

  if (isUserAccountFetched) {
    // canRenderPage boolean is needed because when a Remote admin tries to access a /dashboard route
    // without it there's a render that causes the page to throw an error because the actual user is trying to access resources that are not allowed
    if (canRenderPage) {
      return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
    }
    return null;
  }

  return null;
};

export const useUserContext = () => useContext(UserContext);

/**
 * Performs a mutation an update user on success, both in React.context and in the DOM
 * @param {Function} mutationFn function that performs an asynchronous task and returns a promise.
 * @param {*} options useMutation options
 * @returns
 */
export function useMutationAndUpdateUser(
  mutationFn: () => Promise<any>,
  options: { onSuccess?: (data: $TSFixMe) => void; onError?: (err: $TSFixMe) => void } = {}
) {
  const { refreshUser } = useUserContext();
  return useCustomMutation(mutationFn, {
    ...options,
    onSuccess: async (data: $TSFixMe) => {
      try {
        await refreshUser?.();
        options.onSuccess?.(data);
      } catch (err) {
        options?.onError?.(err);
      }
    },
  });
}

export default UserProvider;
