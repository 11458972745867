import { transparentize } from 'polished';
import type { ElementType, PropsWithChildren, ButtonHTMLAttributes } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

import { sharedTransition } from '../../core/animations';
import type { ButtonProps } from '../../core/button';
import { Button } from '../../core/button';
import { focusVisible } from '../../core/common.styled';
import { Box } from '../../layout';

type AddFieldCardProps = PropsWithChildren<{}> &
  ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

const Circle = styled.span`
  display: block;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    transparentize(0.68, theme.colors.primary)}; /* opacity is 0.32 */
  color: ${({ theme }) => theme.colors.primary};
  margin-right: ${({ theme }) => theme.space[4]}px;
`;

type AddFieldIconProps = {
  Icon: ElementType;
  width?: string;
};

export const AddFieldIcon = ({ Icon, width }: AddFieldIconProps) => (
  <Circle>
    <Icon width={width} />
  </Circle>
);

export const ButtonCardWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NormaRawButton = (props: AddFieldCardProps) => <Button variant="raw" {...props} />;

export const AddFieldCard = styled(NormaRawButton)`
  ${({ theme }) => theme.typography.smMedium};
  ${sharedTransition('border-color')}

  --button-colorText: ${({ theme }) => theme.colors.grey[900]};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px;
  cursor: pointer;
  color: var(--button-colorText);

  /* Set an invisible border so that all variants look similar
   in size, regardless if they have or not an actual border. */
  border: 2px solid transparent;
  border-radius: 12px;

  background: ${({ theme }) => theme.colors.blank};
  border: 2px dashed ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand[100]};
    color: var(
      --button-colorText
    ) !important; /* We need to specify the color directly here so that it's not overridden by the primary shade */
  }

  ${({ theme }) =>
    focusVisible(css`
      box-shadow: 0 0 0 2px ${theme.colors.blank}, 0 0 0 4px ${theme.colors.brand[500]};
      background-color: ${theme.colors.brand[100]};
    `)}
`;
