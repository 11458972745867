import { create } from 'zustand';

type SensitiveActionStore = {
  isSessionMarkedAsInactive: boolean;
  setIsSessionMarkedAsInactive: (isSessionMarkedAsInactive: boolean) => void;
};

export const useSensitiveActionStore = create<SensitiveActionStore>((set) => ({
  isSessionMarkedAsInactive:
    typeof window !== 'undefined' &&
    sessionStorage.getItem('session_marked_as_inactive') === 'true',
  setIsSessionMarkedAsInactive: (isSessionMarkedAsInactive: boolean) => {
    if (typeof window !== 'undefined') {
      if (isSessionMarkedAsInactive) {
        sessionStorage.setItem('session_marked_as_inactive', 'true');
      } else {
        sessionStorage.removeItem('session_marked_as_inactive');
      }
    }
    set({ isSessionMarkedAsInactive });
  },
}));
