/**
 * Copy-paste from react-to-text - https://github.com/lhansford/react-to-text/blob/main/src/index.tsx
 * Why: Because the package depends on react@^18.x, and we are at 17
 *      even though it worked fine locally. Bug opened https://github.com/lhansford/react-to-text/issues/294
 *
 * Modified to properly handle HTML content by stripping HTML tags from string content.
 */

import type React from 'react';
import { isValidElement } from 'react';

/**
 * Strips HTML tags from a string while preserving the text content
 */
function stripHtmlTags(str: string): string {
  // Create a temporary div element to parse HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = str;
  // Return the text content, which automatically strips HTML
  return tempDiv.textContent || tempDiv.innerText || '';
}

function reactToText(node: React.ReactNode | object, resolvers?: any): string {
  if (typeof node === 'string') {
    // Strip HTML tags if the string contains HTML
    return stripHtmlTags(node);
  }

  if (typeof node === 'number' || typeof node === 'boolean') {
    return node.toString();
  }

  if (!node) {
    return '';
  }

  if (Array.isArray(node)) {
    return node.map((entry) => reactToText(entry, resolvers)).join('');
  }

  const [nodeType, nodeProps] = isValidElement(node) ? [node.type, node.props] : [null, null];
  // check if custom resolver is available
  if (nodeType && resolvers?.has(nodeType)) {
    const resolver = resolvers.get(nodeType)!;
    return stripHtmlTags(resolver(nodeProps));
  }

  // Because ReactNode includes {} in its union we need to jump through a few hoops.
  const props: { children?: React.ReactNode } = (node as any).props ? (node as any).props : {};

  if (!props || !props.children) {
    return '';
  }

  return reactToText(props.children, resolvers);
}

export default reactToText;
