import { Content, Overlay, Portal, Root } from '@radix-ui/react-dialog';
import { Box, Tooltip } from '@remote-com/norma';
import { IconInfo } from '@remote-com/norma/icons/IconInfo';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@/src/components/Button';
import { ButtonClose } from '@/src/components/Button/ButtonClose';
import { useIsFormDirty } from '@/src/components/Form/hooks';

export const ModalOverlay = styled(Box)`
  display: grid;
  padding: ${({ theme }) => `${theme.space[5]}px`};
  position: fixed;
  inset: 0;
  background: ${({ theme }) => transparentize(0.56, theme.colors.grey[700])};
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999999;
  place-items: center;
`;

export const ModalArea = styled(Box).attrs({
  mx: 'auto',
  my: [7, 9],
})`
  position: relative;
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth};
`;

export const ModalContent = styled(Box)`
  outline: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  /* Prevent long uninterrupted strings from overflowing the content by using 'overflow-wrap',
   * but avoid using 'word-break: break-word', since it affects money inputs breaking the
   * currency aside into two lines, see https://remote-com.slack.com/archives/C020B57P9QU/p1715797261894059 */
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.colors.grey[600]};
  box-shadow: ${({ theme }) => theme.shadows.large};
  --modalRadius: 10px;
  border-radius: var(--modalRadius);
  /* Do not add overflow:hidden to allow content to go over the edge (eg dropdowns) */

  && {
    /** override reach-ui */
    width: 100%;
    background: transparent;
    margin: 0;
    padding: 0;
  }
`;

export const Header = styled(Box).attrs({
  forwardedAs: 'header',
})`
  --modal-header-spacing-x: ${({ theme, $layout }) =>
    $layout === 'fullWidth' ? 0 : theme.space[5]}px;
  --modal-header-spacing-y: ${({ theme }) => theme.space[5]}px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--modal-header-spacing-y) var(--modal-header-spacing-x);
  background-color: ${({ theme }) => theme.colors.background.surface};
  color: ${({ theme }) => theme.colors.grey[900]};
  border-radius: var(--modalRadius) var(--modalRadius) 0 0;
  border-bottom: ${({ showBorder }) => (showBorder ? '1px solid rgba(0, 35, 75, 0.1)' : 'none')};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    --modal-header-spacing-x: ${({ theme, $layout }) =>
      $layout === 'fullWidth' ? 0 : theme.space[6]}px;
  }
`;

const IconInfoStyled = styled(IconInfo)`
  margin-left: 4px;
  width: 15px;
  fill: var(--colors-Bayoux);
`;

const HeaderTitle = styled.h2`
  ${({ theme }) => theme.typography.lgMedium};
  display: flex;
  align-items: center;
`;

export const ModalButtonClose = styled(ButtonClose)`
  color: var(--colors-bayoux);
  margin-left: auto;
`;

export const Body = styled(Box)`
  --modal-body-spacing-x: ${({ theme, $layout }) =>
    $layout === 'fullWidth' ? 0 : theme.space[5]}px;
  --modal-body-spacing-y: ${({ theme }) => theme.space[6]}px;

  background-color: ${({ theme }) => theme.colors.background.surface};
  padding: var(--modal-body-spacing-y) var(--modal-body-spacing-x);
  padding-top: ${({ hasModalTitle }) => !hasModalTitle && 0};

  /* Remove vertical margins of existing child DynamicForm, because
   modal body already provides a generous margin. */
  --dynamicFormContainerMargin: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    --modal-body-spacing-x: ${({ theme, $layout }) =>
      $layout === 'fullWidth' ? 0 : theme.space[9]}px;
    --modal-body-spacing-y: ${({ theme }) => theme.space[8]}px;
  }

  &:first-child {
    border-radius: var(--modalRadius) var(--modalRadius) 0 0;
  }

  &:last-child {
    border-radius: 0 0 var(--modalRadius) var(--modalRadius);
  }
`;

const Footer = styled.footer`
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  border-top: 1px solid rgba(0, 35, 75, 0.1);
  display: flex;
  align-items: center;
  justify-content: ${({ footerAlignment }) => footerAlignment};

  &:last-child {
    border-radius: 0 0 var(--modalRadius) var(--modalRadius);
  }

  button + button {
    margin-left: 24px;
  }
`;

export const confirmCloseConfigOptions = {
  DISCARD: 'discard',
  CONFIRM: 'confirm',
  CUSTOM: 'custom',
};

const confirmCloseConfig = {
  [confirmCloseConfigOptions.DISCARD]: {
    headerTitle: 'Discard form?',
    body: `Looks like you've already filled out some of this form. Are you sure you want to discard it?`,
    confirmButtonText: 'Discard',
    cancelButtonText: 'Cancel',
  },
  [confirmCloseConfigOptions.CONFIRM]: {
    headerTitle: 'You have unsaved changes',
    body: `Changes have not been saved, are you sure you want to exit?`,
    confirmButtonText: 'Leave without saving',
    cancelButtonText: 'Cancel',
  },
  [confirmCloseConfigOptions.CUSTOM]: {},
};

/**
 * Renders a Confirmation modal, to be used by the main Modal component, when there's data inserted in it, so that users don't lose their progress by mistake.
 *
 * Note: This component has a similar (but simplified) logic to the main Modal component. However, we cannot create a component
 * that reuses the main Modal component codebase because that would create a circular dependency — the "Confirm Close Modal" is being used inside Modal.
 * Having it partially replicated in the same file is the simplest solution for this issue.
 */
export const ConfirmCloseModal = ({
  visible,
  onSave,
  onCancel,
  onDismiss,
  onSaveLoading,
  'data-testid': dataTestid,
  $layout,
  type = 'discard',
  customConfig,
}) => {
  const dismissCallback = onDismiss ?? onCancel;

  return (
    visible && (
      <Root open={visible} modal>
        <Portal>
          <Overlay asChild>
            <ModalOverlay data-testid={dataTestid}>
              <Content
                asChild
                onEscapeKeyDown={dismissCallback}
                onPointerDownOutside={(e) => {
                  const currentTarget =
                    e.currentTarget instanceof HTMLElement ? e.currentTarget : null;
                  // Prevents the modal from closing when clicking on the scroll bar
                  if (currentTarget && e.detail.originalEvent.offsetX > currentTarget.clientWidth) {
                    e.preventDefault();
                  } else {
                    dismissCallback(e);
                  }
                }}
                data-dd-action-name="Confirm Close modal"
                tabIndex={undefined}
              >
                <ModalArea $maxWidth="400px">
                  <ModalContent>
                    <Header showBorder>
                      <HeaderTitle
                        id="confirmCloseModalTitle"
                        data-testid="confirm-close-modal-title"
                      >
                        {customConfig?.headerTitle || confirmCloseConfig[type].headerTitle}
                      </HeaderTitle>
                    </Header>

                    <Body hasModalTitle $layout={$layout} data-testid="confirm-close-modal-body">
                      {customConfig?.body || confirmCloseConfig[type].body}
                    </Body>
                    <Footer footerAlignment="center">
                      <Button
                        type="button"
                        size="sm"
                        variant="outline"
                        onClick={dismissCallback}
                        data-testid="confirm-close-modal-continue-editing"
                      >
                        {customConfig?.cancelButtonText ||
                          confirmCloseConfig[type].cancelButtonText}
                      </Button>
                      <Button
                        tone="destructive"
                        size="sm"
                        data-testid="confirm-close-modal-discard"
                        onClick={onSave}
                        isLoading={onSaveLoading}
                      >
                        {customConfig?.confirmButtonText ||
                          confirmCloseConfig[type].confirmButtonText}
                      </Button>
                    </Footer>
                  </ModalContent>
                </ModalArea>
              </Content>
            </ModalOverlay>
          </Overlay>
        </Portal>
      </Root>
    )
  );
};

ConfirmCloseModal.propTypes = {
  visible: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onSaveLoading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  'data-testid': PropTypes.string,
  $layout: PropTypes.oneOf(['fullWidth']),
  type: PropTypes.oneOf(['discard', 'confirm']),
  customConfig: PropTypes.object,
};

/**
 * @typedef {Object} ModalProps
 * @property {React.ReactNode} children - The content of the modal
 * @property {boolean} [visible] - Controls modal visibility (if false it is not rendered)
 * @property {boolean} [shouldSubmit] - When true, the submit button turns into type="submit"
 * @property {string} [formName] - Passed to submit button if shouldSubmit is true
 * @property {boolean} [confirmLoading] - When true, the submit button displays as loading
 * @property {boolean} [disableSubmitBtn] - Disables the submit button when true
 * @property {boolean} [showCloseIcon] - Shows the close icon when true
 * @property {boolean} [showFooter] - Shows the footer when true
 * @property {boolean} [showSubmitButton] - Shows the submit button when true
 * @property {boolean} [showCancelButton] - Shows the cancel button when true
 * @property {boolean} [shouldShowConfirmCloseModal] - When false, the confirmation modal if the form is dirty is not shown
 * @property {React.ReactNode} [footer] - Override the modal footer with a custom element
 * @property {'left' | 'center' | 'right'} [footerAlignment] - Alignment of the footer content
 * @property {React.ReactNode} [headerTitle] - The title of the modal header
 * @property {string} [saveButtonText] - Custom text for the submit button
 * @property {string} [cancelButtonText] - Custom text for the cancel button
 * @property {Function} [onSave] - Callback function when saving
 * @property {Function} [onCancel] - Callback function when cancelling
 * @property {Function} [onDismiss] - Callback function when dismissing the modal
 * @property {React.ReactElement} [headerPlaceholder] - Custom element for the header placeholder
 * @property {string} [headerToolTipLabel] - Text for the header tooltip
 * @property {'fullWidth' | ''} [$layout] - Layout style of the modal
 * @property {string} [$maxWidth] - Maximum width of the modal
 * @property {boolean} [dangerousAction] - Indicates if the action is dangerous
 * @property {string} [className] - Additional CSS class for the modal
 * @property {'lg' | 'sm' | 'xs' | 'md'} [closeIconButtonSize] - Size of the close icon button
 * @property {React.ReactElement} [Pill] - Custom pill element to display in the header
 * @property {string} ['data-testid'] - Test id for the modal
 * @property {boolean} [hasPortal] - Whether to use a portal for rendering
 */

/**
 * Modal component
 * @param {ModalProps} props
 */
export const Modal = ({
  children,
  visible,
  showCloseIcon,
  shouldSubmit,
  confirmLoading,
  disableSubmitBtn,
  formName,
  showFooter,
  showSubmitButton,
  showCancelButton,
  footer,
  footerAlignment,
  headerTitle,
  saveButtonText,
  cancelButtonText,
  onSave,
  onCancel,
  onDismiss,
  'data-testid': dataTestid,
  dangerousAction,
  className, // pass styled component classes down
  headerPlaceholder,
  headerToolTipLabel,
  $layout,
  $maxWidth,
  hasPortal = true,
  shouldShowConfirmCloseModal,
  closeIconButtonSize,
  Pill,
}) => {
  const triggerElement = useRef(null);
  const focusTimeoutRef = useRef();
  const dismissCallback = onDismiss ?? onCancel;
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const isFormDirty = useIsFormDirty({ active: visible });

  useEffect(() => {
    if (typeof document !== 'undefined') {
      triggerElement.current = document.activeElement;
    }
  }, []);

  // When the modal is closed, we focus back on the element that triggered it.
  // Radix-ui's Dialog would implement this by default, but it only does so if we use the Dialog.Trigger component, which we don't.
  const focusTriggerElement = () => {
    if (typeof document === 'undefined') return;

    if (focusTimeoutRef.current) {
      clearTimeout(focusTimeoutRef.current);
    }
    // Explanation: While a value of 0 might be enough (targeting the next render cycle), on some browsers this
    // was not enough. A value of 10ms seems to work to ensure that the modal is fully closed before focusing
    // back on the trigger element.
    focusTimeoutRef.current = setTimeout(() => {
      if (triggerElement.current) {
        triggerElement.current.focus();
      }
    }, 10);
  };

  const closeModalHandler = (evt, cb = dismissCallback) => {
    // If there's a dirty form in the modal, we show a confirmation dialog before closing it,
    // otherwise we close the modal immediately
    if (isFormDirty && shouldShowConfirmCloseModal) {
      evt.preventDefault();
      evt.stopPropagation();
      setShowConfirmCloseModal(true);
    } else {
      focusTriggerElement();
      cb?.(evt); // Runtime error because of onCancel (L256) not defined (not required prop).
    }
  };

  /**
   * Define the button props based on the component props.
   * If the modal body has a form, we need to provide a way to be able to submit. If not, we just
   * create a normal button with a click event.
   */
  function getButtonProps() {
    const buttonProps = {};

    if (shouldSubmit) {
      buttonProps.type = 'submit';
      buttonProps.form = formName;
    } else {
      buttonProps.onClick = onSave;
      buttonProps.type = 'button';
    }

    return buttonProps;
  }

  function renderSubmitButton() {
    const buttonProps = getButtonProps();

    return (
      <Button
        isLoading={confirmLoading}
        disabled={confirmLoading || disableSubmitBtn}
        tone={dangerousAction ? 'destructive' : 'primary'}
        size="sm"
        data-testid="modal-save-button"
        {...buttonProps}
      >
        {saveButtonText}
      </Button>
    );
  }

  /**
   * If a footer is passed as props we should render this instead. If not, we render the default one.
   */
  function renderFooter() {
    return (
      <Footer footerAlignment={footerAlignment}>
        {footer || (
          <>
            {showCancelButton && (
              <Button
                type="button"
                size="sm"
                variant="outline"
                onClick={(evt) => closeModalHandler(evt, onCancel)}
              >
                {cancelButtonText}
              </Button>
            )}
            {showSubmitButton && renderSubmitButton()}
          </>
        )}
      </Footer>
    );
  }

  const modalAriaTitle = headerTitle
    ? { 'aria-labelledby': '#modalTitle' }
    : { 'aria-label': 'Dialog area' };

  const shouldRenderHeader = !!headerTitle || !!showCloseIcon || !!headerPlaceholder;
  /**
   * The Radix Portal has a Hydration issue that causes an e2e test failure in the local environment.
   * In this use case of the email confirmation modal, we can opt-out.
   * https://github.com/radix-ui/primitives/issues/1386
   */
  const PortalWrapper = hasPortal ? Portal : Fragment;

  return (
    <>
      {visible && (
        <Root open={visible} modal>
          <PortalWrapper>
            <Overlay asChild>
              <ModalOverlay data-testid={dataTestid} className={className}>
                <Content
                  asChild
                  onEscapeKeyDown={closeModalHandler}
                  onPointerDownOutside={(e) => {
                    const currentTarget =
                      e.currentTarget instanceof HTMLElement ? e.currentTarget : null;
                    // Prevents the modal from closing when clicking on the scroll bar
                    if (
                      currentTarget &&
                      e.detail.originalEvent.offsetX > currentTarget.clientWidth
                    ) {
                      e.preventDefault();
                    } else {
                      closeModalHandler(e);
                    }
                  }}
                  data-dd-action-name="Close modal"
                  tabIndex={undefined} // by default, radix dialog sets a tabindex of -1, which traps the focus after closing a react datepicker calendar so we need to disable this. No a11y is harmed.
                >
                  <ModalArea $maxWidth={$maxWidth}>
                    <ModalContent {...modalAriaTitle}>
                      {shouldRenderHeader && (
                        <Header showBorder={!!headerTitle} $layout={$layout}>
                          {headerTitle && (
                            <HeaderTitle id="modalTitle">
                              {headerTitle}
                              {headerToolTipLabel && (
                                <Tooltip
                                  position="right"
                                  label={
                                    <span
                                      /* eslint-disable react/no-danger */
                                      dangerouslySetInnerHTML={{
                                        __html: headerToolTipLabel,
                                      }}
                                    />
                                  }
                                >
                                  <IconInfoStyled />
                                </Tooltip>
                              )}
                            </HeaderTitle>
                          )}
                          {Pill && <Box ml={3}>{Pill}</Box>}
                          {showCloseIcon && (
                            <ModalButtonClose
                              size={closeIconButtonSize}
                              aria-label="Close Modal"
                              onClick={closeModalHandler}
                            />
                          )}
                          {!!headerPlaceholder && headerPlaceholder}
                        </Header>
                      )}

                      <Body hasModalTitle={!!headerTitle} $layout={$layout}>
                        {children}
                      </Body>
                      {showFooter && renderFooter()}
                    </ModalContent>
                  </ModalArea>
                </Content>
              </ModalOverlay>
            </Overlay>
          </PortalWrapper>
        </Root>
      )}
      {/* Note: The ConfirmCloseModal below has a logic similar to this main Modal, however, to avoid a circular dependency, we cannot reuse the main Modal logic without a major overhaul. */}
      <ConfirmCloseModal
        visible={showConfirmCloseModal}
        onSave={() => {
          setShowConfirmCloseModal(false);
          dismissCallback();
          focusTriggerElement();
        }}
        onCancel={() => setShowConfirmCloseModal(false)}
      />
    </>
  );
};

/* eslint-disable react/default-props-match-prop-types */
Modal.defaultProps = {
  visible: false,
  footerAlignment: 'center',
  shouldSubmit: true,
  confirmLoading: false,
  disableSubmitBtn: false,
  formName: '',
  saveButtonText: 'Save',
  cancelButtonText: 'Cancel',
  showCloseIcon: true,
  showFooter: true,
  showSubmitButton: true,
  showCancelButton: true,
  headerPlaceholder: null,
  onSave: () => {},
  onDismiss: null,
  headerTitle: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  'data-testid': 'modal', // dunno why the lint error happens
  $maxWidth: '620px',
  shouldShowConfirmCloseModal: true,
  closeIconButtonSize: 'sm',
  $layout: undefined,
};

export const modalPropTypes = {
  /** Controls modal visibility (if false it is not rendered) */
  visible: PropTypes.bool,
  /** When true, the submit button turns into type="submit" */
  shouldSubmit: PropTypes.bool,
  /** Passed to submit button if shouldSubmit is tru */
  formName: PropTypes.string,
  /** When true, the submit button displays as loading */
  confirmLoading: PropTypes.bool,
  disableSubmitBtn: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  showFooter: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  /** When false, the confirmation modal if the form is dirty is not shown */
  shouldShowConfirmCloseModal: PropTypes.bool,
  /** Override the modal footer with a custom element */
  footer: PropTypes.node,
  footerAlignment: PropTypes.oneOf(['left', 'center', 'right']),
  headerTitle: PropTypes.node,
  /** Custom text to the submit button */
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDismiss: PropTypes.func,
  headerPlaceholder: PropTypes.element,
  headerToolTipLabel: PropTypes.string,
  $layout: PropTypes.oneOf(['fullWidth', '']),
  $maxWidth: PropTypes.string,
  dangerousAction: PropTypes.bool,
  className: PropTypes.string,
  closeIconButtonSize: PropTypes.oneOf(['lg', 'sm', 'xs', 'md']),
  Pill: PropTypes.element,
};

Modal.propTypes = {
  ...modalPropTypes,
  children: PropTypes.node.isRequired,
};
