// eslint-disable-next-line remote/prefer-using-the-data-layer
import { makeApiService } from '@/src/services/ApiClient';
import { VERSIONS } from '@/src/services/defaults';

/**
 * Updates company headquarters address.
 * @param {Object} bodyParams - The company details.
 * @param {string} bodyParams.countrySlug - The company headquarters country slug.
 * @param {string} bodyParams.address - The company headquarters address.
 * @param {string} bodyParams.address_line_2 - The company headquarters address line 2.
 * @param {string} bodyParams.city - The company headquarters city.
 * @param {string} bodyParams.postalCode - The company headquarters postal code.
 * @param {string} bodyParams.state - The company headquarters state.
 */
export const updateOnboardingCompanyAddress = makeApiService({
  method: 'patch',
  path: '/employer/onboarding/company/address',
  asFormData: false,
});

/**
 * Updates company details.
 * @param {Object} bodyParams - The company details.
 * @param {Object} bodyParams.bankAccount - The company bank account details.
 * @param {string} bodyParams.bankAccount.desiredCurrencySlug - The company desired currency.
 * @param {Object} bodyParams.company - The company basic details.
 * @param {string} bodyParams.company.name - The company name.
 * @param {string} bodyParams.company.vat - The company fiscal number.
 */
export const updateOnboardingCompany = makeApiService({
  method: 'post',
  path: '/employer/onboarding/company',
  asFormData: false,
});

export const fetchCompanyOnboardingData = makeApiService({
  path: '/employer/onboarding',
});

export const createCompanyOnboardingBankAccount = makeApiService({
  method: 'post',
  path: '/employer/onboarding/company/bank-account',
  asFormData: false,
});

export const createPreHireCheckEmployeeDetails = makeApiService({
  method: 'post',
  path: '/employer/onboarding/pre-hire-check/employee-details',
});

export const updatePreHireCheckEmployeeDetails = makeApiService({
  method: 'put',
  path: '/employer/onboarding/pre-hire-check/employee-details/[employmentSlug]',
});

export const updatePreHireCheckEducationAndJob = makeApiService({
  method: 'put',
  path: '/employer/onboarding/pre-hire-check/education-and-job/[employmentSlug]',
});

export const updatePreHireCheckPermitOrVisaDetails = makeApiService({
  method: 'put',
  path: '/employer/onboarding/pre-hire-check/permit-or-visa-details/[employmentSlug]',
});

export const finishPreHireCheck = makeApiService({
  method: 'post',
  path: '/employer/onboarding/pre-hire-check/[employmentSlug]/review',
});

export const deletePreHireDocument = makeApiService({
  method: 'delete',
  path: '/employer/onboarding/pre-hire-check/[employmentSlug]/documents/[fileSlug]',
});

const postOnboardingEmployment = makeApiService({
  method: 'post',
  path: '/employer/onboarding/employments',
  asFormData: false,
});

export function createOrUpdateEmployment(values, employmentSlug) {
  const bodyParams = {
    employee: {
      ...values,
    },
  };

  if (employmentSlug) {
    bodyParams.employee.slug = employmentSlug;
  }

  return postOnboardingEmployment({
    bodyParams,
  });
}

export const getEmployment = makeApiService({
  path: '/employer/onboarding/employments/[employmentSlug]',
});

export const getDirectReports = makeApiService({
  path: '/employer/hris/me/reports',
  version: VERSIONS.API_V2,
});

export const updateCompanyDetails = makeApiService({
  method: 'patch',
  path: '/employer/company',
  asFormData: false,
});

export const updateCompanyBankDetails = makeApiService({
  method: 'post',
  path: '/employer/company/bank-account',
  asFormData: false,
});

export const updateEmploymentProduct = makeApiService({
  method: 'put',
  path: '/employer/onboarding/employments/[slug]/product',
});

export const fetchAutomaticContractorInvoicePayments = makeApiService({
  method: 'get',
  path: '/employer/contractor-invoices/automatic-payments',
});

export const upsertAutomaticContractorInvoicePayments = makeApiService({
  method: 'post',
  path: '/employer/contractor-invoices/automatic-payments',
  asFormData: false,
});

export const updateAutomaticContractorInvoicePayments = makeApiService({
  method: 'patch',
  path: '/employer/contractor-invoices/automatic-payments',
  asFormData: false,
});

export const fetchRemoteInvoiceData = makeApiService({
  path: '/employer/invoice-reports/[slug]',
});

export const fetchTransactionReceiptData = makeApiService({
  path: '/employer/transaction-receipts/[slug]',
});

export const createContractDocument = makeApiService({
  method: 'post',
  path: '/employer/contract-documents',
  asFormData: false,
});

export const previewContractDocument = makeApiService({
  method: 'post',
  path: '/employer/contract-documents/[slug]/preview',
  asFormData: false,
  toSnakeCase: false,
});

export const fetchEmploymentPricingOptions = makeApiService({
  path: '/countries/[slug]/prices',
});

export const fetchContractorEmploymentPricingOptions = makeApiService({
  path: '/countries/[slug]/prices',
});

export const acceptTermsOfService = makeApiService({
  path: '/employer/terms-of-service',
  method: 'post',
});

export const fetchTeamPlans = makeApiService({
  path: '/employer/team-plans',
});

export const fetchTeamPlanSeats = makeApiService({
  path: '/employer/team-plans/[slug]/seats',
});

export const acceptTeamPlan = makeApiService({
  path: '/employer/team-plans/[slug]/accept',
  method: 'post',
});

export const fetchEmployerComplianceSettings = makeApiService({
  method: 'get',
  path: '/employer/compliance-settings',
  version: VERSIONS.API_V1,
});
