import { useRef } from 'react';

import type { InvoiceReport } from '@/src/api/config/employ/invoiceReport.types';
import { employeeInvoiceStatuses } from '@/src/domains/invoices/constants';
import { getInvoiceReportFields } from '@/src/domains/invoices/shared/helpers';

export const useInvoiceReportRefetch = (isCurrentTabInvoiceOverview: boolean) => {
  const refetchCount = useRef(0);

  return {
    refetchInterval(response: InvoiceReport) {
      const { invoiceStatus, invoiceFiles, isManualImport } = getInvoiceReportFields(response);

      // Only refetch the invoice overview tab
      // To prevent the client pagination from being reset
      if (
        !isManualImport &&
        invoiceStatus === employeeInvoiceStatuses.FINAL &&
        invoiceFiles?.length === 0 &&
        refetchCount.current <= 60 &&
        isCurrentTabInvoiceOverview
      ) {
        return 5 * 1000 * refetchCount.current;
      }

      return false;
    },
    onSettled() {
      refetchCount.current += 1;
    },
  };
};
