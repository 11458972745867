import React, { useMemo, useState, useEffect } from 'react';

import { IconTimes } from '../../icons/build/IconTimes';
import { IconV2OutlineAlertTriangle } from '../../icons/build/IconV2OutlineAlertTriangle';
import { IconV2OutlineCheckCircle } from '../../icons/build/IconV2OutlineCheckCircle';
import { IconV2OutlineCommentDots } from '../../icons/build/IconV2OutlineCommentDots';
import { IconV2OutlineInfo } from '../../icons/build/IconV2OutlineInfo';
import { IconV2OutlineTimesFull } from '../../icons/build/IconV2OutlineTimesFull';
import type { StackProps } from '../../layout';
import { Box } from '../../layout';
import { SROnly } from '../text';

import {
  FeedbackMessage as Container,
  IconContainer,
  TextContainer,
  Title,
  DismissButton,
} from './FeedbackMessage.styled';
import reactToText from './reactToText';
import type { FeedbackMessageVariant } from './variants';
import {
  FEEDBACK_MESSAGE_ERROR,
  FEEDBACK_MESSAGE_WARNING,
  FEEDBACK_MESSAGE_INFO,
  FEEDBACK_MESSAGE_NEUTRAL,
  FEEDBACK_MESSAGE_SUCCESS,
} from './variants';

type V2Icon =
  | typeof IconTimes
  | typeof IconV2OutlineAlertTriangle
  | typeof IconV2OutlineInfo
  | typeof IconV2OutlineCommentDots
  | typeof IconV2OutlineTimesFull;

const getV2Icon = (variant: FeedbackMessageVariant): V2Icon =>
  ({
    [FEEDBACK_MESSAGE_ERROR]: IconV2OutlineTimesFull,
    [FEEDBACK_MESSAGE_WARNING]: IconV2OutlineAlertTriangle,
    [FEEDBACK_MESSAGE_INFO]: IconV2OutlineInfo,
    [FEEDBACK_MESSAGE_NEUTRAL]: IconV2OutlineCommentDots,
    [FEEDBACK_MESSAGE_SUCCESS]: IconV2OutlineCheckCircle,
  }[variant]);

const getLiveRegionProps = (variant: FeedbackMessageVariant) => {
  switch (variant) {
    case FEEDBACK_MESSAGE_ERROR:
    case FEEDBACK_MESSAGE_WARNING:
    case FEEDBACK_MESSAGE_SUCCESS:
      // role as alert implicitly sets 'aria-live' to 'assertive' but if value
      // is not specified TS assumes it as undefined and throws an error
      return { role: 'alert', 'aria-live': 'assertive' } as const;
    default:
      // we explicitly set 'aria-live' for compatibility: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions#roles_with_implicit_live_region_attributes
      return { role: 'status', 'aria-live': 'polite' } as const;
  }
};

const getMessageText = (title: string | undefined, children: React.ReactNode) => {
  const message = `${title ? `${title}. ` : ''}${reactToText(children)}`;
  if (!message) {
    // TODO: We would ideally want to report this error to Datadog using util, however at this time we do not have such functionality and therefore we are falling back to a simple console.error approach.
    console.error(
      'A FeedbackMessage was used without an accessible message (children). Please verify and fix it.'
    );
  }

  return message;
};

export interface FeedbackMessageProps extends StackProps {
  /** The title of the feedback message */
  title?: string;
  /**
   * Specify the type of Feedback message. Available variants are:
   *  - error
   *  - warning
   *  - info
   *  - neutral
   *  - success - Use toast for success messages after user interactions.
   *
   * Check https://remote-com.gitlab.io/employ-starbase/dragon/?path=/docs/feedback-feedbackmessage--variants storybook page for the UI.
   */
  variant?: FeedbackMessageVariant;
  /** Function to be called when the close button is clicked on the message */
  onDismiss?: (event: Event) => void;

  customIcon?: V2Icon;

  /** Turn the message into an accessible ARIA Live Region. Use it when the message is shown after an action. Check docs on when to (not) use it. Defaults to false. */
  isAriaLiveRegion?: boolean;
}

export const FeedbackMessage = ({
  children,
  title,
  variant = FEEDBACK_MESSAGE_INFO,
  onDismiss,
  customIcon,
  isAriaLiveRegion = false,
  ...props
}: React.PropsWithChildren<FeedbackMessageProps>) => {
  const Icon = customIcon || getV2Icon(variant);
  const liveRegionProps = getLiveRegionProps(variant);
  const [hasMessageChanged, setHasMessageChanged] = useState(false);
  const initialMessageText = useMemo(
    () => getMessageText(title, children),
    // Why we do not want the dep array [] here:
    // We want the initial message to persist, otherwise the
    // screen reader will announce the msg each time it changes,
    // which can be highly annoying, for example in the ErrorSummary.
    // Know more on MR !15731.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isMessageDifferent = initialMessageText !== getMessageText(title, children);

  useEffect(() => {
    if (isMessageDifferent) setHasMessageChanged(true);
  }, [isMessageDifferent]);

  // Hide the message when it changes, to avoid showing a stale message to SR users
  const isScreenReaderMessageDisplayed =
    !(isMessageDifferent || hasMessageChanged) && isAriaLiveRegion;

  // Remove the element from the DOM in tests to avoid breaking existing tests. The duplicated message is noisy in there anyway.
  const isTest = process.env.NODE_ENV === 'test';

  return (
    <Container
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={4}
      px={5}
      mb={5}
      {...props}
      variant={variant}
    >
      <Box display="flex" overflow="hidden">
        <IconContainer mr={3}>
          <Icon width="20px" />
        </IconContainer>

        {!isTest && isScreenReaderMessageDisplayed && (
          <SROnly {...liveRegionProps}>{initialMessageText}</SROnly>
        )}

        <TextContainer
          $hasTitle={!!title}
          data-testid="feedbackMsg"
          // Hide the visual message while the SR message is in the DOM, to avoid saying the same thing twice for SR users.
          {...(isScreenReaderMessageDisplayed ? { 'aria-hidden': 'true' } : {})}
        >
          {title && <Title mb={2}>{title} </Title>}
          {children}
        </TextContainer>
      </Box>
      {onDismiss && variant === FEEDBACK_MESSAGE_NEUTRAL && (
        <DismissButton
          variant="ghost"
          tone="secondary"
          size="xs"
          Icon={IconTimes}
          label="Dismiss"
          onClick={onDismiss}
        />
      )}
    </Container>
  );
};
