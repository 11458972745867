import { useGet } from '@remote-com/data-layer';
import { useMemo } from 'react';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery } from 'react-query';

import { useUserCan } from '@/src/components/UserCan';
import {
  employmentType as employmentTypeEnum,
  employmentTypeForRoute,
} from '@/src/domains/employment/constants';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { useIsZuoraMigratedCompany } from '@/src/domains/invoices/shared/hooks';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import useCompanyData from '@/src/domains/team/hooks/useCompanyData';
import { getKeyByValue } from '@/src/helpers/general';
import {
  fetchContractDocuments,
  fetchContractDocumentsQueryName,
  fetchEmployeeDetails,
} from '@/src/services/Admin';

export const employeeDetailsQueryName = 'fetch-employee-details';

export const useEmployeeDetails = (slug, options) => {
  const { userCan } = useUserCan();
  return useQuery(
    [employeeDetailsQueryName, slug],
    () => fetchEmployeeDetails({ pathParams: { slug } }),
    {
      enabled: userCan('read', Resources.employees),
      retry: false,
      select: ({ data }) => data,
      ...options,
    }
  );
};

export const useEmploymentContractDocuments = ({ enabled = false, contractSlug = null }) => {
  const { data } = useQuery(
    [fetchContractDocumentsQueryName, contractSlug],
    () =>
      fetchContractDocuments({
        queryParams: { contractSlug },
      }),
    {
      enabled,
    }
  );
  return data || {};
};

// todo: remove once multi_entity_fraud flag is enabled
export const useEmployerRiskData = ({ options = {} }) =>
  useGet('/api/v1/employer/compliance-profile/credit-risk-status', {
    options: {
      select: (response) => response?.data,
      ...options,
    },
  });

const useFetchBillToEntityFromEmploymentSlug = (employmentSlug, isEnabled) => {
  const employment = useGet('/api/v1/employer/onboarding/employments/[slug]', {
    params: { pathParams: { slug: employmentSlug } },
    options: {
      select: (res) => res.data ?? {},
      enabled: isEnabled,
    },
  });

  return employment?.data?.employment?.billToLegalEntity?.slug;
};
export const useEmployerRiskDataLegalEntity = ({
  billToEntitySlugFromQuery,
  employmentSlug,
  options = {},
}) => {
  // if legalEntitySlug is not in the query, at this point we should have the employment slug and we need to fetch the employment to get bill to entity
  const billToEntitySlugFromEmployment = useFetchBillToEntityFromEmploymentSlug(
    employmentSlug,
    !!employmentSlug && !billToEntitySlugFromQuery
  );

  return useGet('/api/v1/employer/compliance-profile/credit-risk-status', {
    params: {
      queryParams: {
        legalEntity: billToEntitySlugFromQuery || billToEntitySlugFromEmployment,
      },
    },
    options: {
      select: (response) => response?.data,
      enabled: (billToEntitySlugFromQuery || billToEntitySlugFromEmployment) && options.enabled,
      ...options,
    },
  });
};

export const useEmployerOnboardingReservesStatus = ({
  countrySlug,
  billToEntitySlugFromQuery,
  employmentSlug,
  options,
}) => {
  // if legalEntitySlug is not in the query, at this point we should have the employment slug and we need to fetch the employment to get bill to entity
  const billToEntitySlugFromEmployment = useFetchBillToEntityFromEmploymentSlug(
    employmentSlug,
    !!employmentSlug && !billToEntitySlugFromQuery
  );

  return useGet('/api/v1/employer/compliance-profile/onboarding-reserves-status', {
    params: {
      queryParams: {
        countrySlug,
        legalEntity: billToEntitySlugFromQuery || billToEntitySlugFromEmployment,
      },
    },
    options: {
      select: (response) => response?.data,
      ...options,
    },
  });
};

export const useCreditRiskStatus = ({
  employmentType,
  employmentSlug,
  legalEntitySlug,
  hasGlobalPayroll,
  employeeCountrySlug,
}) => {
  const isMultiEntityEnabled = useIsFeatureFlagEnabled('multi_entity_fraud');
  const formattedEmploymentType = getKeyByValue(employmentTypeForRoute, employmentType);
  const isFullTimeEmployee = formattedEmploymentType === employmentTypeEnum.FULL_TIME;
  const shouldCheckRiskScore = isFullTimeEmployee && !hasGlobalPayroll;

  const {
    data: riskScoreData,
    isLoading: isLoadingRiskScoreCompany,
    refetch: refetchRiskScore,
  } = useEmployerRiskData({
    options: {
      // if is fulltime EOR employee, run this
      // if run on non-EOR, this will return a 404
      enabled: shouldCheckRiskScore && !isMultiEntityEnabled,
    },
  });

  const {
    data: riskScoreDataLegalEntity,
    isLoading: isLoadingRiskScoreLegalEntity,
    refetch: refetchRiskScoreLegalEntity,
  } = useEmployerRiskDataLegalEntity({
    billToEntitySlugFromQuery: legalEntitySlug,
    employmentSlug,
    options: {
      enabled: shouldCheckRiskScore && isMultiEntityEnabled,
    },
  });

  const {
    data: onboardingReservesStatus,
    isLoading: isOnboardingReservesStatusLoading,
    refetch: refetchOnboardingReservesStatus,
  } = useEmployerOnboardingReservesStatus({
    billToEntitySlugFromQuery: legalEntitySlug,
    employmentSlug,
    countrySlug: employeeCountrySlug,
    options: {
      enabled: Boolean(employeeCountrySlug) && !hasGlobalPayroll,
    },
  });

  const riskScore = useMemo(() => {
    if (employeeCountrySlug) {
      return onboardingReservesStatus?.status;
    }
    if (isMultiEntityEnabled) {
      return riskScoreDataLegalEntity?.status;
    }
    return riskScoreData?.status;
  }, [
    employeeCountrySlug,
    onboardingReservesStatus?.status,
    isMultiEntityEnabled,
    riskScoreDataLegalEntity?.status,
    riskScoreData?.status,
  ]);

  const isLoadingRiskScore = useMemo(() => {
    if (employeeCountrySlug) {
      return isOnboardingReservesStatusLoading;
    }
    if (isMultiEntityEnabled) {
      return isLoadingRiskScoreLegalEntity;
    }
    return isLoadingRiskScoreCompany;
  }, [
    employeeCountrySlug,
    isMultiEntityEnabled,
    isOnboardingReservesStatusLoading,
    isLoadingRiskScoreLegalEntity,
    isLoadingRiskScoreCompany,
  ]);

  const refetchCreditRiskScore = useMemo(() => {
    if (employeeCountrySlug) {
      return refetchOnboardingReservesStatus;
    }
    if (isMultiEntityEnabled) {
      return refetchRiskScoreLegalEntity;
    }
    return refetchRiskScore;
  }, [
    employeeCountrySlug,
    isMultiEntityEnabled,
    refetchOnboardingReservesStatus,
    refetchRiskScoreLegalEntity,
    refetchRiskScore,
  ]);

  return {
    riskScore,
    isLoadingRiskScore,
    refetchCreditRiskScore,
  };
};

export const useEmployerComplianceSettingsAll = ({ options = {} } = {}) => {
  return useGet('/api/v1/employer/legal-entities/compliance-settings', {
    options: {
      select: ({ data }) => data,
      ...options,
    },
  });
};

export const useEmployerDepartments = ({ options = {} } = {}) => {
  const { userCan } = useUserCan();
  return useGet('/api/v1/employer/company/departments', {
    params: { pathParams: { paginate: false } },
    options: {
      enabled: userCan('read', Resources.employer.company_departments),
      select: ({ data }) => data.departments,
      ...options,
    },
  });
};

export const useManagementFees = ({ employmentSlug, productDiscount, revision }) => {
  const { userCan } = useUserCan();

  const { data: employmentProductPrices = [], isLoading: isLoadingCurrentProductPrice } = useGet(
    '/api/v1/rivendell/pricing/product-prices',
    {
      params: { queryParams: { employmentSlug } },
      options: {
        enabled: userCan('read', Resources.product_prices),
        select: (response) => response?.data?.productPrices,
      },
    }
  );

  const { data: allProductPrices = [], isLoading: isLoadingProductPrices } = useGet(
    '/api/v1/rivendell/pricing/product-prices',
    {
      params: {
        queryParams: {
          revision,
        },
      },
      options: {
        enabled: userCan('read', Resources.product_prices),
        select: (response) => response?.data?.productPrices,
      },
    }
  );

  // When product prices are fetched passing an employment slug,
  // the `employmentProductPrices` array will always contain a single item.
  const currentProductPrice = employmentProductPrices[0];
  const currentProductPriceWithAllPrices = allProductPrices.find(
    (productPrice) => productPrice.slug === currentProductPrice?.slug
  );

  const managementFeesWithAllPrices = (currentProductPriceWithAllPrices?.prices || []).map(
    (price) => {
      const discountValue = productDiscount?.percent || 0;
      return {
        amount: (1 - discountValue) * price.amount,
        currency: price.currency,
      };
    }
  );

  const managementFees = managementFeesWithAllPrices.find(
    // When product prices are fetched passing an employment slug,
    // the `prices` array will always contain a single item.
    ({ currency }) => currency.code === currentProductPrice?.prices[0]?.currency?.code
  );

  return {
    managementFees,
    isLoading: isLoadingCurrentProductPrice || isLoadingProductPrices,
  };
};

export const useIsNewHireFlowEnabled = () => {
  const isNewEntityFieldsInAddHireFlowFeatureFlagEnabled = useIsFeatureFlagEnabled(
    'new_entity_fields_in_add_hire_flow'
  );
  const { data: company, isLoading: isLoadingCompany } = useCompanyData();
  const { data: companyMigrationStatus, isLoading: isLoadingCompanyMigrationStatus } =
    useIsZuoraMigratedCompany(company?.slug);
  const isZuoraMigrated = companyMigrationStatus?.isZuoraMigrated;

  return {
    enabled: isNewEntityFieldsInAddHireFlowFeatureFlagEnabled && isZuoraMigrated,
    isLoading: isLoadingCompany || isLoadingCompanyMigrationStatus,
  };
};
