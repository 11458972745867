import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

import type { API } from '@/src/api/config/api.types';
import type { ColumnType } from '@/src/components/Table/types';

type Filters = API.RemoteAi.GenerateSmartSearchFilter.Response['data']['filters'];
type GeneratedFilter = {
  id: string;
  value: boolean | number | string | string[];
};

export const getColumnNames = (columns: ColumnType[]) =>
  columns
    .filter((col) => !col.disableFilters)
    .map((c) => c.id)
    .map((id) => snakeCase(id))
    .filter((id) => id !== 'actions')
    .filter(Boolean) as string[];

export const getApplicableFilters = (
  filters: Filters,
  tableColumns: Array<ColumnType>,
  columnContext?: Record<string, { label: string; value: string }[]>
): Filters => {
  return filters
    .map<GeneratedFilter | null>((f: Filters[number]) => {
      const value = f.value === 'false' ? false : f.value;
      const camelCaseId = camelCase(f.id);
      const dataPrefixedId = `data-${f.id.toLowerCase()}`;

      const columnToFilter = tableColumns.find((c) => {
        return c.id === f.id || c.id === camelCaseId || c.id === dataPrefixedId;
      });

      if (columnToFilter?.id) {
        // check if column id is in columnContext (i.e., if we should lookup the
        // filter value in the column context)
        const columnContextValue = columnContext?.[columnToFilter.id];

        if (columnContextValue) {
          const filterValue = columnContextValue.find(
            (c) => c.label.toLowerCase() === (value as string).toLowerCase()
          )?.value;

          return filterValue
            ? {
                id: columnToFilter?.id,
                value: filterValue,
              }
            : null;
        }

        // Country-related filters should be uppercase
        if (columnToFilter.id.includes('country')) {
          return {
            id: columnToFilter.id,
            value: (value as string).toUpperCase(),
          };
        }

        // otherwise, use the filter value as is
        if (columnToFilter.canFilter) {
          return {
            id: columnToFilter.id,
            value,
          };
        }
      }

      return null;
    })
    .filter((f): f is GeneratedFilter => f !== null);
};
