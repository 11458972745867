import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import {
  ADMIN_DASHBOARD_ROUTE,
  DASHBOARD_ROUTE,
  FILE_PREVIEW_ROUTE,
  PUBLIC_ROUTES,
  REMOTE_RECEIPT_DETAILS_ROUTE,
} from '@/src/constants/routes';
import { USER_TYPE } from '@/src/domains/registration/auth/constants';
import { isAdmin, isEmployer, isUserOnboarded } from '@/src/domains/registration/auth/helpers';

type UserAccountResponse = UserAccount;

/**
 * Determines if a user is allowed to access a specific layout based on their role and the pathname.
 */
export function canAccessDashboardRoute(user: UserAccountResponse) {
  const exceptions = [
    window.location.pathname.startsWith(FILE_PREVIEW_ROUTE),
    new RegExp(REMOTE_RECEIPT_DETAILS_ROUTE.replaceAll(/\[[a-zA-Z0-9]*\]/g, '[a-zA-Z0-9-]*')).test(
      window.location.pathname
    ),
  ];

  if (exceptions.some((exception) => Boolean(exception))) {
    return true;
  }

  return (
    (window.location.pathname.startsWith(DASHBOARD_ROUTE) && user.role !== USER_TYPE.ADMIN) ||
    (window.location.pathname.startsWith(ADMIN_DASHBOARD_ROUTE) && user.role === USER_TYPE.ADMIN)
  );
}

export function isDashboardRoute() {
  return (
    window.location.pathname.startsWith(DASHBOARD_ROUTE) ||
    window.location.pathname.startsWith(ADMIN_DASHBOARD_ROUTE)
  );
}

/**
 * Based on the pathname, determines if the route is a public route.
 * @param pathname
 * @returns
 */
export function isPrivateRoute(asPath: string) {
  return !PUBLIC_ROUTES.some((route) => asPath?.startsWith(route));
}

/**
 * Checks if the given user is eligible to fetch tasks.
 *
 * A user is considered eligible to fetch tasks if they are onboarded,
 * not an employer, and not an admin.
 */
export function isUserWithTasks(user: UserAccountResponse) {
  return isUserOnboarded(user) && !isEmployer(user) && !isAdmin(user);
}
