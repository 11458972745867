import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import TiptapUnderline from '@tiptap/extension-underline';
import type { Editor } from '@tiptap/react';
import { Extension } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

export const SelectedText = Extension.create({
  // @ts-expect-error
  addCommands: () => {
    return {
      getSelectedText:
        () =>
        ({ editor }: { editor: Editor }) => {
          const { from, to, empty } = editor.state.selection;

          if (empty) {
            return null;
          }

          return editor.state.doc.textBetween(from, to, ' ');
        },
    };
  },
});

// Keep this in sync with apps/jobs/src/domains/jobs/components/JobDescription.tsx
// The Jobs version only needs to include extension for showing content, not for editing.
export const TiptapExtensions = (placeholder: string = '') => [
  StarterKit.configure({
    italic: false,
    horizontalRule: false,
    dropcursor: {
      color: '#DBEAFE',
      width: 4,
    },
    gapcursor: false,
  }),
  Placeholder.configure({
    placeholder: () => {
      return placeholder;
    },
    includeChildren: true,
  }),
  TiptapUnderline,
  SelectedText,
  TextStyle,
  TaskList.configure({
    HTMLAttributes: {
      class: 'not-prose pl-2',
    },
  }),
  TaskItem.configure({ nested: true }),
  Link.configure({
    protocols: ['ftp', 'mailto'],
    openOnClick: false,
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    alignments: ['left'],
    defaultAlignment: 'left',
  }),
];
