import { Box } from '@remote-com/norma';
import styled from 'styled-components';

export const EditorHTMLStyles = styled(Box)`
  /* h1 and h2 and h3 are intentionally the same value as we want the Job title to be the biggest value */
  h1 {
    ${({ theme }) => theme.typography.lgMedium};
    margin: ${({ theme }) => `${theme.space[5]}px 0`};
  }
  h2 {
    ${({ theme }) => theme.typography.lgMedium};
    margin: ${({ theme }) => `${theme.space[3]}px 0`};
  }
  h3 {
    ${({ theme }) => theme.typography.lgMedium};
    margin: ${({ theme }) => `${theme.space[4]}px 0`};
  }
  p {
    ${({ theme }) => theme.typography.base};
    margin-bottom: ${({ theme }) => `${theme.space[5]}px`};
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.brand[600]};

    text-underline-offset: ${({ theme }) => theme.space[2]}px;
    text-decoration-thickness: ${({ theme }) => theme.space[1]}px;
  }

  em {
    font-style: normal;
  }

  ul,
  ol {
    padding-left: ${({ theme }) => `${theme.space[7]}px`};
  }

  ul li,
  ol li {
    padding: ${({ theme }) => `${theme.space[2]}px 0`};
  }

  ul li p,
  ol li p {
    margin: 0;
  }

  ul li p,
  ol li p {
    margin: 0;
  }

  ol,
  ol > li > ol > li > ol > li > ol {
    list-style-type: decimal;
  }

  ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol,
  ol > * > * > li > * > * > * > ol {
    list-style-type: lower-alpha;
  }

  ol > li > ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol > li > ol,
  ol > li > ol * > * > li > * > * > * > ol,
  ol > * > * > li > * > * > * > ol > li > ol {
    list-style-type: lower-roman;
  }
`;

export const EditorWrapperStyles = styled(Box)`
  background-color: ${({ theme }) => theme.colors.blank};
  flex: 1;
  width: 100%;
  word-break: break-word;

  // Expand the div wrapping the editor to full height so that it is easier to click to edit.
  & > div {
    height: 100%;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: ${({ theme }) => theme.colors.grey[500]};
    pointer-events: none;
    height: 0;
  }

  .ProseMirror-focused {
    outline: none;
  }

  .ProseMirror {
    padding: 16px 24px;
    max-height: 750px;
    overflow: auto;
    height: 100%;
    min-height: 500px;

    table {
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
      width: 100%;

      td,
      th {
        border: 2px solid #ced4da;
        box-sizing: border-box;
        min-width: 1em;
        padding: 3px 5px;
        position: relative;
        vertical-align: top;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        background-color: #f1f3f5;
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        background: rgba(200, 200, 255, 0.4);
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        position: absolute;
        z-index: 2;
      }

      .column-resize-handle {
        background-color: #adf;
        bottom: -2px;
        position: absolute;
        right: -2px;
        pointer-events: none;
        top: 0;
        width: 4px;
      }

      p {
        margin: 0;
      }
    }
  }
`;

export const EditorWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <EditorWrapperStyles className={className}>
      <EditorHTMLStyles>{children}</EditorHTMLStyles>
    </EditorWrapperStyles>
  );
};
