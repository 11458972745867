import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import usePrevious from '@/src/hooks/usePrevious';

type GlobalPreviousRouteState = {
  previousUrl: string;
  currentHistoryLength: number;
};

export const globalPreviousUrlStore = create<GlobalPreviousRouteState>()(
  persist(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (set, get) => ({
      previousUrl: '',
      currentHistoryLength: 0,
    }),
    {
      name: 'previous-url-tracker',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const GlobalPreviousUrlTracker = () => {
  const router = useRouter();
  const previousAsPath = usePrevious(router.asPath);

  useEffect(() => {
    globalPreviousUrlStore.setState((state) => {
      const currentHistoryLength = window.history.length;

      // Handles .replace
      if (state?.currentHistoryLength === currentHistoryLength) {
        return {
          previousUrl: router.asPath,
          currentHistoryLength,
        };
      }

      if (!previousAsPath || previousAsPath === state.previousUrl) return state;

      return {
        previousUrl: previousAsPath,
        currentHistoryLength,
      };
    });
  }, [previousAsPath, router.asPath]);

  return null;
};
