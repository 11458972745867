export const STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  CLOSED: 'closed',
  DELETED: 'deleted',
} as const;

export const COMPENSATION_FREQUENCY = {
  YEARLY: 'yearly',
  MONTHLY: 'monthly',
  HOURLY: 'hourly',
} as const;

export const WORKPLACE_LOCATION = {
  REMOTE: 'remote',
  HYBRID: 'hybrid',
  ON_SITE: 'on_site',
} as const;

export const WORKPLACE_LOCATION_FREQUENCY = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
} as const;

export const LOCATION_TYPE = {
  REGION: 'region',
  COUNTRY: 'country',
} as const;

export const HIRING_LOCATION = {
  GLOBAL: 'global',
  LOCATION: 'location',
  TIMEZONE: 'timezone',
} as const;

export const EMPLOYMENT_TYPE = {
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
  CONTRACTOR: 'contractor',
} as const;

export const TRAVEL_FREQUENCY = {
  NEVER: 'never',
  SOMETIMES: 'sometimes',
  OFTEN: 'often',
} as const;

export const SOURCE_TYPE = {
  MANUAL: 'manual',
  SCRAPE: 'scrape',
} as const;

export const SENIORITY = {
  ENTRY_LEVEL: 'entry_level',
  MID_LEVEL: 'mid_level',
  SENIOR: 'senior',
  MANAGER: 'manager',
  DIRECTOR: 'director',
  EXECUTIVE: 'executive',
} as const;

export const JOB_PARAMS_OMITTED_KEYS = [
  // Can not be changed by the client
  'slug',
  'sourceType',
  'insertedAt',
  'updatedAt',
  'publishedAt',
  'includedInSearch',
  // Is not part of the job
  'companyProfile',
  'reportedJobs',
  'totalApplications',
  'totalViews',
  // Is an inferred property
  'isActiveOneTimePayment',
  // Is updated through separate operation
  'saved',
  // Params have a different format
  'compensation',
  'hiringLocation',
  'workplaceLocation',
] as const;

export const EXTERNAL_ID_TYPE = {
  GREENHOUSE: 'greenhouse',
  LEVER: 'lever',
} as const;
