import type { ValueOf } from 'type-fest';

import { generateSelectOptions } from '@/src/helpers/forms';

export const PAYROLL_CYCLES = {
  MONTHLY: 'monthly',
  SEMI_MONTHLY: 'bi_monthly', // this inconsistency is intentional, due to legacy misnomer in the backend
  BI_WEEKLY: 'bi_weekly',
} as const;

export type PayrollCycle = ValueOf<typeof PAYROLL_CYCLES>;

export const PAYROLL_CYCLES_LABELS: Record<PayrollCycle, string> = {
  [PAYROLL_CYCLES.MONTHLY]: 'Monthly',
  [PAYROLL_CYCLES.SEMI_MONTHLY]: 'Semi-monthly',
  [PAYROLL_CYCLES.BI_WEEKLY]: 'Bi-weekly',
};

export const PAYROLL_CYCLES_SHORT_DESCRIPTIONS: Record<PayrollCycle, string> = {
  [PAYROLL_CYCLES.MONTHLY]: 'Once per month',
  [PAYROLL_CYCLES.SEMI_MONTHLY]: 'Twice per month',
  [PAYROLL_CYCLES.BI_WEEKLY]: 'Every two weeks',
};

export const PAYROLL_CYCLES_LONG_DESCRIPTIONS: Record<PayrollCycle, string> = {
  [PAYROLL_CYCLES.MONTHLY]: 'Monthly, 12 payments per year',
  [PAYROLL_CYCLES.SEMI_MONTHLY]: 'Bi-monthly, 24 payments per year',
  [PAYROLL_CYCLES.BI_WEEKLY]: 'Bi-weekly, 26 or 27 payments per year',
};

export const PAYROLL_CYCLES_OPTIONS = generateSelectOptions(PAYROLL_CYCLES_LABELS).map(
  (option) => ({
    ...option,
    suffix: PAYROLL_CYCLES_SHORT_DESCRIPTIONS[option.value as PayrollCycle],
  })
);

/**
 * Documentation link for these values
 * https://www.notion.so/remotecom/Resource-Status-09df4014dca54f4d810d2edb73eafdc6?pvs=4#2c991bc3b47c4f1aa1fb46435f2df252
 */
export const PAYROLL_CYCLE_STATUS = {
  IN_USE: 'in_use',
  ENABLED: 'enabled',
  IN_CONFLICT: 'in_conflict',
  DISABLED: 'disabled',
  IN_VIOLATION: 'in_violation',
  PROHIBITED: 'prohibited',
} as const;

/**
 * Documentation link for these values
 * https://www.notion.so/remotecom/Resource-Status-09df4014dca54f4d810d2edb73eafdc6?pvs=4#2c991bc3b47c4f1aa1fb46435f2df252
 */
export type PayrollCycleStatus = ValueOf<typeof PAYROLL_CYCLE_STATUS>;

export const PAYROLL_CYCLE_STATUS_LABELS: Record<PayrollCycleStatus, string> = {
  [PAYROLL_CYCLE_STATUS.IN_USE]: 'In use',
  [PAYROLL_CYCLE_STATUS.ENABLED]: 'Enabled',
  [PAYROLL_CYCLE_STATUS.IN_CONFLICT]: 'In conflict',
  [PAYROLL_CYCLE_STATUS.DISABLED]: 'Disabled',
  [PAYROLL_CYCLE_STATUS.IN_VIOLATION]: 'In violation',
  [PAYROLL_CYCLE_STATUS.PROHIBITED]: 'Prohibited',
};
