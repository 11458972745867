import type { ReactElement, PropsWithChildren } from 'react';

import type { SizeProps, ChevronPositionProps } from './Accordion.styled';
import {
  Details,
  DetailsContent,
  Summary,
  SummaryContent,
  ChevronDown,
  SummaryInside,
} from './Accordion.styled';

interface AccordionProps extends SizeProps, ChevronPositionProps {
  summary: string | ReactElement;
  open?: boolean;
  onClick?: (isOpen: boolean) => void;
}

export function Accordion({
  summary,
  open = false,
  children,
  chevronPosition = 'default',
  size = 'default',
  onClick,
  ...props
}: PropsWithChildren<AccordionProps>) {
  return (
    <Details
      open={open}
      {...props}
      onToggle={(event: React.MouseEvent<HTMLDetailsElement, MouseEvent>) =>
        onClick?.(event.currentTarget.open)
      }
    >
      <Summary size={size}>
        <SummaryInside chevronPosition={chevronPosition}>
          <SummaryContent chevronPosition={chevronPosition}>{summary}</SummaryContent>
          <ChevronDown />
        </SummaryInside>
      </Summary>

      <DetailsContent size={size}>{children}</DetailsContent>
    </Details>
  );
}
