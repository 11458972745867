// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconTextItalic = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M15.02 3H19a1 1 0 1 1 0 2h-3.3l-5.26 14H14a1 1 0 1 1 0 2H5a1 1 0 1 1 0-2h3.3l5.26-14H10a1 1 0 0 1 0-2h4.98a1 1 0 0 1 .04 0Z" /></svg>;
const ForwardRef = forwardRef(IconTextItalic);
export { ForwardRef as IconTextItalic };