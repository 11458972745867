import { Box, Columns, Column, Stack, Text } from '@remote-com/norma';
import Head from 'next/head';
import type { PropsWithChildren } from 'react';
import * as React from 'react';
import styled, { css } from 'styled-components';

import RemoteSymbolSvg from '@/public/images/symbol.svg';
import SiteTitle from '@/src/components/SiteTitle';
import type { PublicAssetPath } from '@/src/constants/publicAssetPaths';
import { staticAssetUrl } from '@/src/helpers/general';

const SideIllustration = styled(Box)<{ $bgImage: string }>`
  ${({ theme, $bgImage }) => css`
    background-color: ${theme.colors.grey[900]};
    background-image: url(${$bgImage});
  `}
`;

export type SideIllustrationLayoutDescriptionProps =
  | {
      description?: string;
      metaDescription?: string;
    }
  | {
      description: React.ReactElement;
      metaDescription: string;
    };

export type SideIllustrationLayoutProps = PropsWithChildren<
  {
    brandIllustration: PublicAssetPath;
    title?: string;
    icon?: React.ReactElement;
  } & SideIllustrationLayoutDescriptionProps
>;

const Container = styled(Stack).attrs({
  color: 'grey.900',
  direction: 'row',
  justifyContent: 'center',
  minHeight: '100vh',
})``;

export const SideIllustrationLayout = ({
  brandIllustration,
  metaDescription,
  description,
  title,
  children,
  icon,
}: SideIllustrationLayoutProps) => {
  const assetUrl = staticAssetUrl(brandIllustration);

  const headDescription =
    metaDescription || (typeof description === 'string' ? (description as string) : '');

  return (
    <>
      {title && <SiteTitle>{title}</SiteTitle>}
      {headDescription && (
        <Head>
          <meta name="description" content={headDescription} />
          <link rel="preload" as="image" href={assetUrl} />
          <title>{title}</title>
        </Head>
      )}
      <Container>
        <Box flex="1" justifyContent="center" alignItems="center" display="flex">
          <Columns maxWidth={['none', '420px', '540px', '640px', '880px']} width="100%">
            <Column
              display="flex"
              start={[1, 1, 2, 2, 4]}
              end={[3, 5, 6, 8, 10]}
              flexDirection="column"
              justifyContent={['flex-start', null, null, 'center']}
              mt={[8, 8, 11, 0]}
            >
              {title && <Box alignSelf="center">{icon ? <>{icon}</> : <RemoteSymbolSvg />}</Box>}
              <Stack width="100%">
                <Stack mb={8}>
                  {title && (
                    <Text
                      as="h1"
                      color="grey.900"
                      mt={7}
                      fontFamily="brand"
                      textAlign="center"
                      variant="4xlMedium"
                    >
                      {title}
                    </Text>
                  )}
                  <Text as="p" color="grey.700" mt={3} textAlign="center" variant="sm">
                    {description}
                  </Text>
                </Stack>
                {children}
              </Stack>
            </Column>
          </Columns>
        </Box>
        <SideIllustration
          display={['none', 'none', 'none', 'block', 'block']}
          width={[null, null, null, '320px', '480px']}
          $bgImage={assetUrl}
        />
      </Container>
    </>
  );
};
