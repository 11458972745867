import { SROnly, Box, sharedTransition, Tooltip, Spinner } from '@remote-com/norma';
import { IconTimes } from '@remote-com/norma/icons/IconTimes';
import { rem } from 'polished';
import type { KeyboardEvent } from 'react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@/src/components/Button';
import { useDebounce } from '@/src/hooks/useDebounce';

type SearchFieldVariant = 'default' | 'ghost' | 'secondary';

/** Using this styled.input approach to quickly create an input which forwards its ref (to be used inside the Tooltip) */
const StyledInput = styled.input``;

const ClearButton = styled(Button).attrs({
  variant: 'raw',
})`
  padding: 0;
  border: 0;
  outline: none;
  display: flex;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  svg {
    fill: var(--colors-lynch);
  }
  cursor: pointer;
`;

const InputOpened = css<{ $variant: SearchFieldVariant }>`
  padding: 12px 16px 12px 44px;

  ${({ $variant }) => {
    switch ($variant) {
      case 'ghost':
        return css`
          border: none;
          background: url(/images/icons/gray-search-icon.svg) center no-repeat transparent;
          padding: 12px 16px 12px 35px;
          &:hover,
          &:focus {
            background-color: ${({ theme }) => theme.colors.blank};
          }
          &::-webkit-search-cancel-button {
            display: none;
          }
        `;
      case 'secondary':
        return css`
          border-radius: 8px;
          padding: ${({ theme }) =>
            `${theme.space[4]}px ${theme.space[5]}px ${theme.space[4]}px ${theme.space[7]}px`};
          border: ${({ theme }) => `1px solid ${theme.colors.grey[300]}`};
          background: url(/images/icons/search-v2-outline.svg) center no-repeat transparent;
          background-size: 16px;
          color: ${({ theme }) => theme.colors.grey[900]};

          &:hover {
            border-color: ${({ theme }) => theme.colors.grey[400]};
          }

          &:focus {
            border-color: ${({ theme }) => theme.colors.grey[500]};
          }

          &::-webkit-search-cancel-button {
            display: none;
          }
        `;
      default:
        return css`
          border: 1px solid;
          border-color: ${({ theme }) => theme.colors.grey[300]};
          border-radius: 25px;
          background: url(/images/icons/gray-search-icon.svg) center no-repeat transparent;
          padding: 12px 16px 12px 35px;
          color: ${({ theme }) => theme.colors.grey[900]};

          &:hover {
            border-color: ${({ theme }) => theme.colors.grey[400]};
          }

          &:focus {
            border-color: ${({ theme }) => theme.colors.grey[500]};
          }

          &::-webkit-search-cancel-button {
            display: none;
          }
        `;
    }
  }}
  background-position: 12px center;
  max-width: 100%;
  padding-right: 32px;
  cursor: auto;
`;

const SearchContainer = styled(Box)<{ $variant: SearchFieldVariant; $width: string }>`
  display: flex;

  height: ${({ $variant }) => $variant === 'default' && '34px'};
  width: 100%;
  max-width: ${({ $width }) => $width};
  position: relative;

  input {
    font-weight: normal;
    font-size: ${rem('14px')};
    line-height: 17px;
    height: auto;
    border-radius: 20px;
    background: url('/images/icons/search.svg') center no-repeat transparent;
    background-size: 16px;
    outline: 0;
    will-change: width;
    ${sharedTransition('background, width')}

    ${InputOpened}
    width: 100%;
  }
`;

const doneTypingInterval = 400;

export function trimInputValue(value?: string) {
  return value?.toString().trim();
}

type CommonSearchFieldProps = {
  autofocus?: boolean;
  defaultValue?: string;
  id?: string;
  isLoading?: boolean;
  label?: string;
  placeholder: string;
  variant?: SearchFieldVariant;
  width?: string;
};

type ControlledSearchFieldProps = {
  onChange?: never;
  setSearchQuery: (value: string) => void;
  value: string;
} & CommonSearchFieldProps;

type UncontrolledSearchFieldProps = {
  onChange: (value: string | undefined) => void;
  setSearchQuery?: never;
  value?: never;
} & CommonSearchFieldProps;

type SearchFieldProps = ControlledSearchFieldProps | UncontrolledSearchFieldProps;

/**
 * This search component prevents the search callback to be called if the user is still typing.
 */
const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(
  (
    {
      id = 'search',
      placeholder,
      defaultValue,
      value,
      label,
      setSearchQuery = () => null,
      onChange = () => null,
      width = '200px',
      variant = 'default',
      autofocus = false,
      isLoading = false,
      ...props
    },
    ref
  ) => {
    const valueAttr = defaultValue !== undefined ? { defaultValue } : { value };
    const [hasValue, setHasValue] = useState(!!(valueAttr.defaultValue || valueAttr.value));
    const debouncedOnChange = useDebounce(onChange, doneTypingInterval);

    const searchInputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => searchInputRef.current!, []);

    function handleOnChange({ target }: { target: { value: string } }) {
      const searchQuery = trimInputValue(target.value);

      setHasValue(!!searchQuery);
      debouncedOnChange(searchQuery);

      // When is a controlled input, let the parent know about the new value immediately
      if (value !== undefined) setSearchQuery(target.value);
    }

    function handleOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
      if (event.key === 'Enter') {
        debouncedOnChange.cancel();
        onChange(trimInputValue(event.currentTarget.value));
      }
    }

    return (
      <SearchContainer $variant={variant} $width={width} {...props}>
        <SROnly as="label" htmlFor={id}>
          {label || placeholder}
        </SROnly>
        <Tooltip label={label || placeholder} type="caption" maxWidth="none">
          <StyledInput
            type="search"
            id={id}
            name="search"
            data-testid="search-field-input"
            {...valueAttr}
            onChange={handleOnChange}
            autoComplete="false"
            onKeyDown={handleOnKeyDown}
            ref={searchInputRef}
            placeholder={placeholder}
            autoFocus={autofocus}
          />
        </Tooltip>
        {hasValue && isLoading ? (
          <Box position="absolute" top="12px" right="12px">
            <Spinner />
          </Box>
        ) : null}
        {hasValue && !isLoading ? (
          <ClearButton
            aria-label="clear search field"
            onClick={() => {
              handleOnChange({ target: { value: '' } });

              if (searchInputRef?.current) {
                searchInputRef.current.value = '';
              }
            }}
          >
            <IconTimes width="14px" />
          </ClearButton>
        ) : null}
      </SearchContainer>
    );
  }
);

export default SearchField;
