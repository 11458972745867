import { usePost } from '@remote-com/data-layer';
import { useState } from 'react';

import type { API } from '@/src/api/config/api.types';
import type { ColumnType } from '@/src/components/Table/types';
import { useSavedSearchValue } from '@/src/hooks/useSavedSearchValue';

import { getApplicableFilters, getColumnNames } from './helpers';

const useSmartSearchRequest = () => {
  const { mutateAsync, data, isError, isLoading } = usePost(
    '/remote-ai/api/v1/generate/smart-search-filter',
    {
      retry: false,
    }
  );

  return { data, isError, isLoading, mutateAsync };
};

interface SmartSearchHookArgs {
  /**
   * The table columns
   */
  columns: ColumnType[];
  /**
   * The filters to be set in the table
   */
  setFilters: (filters: API.RemoteAi.GenerateSmartSearchFilter.Response['data']['filters']) => void;
  /**
   * The global search query to be set in the table
   */
  setGlobalSearch?: (filter: string) => void;
  /**
   * The API path to the endpoint that will be used to fetch the table date
   */
  apiPath: string;
  /**
   * What field or fields are searchable by the table's global search (eg: "name", "name and email", "slug")
   */
  globalSearchFields?: string;
}

export const useSmartSearch = ({
  columns,
  setFilters,
  setGlobalSearch,
  apiPath,
  globalSearchFields,
}: SmartSearchHookArgs) => {
  const [status, setStatus] = useState<'idle' | 'error' | 'partial-success' | 'success'>('idle');
  const { setSearchTerm } = useSavedSearchValue();
  const { isLoading, isError, mutateAsync } = useSmartSearchRequest();

  const handleFiltersResponse = (
    filters: API.RemoteAi.GenerateSmartSearchFilter.Response['data']['filters']
  ) => {
    if (!filters) {
      return [];
    }

    const additionalColumnsContext = columns.reduce<
      Record<string, { label: string; value: string }[]>
    >((acc, column) => {
      if (column.context) {
        acc[column.id as keyof typeof acc] = column.context;
      }
      return acc;
    }, {});

    const applicableFilters = getApplicableFilters(filters, columns, additionalColumnsContext);

    if (applicableFilters.length > 0) {
      setFilters(applicableFilters);
    }

    return applicableFilters;
  };

  const handleGlobalFilterResponse = (globalFilter?: string) => {
    if (!globalFilter) {
      return;
    }

    setGlobalSearch?.(globalFilter);
    setSearchTerm(globalFilter);
  };

  const handleSortResponse = (
    sort: API.RemoteAi.GenerateSmartSearchFilter.Response['data']['sort']
  ) => {
    if (!sort || !sort.sortBy) {
      return;
    }

    const column = columns.find((c) => c.id === sort.sortBy);

    if (!column?.toggleSortBy) {
      // If no valid sort or column found, maintain current sort state
      return;
    }

    // Clear any existing sorts before applying new one
    columns.forEach((c) => {
      if (c.id !== sort.sortBy && c.clearSortBy) {
        c.clearSortBy();
      }
    });

    column.toggleSortBy(sort.order === 'desc');
    return true;
  };

  const run = async (userQuery: string) => {
    setStatus('idle');
    if (!userQuery.trim()) {
      return;
    }

    const { data } = await mutateAsync({
      bodyParams: {
        userQuery: userQuery.trim(),
        endpoint: apiPath,
        columnNames: getColumnNames(columns),
        globalSearch: globalSearchFields,
      },
    });

    const {
      filters: responseFilters,
      sort: responseSort,
      globalFilter: responseGlobalFilter,
    } = data;

    const applicableFilters = handleFiltersResponse(responseFilters);
    handleGlobalFilterResponse(responseGlobalFilter);
    handleSortResponse(responseSort);

    // Filter out sort operations from response filters since they are treated separately
    // Partial success: it only applies when filters were requested but some couldn't be applied
    const partiallySuccessfulFilters =
      responseFilters.length > 0 && applicableFilters.length < responseFilters.length;

    // Error: we have no valid operations to apply
    const noQueryToApply = responseFilters.length === 0 && !responseGlobalFilter && !responseSort;

    // Determine the operation status based on conditions
    if (noQueryToApply) {
      setStatus('error');
    } else if (partiallySuccessfulFilters) {
      setStatus('partial-success');
    } else {
      setStatus('success');
    }
  };

  return {
    status,
    run,
    isLoading,
    isError,
  };
};
