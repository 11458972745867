import * as light from './palettes';
import * as dark from './palettes-dark';
import type { ColorPalette } from './palettes-type';

type SemanticColorValues = {
  foreground: string;
  background: string;
  border: string;
};

function getSemanticColors(Palettes: ColorPalette) {
  const colors = {
    negative: {
      foreground: Palettes.red[700],
      background: Palettes.red[50],
      border: Palettes.red[400],
    },
    positive: {
      foreground: Palettes.green[800],
      background: Palettes.green[50],
      border: Palettes.green[400],
    },
    attention: {
      foreground: Palettes.yellow[800],
      background: Palettes.yellow[50],
      border: Palettes.yellow[400],
    },
    informative: {
      foreground: Palettes.brand[700],
      background: Palettes.blueLight[50],
      border: Palettes.brand[300],
    },
    neutral: {
      foreground: Palettes.grey[600],
      background: Palettes.grey[50],
      border: Palettes.grey[400],
    },
  } satisfies Record<string, object>;

  return colors;
}

export const SEMANTIC_COLORS = getSemanticColors(light);

export const SEMANTIC_COLORS_DARK = getSemanticColors(dark);

export type SemanticColorNames = keyof ReturnType<typeof getSemanticColors>;

/**
 * Flatten the type like `${"neutral"}.${"foreground"}
 */
export type SemanticColorForStyledSystem = Record<
  `${SemanticColorNames}.${keyof SemanticColorValues}`,
  string
>;
