import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { fonts } from './fonts';

function injectColors({ theme }) {
  function makeColor(key) {
    const value = theme.colors[key];
    // It's possible to recursively inject color palettes,
    // but we want to recommend using "theme" param,
    // and also to preserve backwards compatibility.
    const isNotPalette = typeof value === 'string';
    return isNotPalette ? `--colors-${key}: ${value};` : null;
  }

  const variables = Object.keys(theme.colors)
    .map(makeColor)
    .filter(Boolean) // Filter out null values
    .join('\n');

  return variables;
}

export const GlobalStyles = createGlobalStyle`
  ${normalize}

  :root {
    --typography-fontPrimary: ${fonts.body};
    --typography-fontMono: ${fonts.mono};
    --typography-fontBrand: ${fonts.brand};

    ${injectColors}
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-interpolation-mode: nearest-neighbor;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    font-family: var(--typography-fontPrimary);
    color: ${({ theme }) => theme.colors.grey[900]}; 

    ${({ theme }) => theme.typography.base}
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
  }
`;
