import isEqual from 'lodash/isEqual';
import type { TableInstance, Hooks, TableState } from 'react-table';

interface GroupByState extends TableState<any> {
  groupBy: string[];
}

interface GroupByInstance extends TableInstance<any> {
  state: GroupByState;
  setGroupBy: (groupBy: string[]) => void;
}

export const getUseControlledGroupBy =
  (isFirstRender: { current: boolean }, controlledGroupBy: string[]) => (hooks: Hooks<any>) => {
    hooks.useInstance.push((instance: TableInstance<any>) => {
      const groupByInstance = instance as GroupByInstance;

      if (
        !isFirstRender.current &&
        controlledGroupBy &&
        !isEqual(controlledGroupBy, groupByInstance.state.groupBy)
      ) {
        groupByInstance.setGroupBy(controlledGroupBy);
      }

      if (isFirstRender.current) {
        isFirstRender.current = false;
      }
    });
  };
