import { IconTextBold } from '@remote-com/norma/icons/IconTextBold';
import { IconTextItalic } from '@remote-com/norma/icons/IconTextItalic';
import { IconTextUnderline } from '@remote-com/norma/icons/IconTextUnderline';
import { IconV2OutlineLinkAlt } from '@remote-com/norma/icons/IconV2OutlineLinkAlt';
import { type Editor } from '@tiptap/core';
import type { JSONContent } from '@tiptap/react';

import type { $TSFixMe } from '@/types';

import { H2Icon, BulletListIcon, OrderedListIcon } from './icons';

const boldHandler = (editor: Editor) => editor.chain().focus().toggleBold().run();
const underlineHandler = (editor: Editor) => editor.chain().focus().toggleUnderline().run();
const italicHandler = (editor: Editor) => editor.chain().focus().toggleItalic().run();
const headingHandler = (editor: Editor, level: $TSFixMe) =>
  editor.chain().focus().toggleHeading({ level }).run();
const bulletListHandler = (editor: Editor) => editor.chain().focus().toggleBulletList().run();
const orderedListHandler = (editor: Editor) => editor.chain().focus().toggleOrderedList().run();

export const getInlineStylesTools = (editor: Editor, openModal: () => void) => {
  const formattingMarks = [
    { style: 'Bold', name: 'bold', icon: IconTextBold, handler: () => boldHandler(editor) },
    {
      style: 'Underline',
      name: 'underline',
      icon: IconTextUnderline,
      handler: () => underlineHandler(editor),
    },
  ];

  // Some editors e.g. Jobs disable italics for style reasons – inclusion in toolbar is conditional. ESLint suppression is because the types define toggleItalic as always present.
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!editor.commands.toggleItalic) {
    formattingMarks.push({
      style: 'Italic',
      name: 'italic',
      icon: IconTextItalic,
      handler: () => italicHandler(editor),
    });
  }

  return [
    ...formattingMarks,
    {
      style: 'Link',
      name: 'link',
      icon: IconV2OutlineLinkAlt,
      handler: openModal,
    },
  ];
};

export const getListStylesTools = (editor: Editor) => [
  {
    style: 'Ordered list',
    name: 'orderedList',
    icon: OrderedListIcon,
    handler: () => orderedListHandler(editor),
  },
  {
    style: 'Bullet List',
    name: 'bulletList',
    icon: BulletListIcon,
    handler: () => bulletListHandler(editor),
  },
];

export const getHeadingStylesTools = (editor: Editor) =>
  // If heading extension is disabled, this will not be present – types do not factor this in.
  // eslint-disable-next-line no-extra-boolean-cast
  !!editor.commands.toggleHeading
    ? [
        // The Job title should be the only H1 on the page
        {
          style: 'Heading 2',
          name: 'heading',
          level: 2,
          icon: H2Icon,
          handler: () => headingHandler(editor, 2),
        },
      ]
    : [];

export function isContentBlockEmpty(block: JSONContent): boolean {
  if (!block || !block.type) {
    return true;
  }

  if ('text' in block) {
    return !block.text?.trim();
  }

  return block.content ? block.content.every((b) => isContentBlockEmpty(b)) : true;
}
