/* eslint-disable import/no-extraneous-dependencies */
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import Router, { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery, useQueryClient } from 'react-query';

import { useApiService } from '../ApiServiceContext';
import { createQueryKey } from '../utils';

import { parseQueryFromUrl, stringifyTableQuery } from './utils/queryParser';
import {
  decodeQueryParam,
  getDataWithRenamedProperty,
  APIResponseToState,
  supportedTableParams,
  urlToTableState,
  stateToAPIQuery,
  validateTableId,
} from './utils/table';

const METHOD = 'GET';
const DEFAULT_TABLE_PAGE_SIZE = 25;

const normalizeData = (response, dataProperty) =>
  dataProperty && response ? getDataWithRenamedProperty(response, dataProperty) : response;

// Convert the table state to page query params.
const updateQueryParams = async (tableId, tableState) => {
  const newQueryString = stringifyTableQuery(
    {
      ...parseQueryFromUrl(Router.asPath),
      ...(tableId
        ? { [tableId]: pick(tableState, supportedTableParams) }
        : pick(tableState, supportedTableParams)),
      // Use router's slug
      ...(!!Router?.query?.slug && { slug: Router.query.slug }),
      // Use router's userSlug
      ...(!!Router?.query?.userSlug && { userSlug: Router.query.userSlug }),
    },
    {
      uniqueTableId: tableId,
      skipNulls: true,
      skipEmptyStrings: true,
      skipCustomMatcher: (key, val) => key === 'pageIndex' && (val === 0 || val === '0'),
    }
  );

  if (newQueryString === '') {
    await Router.replace(Router.pathname, undefined, { shallow: true });
  } else {
    await Router.replace(
      {
        pathname: Router.asPath.split('?')[0],
        query: newQueryString,
      },
      undefined,
      { shallow: true }
    );
  }
};

/**
 * @typedef {Object} TableProps
 * @property {Array} queryKey - The query key in use to cache the API response
 */

/**
 * Perform a fetch to an API endpoint and properly transform its response to be
 * rendered in a table
 * @param {string} path The path of the API endpoint
 * @param {object} config.params API Client path/query params
 * @param {object} config.options React Query options
 * @param {object} config.tableOptions.initialState Initial state used to create APIQuery and passed to Table, for example initial sorting
 * @param {object} config.tableOptions.transformParams Allows to transform the API call params as desired before calling the service
 * @param {object} config.tableOptions.withQS Whether the table query state (filters, ordering, search) should be reflected in the URL as a query string
 * Please refer to src/components/Table/helpers/helpers.js{stateToAPIQuery} for the rest of the config.tableOptions
 * @param {object} config.tableOptions.filterConfig An object that allows transformation of how filters are sent to the backend
 * @param {object} config.tableOptions.globalFilterQueryKey Which queryKey to use to send search filter from the table. Defaults to `globalFilter
 * @param {string} config.tableOptions.dataProperty Transform the response to make use of getDataWithRenamedProperty
 * @param {string} config.tableOptions.tableId Identifier for the table data being fetched (useful when a page has multiple tables)
 * @returns {TableProps} Properties which are used to build a table
 */
export const useGetForTable = (path, config = { params: {}, options: {}, tableOptions: {} }) => {
  const queryClient = useQueryClient();
  const {
    transformParams = (params) => params,
    withQS = true,
    tableId = null,
    dataProperty,
    ...stateToQueryAPIOptions
  } = config.tableOptions || {};

  const { asPath } = useRouter();

  /**
   * Validate our tableId if it is different to null.
   */
  try {
    if (tableId) {
      validateTableId(tableId);
    }
  } catch (error) {
    throw new Error(
      'Invalid tableId: It must be a string no larger than 5 characters to maintain short URLs. Use tableId only when multiple tables are present in a single view.'
    );
  }

  const urlTableState = urlToTableState(asPath, {
    uniqueId: tableId,
  });
  const initialTableState =
    withQS && !isEmpty(urlTableState) ? urlTableState : config.tableOptions?.initialState || {};

  if (initialTableState.globalFilter) {
    initialTableState.globalFilter = decodeQueryParam(initialTableState.globalFilter);
  }

  if (config.tableOptions?.globalFilterQueryKey) {
    initialTableState.globalFilterQueryKey = config.tableOptions.globalFilterQueryKey;
  }

  const [tableState, setTableState] = useState({
    ...initialTableState,
    pageSize: initialTableState?.pageSize || DEFAULT_TABLE_PAGE_SIZE,
  });

  const onStateChange = useCallback(
    async (state) => {
      const updatedState = {
        ...state,
        ...(initialTableState.globalFilterQueryKey
          ? { globalFilterQueryKey: initialTableState.globalFilterQueryKey }
          : {}),
      };

      setTableState(updatedState);

      if (withQS) {
        // Note: previously we used `useComponentDidUpdate` hook to call
        // `updateQueryParams` when `tableState` changed. Unfortunately, it was
        // causing ghost updates to the page URL even after the user would navigate
        // away from the table. Looks like calling `updateQueryParams` directly in
        // the `onStateChange` callback makes it harder to reproduce the buggy
        // scenario.
        await updateQueryParams(tableId, updatedState);
      }
    },
    [initialTableState.globalFilterQueryKey, withQS, tableId]
  );

  const stateQueryParams = stateToAPIQuery(tableState, stateToQueryAPIOptions);

  const serviceArgs = {
    ...config.params,
    queryParams: { ...stateQueryParams, ...config.params?.queryParams },
  };

  const { createService } = useApiService();
  const service = createService(path, METHOD);

  const queryKey = createQueryKey(path, transformParams(serviceArgs));
  const queryFn = async ({ signal, ...queryParams }) => {
    // Call `config.tableOptions.transformParams` inside of `queryFn` to
    // correctly transform parameters when using table export or bulk
    // selection features.
    const params = transformParams({
      ...serviceArgs,
      queryParams: { ...serviceArgs.queryParams, ...queryParams },
    });

    return service({ signal, ...params });
  };

  // The "normalize" step must be applied after data has been fetched through
  // React Query so that it's stored in the cache exactly as it comes from the
  // BE, thus allowing the same API endpoint to be used by different queries
  // with distinct normalization/transform strategies
  const { options: { select = (res) => res, ...queryOptions } = {} } = config;
  const response = useQuery(queryKey, ({ signal }) => queryFn({ signal }), {
    retry: false,
    ...queryOptions,
    select: (res) => select(normalizeData(res, dataProperty)),
  });

  // Make sure `select` and `normalizeData` are applied to the response when
  // exporting a CSV too
  const exportCSVQueryFn = async (args) => {
    return select(normalizeData(await queryFn(args), dataProperty));
  };

  async function refetchData() {
    return queryClient.invalidateQueries(queryKey);
  }

  const { data, isLoading, isEmptyTable, pageIndex, pageCount, totalCount, metadata } =
    APIResponseToState(response);

  const isSearchOrFilterApplied = !!(tableState.filters?.length || tableState.globalFilter);

  // For initial render or page refresh
  const isInitialLoading =
    isLoading && !isSearchOrFilterApplied && tableState.pageIndex === undefined;

  return {
    tableState,
    isSuccess: response.isSuccess,
    isFetching: response.isFetching,
    isFetched: response.isFetched,
    isError: response.isError,
    error: response.error,
    isSearchOrFilterApplied,
    isInitialLoading,
    isEmpty: isEmptyTable,
    isInitialStateEmpty: isEmptyTable && !isSearchOrFilterApplied && !pageIndex,
    onStateChange,
    // Note: The query function is used by the export table data feature
    queryFn: exportCSVQueryFn,
    refetchData,
    queryKey,
    data,
    isLoading,
    pageCount,
    totalCount,
    initialState: {
      pageIndex,
      ...initialTableState, // initialTableState.pageIndex can override default value from response
    },
    controlledPageIndex: pageIndex,
    metadata,
  };
};
