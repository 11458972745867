import 'focus-visible';
import '@remote-com/norma/styles.css'; // fonts imports

import { initRudderStack, setupAnalyticsConfig, setupDatadogRum } from '@remote-com/analytics';
import { ApiServiceProvider } from '@remote-com/data-layer';
import App from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { createPortal } from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { setupDataLayer } from '@/src/api/config';
import { AppErrorBoundary } from '@/src/components/AppErrorBoundary';
import { BrowserContextProvider } from '@/src/components/BrowserContext';
import { Favicon } from '@/src/components/Favicon/Favicon';
import { GlobalPreviousUrlTracker } from '@/src/components/GlobalPreviousUrlTracker/GlobalPreviousUrlTracker';
import { LazyURLModal } from '@/src/components/Modal/LazyURLModal';
import UserProvider from '@/src/components/UserProvider';
import { queryClient } from '@/src/config/queryClient';
import { GlobalStyles, themeV2 as theme } from '@/src/config/styles';
import { Analytics } from '@/src/domains/analytics/Analytics';
import AnalyticsWrapper from '@/src/domains/analytics/AnalyticsWrapper';
import { BroadcastMessageHandler } from '@/src/domains/broadcast/BroadcastMessageHandler';
import { ContentSecurityPolicy } from '@/src/domains/contentSecurityPolicy/ContentSecurityPolicy';
import { InlineScripts } from '@/src/domains/contentSecurityPolicy/InlineScripts';
import { EmployFeatureFlagProvider } from '@/src/domains/feature-flag/host/host';
import { UploadConfig } from '@/src/domains/files/UploadConfig';
import { findTeamByRoute } from '@/src/domains/governance/utils';
import { ImpersonatedBanner } from '@/src/domains/impersonation/ImpersonatedBanner';
import { PowerSearchContextProvider } from '@/src/domains/powerSearch/PowerSearchContext';
import { AccessTokenProvider } from '@/src/domains/registration/auth/AccessTokenProvider';
import { CleanOAPFromUrl } from '@/src/domains/registration/auth/CleanOAPFromUrl';
// DO NOT IMPORT setupAPIMocksForDev FROM '@/src/domains/remoteControlPanel/tools/api-mocks' as it serves as a barrel file.
import { PermissionSignature } from '@/src/domains/registration/auth/PermissionsSignature';
import { setupAPIMocksForDev } from '@/src/domains/remoteControlPanel/tools/api-mocks/utils';
import { ReturnPathQueryHandler } from '@/src/domains/returnPath/ReturnPathQueryHandler';
import { ThemeProvider } from '@/src/domains/theme/provider';
import { UserSettingsFavoritesProvider } from '@/src/domains/userSettings/UserSettingsFavoritesContext';
import { WebSocketProvider } from '@/src/domains/websockets/WebSocketProvider';
import { isPublicEnvironment } from '@/src/helpers/general';
import DefaultLayout from '@/src/layouts/Default';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { makeApiService } from '@/src/services/ApiClient';

const Toaster = dynamic(() => import('@remote-com/norma').then((c) => c.Toaster), {
  ssr: false,
});

const ConfirmEmailModal = dynamic(
  () =>
    import('@/src/domains/registration/onboarding/company/two-part-signup/ConfirmEmailModal').then(
      (c) => c.ConfirmEmailModal
    ),
  {
    ssr: false,
  }
);

const RemoteControlPanel = dynamic(
  () =>
    import('@/src/domains/remoteControlPanel/RemoteControlPanel').then((c) => c.RemoteControlPanel),
  {
    ssr: false,
  }
);

const {
  publicRuntimeConfig: {
    DATADOG_RUM_ENABLED,
    DATADOG_CLIENT_TOKEN,
    DATADOG_APPLICATION_ID,
    DATADOG_SESSION_REPLAY_ENABLED,
    ENVIRONMENT,
    API_BASE_URL_CLIENT,
    TALENT_API_BASE_URL,
    RUDDERSTACK_WRITE_KEY,
    RUDDERSTACK_DATAPLANE_URL,
    RUDDERSTACK_ENABLED,
    RUDDERSTACK_USE_SERVER_SIDE_COOKIES,
  },
} = getConfig();

const isServer = typeof window === 'undefined';

setupAPIMocksForDev();
setupDataLayer();
setupAnalyticsConfig({
  DATADOG_RUM_ENABLED,
});

class Employ extends App {
  componentDidMount() {
    initRudderStack({
      RUDDERSTACK_WRITE_KEY,
      RUDDERSTACK_DATAPLANE_URL,
      RUDDERSTACK_ENABLED,
      RUDDERSTACK_USE_SERVER_SIDE_COOKIES,
    });

    if (DATADOG_RUM_ENABLED) {
      setupDatadogRum({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        service: 'dragon',
        env: ENVIRONMENT,
        version: process.env.RELEASE,
        allowedTracingUrls: [
          { match: API_BASE_URL_CLIENT, propagatorTypes: ['datadog', 'tracecontext'] },
          { match: TALENT_API_BASE_URL, propagatorTypes: ['tracecontext'] },
        ],
        startSessionReplayRecording: DATADOG_SESSION_REPLAY_ENABLED,
        beforeSend: (event, context) => {
          const errorCode = context?.error?.code;
          if (errorCode) {
            event.context.errorCode = errorCode;
          }
          // If no ownership is provided, we try to find it by the current route.
          if (event.type === 'error' && !event.context.ownership) {
            const routeOwner = findTeamByRoute({ route: Router.pathname, query: Router.query });
            event.context.ownership = routeOwner;
          }
          return true;
        },
      });
    }
  }

  handleGlobalForceUpdate = () => {
    this.forceUpdate();
  };

  render() {
    const { Layout, Parent, Component } = this.props;

    // Component.Layout is accessed when in browser
    // Layout is provided when in SSR
    const ComponentLayout = Component.Layout || Layout || DefaultLayout;
    const ParentComponent = Component.Parent || Parent || DefaultLayout;

    return (
      <>
        <Head>
          <title key="page-title">Remote</title>
          <link rel="manifest" href="/manifest.json" />
        </Head>
        <Favicon />
        <ContentSecurityPolicy>
          <InlineScripts />
          <ApiServiceProvider apiService={makeApiService}>
            <QueryClientProvider client={queryClient}>
              <UserProvider>
                <EmployFeatureFlagProvider>
                  <ThemeProvider>
                    <GlobalPreviousUrlTracker />
                    <GlobalStyles />
                    <BroadcastMessageHandler />
                    <ReturnPathQueryHandler />
                    <CleanOAPFromUrl />
                    <PermissionSignature />
                    <Analytics />
                    <AppErrorBoundary>
                      <BrowserContextProvider>
                        <WebSocketProvider>
                          <PowerSearchContextProvider>
                            <AnalyticsWrapper />
                            <NextNProgress
                              color={theme.colors.cyan[700]}
                              // Do not trigger loading animation on `shallow: true` route
                              // changes.
                              showOnShallow={false}
                            />
                            {!isServer && createPortal(<Toaster />, document.body)}
                            <UploadConfig />
                            <AccessTokenProvider>
                              <UserSettingsFavoritesProvider>
                                <ComponentLayout {...Component?.layoutProps}>
                                  <ParentComponent>
                                    <ImpersonatedBanner />
                                    <Component />
                                    <LazyURLModal />
                                    {!isPublicEnvironment() && (
                                      <RemoteControlPanel
                                        onGlobalForceUpdate={this.handleGlobalForceUpdate}
                                      />
                                    )}
                                  </ParentComponent>
                                </ComponentLayout>
                              </UserSettingsFavoritesProvider>
                              <ConfirmEmailModal />
                            </AccessTokenProvider>
                            <ReactQueryDevtools initialIsOpen={false} />
                          </PowerSearchContextProvider>
                        </WebSocketProvider>
                      </BrowserContextProvider>
                    </AppErrorBoundary>
                  </ThemeProvider>
                </EmployFeatureFlagProvider>
              </UserProvider>
            </QueryClientProvider>
          </ApiServiceProvider>
        </ContentSecurityPolicy>
      </>
    );
  }
}

export default Employ;
