import { Box, Stack } from '@remote-com/norma';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const ContentLayout = styled.section`
  background: ${({ theme }) => theme.colors.blank};
  border-radius: 10px;
  display: flex;
  flex-direction: ${({ $direction }) => ($direction === 'row' ? 'row' : 'column')};

  & + & {
    margin-top: 50px;
  }

  ${({ $space }) => {
    switch ($space) {
      case 'comfortable':
        return css`
          padding: 32px 26px 56px;
        `;
      case 'narrow':
        return css`
          padding: 25px 0 0;
        `;
      default:
        return css``;
    }
  }}
`;

ContentLayout.defaultProps = {
  $direction: 'row',
  $space: 'none',
};

ContentLayout.propTypes = {
  $direction: PropTypes.oneOf(['row', 'column']),
  $space: PropTypes.oneOf(['none', 'comfortable', 'narrow']),
};

export const ContentLayoutArea = styled(Box)`
  flex: 1;
  padding: var(--layoutSpacingY) var(--layoutSpacingX);
`;

export const ContentLayoutSingle = styled(ContentLayoutArea)`
  --layoutSpacingY: 34px;
  --layoutSpacingX: 26px;
`;

export const ContentLayoutMain = styled(ContentLayoutArea)`
  --layoutSpacingY: 56px;
  --layoutSpacingX: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    --layoutSpacingY: 56px;
    --layoutSpacingX: 40px;
  }
`;

export const ContentLayoutActions = styled(Box)`
  visibility: ${({ $invisible }) => ($invisible ? 'hidden' : 'visible')};
  padding: var(--layoutSpacingX) var(--layoutSpacingX);
  margin: calc(var(--layoutSpacingY) * -1) calc(var(--layoutSpacingX) * -1);
  margin-top: 0;
  background-color: ${({ theme }) => theme.colors.grey[50]};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    --spacingX: 28px;
    --spacingY: 24px;
  }
`;

export const ContentLayoutActionsButtons = styled(Box)`
  display: flex;
  visibility: ${({ $invisible }) => ($invisible ? 'hidden' : 'visible')};

  > * + * {
    margin-left: 16px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    --spacingX: 28px;
    --spacingY: 24px;

    margin-top: 16px;
  }
`;

export const ContentLayoutAside = styled.aside`
  flex: 1 0 358px;
  max-width: 358px;
  padding: 58px 46px;
  background-color: ${({ theme }) => theme.colors.background.subtle};
`;

// TODO: hotfix to minimise impact -> update with fully responsive design
export const ContentLayoutResponsive = styled(ContentLayout)`
  flex-wrap: wrap;
  ${ContentLayoutMain},
  ${ContentLayoutAside} {
    max-width: 100%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-wrap: nowrap;
    ${ContentLayoutAside} {
      max-width: 358px;
    }
  }
`;

export const ContentHeader = styled.header`
  padding: 34px 36px;

  ${({ showBorder, theme }) =>
    showBorder &&
    css`
      border-bottom: 1px solid ${theme.colors.grey[200]};
    `}
`;

ContentHeader.defaultProps = {
  showBorder: true,
};

ContentHeader.propTypes = {
  showBorder: PropTypes.bool,
};

export const ContentBody = styled.section`
  padding: 48px 40px;
`;

export const ContentFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.grey[50]};
  padding: 32px;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: ${({ alignment }) => {
    let value;
    switch (alignment) {
      case 'left':
        value = 'flex-start';
        break;
      case 'center':
        value = 'center';
        break;
      case 'right':
        value = 'flex-end';
        break;
    }

    return value;
  }};
  flex-direction: ${({ direction }) => direction};

  > * + * {
    margin-left: 16px;
  }
`;

ContentFooter.defaultProps = {
  alignment: 'center',
  direction: 'row',
};

ContentFooter.propTypes = {
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  direction: PropTypes.oneOf(['row', 'column']),
};

// TODO: this HeaderRow is the same as the HeaderTitle!!!!
export const HeaderRowStyled = styled(Box).attrs({
  alignItems: 'center',
  as: 'header',
  display: 'flex',
  justifyContent: 'space-between',
  mb: 5,
})`
  min-height: 50px; /* to have similar height, with or without cta */
`;

const HeaderRowChild = styled(Stack).attrs({
  alignItems: ['flex-start', 'center'],
  direction: ['column', 'row'],
  gap: 5,
  justifyContent: 'space-between',
})`
  width: 100%;
`;

/**
 * This component is getting deprecated in favor of PageHeader, avoid when possible.
 * Use the one in src/components/Ui/Layout/PageHeader.jsx
 * @deprecated use PageHeader instead
 */
export const HeaderRow = ({ children }) => {
  return (
    <HeaderRowStyled>
      <HeaderRowChild>{children}</HeaderRowChild>
    </HeaderRowStyled>
  );
};
