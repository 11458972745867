import type { PayrollRun } from '@/src/api/config/employ/payrollRun.types';
import { ADMIN_PAYROLL_RUN_REPORTS_ROUTE } from '@/src/constants/routes';

export function renameFileForBulkUpload(file: { name: string }, rename?: string) {
  if (!rename) return undefined;

  const fileExtension = file.name.split('.').length > 1 ? `.${file.name.split('.').pop()}` : '';

  return `${rename}${fileExtension}`;
}

export function getLatestGrossToNetReport(payrollRun: PayrollRun.AdminPayrollRunResponse['data']) {
  return payrollRun.latestReportOfEachType?.find((report) => report.type === 'gross_to_net_report');
}

export function buildPayrollRunReportPathname(
  payrollRunSlug: string,
  grossToNetFileSlug: string
): string {
  return ADMIN_PAYROLL_RUN_REPORTS_ROUTE.replace('[slug]', payrollRunSlug).replace(
    '[report-slug]',
    grossToNetFileSlug
  );
}
