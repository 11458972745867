import Router from 'next/router';

import {
  TEAM_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  DASHBOARD_ROUTE,
  ONBOARDING_COMPANY_ROUTE,
  ONBOARDING_FREELANCER_ROUTE,
  ONBOARDING_EMPLOYEE_TASKS_ROUTE,
  PENDING_TASKS_EMPLOYEE_TASKS_ROUTE,
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  SSO_SIGN_IN_PAGE,
  SIGN_UP_STEP_ROUTE,
  SETUP_2FA_ROUTE,
} from '@/src/constants/routes';
import {
  USER_TYPE,
  employeePersona as employeePersonaEnum,
} from '@/src/domains/registration/auth/constants';
import {
  isUserAbleToAccessDashboard,
  getUserRole,
  isEmployee,
  isSCA2FARequired,
} from '@/src/domains/registration/auth/helpers';
import { redirectToPath } from '@/src/helpers/general';
/**
 * Helper function to verify if the current page the user is trying to access is part of the
 * onboarding flow.
 * @param {Object} context
 */
export function isOnboardingPage(context) {
  return /^\/onboarding/.test(context.pathname);
}

export function isCompanyOnboardingPage(context) {
  return /^\/onboarding\/company/.test(context.pathname);
}

export function isSetup2FAPage(context) {
  return /\/setup-2fa/.test(context.pathname);
}

export function isEmployeeOnboardingPage(context) {
  return /^\/onboarding\/employee/.test(context.pathname);
}

export function isHandbookPage(context) {
  return /^\/dashboard\/handbook/.test(context.pathname);
}

/**
 * Returns true if it is the onboarding tasks list page
 * @param {Object} context
 */
export function isOnboardingTasksListPage(context) {
  return isOnboardingPage(context) && context.pathname.includes('/tasks');
}

function isPendingTasksPage(context) {
  return context.pathname.includes('/pending-tasks');
}

/**
 * Helper function to verify if the current page the user is trying to access is a success page
 * @param {Object} context
 */
export function isSuccessPage(context) {
  return context.pathname.includes('/success');
}

function isAuthenticationPage(context) {
  return [SIGN_IN_ROUTE, SIGN_UP_ROUTE, SSO_SIGN_IN_PAGE, SIGN_UP_STEP_ROUTE].includes(
    context.pathname
  );
}

export function getUserOnboardingPage(user) {
  const { role, employeePersona } = getUserRole(user);
  const { activeEmployment } = user;

  if (employeePersona === employeePersonaEnum.FREELANCER) {
    return ONBOARDING_FREELANCER_ROUTE;
  }

  if (role === USER_TYPE.EMPLOYEE) {
    return ONBOARDING_EMPLOYEE_TASKS_ROUTE.replace('[employmentSlug]', activeEmployment.slug);
  }

  if (role === USER_TYPE.EMPLOYER) {
    return ONBOARDING_COMPANY_ROUTE;
  }

  if (role === USER_TYPE.CANDIDATE) {
    // Candidates have no onboarding page
    return DASHBOARD_ROUTE;
  }

  return null;
}

export function getDashboardRedirect(user = {}) {
  switch (user.role) {
    case USER_TYPE.EMPLOYEE:
      return DASHBOARD_ROUTE;
    case USER_TYPE.FREELANCER:
      return DASHBOARD_ROUTE;
    case USER_TYPE.CANDIDATE:
      return DASHBOARD_ROUTE;
    case USER_TYPE.SERVICE_PROVIDER:
      return TEAM_ROUTE;
    case USER_TYPE.ADMIN:
      return ADMIN_DASHBOARD_ROUTE;
    case USER_TYPE.EMPLOYER:
      return DASHBOARD_ROUTE;
  }

  return null;
}

async function getUserPendingTasksPage({ user, tasks }) {
  const { role, activeEmployment } = user;
  const isSingleTask = tasks.length === 1;

  switch (role) {
    case USER_TYPE.EMPLOYEE: {
      // These two imports need to be lazy loaded to avoid loading 'src/domains/bankAccounts/constants/index.js' barrel file at build time.
      // Otherwise all bankAccounts constants will be part of _app.js bundle, which is unnecessary.
      const [tasksConstants, tasksHelpers] = await Promise.all([
        import('@/src/domains/tasks/constants'),
        import('@/src/domains/tasks/helpers'),
      ]);
      const { taskTypes } = tasksConstants;
      const { getTaskRoute } = tasksHelpers;

      return isSingleTask
        ? getTaskRoute(tasks[0], taskTypes.PENDING_TASK).replace(
            '[employmentSlug]',
            activeEmployment.slug
          )
        : PENDING_TASKS_EMPLOYEE_TASKS_ROUTE.replace('[employmentSlug]', activeEmployment.slug);
    }
    default:
      return null;
  }
}

/**
 * This function relies on a set of conditions to return the correct URL.
 * @param {Object} user
 * @param {Object} context
 */
export function getUserDestination({ user, context = { pathname: Router.pathname }, tasks }) {
  // onboarded user
  if (isUserAbleToAccessDashboard(user)) {
    // user has incomplete tasks
    const hasIncompleteTasks = tasks && tasks.filter((task) => task.required).length > 0;

    if (hasIncompleteTasks) {
      if (isPendingTasksPage(context)) return null; // don't redirect, the user is there
      return getUserPendingTasksPage({ user, tasks });
    }

    // user is on /sign-in, /sign-up or onboarding page
    const shouldRedirect =
      (isAuthenticationPage(context) ||
        isCompanyOnboardingPage(context) ||
        isEmployeeOnboardingPage(context)) &&
      !isSuccessPage(context);

    if (shouldRedirect) {
      return getDashboardRedirect(user);
    }
    // not onboarded, and not on the onboarding or handbook pages
    // handbook page is only visible to employees
  } else if (
    (!isOnboardingPage(context) && !isHandbookPage(context)) ||
    (!isEmployee(user) && isHandbookPage(context))
  ) {
    // If we are already on setup2fa screen, we escape the redirect function logic
    if (isSetup2FAPage(context)) {
      return null;
    }

    // (@IAM-Team) If user is SCA required user we do not allow them to proceed before they have 2FA setup
    const userShouldSetup2FA = !user?.totp?.enabled && isSCA2FARequired(user);
    if (userShouldSetup2FA) {
      return SETUP_2FA_ROUTE;
    }

    return getUserOnboardingPage(user);
  }

  return null;
}

/**
 * Verify if the user should be redirected, composed by specific helpers to get the correct
 * next URL. If no URL is returned, the user is not redirected.
 * @param {Object} user
 * @param {Object} context
 */
export async function redirectUser({ user, context, tasks, userProfiles }) {
  const redirectDestination = await getUserDestination({ user, context, tasks, userProfiles });

  if (redirectDestination) {
    redirectToPath(redirectDestination);
  }
}

/**
 * If the redirect query param is set, this function gets the url.
 * @param {Object} params
 * @param {Object} params.url
 * @param {Object} params.context
 */
export function getRedirectPathFromUrl({ url, asPath }) {
  const [redirectPathname, redirectQuery = ''] = url.split('?');
  const redirectQueryParams = new URLSearchParams(redirectQuery);

  const asPathQuery = window.location.search ? asPath + window.location.search : asPath;
  redirectQueryParams.set('redirect', asPathQuery);

  return `${redirectPathname}?${redirectQueryParams.toString()}`;
}
