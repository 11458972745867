export const ZAF_BANK_CODES = [
  { value: '632005', label: 'Absa Bank' },
  { value: '470010', label: 'Capitec Bank Limited (Personal accounts)' },
  { value: '679000', label: 'Discovery Bank Limited' },
  { value: '250655', label: 'First National Bank' },
  { value: '580105', label: 'Investec Bank Limited' },
  { value: '198765', label: 'Nedbank' },
  { value: '051001', label: 'Standard Bank' },
  { value: '678910', label: 'Tyme Bank' },
];
