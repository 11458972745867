import { borderRadius } from './borderRadius';
import { breakpoints, breakpointProperties, breakpointValues } from './breakpoints';
import { colors, colorsDark } from './colors';
import { space } from './space';
import { typography } from './typography';
import { theme } from './v1/theme';

export const themeV2 = {
  ...theme,
  version: 'v2',
  borderRadius,
  radii: borderRadius,
  borderWidths: {} as Record<string | number, string>,
  breakpoints,
  breakpointProperties,
  breakpointValues,
  space,
  colors,
  typography,
};

export const themeV2Dark = {
  ...themeV2,
  colors: colorsDark,
};

export type Theme = typeof themeV2;
