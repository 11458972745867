import { IconMinusCircle } from '@remote-com/norma/icons/IconMinusCircle';
import PropTypes from 'prop-types';

import {
  ChildWrapper,
  Legend,
  LegendLabel,
  LegendInnerContainer,
  Body,
  CircleIconButton,
} from './FieldGroupArray.styled';

const FieldGroupChildrenFieldset = ({
  children,
  label,
  index,
  onRemoveChild,
  shouldHideRemoveAction,
  ...props
}) => {
  // This is necessary so the text don't wrap on white space
  const formattedLabel = label.replace(/ /g, '\u00a0');
  const hasRemoveAction = Boolean(onRemoveChild);
  return (
    <ChildWrapper id={props.name} {...props}>
      <Legend hasRemoveAction={hasRemoveAction} shouldHideRemoveAction={shouldHideRemoveAction}>
        <LegendInnerContainer>
          <LegendLabel>{formattedLabel}</LegendLabel>

          {hasRemoveAction && !shouldHideRemoveAction && (
            <CircleIconButton group onClick={onRemoveChild} Icon={IconMinusCircle} index={index} />
          )}
        </LegendInnerContainer>
      </Legend>

      <Body>{children}</Body>
    </ChildWrapper>
  );
};

export default FieldGroupChildrenFieldset;

FieldGroupChildrenFieldset.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemoveChild: PropTypes.func,
};
