export const usCountryCode = 'USA';
export const puertoRicoCountryCode = 'PRI';
export const ukCountryCode = 'GBR';
export const canadaCountryCode = 'CAN';
export const mexicoCountryCode = 'MEX';
export const moroccoCountryCode = 'MAR';
export const southAfricaCountryCode = 'ZAF';
export const australiaCountryCode = 'AUS';
export const colombiaCountryCode = 'COL';
export const switzerlandCountryCode = 'CHE';
export const argentinaCountryCode = 'ARG';
export const newZealandCountryCode = 'NZL';
export const philippinesCountryCode = 'PHL';
export const brazilCountryCode = 'BRA';
export const japanCountryCode = 'JPN';
export const kyrgyzstanCountryCode = 'KGZ';
export const lithuaniaCountryCode = 'LTU';
export const indiaCountryCode = 'IND';
export const paraguayCountryCode = 'PRY';
export const ukraineCountryCode = 'UKR';
export const pakistanCountryCode = 'PAK';
export const irelandCountryCode = 'IRL';
export const southKoreaCountryCode = 'KOR';
export const spainCountryCode = 'ESP';
export const italyCountryCode = 'ITA';
export const nigeriaCountryCode = 'NGA';
export const netherlandsCountryCode = 'NLD';
export const panamaCountryCode = 'PAN';
export const ugandaCountryCode = 'UGA';
export const unitedArabEmiratesCountryCode = 'ARE';
export const tunisiaCountryCode = 'TUN';
export const jamaicaCountryCode = 'JAM';
export const thailandCountryCode = 'THA';
export const zimbabweCountryCode = 'ZWE';
export const germanyCountryCode = 'DEU';
