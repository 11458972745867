import styled, { css } from 'styled-components';

import { Box } from '../../layout';
import { ButtonIcon } from '../button';
import { ButtonInlineStyles } from '../button/Button.styled';
import { Text } from '../text';

import type { FeedbackMessageVariant } from './variants';
import {
  FEEDBACK_MESSAGE_ERROR,
  FEEDBACK_MESSAGE_WARNING,
  FEEDBACK_MESSAGE_NEUTRAL,
  FEEDBACK_MESSAGE_SUCCESS,
} from './variants';

export const Title = styled(Text).attrs({
  variant: 'smMedium',
  mb: 2,
})`
  display: block;
`;

type StyledFeedbackMessageProps = {
  variant: FeedbackMessageVariant;
};

export const FeedbackMessage = styled(Box)<StyledFeedbackMessageProps>`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 1px solid;
  text-align: left;

  ${({ variant, theme: { colors } }) => {
    switch (variant) {
      case FEEDBACK_MESSAGE_ERROR: {
        return css`
          --iconFill: ${colors.negative.foreground};
          background-color: ${colors.negative.background};
          border-color: ${colors.negative.border};
        `;
      }

      case FEEDBACK_MESSAGE_WARNING: {
        return css`
          --iconFill: ${colors.yellow[700]};
          background-color: ${colors.attention.background};
          border-color: ${colors.attention.border};
        `;
      }

      case FEEDBACK_MESSAGE_NEUTRAL: {
        return css`
          --iconFill: ${colors.grey[800]};
          background-color: ${colors.neutral.background};
          border-color: ${colors.neutral.border};
        `;
      }

      case FEEDBACK_MESSAGE_SUCCESS: {
        return css`
          --iconFill: ${colors.positive.foreground};
          background-color: ${colors.positive.background};
          border-color: ${colors.positive.border};
        `;
      }

      // case INFO: matches default
      default: {
        return css`
          --iconFill: ${colors.informative.foreground};
          background-color: ${colors.informative.background};
          border-color: ${colors.informative.border};
        `;
      }
    }
  }}
`;

export const IconContainer = styled(Box).attrs({ as: 'span' })`
  width: 20px;
  height: 20px;
  color: var(--iconFill);
`;

type StyledTextContainerProps = {
  $hasTitle: boolean;
};

export const TextContainer = styled(Text).attrs(({ $hasTitle }: StyledTextContainerProps) => ({
  color: 'grey.700',
  variant: $hasTitle ? 'xs' : 'sm',
  $tone: 'default' as const,
  $variant: 'default' as const,
}))<StyledTextContainerProps>`
  word-break: break-word;
  align-self: center;
  white-space: pre-line;

  overflow: hidden;

  a {
    ${ButtonInlineStyles}
  }

  strong {
    font-weight: 500;
  }
`;

export const DismissButton = styled(ButtonIcon)`
  align-self: flex-start;

  &:active {
    span {
      border: 1px solid ${({ theme }) => theme.colors.primary};
      box-shadow: 0px 0px 0px 2px rgba(0, 97, 255, 0.2);
    }
  }
`;
