import type { CompensationWageType } from '@/src/api/config/employ/compensation.types';
import { COMPENSATION_WAGE_TYPES_LABELS } from '@/src/api/config/employ/compensation.types';
import { friendlyLabel } from '@/src/helpers/general';

import type { PayrollCycle, PayrollCycleStatus } from '../payroll/PayrollCycles/constants';
import {
  PAYROLL_CYCLE_STATUS_LABELS,
  PAYROLL_CYCLES_LABELS,
  PAYROLL_CYCLES_LONG_DESCRIPTIONS,
  PAYROLL_CYCLES_SHORT_DESCRIPTIONS,
} from '../payroll/PayrollCycles/constants';

export const getPayrollCycleLabel = (payrollCycle?: PayrollCycle) => {
  return (payrollCycle && PAYROLL_CYCLES_LABELS[payrollCycle]) || friendlyLabel(payrollCycle);
};

export const getPayrollCycleShortDescription = (payrollCycle?: PayrollCycle) => {
  return (
    (payrollCycle && PAYROLL_CYCLES_SHORT_DESCRIPTIONS[payrollCycle]) || friendlyLabel(payrollCycle)
  );
};

export const getPayrollCycleLongDescription = (payrollCycle?: PayrollCycle) => {
  return (
    (payrollCycle && PAYROLL_CYCLES_LONG_DESCRIPTIONS[payrollCycle]) || friendlyLabel(payrollCycle)
  );
};

export const getPayrollCycleStatusLabel = (payrollCycleStatus?: PayrollCycleStatus) => {
  return (
    (payrollCycleStatus && PAYROLL_CYCLE_STATUS_LABELS[payrollCycleStatus]) ||
    friendlyLabel(payrollCycleStatus)
  );
};

export const getWageTypeDescription = (wageType?: CompensationWageType) => {
  return (wageType && COMPENSATION_WAGE_TYPES_LABELS[wageType]) || friendlyLabel(wageType);
};

export const getOvertimeEligibleDescription = (
  overtimeEligible?: boolean | `${boolean}` | null
) => {
  switch (overtimeEligible) {
    case true:
    case 'true':
      return 'Eligible for statutory overtime';
    case false:
    case 'false':
      return 'No statutory overtime';
    default:
      return friendlyLabel(overtimeEligible);
  }
};
