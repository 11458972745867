import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

import { useUser } from '@/src/components/UserProvider/context';
import {
  ADMIN_BILLING_ROUTE,
  ADMIN_COMPANIES_PROFILE_ROUTE,
  BILLING_ROUTE,
  PAYMENT_REQUESTS_PREFUNDING_ROUTE,
} from '@/src/constants/routes';
import { billingTabs } from '@/src/domains/billing/constants';
import { useBillingDocumentsTableStorageKey } from '@/src/domains/billing/shared/hooks/useBillingTableStorageKey';
import { getFormattedURL } from '@/src/helpers/general';
import { getLocalStorageItem } from '@/src/helpers/localStorage';

import { useInvoiceTableStorageKey } from './useInvoiceTableStorageKey';

const adminRoute = {
  billing: ADMIN_BILLING_ROUTE,
  companyScopedBilling: ADMIN_COMPANIES_PROFILE_ROUTE,
};

export const useInvoiceDetailsPreviousPage = () => {
  const router = useRouter();

  // We use referrer query param to determine if user came from prefunding page
  const searchParams = useSearchParams();
  const isPrefundingReferrer = searchParams?.get('referrer') === 'prefunding';

  const { userIsAdmin } = useUser();

  const isCompanyScoped = userIsAdmin && router.query.company === 'true';

  const invoiceTableStorageKey = useInvoiceTableStorageKey();
  const billingDocumentsTableStorageKey = useBillingDocumentsTableStorageKey({ isCompanyScoped });

  const invoicesTableState = getLocalStorageItem(invoiceTableStorageKey, {});
  const billingDocumentsTableState = getLocalStorageItem(billingDocumentsTableStorageKey, {});

  if (isCompanyScoped) {
    const { company, ...companyScopedBillingTableState } = billingDocumentsTableState;

    const formattedURL = getFormattedURL(
      adminRoute.companyScopedBilling,
      {
        '[slug]': company,
      },
      {
        ...companyScopedBillingTableState,
        selectedTab: 'billing',
      }
    ) as string;

    return {
      hrefTitle: isPrefundingReferrer ? 'Back to prepaid funds' : 'Back to billing',
      href: isPrefundingReferrer
        ? getFormattedURL(PAYMENT_REQUESTS_PREFUNDING_ROUTE)
        : formattedURL,
    };
  }

  if (userIsAdmin) {
    /**
     * User reached details page through `Customers invoice (old)` tab
     */
    const hasCustomerInvoicesQueryParam = router.query.customerInvoices === 'true';

    return hasCustomerInvoicesQueryParam
      ? {
          hrefTitle: 'Back to invoices',
          href: getFormattedURL(
            adminRoute.billing,
            {},
            { ...invoicesTableState, selectedTab: billingTabs.CUSTOMER_INVOICES }
          ) as string,
        }
      : {
          hrefTitle: 'Back to billing',
          href: getFormattedURL(
            adminRoute.billing,
            {},
            { ...billingDocumentsTableState, selectedTab: billingTabs.BILLING }
          ) as string,
        };
  }

  return {
    hrefTitle: isPrefundingReferrer ? 'Back to prepaid funds' : 'Back to billing',
    href: isPrefundingReferrer
      ? getFormattedURL(PAYMENT_REQUESTS_PREFUNDING_ROUTE)
      : (getFormattedURL(BILLING_ROUTE, {}, invoicesTableState) as string),
  };
};
