import isEqual from 'lodash/isEqual';
import { useCallback, useMemo } from 'react';

import { TableControlItemUi, type TableControlItemUiProps } from './TableControlItemUi';
import type { TableControlItemConfig, TableStateControlProps } from './types';

export interface TableControlItemProps extends TableControlItemConfig, TableStateControlProps {}

export const TableControlItem = ({
  count,
  isLoading = false,
  filtersToApply,
  sortBy,
  tableState,
  setAllFilters,
  setSortBy,
  columns,
  renderCustomUiComponent,
  ...uiProps
}: TableControlItemProps) => {
  const isSelected = useMemo(
    () => isEqual(tableState.filters, filtersToApply),
    [tableState.filters, filtersToApply]
  );

  const onSelected = useCallback(() => {
    if (isSelected) {
      setAllFilters([]);

      if (sortBy) {
        setSortBy([]);
      }
    } else {
      setAllFilters(filtersToApply);

      if (sortBy) {
        setSortBy(sortBy);
      }
    }
  }, [filtersToApply, setAllFilters, setSortBy, sortBy, isSelected]);

  const controlProps = {
    ...uiProps,
    isLoading,
    count,
    isSelected,
    onSelected,
  } satisfies TableControlItemUiProps;

  if (renderCustomUiComponent) {
    return <>{renderCustomUiComponent(controlProps)}</>;
  }

  return <TableControlItemUi {...controlProps} />;
};
