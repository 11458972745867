export const OrderedListIcon = () => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 2C4.94772 2 4.5 2.44772 4.5 3C4.5 3.55228 4.94772 4 5.5 4H17C17.5523 4 18 3.55228 18 3C18 2.44772 17.5523 2 17 2H5.5ZM4.5 13C4.5 13.5523 4.94772 14 5.5 14H17C17.5523 14 18 13.5523 18 13C18 12.4477 17.5523 12 17 12H5.5C4.94772 12 4.5 12.4477 4.5 13ZM4.5 8C4.5 8.55228 4.94772 9 5.5 9H17C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7H5.5C4.94772 7 4.5 7.44772 4.5 8Z"
      fill="#525F7F"
    />
    <path
      d="M1.17184 1.98884C1.3656 2.19378 1.62442 2.4145 1.62442 2.69653V5.39082C1.62442 5.73812 1.92011 6 2.31234 6C2.69799 6 3 5.73241 3 5.39082V0.602106C3 0.264524 2.71081 0 2.34165 0C2.01692 0 1.85447 0.208516 1.80112 0.277056C1.7989 0.279954 1.79667 0.282852 1.79444 0.285921L1.21322 1.07293C1.10037 1.1904 1 1.37829 1 1.5585C0.999957 1.72472 1.06528 1.87612 1.17184 1.98884Z"
      fill="#525F7F"
    />
    <path
      d="M0.688252 15H3.36463C3.71494 15 4 14.6627 4 14.2482C4 13.8381 3.71503 13.5044 3.36463 13.5044H1.43352C1.42676 13.5044 1.42128 13.4989 1.42128 13.4922C1.42128 13.2689 1.88983 12.9075 2.26635 12.6172C3.01531 12.0397 3.94739 11.321 3.94739 10.0746C3.94739 8.89182 3.1086 8 1.99622 8C0.931079 8 0.127967 8.80002 0.127967 9.86092C0.127967 10.4766 0.494664 10.6951 0.808665 10.6951C1.25809 10.6951 1.52668 10.3294 1.52668 9.97599C1.52668 9.75629 1.57838 9.50407 1.98121 9.50407C2.52538 9.50407 2.54104 10.031 2.54104 10.0911C2.54104 10.5647 1.97857 11.0074 1.43457 11.4356C0.762156 11.9648 1.74499e-08 12.5647 1.74499e-08 13.4923V14.2892C-9.09971e-05 14.7144 0.355867 15 0.688252 15Z"
      fill="#525F7F"
    />
  </svg>
);

export const BulletListIcon = () => (
  <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 5.5C0.7 5.5 0 6.2 0 7C0 7.8 0.7 8.5 1.5 8.5C2.3 8.5 3 7.8 3 7C3 6.2 2.3 5.5 1.5 5.5ZM1.5 0.5C0.7 0.5 0 1.2 0 2C0 2.8 0.7 3.5 1.5 3.5C2.3 3.5 3 2.8 3 2C3 1.2 2.3 0.5 1.5 0.5ZM1.5 10.5C0.7 10.5 0 11.2 0 12C0 12.8 0.7 13.5 1.5 13.5C2.3 13.5 3 12.8 3 12C3 11.2 2.3 10.5 1.5 10.5ZM6 1C5.44772 1 5 1.44772 5 2C5 2.55228 5.44772 3 6 3H17.5C18.0523 3 18.5 2.55228 18.5 2C18.5 1.44772 18.0523 1 17.5 1H6ZM5 12C5 12.5523 5.44772 13 6 13H17.5C18.0523 13 18.5 12.5523 18.5 12C18.5 11.4477 18.0523 11 17.5 11H6C5.44772 11 5 11.4477 5 12ZM5 7C5 7.55228 5.44772 8 6 8H17.5C18.0523 8 18.5 7.55228 18.5 7C18.5 6.44772 18.0523 6 17.5 6H6C5.44772 6 5 6.44772 5 7Z"
      fill="#525F7F"
    />
  </svg>
);

export const H1Icon = () => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0759943 11H2.0522V6.36577H7.10724V11H9.08878V0.0909088H7.10724V4.70916H2.0522V0.0909088H0.0759943V11ZM15.5687 0.0909088H13.6884L10.9771 1.83274V3.64382L13.5286 2.01385H13.5925V11H15.5687V0.0909088Z"
      fill="#525F7F"
    />
  </svg>
);

export const H2Icon = () => (
  <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0759943 12H2.0522V7.36577H7.10724V12H9.08878V1.09091H7.10724V5.70916H2.0522V1.09091H0.0759943V12ZM11.1316 12H18.6103V10.3487H13.8588V10.2741L15.7392 8.36186C17.8592 6.32706 18.4451 5.33629 18.4451 4.10582C18.4451 2.27876 16.959 0.941761 14.7644 0.941761C12.6017 0.941761 11.0676 2.28409 11.0676 4.35618H12.948C12.948 3.2429 13.6511 2.5451 14.7377 2.5451C15.7765 2.5451 16.5488 3.17898 16.5488 4.20703C16.5488 5.1179 15.9949 5.76776 14.9189 6.85973L11.1316 10.5724V12Z"
      fill="#525F7F"
    />
  </svg>
);

export const H3Icon = () => (
  <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0759943 12H2.0522V7.36577H7.10724V12H9.08878V1.09091H7.10724V5.70916H2.0522V1.09091H0.0759943V12ZM15.0467 12.1491C17.3798 12.1491 19.079 10.8121 19.0737 8.9691C19.079 7.60547 18.2267 6.62536 16.698 6.40696V6.32173C17.8805 6.06605 18.6742 5.18714 18.6689 3.962C18.6742 2.30007 17.2573 0.941761 15.0787 0.941761C12.9586 0.941761 11.318 2.20419 11.2754 4.03125H13.177C13.209 3.11506 14.0613 2.5451 15.068 2.5451C16.0854 2.5451 16.7619 3.163 16.7566 4.07919C16.7619 5.03267 15.9735 5.66655 14.8443 5.66655H13.8801V7.18999H14.8443C16.2239 7.18999 17.0442 7.88246 17.0389 8.8679C17.0442 9.83203 16.2079 10.4925 15.0414 10.4925C13.9441 10.4925 13.0971 9.92259 13.0492 9.03303H11.0463C11.0996 10.8761 12.7456 12.1491 15.0467 12.1491Z"
      fill="#525F7F"
    />
  </svg>
);
