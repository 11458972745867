import camelcaseKeys from 'camelcase-keys';
import { addDays, isBefore, isWithinInterval } from 'date-fns';
import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import has from 'lodash/has';
import identity from 'lodash/identity';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import snakeCase from 'lodash/snakeCase';
import some from 'lodash/some';
import transform from 'lodash/transform';
import truncate from 'lodash/truncate';
import getConfig from 'next/config';
import Router from 'next/router';
import { isValidElement } from 'react';

import { captureException } from '@/src/helpers/captureException';
import { stringifyQuery } from '@/src/helpers/queryParser';

// Leave `process.env.NODE_ENV` without destructuring as this way
// it is going to be replaced with a static string in browser builds.
// eslint-disable-next-line prefer-destructuring
const NODE_ENV = process.env.NODE_ENV;

const {
  publicRuntimeConfig: { BASE_URL, REMOTE_JOBS_BASE_URL, ENVIRONMENT },
} = getConfig();

export const friendlyPlaceholderDash = '—';

/**
 * Read JSON from blob exception response.
 * @param {Blob} blob
 */
export async function convertBlobToJson(blob) {
  if (!(blob instanceof Blob)) {
    return {};
  }

  let jsonValue;
  try {
    jsonValue = JSON.parse(await blob.text());
  } catch {
    jsonValue = {};
  }

  return jsonValue;
}

export const defaultErrorMessage = 'Something went wrong';

/**
 * Extract message and errors from exception object
 * Most commonly used when handling an exception thrown from an axios request
 *
 * @param {unknown} exception
 * @returns {{ message: string; errors: any }} message and errors present in the exception
 */
export function getExceptionInfo(exception, { defaultMessage = defaultErrorMessage } = {}) {
  const { message = defaultMessage, errors = {} } =
    (exception?.response || {}).data || (exception?.message ? exception : {}) || {};

  return {
    message,
    errors,
  };
}

/**
 * Given a server fieldName, check if it is part from an array, and return the main fieldName
 * This is used for JSON Schemas multi-select fields
 * Created on Slack thread https://remote-com.slack.com/archives/CFEH4JTSM/p1666275829430289?thread_ts=1666274121.978839&cid=CFEH4JTSM
 * @examples
 * "foo/1" -> "foo"
 * "foo/bar/2" -> "foo/bar"
 * "foo/1/bar" -> "foo/1/bar"
 * More examples in the test file
 */
export function excludeFieldIndex(fieldName) {
  if (!fieldName) return fieldName;
  const matchArr = fieldName.match(/(?:(.+)\/\d+)$/);
  return matchArr ? matchArr[1] : fieldName;
}

/**
 * Transforms JSON Schema fieldset path into Formik version.
 * @param {String} name input name
 * @param {Boolean} toSnakeCase - Should transform names to snake_case.
 * @returns {String}
 * Why: JSON Schema errors for fieldsets are separated by '/' while Formik uses '.' for the same purpose.
 * To match the field name and to avoid invalid selectors (unescaped '/' are not allowed)
 * we need to replace the separator before using the name.
 * @example
 * 'foo/bar' -> foo.bar
 */
function getFormikFieldName(name, toSnakeCase) {
  return name
    .split('/')
    .map(toSnakeCase ? snakeCase : identity)
    .join('.');
}

/**
 As applyFormikFieldErrors does not have access to the form's "fields",
 we cannot map the error's name to the field's config.
 So this is "guess" based on JSON Schemas error specs:
 TODO consider "humanizing" these errors on Backend instead. MR!11559
 */
function getErrorFromJSONSchemas(fieldName, fieldErrorsList) {
  if (fieldErrorsList.length >= 2) {
    /* This is a radio/select field where the value sent was not valid.
    @example
      - payload: { has_car: 'maybe' }
      - error: { has_car: ['Expected data to be "yes".', 'Expected data to be "no".'] }
    */
    const isSoloOption = fieldErrorsList.some((val) => val.includes?.('Expected data to be '));

    return isSoloOption
      ? {
          fieldName: snakeCase(fieldName),
          errorText: 'The selected option is no longer valid.',
        }
      : false;
  }

  if (fieldErrorsList.length === 1) {
    /* This is a multi-select field where one of the options is invalid.
       In multi-selects the /1 targets which index of the array is invalid, and the message is always the same.
    @example
      - payload: { nationality: ['PRT', 'foo'] }
      - error: { "nationality/1": ["Expected any of the schemata to match but none did."] }
     */
    const isMultiOption =
      fieldErrorsList[0] === 'Expected any of the schemata to match but none did.';

    return isMultiOption
      ? {
          fieldName: getFormikFieldName(excludeFieldIndex(fieldName), true),
          errorText: 'One of the options is no longer valid.',
        }
      : false;
  }

  return false;
}

/**
 * Processes the error fieldset map and returns a new mapped errors object.
 *
 * @param {Object} mappedErrors - The original mapped errors object.
 * @param {Object} errorFieldsetMap - The map defining how to transform the errors.
 * @param {string} errorFieldsetMap.apiKey - The key in the original errors object.
 * @param {Object} errorFieldsetMap.formKey - The key in the new errors object.
 * @param {Array<string>} errorFieldsetMap.fields - The fields to include in the new errors object.
 * @param {string} errorFieldsetMap.excludeErrorKey - The key to exclude from the new errors object.
 * @returns {Object} The new mapped errors object with the transformations applied.
 */
function processErrorFieldsetMap(mappedErrors, errorFieldsetMap) {
  const newMappedErrors = { ...mappedErrors };

  Object.entries(errorFieldsetMap).forEach(([apiKey, { formKey, fields }]) => {
    const errorValue = get(newMappedErrors, apiKey);

    if (errorValue) {
      fields.forEach((field) => {
        if (!newMappedErrors[formKey]) {
          newMappedErrors[formKey] = [];
        }

        const existingErrorIndex = newMappedErrors[formKey].findIndex(
          (err) => Object.keys(err)[0] === field
        );

        if (existingErrorIndex === -1) {
          newMappedErrors[formKey].push({ [field]: errorValue });
        }
      });
    }
  });

  // Clean up original error keys after all mappings are done
  Object.values(errorFieldsetMap).forEach(({ excludeErrorKey }) => {
    if (excludeErrorKey) {
      delete newMappedErrors[excludeErrorKey];
    }
  });

  return newMappedErrors;
}

/**
 * Processes the error field map and returns a new mapped errors object.
 *
 * @param {Object} mappedErrors - The original mapped errors object.
 * @param {Object} errorFieldMap - The map defining how to transform the errors.
 * @param {string} errorFieldMap.apiKey - The key in the original errors object.
 * @param {Object} errorFieldMap.formKey - The key in the new errors object.
 * @param {string} errorFieldMap.excludeErrorKey - The key to exclude from the new errors object.
 * @returns {Object} The new mapped errors object with the transformations applied.
 */
function processErrorFieldMap(mappedErrors, errorFieldMap) {
  const newMappedErrors = { ...mappedErrors };

  Object.entries(errorFieldMap).forEach(([apiKey, { formKey }]) => {
    const errorValue = get(newMappedErrors, apiKey);

    if (errorValue) {
      newMappedErrors[formKey] = errorValue;
    }
  });

  // Clean up original error keys after all mappings are done
  Object.values(errorFieldMap).forEach(({ excludeErrorKey }) => {
    if (excludeErrorKey) {
      delete newMappedErrors[excludeErrorKey];
    }
  });

  return newMappedErrors;
}

/**
 * Pushes API errors to formik form
 *
 * @param {Object} errors - Errors object as coming from an API request
 * @param {Function} setFieldError - formikBag setFieldError function
 * @param {Object} [errorFieldMap] - a map of the API response errors to the form fields
 * @param {string} errorFieldMap.apiKey - The key in the original errors object.
 * @param {Object} errorFieldMap.formKey - The key in the new errors object.
 * @param {string} errorFieldMap.excludeErrorKey - The key to exclude from the new errors object.
 * @param {Object} [errorFieldsetMap] - a map of the API response errors to form fieldset fields
 * @param {string} errorFieldsetMap.apiKey - The key in the original errors object.
 * @param {Object} errorFieldsetMap.formKey - The key in the new errors object.
 * @param {Array<string>} errorFieldsetMap.fields - The fields to include in the new errors object.
 * @param {string} errorFieldsetMap.excludeErrorKey - The key to exclude from the new errors object.
 */
export function applyFormikFieldErrors(errors, setFieldError, errorFieldMap, errorFieldsetMap) {
  let mappedErrors =
    errorFieldsetMap || errorFieldMap ? camelcaseKeys(errors, { deep: true }) : null;

  // This is temporary, a refactor will remove the need to map errors https://linear.app/remote/issue/WFP-684/refactor-manager-assignment-field-in-onboarding-flows-and-ee-profile
  if (errorFieldsetMap && mappedErrors) {
    mappedErrors = processErrorFieldsetMap(mappedErrors, errorFieldsetMap);
  }

  if (errorFieldMap && mappedErrors) {
    mappedErrors = processErrorFieldMap(mappedErrors, errorFieldMap);
  }

  function setErrorCameless(fieldName, errorText) {
    const formikName = getFormikFieldName(fieldName);
    const formikNameSnaked = getFormikFieldName(fieldName, true);

    setFieldError(formikName, errorText);
    if (formikName !== formikNameSnaked) {
      setFieldError(formikNameSnaked, errorText); // For JSON Schema fields
    }
  }

  const errorsObj =
    errorFieldsetMap || errorFieldMap ? mappedErrors : camelcaseKeys(errors, { deep: true });

  Object.entries(errorsObj).forEach(([fieldName, fieldError]) => {
    if (Array.isArray(fieldError)) {
      const jsonSchemaError = getErrorFromJSONSchemas(fieldName, fieldError);

      if (jsonSchemaError) {
        // Replace the verbose/robotic server error by a humanized version
        setFieldError(jsonSchemaError.fieldName, jsonSchemaError.errorText);
      } else if (fieldError.length === 2) {
        const errorText = fieldError.reduce((acc, cur) => `${acc} ${cur}`, '');
        setErrorCameless(fieldName, errorText);
      } else {
        setErrorCameless(fieldName, fieldError[fieldError.length - 1]);
      }
    } else {
      setErrorCameless(fieldName, fieldError);
    }
  });
}

/**
 * Extract message and errors from exception object
 * and pushes errors to formik form
 *
 * @param {unknown} exception
 * @param {Function} setFieldError - formikBag setFieldError function
 * @param {Object} [errorFieldMap] - a map of the API response errors to the form fields
 * @param {string} errorFieldMap.apiKey - The key in the original errors object.
 * @param {Object} errorFieldMap.formKey - The key in the new errors object.
 * @param {string} errorFieldMap.excludeErrorKey - The key to exclude from the new errors object.
 * @param {Object} [errorFieldsetMap] - a map of the API response errors to form fieldset fields
 * @param {string} errorFieldsetMap.apiKey - The key in the original errors object.
 * @param {Object} errorFieldsetMap.formKey - The key in the new errors object.
 * @param {Array<string>} errorFieldsetMap.fields - The fields to include in the new errors object.
 * @param {string} errorFieldsetMap.excludeErrorKey - The key to exclude from the new errors object.
 */
export function extractAndApplyFormikFieldErrors(
  exception,
  setFieldError,
  errorFieldMap,
  errorFieldsetMap
) {
  const { errors } = getExceptionInfo(exception);
  applyFormikFieldErrors(errors, setFieldError, errorFieldMap, errorFieldsetMap);
}

const ALLOWED_EXTERNAL_BASE_URLS = [REMOTE_JOBS_BASE_URL];

export function redirectToPath(toPath) {
  if (
    toPath &&
    BASE_URL &&
    ALLOWED_EXTERNAL_BASE_URLS.some((baseUrl) => toPath.startsWith(baseUrl))
  ) {
    // For browser - External links
    window.location = toPath;
  } else {
    // For browser- Internal links
    Router.push(toPath);
  }
}

/**
 * We have some use cases where we need to hide some properties from the API response to not be
 * rendered in HTML.
 *
 * This function will filter an object with key values from the keys provided as parameters
 * @param {Object} contentList content object to be rendered on the page
 * @param {Array} keys list of keys to be filtered from the main list
 */
export function removeFields(contentList, keys) {
  return Object.fromEntries(Object.entries(contentList).filter(([key]) => !keys.includes(key)));
}

/**
 * Verify if the parameter is null to return a predefined friendly label instead.
 * If the parameter is an object, it should not return itself because React does not render objects
 * @param {string|undefined|null} property property to be verified if is not null
 */
export function friendlyLabel(property, placeholder = friendlyPlaceholderDash) {
  if (property === undefined || property === null || property === '') {
    return placeholder;
  }
  return property;
}

/**
 * Gets a friendly indexed label from a label map, handling null and undefined values.
 *
 * @param {Record<string, string>} labelMap - The map containing index-label pairs.
 * @param {string | null | undefined} value - The value to be used as an index.
 * @param {string} [placeholder=friendlyPlaceholderDash] - The placeholder to use for null or undefined values.
 * @returns {string} - The friendly indexed label.
 */
export function friendlyIndexedLabel(labelMap, value, placeholder = friendlyPlaceholderDash) {
  if (value === undefined || value === null || value === '') {
    return placeholder;
  }
  return labelMap[value] || value;
}

/**
 * Verify if the parameter is null, undefined or empty string.
 * @param {string} [value=] value to be verified
 */
export function isNilOrEmptyString(value) {
  return value === undefined || value === null || value === '';
}

/**
 * Return a formatted string of the version of the file
 * @param {string} version document version
 */
export function getVersionLabel(version) {
  return version ? `[V${version}]` : '';
}

/**
 * Normalize list of items as options to a select element
 * @param {Array} list
 */
export function getOptionsFromList(list) {
  return (list || []).map((item) => ({
    value: item.slug,
    label: item.name,
  }));
}

/**
 * Generates static asset url including ASSET_PREFIX if exists
 * @param {import("@/src/constants/publicAssetPaths").PublicAssetPath} publicAssetPath
 */
export function staticAssetUrl(publicAssetPath) {
  const {
    publicRuntimeConfig: { ASSET_PREFIX = '' },
  } = getConfig() || {};
  // Check README.md for an explanation for getConfig usage

  return `${ASSET_PREFIX}${publicAssetPath}`;
}

/**
 * Format key to label
 *
 * Transforms a key into a friendly label
 * @param {String | null} key A string in kebab-case or snake_case
 * @example hello-world -> Hello World
 *  * @example hello_world -> Hello World
 */
export function friendlyKeyToLabel(key) {
  const keySafe = key || '';
  return capitalize(keySafe.replace(/[_-]/g, ' '));
}

/**
 * Format boolean value to Yes or No
 *
 * Transforms a boolean value into a friendly Yes or No
 * @param {Boolean*} value A boolean value
 * @param {Array} defaults Override the default values
 * @example friendlyBooleanToLabel(true) -> 'Yes'
 * @example friendlyBooleanToLabel(false) -> 'No'
 * @example friendlyBooleanToLabel(true, ['Oui', 'No']) -> 'Oui'
 */
export function friendlyBooleanToLabel(value, defaults = ['Yes', 'No']) {
  // We don't want a false negative
  if (value === undefined || value === null || value === '') {
    return null;
  }
  return value ? defaults[0] : defaults[1];
}

export function replaceDynamicPaths(str, map) {
  return str.replace(/\[.*?\]/g, (matched) => map[matched]);
}

export function isDev() {
  return NODE_ENV === 'development';
}

export function isTest() {
  return NODE_ENV === 'test';
}

export function isPublicEnvironment() {
  return ENVIRONMENT === 'production' || ENVIRONMENT === 'sandbox';
}

/**
 * console.log only in development
 */
function logIfDev() {
  // eslint-disable-next-line no-unused-vars
  let log = function log(message, ...optionalParams) {};

  if (console && isDev()) {
    /* eslint-disable no-console */
    log = console.log.bind(console);
    /* eslint-enable no-console */
  }

  return log;
}

export const debug = logIfDev();

/**
 * throw error on development
 * or capture the exception on Datadog if on a live env
 * @param {unknown} message - can be a string or an Error (caught by a try/catch)
 * @param {Object} options
 * @param {EngineeringTeamName[]} options.ownership - An array of engineering teams that are responsible for the error.
 */
export function error(exception, options = {}) {
  if (isDev() || isTest()) {
    if (exception instanceof Error) {
      throw exception;
    }

    throw new Error(exception);
  }

  captureException(exception, { logToConsole: isDev(), ...options });
}

export function throwError(exception) {
  if (NODE_ENV === 'production') {
    captureException(exception);
  }

  throw exception;
}

export function fromCamelCaseToSentence(word) {
  return word
    .replace(/([A-Z][a-z]+)/g, ' $1')
    .replace(/([A-Z]{2,})/g, ' $1')
    .replace(/\s{2,}/g, ' ')
    .trim();
}

/**
 * By default camelCase also strims dots. This alt keeps the dots.
 * Useful when accessing nested objects with lodash/get
 * @param {str} string
 * @example 'foo_bar' -> 'fooBar'
 * @example 'foo.bar_zero' -> foo.barZero
 * @returns String
 */
export function camelCaseKeepDots(str) {
  if (!str) return str;
  return str
    .split('.')
    .map((s) => camelCase(s))
    .join('.');
}

/**
 * Compare a given word to another in camelCase or snake_case
 * @param {String} word1
 * @param {String} word2
 * @returns {Boolean}
 * @example
 * dark_color, dark_color -> true
 * dark_color, darkColor -> true
 * darkColor, dark_color -> true
 */
export function matchWord(word1, word2) {
  return word1 === word2 || camelCase(word1) === camelCase(word2);
}

/**
 * Given an object and a key's name, it looks for it in the object, as snake_ase or camelCase
 * E.g. Support forms where the field keys are snake_case (JSON Schema forms)
 * @param {Object} obj
 * @param {String} key
 * @example ({ fav_color: 'black' }, 'favColor') -> 'black'
 * @example ({ favColor: 'black' }, 'fav_Color') -> 'black'
 * @example ({ favColor: 'black' }, 'favColor') -> 'black'
 * @example (undefined, 'favColor') -> undefined

 * @returns {(Array|Record<string, any>|undefined)} - key's value
 */
export function pickKey(obj, key) {
  if (!obj) {
    return undefined;
  }
  return obj[key] ?? obj[snakeCase(key)] ?? obj[camelCase(key)];
}

/**
 * Given an array of words, it extends the array with the respective snake_case or camelCase
 * @param {Array} arr
 * @example ['favColor'] -> ['favColor', 'fav_color']
 * @example ['fav_color'] -> ['favColor', 'fav_color']
 * @example null -> null
 * @returns {Array}
 */
export function arrayCaseless(arr) {
  if (!arr) {
    return arr;
  }
  return arr.flatMap((word) => [snakeCase(word), camelCase(word)]);
}

/**
 * A dummy Promise to take as long as you need.
 * @param {number} timeMs
 * @returns
 */
export function sleep(timeMs) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeMs);
  });
}

/**
 * loads a script dynamically
 */
export const loadScript = ({ scriptId, scriptSrc, callback, errorCallback }) => {
  const existingScript = document.getElementById(scriptId);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.id = scriptId;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };

    script.onerror = () => {
      if (errorCallback) errorCallback(new Error(`Failed to load script: ${scriptSrc}`));
    };
  }
  if (existingScript && callback) callback();
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

/**
 * Truncate multiline text following the rules:
 *   1. Only show one line
 *   2. Make sure that that line is no longer that 30 chars.
 * @param {string} value
 * @returns {string} truncated
 */
export const multilineTruncate = (value, truncateLength = 30) => {
  const omission = '…';
  if (isValidElement(value)) {
    return value;
  }
  const lines = value.split(/\n/);

  let preview = truncate(lines[0], {
    omission,
    length: truncateLength,
  });

  // text has multiple lines, but the first line is short
  if (lines.length > 1 && preview === lines[0]) {
    preview += omission;
  }

  return preview;
};

/**
 * Generate an integer between a range of numbers
 * @param {number} min
 * @param {number} max
 * @returns
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Join array in a nice way. For example, ['.pdf', '.doc', '.xls'] => ".pdf, .doc or .xls"
 * Similar to how Intl.ListFormat API works.
 * @param {Array} arr Array with of items that need to be joined
 * @param {Object} options
 * @returns
 */
export function joinArray(arr, { lastDelimiter }) {
  if (arr.length > 1) {
    const [lastType] = arr.splice(arr.length - 1);
    return `${arr.join(', ')} ${lastDelimiter} ${lastType}`;
  }

  return arr[0];
}

/**
 * Apply a function to all the values of an object
 * @param {Function} fn Function that will be applied to all values. It
 * receives the arguments (value, key).
 * @param {Object} object
 * @returns New object with the same keys as the original one but each value is
 * replaced by the return of the corresponding function evaluation.
 */
export function mapObjectValues(fn, object) {
  if (!object) {
    return {};
  }

  if (typeof fn !== 'function') {
    return object;
  }

  return Object.fromEntries(Object.entries(object).map(([key, value]) => [key, fn(value, key)]));
}

/**
 * Returns a boolean value that states if a release is within its release period or not.
 * The release period is set to be a specific number of days.
 * @param {releaseDate} Date Release date
 * @param {releaseDaysPeriod} number Number of days after the release date where it is within the release period
 * @returns Boolean
 */
export function isWithinReleasePeriod(releaseDate, releaseDaysPeriod) {
  const today = new Date();

  const finishPeriodDate = addDays(releaseDate, releaseDaysPeriod);

  return isWithinInterval(today, {
    start: releaseDate,
    end: finishPeriodDate,
  });
}

export const splitName = (name) => {
  const namesArray = name?.split(' ').filter(Boolean) || []; // remove empty spaces
  return {
    firstName: namesArray[0] || null,
    lastName: namesArray.length > 1 ? namesArray.slice(-1).join(' ') : null,
  };
};

export const isAllSameValue = ({ data = [], fieldPath }) =>
  data.length > 0 && data.every((item) => get(item, fieldPath) === get(data[0], fieldPath));

export const integerToOrdinalSuffix = (n) => {
  if (n > 3 && n < 21) return 'th';
  switch (n % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const integerToOrdinal = (n) => `${n}${integerToOrdinalSuffix(n)}`;

export const isOnMobileDevice = () => 'ontouchstart' in window.document.documentElement;

export const isOnClient = () => typeof window !== 'undefined';

export const isIosDevice = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

// From: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
export const isAndroidDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return true;
  }

  return false;
};

// Helper to read browser user-agent to determine if user is on macOS or not.
export const isUserOnMacOS = () => {
  // @TODO: This is a temporary solution to avoid SSR issues. Should be remove when migration to SPA is completed.
  if (typeof window === 'undefined') {
    return false;
  }
  return navigator.userAgent.indexOf('Macintosh') !== -1;
};

export const getCommandKeyShortcutAndLabel = () =>
  isUserOnMacOS() ? ['⌘', 'Meta'] : ['ctrl', 'Control'];

export const getFormattedURL = (urlToFormat, paramsMap, queriesMap) => {
  const queryParams = stringifyQuery(queriesMap, {
    skipNulls: true,
    skipEmptyStrings: true,
    skipCustomMatcher: (key, val) => key === 'pageIndex' && (val === 0 || val === '0'),
  });

  const formattedURL = replaceDynamicPaths(urlToFormat, paramsMap);
  return queryParams ? formattedURL.concat(`?${queryParams}`) : formattedURL;
};

/**
 * This omits empty and undefined values from a form payload
 *
 * We want to keep `null` values as it's a indication that the user
 * really wants to unset that field!
 */
export function omitEmptyValues(formValues) {
  return omitBy(formValues, (value) => isUndefined(value) || value === '');
}

/**
 * This omits undefined and null values from an object.
 *
 * @template T
 * @param {T} formValues - The object to clean
 * @returns {T} A new object with nil values omitted
 */
export function omitNilValues(formValues) {
  return omitBy(formValues, (value) => isNil(value));
}

/**
 * This omits empty strings, undefined and null values from given object
 *
 * @template T
 * @param {T} formValues - The object to clean
 * @returns {T} A new object with empty values omitted
 */
export function omitEmptyAndNilValues(formValues) {
  return omitBy(formValues, (value) => isNil(value) || isUndefined(value) || value === '');
}

/**
 * Returns the search params of an URL object as an object
 * @param {URL} url
 * @returns {object}
 */
export function urlSearchToObject(url) {
  const result = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of url.searchParams.entries()) {
    result[key] = value;
  }
  return result;
}

/**
 * Given an object, removes empty objects recursively (immutable)
 * @param {Object} obj
 * @returns {Object}
 * @example
 * { a:1, b:{}, c:{ z:1, b:{} } } -> { a:1, c: { z:1 } }
 * @link Stolen from https://stackoverflow.com/a/44808210/4737729
 */
export function cleanObject(obj) {
  if (!isObject(obj)) return obj;

  return transform(obj, (result, value, key) => {
    const isObj = isObject(value);
    const cleanedObj = isObj ? cleanObject(value) : value;

    if (isObj && isEmpty(cleanedObj)) {
      return;
    }

    if (isArray(result)) {
      result.push(cleanedObj);
    } else {
      result[key] = cleanedObj;
    }
  });
}

/**
 * Simple string variable interpolation. Given a template with a variable to
 * be interpolated (e.g. "Send email to {{COMPANY_EMAIL}}") and an object, it
 * returns a string with the interpolated variables.
 *
 * This is particularly useful for templates saved on CMS-like systems (such
 * as the KDB facts).
 *
 * We might look into a templating library later (or pre-compiling this on the
 * server), or at the very least pre-compiling it. So don't use it on large
 * strings for now.
 *
 * Lodash's template() doesn't work, as the eval use violates our CSP
 * policy.
 *
 * @param {string} template String with content to be interpolated as `{{MY_VARIABLE}}`
 * @param {Object} data Object with data to be interpolated
 */
export function interpolate(template, data) {
  return template.replace(/{{(.*?)}}/g, (match) => {
    if (!data) {
      return '';
    }
    return data[match.split(/{{|}}/).filter(Boolean)[0]?.trim()] || '';
  });
}

/**
 * Get the keys from an object, up to a max number of levels deep.
 *
 * @param {Object} obj
 * @param {number} maxLevels
 */
export function getShallowKeys(obj, maxLevels = 3) {
  function collectObjectKeys(subject, level) {
    const nextLevel = level - 1;

    return Object.entries(subject || {}).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: nextLevel === 1 ? Object.keys(value || {}) : collectObjectKeys(value, nextLevel),
      }),
      {}
    );
  }

  return collectObjectKeys(obj, maxLevels);
}

/**
 * Checks if the given object or any of its nested objects has the specified key.
 *
 * @param {Object} obj - The object to search for the key.
 * @param {string} searchKey - The key to search for.
 * @return {boolean} True if the key is found, false otherwise.
 */
export function deepHasKey(obj, searchKey) {
  if (has(obj, searchKey)) return true;

  return some(obj, (value) => {
    if (isObject(value)) {
      return deepHasKey(value, searchKey);
    }
    return false;
  });
}

export const getRandomArrayElement = (array) => array[Math.floor(Math.random() * array.length)];

/**
 * Returns different copy based on whether the start date is before or past the target date.
 *
 * E.g.: if 'startDate = 2024-01-01' and 'targetDate = 2024-01-15', return 'beforeCopy'.
 *
 * @param {Object} params
 * @param {Date} [params.startDate=new Date()] - The date to compare from. Defaults to today.
 * @param {Date} params.targetDate - The date to compare against.
 * @param {string} params.beforeCopy - The string to return if startDate is before targetDate.
 * @param {string} params.afterCopy - The string to return if startDate is at or past targetDate.
 * @return {string}
 */
export const getBeforeAfterCopy = ({
  startDate = new Date(),
  targetDate,
  beforeCopy,
  afterCopy,
}) => {
  if (isBefore(startDate, targetDate)) {
    return beforeCopy;
  }

  return afterCopy;
};

/**
 * Converts URLs in a text string to Markdown links.
 *
 * @param {string} text - The text to convert
 * @param {string} [placeholder=''] - The placeholder text to use for the link. Leave empty to use the URL as the placeholder.
 * @returns {string} The text with URLs converted to Markdown links
 */
export const convertUrlToMarkdownLinks = (text, placeholder = '') => {
  if (typeof text !== 'string') {
    return '';
  }

  const urlRegex = /https?:\/\/[^\s]+/g;
  return text.replace(urlRegex, (url) => {
    return `[${placeholder !== '' ? placeholder : url}](${url})`;
  });
};

/**
 * Appends query parameters to a URL.
 *
 * @param {string} url - The URL to append the query parameters to.
 * @param {Object} queries - The query parameters to append.
 * @returns {string} The URL with the appended query parameters.
 */
export const appendQueryParams = (url, queries) => {
  if (!url) {
    return '';
  }

  const isAbsoluteUrl = url.startsWith('http://') || url.startsWith('https://');
  const baseUrl = isAbsoluteUrl ? '' : 'http://example.com';
  const urlObj = new URL(isAbsoluteUrl ? url : `${baseUrl}${url}`);

  Object.entries(queries).forEach(([key, value]) => {
    urlObj.searchParams.set(key, value);
  });

  return isAbsoluteUrl ? urlObj.toString() : urlObj.pathname + urlObj.search;
};

/**
 * Removes HTML tags from a string.
 *
 * @param {string} text - The string to remove HTML tags from.
 * @returns {string} The string with HTML tags removed.
 */
export const removeHTMLTags = (text) => (text || '').replace(/<[^>]*>/g, '').trim();
