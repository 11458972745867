import type { ValueOf } from 'type-fest';

import type { PaymentTimelineEventStatus } from '@/src/api/config/employ/contractorInvoice.types';
import type { ScreamingSnakeCaseMapping } from '@/src/types/case';

export const paymentTypes = {
  CONTRACTOR: 'contractor',
  PAYROLL_RUN: 'payroll_run',
  REFUND: 'refund',
} as const;

export type PaymentType = ValueOf<typeof paymentTypes>;

export const paymentStatus = {
  // REVIEW with product status
  // https://www.notion.so/remotecom/Resource-Status-09df4014dca54f4d810d2edb73eafdc6#5c01313cd7ac473bbee0b6b0baeea6aa
  // paid, pending, expired - do not match with this.
  BLOCKED: 'blocked',
  CANCELLED: 'cancelled',
  /* debit and credit cards only */
  COLLECTING: 'collecting',
  MARKED_COLLECTING: 'marked_collecting',
  COMPLETED: 'completed',
  CREATED: 'created',
  CREDITED: 'credited',
  CREDIT_DUE: 'credit_due',
  REFUNDED: 'refunded',
  DELETED: 'deleted',
  /* queued up in payments system, waiting for worker */
  ENQUEUED: 'enqueued',
  PAID_IN: 'paid_in',
  PAY_IN_FAILED: 'pay_in_failed',
  /* one or more invoice payments have failed */
  PAY_OUT_FAILED: 'pay_out_failed',
  /* an installment has failed */
  PARTIAL_FAILURE: 'partial_failure',
  /* within our payments system */
  PROCESSING: 'processing',
  /* automated direct debit payment methods */
  SCHEDULED: 'scheduled',
  /* partial payment received, not full amount */
  SHORT_PAID_IN: 'short_paid_in',
  /* ACH DD, SEPA DD and card */
  DISPUTED: 'disputed',
  /* payment to a bank account country not supported by wise */
  MANUAL_PAYOUT: 'manual_payout',
  APPLIED_OFF_PLATFORM: 'applied_off_platform',
  // Billing Document only credit statuses
  CLOSED: 'closed', // Legacy: credit_applied/refunded, Zuora: Credit balance = 0
  CREDIT_AVAILABLE: 'credit_available', // Same as credit_due in legacy but more explicit,
  OVERDUE: 'overdue', // FE only status

  /* for contractor payments, the TR is paid in, but the company decided to schedule the payout later */
  PAY_OUT_SCHEDULED: 'pay_out_scheduled',
} as const;

export type PaymentStatus = ValueOf<typeof paymentStatus>;

export const PAYMENT_TIMELINE_EVENT_STATUSES: ScreamingSnakeCaseMapping<PaymentTimelineEventStatus> =
  {
    PENDING: 'pending',
    COMPLETED: 'completed',
    FAILED: 'failed',
    DELETED: 'deleted', // filtered out on the backend
  };

export const paymentTypeOptions: Array<{ value: PaymentType; label: string }> = [
  { value: paymentTypes.PAYROLL_RUN, label: 'Invoice' },
  { value: paymentTypes.CONTRACTOR, label: 'Transaction receipt' },
];

export const ACCOUNT_TYPE_PRIVATE = 'PRIVATE';
export const ACCOUNT_TYPE_BUSINESS = 'BUSINESS';

export type AccountOwnershipType = typeof ACCOUNT_TYPE_BUSINESS | typeof ACCOUNT_TYPE_PRIVATE;

export const accountTypes = {
  PRIVATE: ACCOUNT_TYPE_PRIVATE,
  BUSINESS: ACCOUNT_TYPE_BUSINESS,
} as const;

export const accountTypeOptions = [
  {
    value: ACCOUNT_TYPE_PRIVATE,
    label: 'Personal',
  },
  {
    value: ACCOUNT_TYPE_BUSINESS,
    label: 'Business',
  },
];

export const SAVINGS_TYPE = 'savings';
export const CHECKING_TYPE = 'checking';

export const savingsCheckingOptions = [
  {
    value: SAVINGS_TYPE,
    label: 'Savings',
  },
  {
    value: CHECKING_TYPE,
    label: 'Checking',
  },
];

export const jpnAccountTypeOptions = [
  {
    value: SAVINGS_TYPE.toUpperCase(),
    label: '貯蓄 (Chochiku) Savings deposit',
  },
  {
    value: CHECKING_TYPE.toUpperCase(),
    label: '当座 (Touza) Checking/Current',
  },
  {
    value: 'CURRENT',
    label: '普通 (Futsuu) Savings/General',
  },
];

export const savingsCheckingCuentaVistaOptions = [
  {
    value: SAVINGS_TYPE.toUpperCase(),
    label: 'Savings',
  },
  {
    value: CHECKING_TYPE.toUpperCase(),
    label: 'Checking',
  },
  {
    value: 'CUENTA_VISTA',
    label: 'Cuenta Vista',
  },
];

export const ACCOUNT_STATUS_DENIED = 'denied';
export const ACCOUNT_STATUS_VERIFIED = 'verified';
export const ACCOUNT_STATUS_PENDING = 'pending_verification';
export const ACCOUNT_STATUS_DELETED = 'deleted';

export const defaultStatusLabel = 'Pending verification';
export const bankAccountStatusOptions = [
  { value: ACCOUNT_STATUS_DENIED, label: 'Denied' },
  { value: ACCOUNT_STATUS_VERIFIED, label: 'Verified' },
  { value: ACCOUNT_STATUS_PENDING, label: 'Pending Verification' },
];

export type AccountStatus =
  | typeof ACCOUNT_STATUS_DENIED
  | typeof ACCOUNT_STATUS_VERIFIED
  | typeof ACCOUNT_STATUS_PENDING
  | typeof ACCOUNT_STATUS_DELETED;

const defaultPaymentDetailsLabels: Record<string, string> = {
  bankName: 'Bank name',
  iban: 'IBAN',
  accountHolder: 'Account holder',
  accountNumber: 'Bank account number',
  address: 'Address',
  bankCode: 'Bank code',
  swift: 'SWIFT/BIC',
  charge: 'SWIFT charge',
  intermediaryBankCode: 'Intermediary bank code',
  achRoutingNumber: 'ACH routing number',
  wireRoutingNumber: 'Wire routing number',
  ukSortCode: 'UK sort code',
  sortCode: 'Sort code',
  branchCode: 'Branch code',
  branchName: 'Branch name',
  transitNumber: 'Transit number',
  institutionNumber: 'Institution number',
  bsbCode: 'BSB code',
  ifsc: 'IFSC code',
  bankBranchName: 'Bank branch name',
};

const customPaymentDetailsLabels: Record<string, Record<string, string>> = {
  USD: {
    routingNumber: 'ACH or wire routing number',
    achCode: 'ACH routing number',
    achAccountNumber: 'Account number',
  },
};

export const getPaymentDetailsLabels = (currencyCode: string) => {
  const customLabels = customPaymentDetailsLabels[currencyCode];

  if (customLabels) {
    return { ...defaultPaymentDetailsLabels, ...customLabels };
  }

  return defaultPaymentDetailsLabels;
};

export const bankAddressDetailsLabels = {
  street: 'Address',
  postalCode: 'Post code',
  city: 'City',
  country: 'Country',
} as const;

export const DECLINE_REASON_OTHER = 'other';
export const DECLINE_REASON_EXTERNALLY_PAID = 'externally paid';

export const REASONS_WITH_FREE_TEXT_OPTION = [DECLINE_REASON_OTHER, DECLINE_REASON_EXTERNALLY_PAID];

export const declineReasonsOptions = [
  {
    value: DECLINE_REASON_EXTERNALLY_PAID,
    label: 'Invoice has been paid outside Remote',
  },
  { value: 'the dates are incorrect', label: 'The dates are incorrect' },
  {
    value: 'the employer address information is incorrect',
    label: 'The company address information is incorrect',
  },
  { value: 'the entity name not correct', label: 'The entity name is incorrect' },
  {
    value: 'the entity tax details not correct',
    label: 'The entity tax details are incorrect',
  },
  {
    value: 'the invoice items are unexpected',
    label: 'The invoice items are unexpected',
  },
  { value: 'the invoice number is incorrect', label: 'The invoice number is incorrect' },
  { value: 'the invoice total is too high', label: 'The invoice total is too high' },
  { value: 'the invoice total is too low', label: 'The invoice total is too low' },
  { value: 'duplicate', label: 'This is a duplicate invoice' },
  { value: DECLINE_REASON_OTHER, label: 'Other' },
];

export const nicTypePakOptions = [
  { value: 'snic', label: 'SNIC' },
  { value: 'cnic', label: 'CNIC' },
];

// In case of BE config API error, show the default config value
export const debitAdvanceDaysNotice = {
  ACH: 3,
  SEPA: 3,
};

export const contractorPayOutMethods = {
  WISE: 'wise',
  STRIPE_CONNECT: 'stripe_connect',
  NIUM: 'nium_beneficiary_account',
} as const;

export type ContractorPayOutMethod = ValueOf<typeof contractorPayOutMethods>;

// type that tells if the bank account is managed by a provider like Wise or Coinbase.
// if it is a regular bank account then it will be generic (default).
export const bankAccountProviders = {
  GENERIC: 'generic',
};

export const VALID_EMPLOYEE_DEPOSIT_METHOD_PROVIDERS = [bankAccountProviders.GENERIC];

export const stripePlatforms = {
  NL: 'NL',
  UK: 'UK',
  US: 'US',
} as const;

export type StripePlatform = ValueOf<typeof stripePlatforms>;

export const NORDIC_AND_SWISS_CURRENCIES = ['DKK', 'NOK', 'SEK', 'CHF'];

export const CRYPTO_CURRENCY_CODES = new Set(['USDC']);
