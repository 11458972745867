// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineBankHouse = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M9.75 7a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0ZM12 6.25a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M3.25 11.75H3c-.97 0-1.75-.78-1.75-1.75V6.68c0-.72.44-1.36 1.1-1.63l9-3.6c.42-.16.88-.16 1.3 0l9 3.6c.66.27 1.1.91 1.1 1.63V10c0 .97-.78 1.75-1.75 1.75h-.25v5.5H21c.96 0 1.75.79 1.75 1.75v2.25H23a.75.75 0 0 1 0 1.5H1a.75.75 0 0 1 0-1.5h.25V19c0-.96.79-1.75 1.75-1.75h.25v-5.5Zm8.66-8.9a.25.25 0 0 1 .18 0l9 3.6c.1.03.16.12.16.23V10c0 .14-.11.25-.25.25H3a.25.25 0 0 1-.25-.25V6.68c0-.1.06-.2.16-.23l9-3.6Zm-7.16 8.9v5.5h2.5v-5.5h-2.5Zm4 0v5.5h2.5v-5.5h-2.5Zm4 0v5.5h2.5v-5.5h-2.5Zm4 0v5.5h2.5v-5.5h-2.5Zm4.5 9.5V19a.25.25 0 0 0-.25-.25H3a.25.25 0 0 0-.25.25v2.25h18.5Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineBankHouse);
export { ForwardRef as IconV2OutlineBankHouse };