export const USER_TYPE = /** @type {const} */ ({
  EMPLOYEE: 'employee',
  EMPLOYER: 'employer',
  DELETED: 'deleted',
  ADMIN: 'admin',
  SERVICE_PROVIDER: 'service_provider',
  // The candidate role is for usage with the Jobs application not Employ.
  // Candidates should only see any empty state when using Employ or be switched
  // to another user profile in their account with a different role.
  CANDIDATE: 'candidate',
});

export const COMMON_EVENT_NAMES = {
  OBJECT_CLICKED: 'Object Element Clicked',
  OBJECT_HOVERED: 'Object Element Hovered',
  FORM_SUBMITTED: 'Form Submitted',
  NAVIGATION_CLICKED: 'Navigation Element Clicked',
  CONTENT_DISPLAYED: 'Content Card Displayed',
  EXPERIMENT_STARTED: 'Experiment Started',
  DEMO_EVENT_COMPLETED: 'Demo Event Completed',
  INACTIVITY_EVENT_SIGN_OUT: 'Inactivity Signed Out',
  SURVEY_ACTION_COMPLETED: 'Survey Action Completed',
};

export const zendeskWidgetEvents = {
  OPEN: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'zendesk-widget',
      navigationLevel: 'primary',
    },
  },
  ZD_LOGIN_FAILED: {
    name: 'Zendesk Widget Login Failed',
    eventProps: {
      elementName: 'zendesk-widget',
      logIn: 'failed',
    },
  },
};

export const toggleEvents = {
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementType: 'toggle',
  },
};

export const helpCenterEvents = {
  EMPLOYEE_SELF_HELP: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'payslip-help-center',
      elementType: 'link',
      locationOfAction: 'payslips',
    },
  },
};

export const navigationEvents = {
  PRIMARY: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      navigationLevel: 'primary',
    },
  },
  SECONDARY: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      navigationLevel: 'secondary',
    },
  },
  TOP_LEVEL: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      navigationLevel: 'top-level',
    },
  },
};

export const notificationEvents = {
  NOTIFICATION_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'notification-cta',
      elementType: 'button',
    },
  },
  NOTIFICATION_CLOSED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'notification-dismiss',
      elementType: 'button',
    },
  },
};

const employeeSignUpEventProps = {
  domain: 'sign_up',
  userType: USER_TYPE.EMPLOYEE,
};

export const employeeSignUpEvents = {
  SIGN_UP_STARTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: employeeSignUpEventProps,
  },
  ACCOUNT_CREATED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: employeeSignUpEventProps,
  },
};

const signUpEventProps = {
  locationOfAction: 'customer-signup-form',
  userType: USER_TYPE.EMPLOYER,
};

export const signUpEvents = {
  SIGN_UP_STARTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: signUpEventProps,
  },
  PREVIOUS_ONBOARDING: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: signUpEventProps,
  },
  PREVIOUS_GOAL_PROVIDED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: signUpEventProps,
  },
  LOG_IN_GOAL_PROVIDED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: signUpEventProps,
  },
  GOAL_PROVIDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: signUpEventProps,
  },
  ACCOUNT_CREATED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: signUpEventProps,
  },
  ACCEPT_TERMS: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: signUpEventProps,
  },
  PERSONAL_DETAILS_ADDED: {
    name: 'personal details added',
    eventProps: signUpEventProps,
  },
  COMPANY_INFO_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: signUpEventProps,
  },
  LEGAL_REPRESENTATIVE_ADDED: {
    name: 'legal representative added',
    eventProps: signUpEventProps,
  },
  BILLING_CONTACT_ADDED: {
    name: 'billing contact added',
    eventProps: signUpEventProps,
  },
  COMPANY_ADDRESS_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: signUpEventProps,
  },
  COMPANY_WEBSITE_ADDED: {
    name: 'company website added',
    eventProps: signUpEventProps,
  },
  END_SIGN_UP: {
    name: 'end sign up',
    eventProps: signUpEventProps,
  },
  RESEND_CONFIRM_EMAIL: {
    name: 'resend confirmation email',
    eventProps: signUpEventProps,
  },
  COMPANY_ONBOARDING_COMPLETED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: signUpEventProps,
  },
  COMPANY_ONBOARDING_MODAL_BLOCK: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: signUpEventProps,
  },
  TYPE_TOGGLE_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'sign-up-type-toggle',
      elementType: 'toggle',
      locationOfAction: 'sign-up-form',
    },
  },
  REGISTRATION_FLOW_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'sign-up-product-selector',
      elementType: 'button',
      locationOfAction: 'sign-up-form',
    },
  },
  COMPANY_INTENTIONS_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'company-intent',
      locationOfAction: 'sign-up-form',
      domain: 'sign-up',
      // formFieldValues to be passed in at the time of event tracking
    },
  },
  MAIN_FLOW_SELECTED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'sign-up-type',
      elementType: 'button',
      locationOfAction: 'sign-up-form',
      domain: 'sign-up',
      // formFieldValues to be passed in at the time of event tracking
    },
  },
};

// The 'trackingName' used for the googleTagManager
// is not consistence with the Rudderstack 'locationOfAction'
export const signUpEventsMapping = {
  signup: 'sign-up-create-account',
  'company-information': 'onboarding-company-details',
  'company-address': 'onboarding-company-address',
};

const signInEventProps = {
  domain: 'sign-in',
};

export const signInEvents = {
  OBJECT_ELEMENT: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: signInEventProps,
  },
  INIT_SIGN_IN: {
    name: 'User Log In Attempted',
    eventProps: signInEventProps,
  },
  END_SIGN_IN: {
    name: 'Authentication Event Completed',
    eventProps: signInEventProps,
  },
  SET_NEW_PASSWORD: {
    name: 'Password Event Completed',
    eventProps: signInEventProps,
  },
  INIT_RECOVER_PASSWORD: {
    name: 'Recover Password Attempted',
    eventProps: signInEventProps,
  },
  RECOVER_PASSWORD_SUCCESS: {
    name: 'Password Event Completed',
    eventProps: signInEventProps,
  },
  RECOVER_PASSWORD_FAILED: {
    name: 'Recover Password Failed',
    eventProps: signInEventProps,
  },
  INIT_SSO_LOGIN: {
    name: 'SSO Login selected',
    eventProps: signInEventProps,
  },
  SSO_LOGIN_SUCCESS: {
    name: 'SSO Login Completed',
    eventProps: signInEventProps,
  },
  SSO_LOGIN_FAILED: {
    name: 'SSO Login Failed',
    eventProps: signInEventProps,
  },
  SSO_IDP_INIT: {
    name: 'User directed to SSO IDP',
    eventProps: {
      result: 'idp-redirect',
      authMethod: 'sso',
    },
  },
  SIGN_UP_NOW: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'sign-up-now',
      elementType: 'link',
      locationOfAction: 'sign-up-form',
    },
  },
};

const onboardingEventProps = {
  domain: 'onboarding',
};

export const onboardingEvents = {
  ONBOARDING_STARTED: {
    name: 'Onboarding started',
    eventProps: onboardingEventProps,
  },
  PERSONAL_PROFILE_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: onboardingEventProps,
  },
  BANK_DETAILS_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: onboardingEventProps,
  },
  ADMINISTRATIVE_DETAILS_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: onboardingEventProps,
  },
  EMPLOYMENT_ELIGIBILITY_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: onboardingEventProps,
  },
  BUSINESS_INFORMATION_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: onboardingEventProps,
  },
  HOME_ADDRESS_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: onboardingEventProps,
  },
  EMERGENCY_CONTACT_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: onboardingEventProps,
  },
  IDENTITY_VERIFICATION_STARTED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: onboardingEventProps,
  },
  IDENTITY_VERIFICATION_DONE: {
    name: 'identity verification finished',
    eventProps: onboardingEventProps,
  },
  DOCUMENTATION_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: onboardingEventProps,
  },
  ONBOARDING_END: {
    name: 'onboarding finished',
    eventProps: onboardingEventProps,
  },
};

export const buildAddEmployeeClickedEvent = (employeeType) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'add-employee',
    elementType: 'button',
    elementData: employeeType,
  },
});

export const addNewHireLocationOfActionEmptyMap = {
  'people-hiring': 'onboarding-empty',
  people: 'team-empty',
};

export const addNewHireLocationOfActionMap = {
  'people-hiring': 'onboarding',
  people: 'team',
};

const addNewHireEventProps = {
  formName: 'add-new-hire',
};

const addNewHireBulkUploadProps = {
  formName: 'add-multiple-hires',
};

export const bulkUploadEvents = {
  NEW_BULK_UPLOAD_HIRES: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  },
  SELECT_COUNTRY_AND_UPLOAD_CSV: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireBulkUploadProps,
  },
  PREVIEW_DATA: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireBulkUploadProps,
  },
};

export const directBulkUploadEvents = {
  GENERATE_YOUR_TEMPLATE_NAVIGATION: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'generate-your-template',
      navigationLevel: 'secondary',
      locationOfAction: 'add-employee-bulk',
      elementType: 'link',
      elementData: { url: 'dashboard/people/new/direct/generate-csv' },
    },
  },
  GENERATE_YOUR_TEMPLATE_SUBMIT: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'generate-csv',
      elementType: 'button',
      locationOfAction: 'add-employee-bulk',
    },
  },
  WARNING_DISPLAYED: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'upload-employee-profiles',
      elementData: 'file',
      eventType: 'inline-notification',
      locationOfAction: 'add-employee-bulk',
      productEmploymentType: 'direct',
    },
  },
  EXPORT_FAILURES_CSV: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'export-and-fix-csv',
      elementType: 'button',
      locationOfAction: 'add-employee-bulk',
    },
  },
  CONFIRM_PARSING_RESULTS: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'confirm',
      elementType: 'button',
      locationOfAction: 'add-employee-bulk',
    },
  },
  INVITE_EMPLOYEES: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'invite-employees',
      elementType: 'button',
      locationOfAction: 'add-employee-bulk',
    },
  },
};

export const addNewHireEvents = {
  NEW_HIRE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  },
  NEW_HIRE_FORM_SUBMIT: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
  },
  EMPLOYMENT_ELIGIBILITY: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  BASIC_INFORMATION: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  CONTRACT_DETAILS: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  INVOICING_PREFERENCES: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  CONTRACTOR_AGREEMENT_ORIGIN: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  PROMOTION_TERMS: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: addNewHireEventProps,
  },
  PROMOTIONS: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  PRICING_PLAN: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  BILLING_CYCLE: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  CONTACT_SALES: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: addNewHireEventProps,
  },
  SAVE_DRAFT: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  CREATE_RESERVE: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  SEND_INVITATION: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: addNewHireEventProps,
  },
  LEARN_MORE_PROBATION: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: addNewHireEventProps,
  },
  JOB_TITLE_ELIGIBILITY_CHECK: {
    name: 'Job Title Eligibility Check',
    eventProps: {
      ...addNewHireEventProps,
      locationOfAction: 'basic-information',
    },
  },
};

export const onboardingGuideEvents = {
  COMMON_ELEMENT: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  },
};

const teamPlanEventsProps = {
  formName: 'team-plans',
};

export const teamPlanEvents = {
  TEAM_PLAN_CREATED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: teamPlanEventsProps,
  },
  TEAM_PLAN_SHARED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: teamPlanEventsProps,
  },
  TEAM_PLAN_ACCEPTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: teamPlanEventsProps,
  },
};

const tieredProductEventsProps = {
  locationOfAction: 'company-tiered-products',
};

export const tieredProductEvents = {
  PLAN_CREATED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: tieredProductEventsProps,
  },
  PLAN_SHARED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: tieredProductEventsProps,
  },
  PLAN_ACCEPTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: tieredProductEventsProps,
  },
  DISCOUNT_EDITED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: tieredProductEventsProps,
  },
  PLAN_CANCELED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: tieredProductEventsProps,
  },
  PLAN_DELETED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: tieredProductEventsProps,
  },
  PLAN_ARCHIVED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: tieredProductEventsProps,
  },
};

export const referralProgramEvents = {
  GET_LINK_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'get-referral-link',
      elementType: 'button',
      locationOfAction: 'referral-program',
    },
  },
  COPY_LINK_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'copy-referral-link',
      elementType: 'button',
      locationOfAction: 'referral-program',
    },
  },
};

const paymentMethodEventProps = {
  domain: 'payment method',
  userType: USER_TYPE.EMPLOYER,
};

export const paymentMethodEvents = {
  INIT_SEPA_METHOD: {
    name: 'init SEPA method',
    eventProps: paymentMethodEventProps,
  },
  INIT_ACH_METHOD: {
    name: 'init ACH method',
    eventProps: paymentMethodEventProps,
  },
  INIT_CARD_METHOD: {
    name: 'init Card method',
    eventProps: paymentMethodEventProps,
  },
  CARD_ADDED: {
    name: 'Card added',
    eventProps: paymentMethodEventProps,
  },
  CARD_UPDATED: {
    name: 'Card updated',
    eventProps: paymentMethodEventProps,
  },
  INIT_ACH_VERIFICATION: {
    name: 'init ACH verification',
    eventProps: paymentMethodEventProps,
  },
  ACH_ADDED: {
    name: 'ACH added',
    eventProps: paymentMethodEventProps,
  },
  INIT_SEPA_MANDATE: {
    name: 'init SEPA mandate',
    eventProps: paymentMethodEventProps,
  },
  SEPA_ADDED: {
    name: 'SEPA added',
    eventProps: paymentMethodEventProps,
  },
  PAYMENT_METHOD_SET_DEFAULT: {
    name: 'payment method set default',
    eventProps: paymentMethodEventProps,
  },
  PAYMENT_METHOD_DELETED: {
    name: 'payment method deleted',
    eventProps: paymentMethodEventProps,
  },
};

const payContractorsEventProps = {
  domain: 'payment contractors',
  userType: USER_TYPE.EMPLOYER,
};

export const payContractorsEvents = {
  INIT_ADD_PAYMENT_METHOD: {
    name: 'init add payment method',
    eventProps: payContractorsEventProps,
  },
  SKIP_ADDING_PAYMENT: {
    name: 'skip adding payment',
    eventProps: payContractorsEventProps,
  },
  INIT_PAYMENT: {
    name: 'init payment',
    eventProps: payContractorsEventProps,
  },
  ADD_PAYMENT_METHOD_WHEN_PAYING: {
    name: 'add payment method when paying',
    eventProps: payContractorsEventProps,
  },
  INIT_PAYMENT_CONFIRMATION: {
    name: 'init payment confirmation',
    eventProps: payContractorsEventProps,
  },
  PAYMENT_CONFIRMED: {
    name: 'payment confirmed',
    eventProps: payContractorsEventProps,
  },
  PAYMENT_FAILED: {
    name: 'payment failed',
    eventProps: payContractorsEventProps,
  },
  RETRY_PAYMENT_SUCCESS: {
    name: 'retry payment success',
    eventProps: payContractorsEventProps,
  },
  RETRY_PAYMENT_ERROR: {
    name: 'retry payment error',
    eventProps: payContractorsEventProps,
  },
  INIT_INVOICE_DECLINE: {
    name: 'init invoice decline',
    eventProps: payContractorsEventProps,
  },
  INVOICE_DECLINED: {
    name: 'invoice declined',
    eventProps: payContractorsEventProps,
  },
};

export const employeeUploadContractorInvoiceEvents = {
  ELEMENT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'contractor-upload-invoice',
      elementType: 'button',
      userType: 'contractor',
    },
  },
};

export const employeeCreateContractorInvoiceEvents = {
  ELEMENT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'contractor-create-invoice',
      elementType: 'button',
      userType: 'contractor',
    },
  },
  TIME_TRACKING_BILLABLE_ITEMS: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'time-tracking-billable-items',
      elementType: 'radio',
      locationOfAction: 'contractor-create-invoice',
    },
  },
  EDIT_IN_TIME_TRACKING: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'edit-in-time-tracking',
      elementType: 'link',
      locationOfAction: 'contractor-create-invoice',
    },
  },
  USE_INVOICE_AS_TEMPLATE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'use-invoice-as-template',
      elementType: 'button',
      locationOfAction: 'invoices',
    },
  },
  QUICK_TASKS_ACTION_CLICKED: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementType: 'link',
      locationOfAction: 'dashboard',
      navigationLevel: 'secondary',
    },
  },
  YEAR_TO_DATE_EARNINGS_MONTH_HOVERED: {
    name: COMMON_EVENT_NAMES.OBJECT_HOVERED,
    eventProps: {
      locationOfAction: 'dashboard',
      elementType: 'canvas',
      userType: 'contractor',
    },
  },
  YEAR_TO_DATE_EARNINGS_STAT_CLICKED: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      locationOfAction: 'dashboard',
      elementType: 'link',
      userType: 'contractor',
    },
  },
};

export const employerCreateContractorInvoiceEvents = {
  ELEMENT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'contractor-create-invoice',
      elementType: 'button',
      userType: 'employer',
    },
  },
};

const costCalculatorEventProps = {
  domain: 'tce-calculator',
};

export const costCalculatorEvents = {
  FORM_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: costCalculatorEventProps,
  },
  ELEMENT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: costCalculatorEventProps,
  },
  ONBOARDING_MODAL_VIEWED: {
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      locationOfAction: 'cost-calculator',
    },
  },
  ONBOARDING_MODAL_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'cost-calculator',
      elementType: 'button',
    },
  },
  ONBOARDING_EXPERIMENT_STARTED: {
    name: COMMON_EVENT_NAMES.EXPERIMENT_STARTED,
    eventProps: {
      'Experiment name': 'EXPERIMENT_SIGNUP_REDIRECT_TO_COST_CALCULATOR',
    },
  },
  TEST_ONBOARDING_EXPERIMENT_STARTED: {
    name: COMMON_EVENT_NAMES.EXPERIMENT_STARTED,
    eventProps: {
      'Experiment name': 'EXPERIMENT_A_A_TEST',
    },
  },
  WORLDWIDE_DETAIL_OPENED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'cost-calculator',
      elementType: 'button',
      elementName: 'next',
    },
  },
};

const compensationExplorerEventProps = {
  domain: 'compensation-explorer',
};

export const compensationExplorerEvents = {
  OBJECT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: compensationExplorerEventProps,
  },
  FORM_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: compensationExplorerEventProps,
  },
};

const talentInsightsEventProps = {
  domain: 'talent-insights',
};

export const talentInsightsEvents = {
  FORM_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: talentInsightsEventProps,
  },
  ELEMENT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: talentInsightsEventProps,
  },
};

const sourcingEventProps = {
  domain: 'sourcing',
};

export const sourcingEvents = {
  FORM_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: sourcingEventProps,
  },
  ELEMENT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: sourcingEventProps,
  },
};

const kdbEventProps = {
  domain: 'knowledge-database-page',
};

export const kdbEvents = {
  FORM_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: kdbEventProps,
  },
  ELEMENT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: kdbEventProps,
  },
  FACT_VALUE_CREATED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'create-kdb-fact-value',
    },
  },
  NEW_FACT_VALUE_VERSION: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'create-kdb-fact-value-version',
    },
  },
  FACT_VALUE_EFFECTIVE_DATE_MODIFIED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'modify-kdb-fact-value-effective-date',
    },
  },
};

const timeOffEventProps = {
  domain: 'time-off',
};

/**
 * Defining the names of modals and forms here for consistent
 * reporting
 */
export const timeOffEventDetails = {
  REQUEST_TIME_OFF: 'request time off',
  DELETE_TIME_OFF: 'delete time off',
  EDIT_TIME_OFF: 'edit time off',
  VIEW_DETAILS: 'view details',
  APPROVE_TIME_OFF: 'approve time off',
  DECLINE_TIME_OFF: 'decline time off',
  LOCATIONS: {
    QUICK_ACTION: 'location quick action',
    VIEW_DETAILS_MODAL: 'location view details modal',
    TABLE_BULK_ACTION: 'location table bulk action',
    TABLE_ROW_MENU: 'location table row menu',
    HOLIDAY_DRAWER: 'location holiday drawer',
  },
  LOCAL_PUBLIC_HOLIDAYS: 'local public holidays',
  FORM_ACTION: {
    CREATED: 'created',
    EDITED: 'edited',
    DELETED: 'deleted',
  },
  LOCATION_CALENDAR: 'calendar',
  LOCATION_GENERIC: 'time-off',
};

export const timeTrackingEvents = {
  FAQ_CONTACT_US: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'employment-requests-link',
      locationOfAction: 'employer-time-tracking-learn-more-drawer',
    },
  },
  TABLE_VIEW_BREAKDOWN: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'link',
      elementName: 'view-timesheet-breakdown',
      locationOfAction: 'time-tracking-table',
    },
  },
  TABLE_DOWNLOAD_TIMESHEET: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'link',
      elementName: 'download-timesheet',
      locationOfAction: 'time-tracking-table',
    },
  },
  SEND_BACK_BUTTON: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      eventType: 'button',
      elementName: 'send-back-button',
      locationOfAction: 'time-tracking-table',
    },
  },
  SEND_BACK_FORM_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'send-back-timesheet',
    },
  },
  // employee domain
  TIMESHEET_SUBMIT_MODAL_CONFIRM: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementData: 'confirm-submit-hours',
      elementName: 'confirm-weekly-timesheet',
      elementType: 'button',
      locationOfAction: 'time-tracking',
    },
  },
  TIMESHEET_SUBMIT_MODAL_CANCEL: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementData: 'cancel',
      elementName: 'confirm-weekly-timesheet',
      elementType: 'button',
      locationOfAction: 'time-tracking',
    },
  },
  TIMESHEET_SUBMIT_MODAL_DISMISS: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementData: 'notification-dismiss',
      elementName: 'confirm-weekly-timesheet',
      elementType: 'button',
      locationOfAction: 'time-tracking',
    },
  },
  TIMESHEET_ADD_HOURS_BUTTON: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'add-hours',
      elementType: 'button',
      locationOfAction: 'time-tracking',
    },
  },
  TIMESHEET_SUBMITTED_CONTACT_US: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'contact-us',
      elementType: 'link',
      locationOfAction: 'time-tracking',
    },
  },
  TIMESHEET_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'weekly-timesheet',
      locationOfAction: 'time-tracking',
      // formFieldValues to be passed in at the time of event tracking
    },
  },
  EXPORT_CSV: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'time-sheet-export-drawer',
      locationOfAction: 'time-tracking',
      // formFieldValues to be passed in at the time of event tracking
    },
  },
  TIMESHEET_DELETE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'delete-time-entry',
      elementType: 'button',
      locationOfAction: 'time-tracking',
      // elementData to be passed in at the time of event tracking
    },
  },
  TIMESHEET_COPY_ROW: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'copy-to-all-days',
      elementType: 'button',
      locationOfAction: 'time-tracking',
      // elementData to be passed in at the time of event tracking
    },
  },
  TIMESHEET_ERROR_DISPLAYED: {
    name: 'Warning Displayed',
    eventProps: {
      elementName: 'weekly-timesheet',
      elementData: 'timeEntry',
      locationOfAction: 'time-tracking',
      // resultFeedback to be passed in at the time of event tracking
    },
  },
  TIMESHEET_CUSTOMIZE_HOURS: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'customize-time-settings',
      elementType: 'link',
      locationOfAction: 'time-tracking',
    },
  },
  TIMESHEET_CUSTOMIZE_TIMEZONE: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'customize-timezone',
      elementType: 'link',
      locationOfAction: 'time-tracking',
    },
  },
  TIME_SETTINGS_SAVE: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'customize-default-work-hours',
      locationOfAction: 'account-settings',
    },
  },
  REOPEN_TIMESHEET: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'reopen-timesheet',
      elementType: 'button',
      locationOfAction: 'time-tracking',
    },
  },
  NEW_INVOICE: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'new-invoice',
      elementType: 'button',
      locationOfAction: 'time-tracking',
    },
  },
  CALENDAR_NEW_INVOICE: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'calendar-new-invoice',
      elementType: 'button',
      locationOfAction: 'time-tracking',
    },
  },
  TIMESHEET_UPDATE_STATUS: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'update-timesheet-status',
      elementType: 'button',
      locationOfAction: 'time-tracking',
      // status to be passed in from component
    },
  },
  TIME_TRACKING_MODAL_SHOWN: {
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'open-time-tracking-modal',
      elementType: 'button',
      locationOfAction: 'time-tracking',
      // mode to be passed in from component
    },
  },
  TIME_TRACKING_MODAL_SUBMIT: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'submit-time-tracking-modal',
      elementType: 'button',
      locationOfAction: 'time-tracking',
      // mode to be passed in from component
    },
  },
  TIMESHEET_NOT_FOUND_APPLY_TEMPLATE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'apply-timesheet-creation-from-template',
      elementType: 'button',
      locationOfAction: 'time-tracking',
      // mode to be passed in from component
    },
  },
  TIMESHEET_EXISTS_APPLY_TEMPLATE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'apply-timesheet-copy-from-template',
      elementType: 'button',
      locationOfAction: 'time-tracking',
      // mode to be passed in from component
    },
  },
  TIMESHEET_NOT_FOUND_EMPTY_TIMESHEET_SUBMITTED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      formName: 'timesheet-creation-as-empty',
      locationOfAction: 'time-tracking',
    },
  },
};

export const projectsEvents = {
  ADD_PROJECT_BUTTON: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      eventType: 'button',
      elementName: 'add-project-button',
      locationOfAction: 'projects-table',
    },
  },
  EDIT_PROJECT_BUTTON: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      eventType: 'button',
      elementName: 'edit-project-button',
      locationOfAction: 'projects-table',
    },
  },
  ARCHIVE_PROJECT_BUTTON: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      eventType: 'button',
      elementName: 'archive-project-button',
      locationOfAction: 'projects-table',
    },
  },
  UNARCHIVE_PROJECT_BUTTON: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      eventType: 'button',
      elementName: 'unarchive-project-button',
      locationOfAction: 'projects-table',
    },
  },
  DELETE_PROJECT_BUTTON: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      eventType: 'button',
      elementName: 'delete-project-button',
      locationOfAction: 'projects-table',
    },
  },
  DOWNLOAD_EXPORT_BUTTON: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      eventType: 'button',
      elementName: 'export-csv-project-button',
      locationOfAction: 'projects-table',
    },
  },
  ADD_PROJECT_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'created-project',
      locationOfAction: 'projects-table',
      // formFieldValues to be passed in at the time of event tracking
    },
  },
  EDIT_PROJECT_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'edited-project',
      locationOfAction: 'projects-table',
      // formFieldValues to be passed in at the time of event tracking
    },
  },
  ARCHIVED_PROJECT_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'archived-project-modal',
      locationOfAction: 'projects-table',
    },
  },
  DELETED_PROJECT_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'deleted-project-modal',
      locationOfAction: 'projects-table',
    },
  },
};

export const clockInOutWidgetEvents = {
  TIME_SETTINGS_TOGGLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'time-settings-widget-toggle',
      elementType: 'button',
      locationOfAction: 'time-settings',
      // action of enabled or disabled to be passed in from component
    },
  },
  SEE_MORE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'clock-in-widget-see-more',
      elementType: 'button',
      locationOfAction: 'clock-in-widget',
      // isCollapsed value passed in from component
    },
  },
  TIMER_ACTION: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'clock-in-widget-timer-action',
      elementType: 'button',
      locationOfAction: 'clock-in-widget',
      // values passed from the component:
      // 1. timerType ("overtime", "regular_hours", "break", "on_call")
      // 2. isCollapsed (true or false)
      // 3. action ("start" or "stop")
    },
  },
  DISCARD_TRACKED_TIME: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'discard-tracked-time',
      locationOfAction: 'clock-in-widget',
    },
  },
};

const discountEventsProps = {
  domain: 'discounts',
};

export const discountEvents = {
  DISCOUNT_REVISED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: discountEventsProps,
  },
  CONFIRM_DISCOUNT_REMOVED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: discountEventsProps,
  },
  DISCOUNT_EDITED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: discountEventsProps,
  },
};

/**
 * The naming is based off recommendation from Amplitude Data Taxonomy Playbook
 * https://help.amplitude.com/hc/en-us/articles/115000465251-Data-Taxonomy-Playbook#h_437a698b-bcc5-470b-aac3-ef87dfb59221
 * Keeping the event names high-level for this grouping, and then an
 * `eventDetail` eventProp with the names outlined in timeOffEventDetails needs to be
 * passed in. See https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/9351
 */
export const timeOffEvents = {
  OPEN_MODAL: {
    name: 'open time off modal', // deprecated
    eventProps: timeOffEventProps,
  },
  TIME_OFF_REQUEST_DECLINED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: { ...timeOffEventProps, userType: USER_TYPE.EMPLOYER },
  },
  CLICK_BUTTON: {
    name: 'click time off button', // deprecated
    eventProps: timeOffEventProps,
  },
  SELECT_OPTION: {
    name: 'select time off option', // deprecated
    eventProps: timeOffEventProps,
  },
  SUBMIT_TIME_OFF_FORM: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'time-off-request',
    },
  },
  CANCEL_TIME_OFF_FORM: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'cancel-time-off-request',
    },
  },
  PTO_BALANCE_LEARN_MORE_LINK: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'link',
      elementName: 'pto-balance-learn-more',
      locationOfAction: 'time-off',
    },
  },
  OPEN_TYPE_OF_LEAVE_USING_ACCORDION: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'link',
      elementName: 'leave-type-summary',
      locationOfAction: 'time-off',
    },
  },
  OPEN_TYPE_OF_LEAVE_USING_DRAWER: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'link',
      elementName: 'leave-type-summary-drawer',
      locationOfAction: 'time-off',
    },
  },
  NEW_CUSTOM_LEAVE_POLICIES: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'new-custom-leave-policies',
      locationOfAction: 'time-off',
    },
  },
  EDIT_CUSTOM_POLICY_VARIANT: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'edit-custom-policy-variant',
      locationOfAction: 'time-off',
    },
  },
  EDIT_CUSTOM_POLICY_VARIANT_BENEFICIARIES: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'edit-custom-policy-variant-beneficiaries',
      locationOfAction: 'time-off',
    },
  },
  SUBMIT_POLICY_VARIANT_FORM: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'policy-form-review',
      // action added in event tracking; either published or updated
    },
  },
};

// Todo Events
export const todoItemEvents = {
  TODO_ITEM_VIEWED: {
    name: 'Message Event Delivered',
    eventProps: { messageType: 'task-notification', platform: 'web' },
  },
  TODO_ITEM_CLICKED: {
    name: 'Message Event Completed',
    eventProps: { messageType: 'task-notification', platform: 'web' },
  },
};

// Todos View Events
export const todosViewEvents = {
  TODOS_TAB_SELECTED: (elementName) => ({
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName,
      elementType: 'tab',
      locationOfAction: 'todos-page',
      navigationLevel: 'secondary',
    },
  }),
  TODOS_SORT_OPTION_SELECTED: (sortOption) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'sort-todos',
      elementType: 'button',
      locationOfAction: 'todos-page',
      sortOption,
    },
  }),
  TODOS_LOAD_MORE_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'load-more-todos',
      elementType: 'button',
      locationOfAction: 'todos-page',
    },
  },
};

// Todos Overlay Events
export const todosOverlayEvents = {
  TODOS_OVERLAY_OPENED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'todos-overlay-button',
      elementType: 'button',
    },
  },
};

// In app notification Events
export const inAppNotificationEvents = {
  NOTIFICATION_BELL_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {},
  },
  NOTIFICATION_VIEWED: {
    name: 'Message Event Delivered',
    eventProps: {},
  },
  NOTIFICATION_CLICKED: {
    name: 'Message Event Completed',
    eventProps: {},
  },
};

// Account menu Events
export const accountMenuEvents = {
  ACCOUNT_MENU_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'account-menu',
      elementType: 'button',
    },
  },
};

// PowerSearch Events
export const powerSearchEvents = {
  CONTEXTUAL_ACTIONS_VIEWED: {
    name: 'Contextual Action Viewed',
    eventProps: {},
  },
  ELEMENT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {},
  },
  SEARCH_COMPLETED: {
    name: 'Search Action Completed',
    eventProps: {},
  },
};

// Dashboard/Homepage Events

/**
 * @typedef {import('./types').Event} Event
 */

/**
 * Builds a "Content Card Displayed" event for the homepage
 * @param {string} cardTitle
 * @param {string} [location]
 * @returns {Event} Event
 */
const buildContentCardLoadedEvent = (cardTitle, location) => ({
  name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
  eventProps: {
    title: cardTitle,
    userType: USER_TYPE.EMPLOYER,
    locationOfAction: location ?? 'homepage',
  },
});

/**
 * Builds a "Card clicked" event for homepage content cards
 * @param {string} cardTitle
 * @param {string} [location]
 * @param {string} [userType]
 * @returns {Event} Event
 */
const buildContentCardClickedEvent = (cardName, location, userType) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: cardName,
    elementType: 'button',
    locationOfAction: location ?? 'homepage-content-card',
    userType: userType ?? USER_TYPE.EMPLOYER,
  },
});

/**
 * Builds a "Card loaded" event for homepage insights tab
 * @param {'Team'|'Needs attention'|'Public holidays'|'Onboarding'|'Expenses'|'Time off'} cardTitle
 * @returns {Object}
 */
const buildInsightsCardLoadedEvent = (cardTitle) => ({
  name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
  eventProps: {
    title: cardTitle,
    content: 'values',
    locationOfAction: 'insights',
  },
});

/**
 * Builds a "Card loaded" event for homepage insights V2 tab
 * @param {'Your team widget'|'Upcoming public holidays widget'|'Upcoming birthdays widget'|'Team by country widget'|'Who's away widget'} cardTitle
 * @returns {Object}
 */
const buildInsightsCardLoadedEventV2 = (cardTitle) => ({
  name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
  eventProps: {
    title: cardTitle,
    content: 'values',
    locationOfAction: 'homepage',
  },
});

/**
 * Builds a "Card clicked" event for homepage insights tab
 * @param {'Team'|'Needs attention'|'Public holidays'|'Onboarding'|'Expenses'|'Time off'} cardTitle
 * @returns {Object}
 */
const buildInsightsCardClickedEvent = (cardTitle) => ({
  name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
  eventProps: {
    navigationLevel: 'secondary',
    elementName: cardTitle,
    locationOfAction: 'insights',
  },
});

/**
 * Builds a "Card clicked" event for homepage insights tab v2
 * @param {'Upcoming birthdays widget'|'Team by country widget'|'Your team widget'|'Who's away widget} cardTitle
 * @returns {import("./methods").Event}
 */
const buildInsightsCardClickedEventV2 = (cardTitle) => ({
  name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
  eventProps: {
    navigationLevel: 'secondary',
    elementName: cardTitle,
    elementType: 'card',
    locationOfAction: 'homepage',
  },
});

/**
 * Builds a Tab Viewed event for the homepage tabs
 *
 * Note: This event is only fired when the tab is selected based on the existence of
 * "Needs Attention" notifications. That's why 'updates' won't be a possible
 * value for the tabName param
 * @param {'insights'|'needs-action'} tabName
 * @returns {Object}
 */
const buildTabViewedEvent = (tabName) => ({
  name: 'Tab viewed',
  eventProps: {
    content_displayed: tabName,
  },
});

/**
 * Builds a "Card clicked" event for dashboard carousel cards
 * @param {string} cardName
 * @param {'homepage-content-card'|'homepage-recommended'} [location]
 * @returns {Event} Event
 */
const buildCarouselCardClickedEvent = (cardName, location) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: cardName,
    elementType: 'card',
    locationOfAction: location ?? 'homepage-content-card',
  },
});

/**
 * Builds a "Card displayed" event for dashboard carousel cards
 * @param {string} cardTitle
 * @param {'homepage-do-more'|'homepage-recommended'|'homepage-insights'} [location]
 * @returns {Event} Event
 */
const buildCarouselCardLoadedEvent = (cardTitle, location) => ({
  name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
  eventProps: {
    title: cardTitle,
    locationOfAction: location ?? 'homepage-do-more',
  },
});

export const homepageEvents = {
  INSIGHTS_TAB_OPENED_BY_DEFAULT: buildTabViewedEvent('insights'),
  NEEDS_ACTION_TAB_OPENED_BY_DEFAULT: buildTabViewedEvent('needs-action'),
  insightsCardLoaded: buildInsightsCardLoadedEvent,
  insightsCardLoadedV2: buildInsightsCardLoadedEventV2,
  insightsCardClicked: buildInsightsCardClickedEvent,
  insightsCardClickedV2: buildInsightsCardClickedEventV2,
  contentCardLoaded: buildContentCardLoadedEvent,
  contentCardClicked: buildContentCardClickedEvent,
  carouselCardLoaded: buildCarouselCardLoadedEvent,
  carouselCardClicked: buildCarouselCardClickedEvent,
};

/**
 * Table Tracking Events
 * https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/11991
 */

const TABLE_INTERACTION_COMPLETED = 'Table Interaction Completed';

export const tableEvents = {
  TABLE_VIEW: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'view',
    },
  },
  TABLE_SEARCH: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'search',
    },
  },
  TABLE_SORT: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {},
  },
  TABLE_FILTER: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'filter',
    },
  },
  TABLE_SAVE_FILTER: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'save-filters',
    },
  },
  TABLE_EXPORT: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'export',
    },
  },
  TABLE_EDIT_COLUMNS: {
    name: TABLE_INTERACTION_COMPLETED,
  },
  TABLE_SAVE_CONFIG: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'save-configuration',
    },
  },
  TABLE_DENSITY_COMPACT: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'density-compact',
    },
  },
  TABLE_DENSITY_LARGE: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'density-large',
    },
  },
  TABLE_HIDE_COLUMN: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'hide-individual-column',
    },
  },
  TABLE_CLICK_ROW_MENU: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'click-row-menu',
    },
  },
  TABLE_ROW_MENU_ACTION_CLICK: {
    name: TABLE_INTERACTION_COMPLETED,
  },
  TABLE_CHANGE_COLUMNS_ORDER: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      action: 'change-columns-order',
    },
  },
};

export const bookitEvents = {
  DISPLAY: {
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      title: 'bookit-demo',
      content: 'Book a demo call',
      locationOfAction: 'sign-up-create-account',
    },
  },
  CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      locationOfAction: 'sign-up-create-account',
    },
  },
  SUBMITTED: ({ locationOfAction = 'homepage' }) => ({
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      domain: 'leandata',
      formName: 'booking-confirmation-talk-to-expert',
      locationOfAction,
      userType: 'employer',
    },
  }),
  HOTLINK_MODAL_OPENED: ({ locationOfAction }) => ({
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      elementName: 'booking-modal-via-hotlink',
      locationOfAction,
    },
  }),
};

export const requestsEvents = {
  NEW_REQUEST: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  },
  CONTINUE_TO_REQUEST: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  },
  SUBMIT_REQUEST: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
  },
  AMENDMENT: {
    AMEND_CONTRACT_DETAILS: {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    },
    START_REQUEST: {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    },
    SUBMIT_REQUEST: {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    },
  },
  RESIGNATION: {
    VERIFY_PTO: {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        locationOfAction: 'request-hub',
        elementName: 'verified-pto',
        elementType: 'radio',
      },
    },
    VALIDATE_REQUEST: {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    },
  },
};

const downloadPayrollReportsEventProps = {
  elementName: 'download-report',
  elementType: 'button',
  locationOfAction: 'payroll-reports',
};

const previewPayrollReportsEventProps = {
  elementName: 'preview-report',
  elementType: 'button',
  locationOfAction: 'payroll-reports',
};

export const PAYROLL_REPORTS_EVENTS = {
  DOWNLOAD_REPORT: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: downloadPayrollReportsEventProps,
  },
  PREVIEW_REPORT: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: previewPayrollReportsEventProps,
  },
};

const downloadPayrollInputEventProps = {
  elementName: 'download-input-spreadsheet',
  elementType: 'button',
  locationOfAction: 'payroll-run',
};

export const PAYROLL_RUN_EVENTS = {
  DOWNLOAD_INPUT_SPREADSHEET: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: downloadPayrollInputEventProps,
  },
  UPLOAD_PAYROLL_OUTPUTS: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'upload-payroll-outputs-form',
      locationOfAction: 'payroll-run',
    },
  },
};

export const buildViewPayrollRunDetailsEvent = (data) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'view-payroll-run',
    elementType: 'button',
    locationOfAction: 'payroll-runs-list',
    userType: 'employer',
    elementData: data,
  },
});

export const buildMarkPayrollRunAsCompletedEvent = (data) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'mark-payroll-run-as-completed',
    elementType: 'button',
    locationOfAction: 'payroll-runs-list',
    userType: 'employer',
    elementData: data,
  },
});

export const buildOpenPayrollRunCommentsDrawerEvent = (data) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'payroll-run-comments',
    elementType: 'button',
    locationOfAction: 'payroll-run',
    userType: 'employer',
    elementData: data,
  },
});

export const buildViewEmployeePayDetailsFromPayrollRunEvent = (data) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'employee-pay-details',
    elementType: 'link',
    locationOfAction: 'payroll-run-employees-table',
    userType: 'employer',
    elementData: data,
  },
});

const buildNewRoleClickedEvent = (elementType) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    locationOfAction: 'roles-dashboard',
    elementName: 'create-new-role',
    elementType,
  },
});

const buildPermissionEvent = (formName) => {
  return (formFieldValues) => ({
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName,
      formFieldValues: {
        roleName: formFieldValues.name,
        employeeAccess: formFieldValues.dataScope,
        cta: formFieldValues.cta,
      },
      locationOfAction: 'permissions',
    },
  });
};

export const buildUnmaskSalaryEmployerEvent = (locationOfAction) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'unmask-salary-employer-view',
    elementType: 'button',
    locationOfAction,
  },
});

const buildAssignedPeopleEvent = (formName) => {
  return (formFieldValues) => ({
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName,
      formFieldValues: {
        assignedPeople: formFieldValues.assignedPeople,
      },
      locationOfAction: 'assign-people',
    },
  });
};

const buildUserActionClick = ({ elementName, elementType }) => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementData: 'user',
      elementName,
      elementType,
      locationOfAction: 'users-list',
    },
  };
};

const buildUserMoreActionClick = ({ elementData, elementName = 'more-actions' }) => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementData,
      elementName,
      elementType: 'button',
      locationOfAction: 'roles-dashboard-card',
    },
  };
};

export const INACTIVITY_EVENTS = {
  userDismissInactivityModal: ({ role }) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'stay-logged-in',
        userType: role,
      },
    };
  },
  userSignedOutDueToInactivity: ({ role }) => {
    return {
      name: COMMON_EVENT_NAMES.INACTIVITY_EVENT_SIGN_OUT,
      eventProps: {
        userType: role,
      },
    };
  },
};

export const TWO_FACTOR_EVENTS = {
  twoFactorSetupComplete: ({ locationOfAction, scaEnforced = false, role }) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        locationOfAction,
        formName: '2fa-setup-complete',
        eventType: scaEnforced ? 'sign-in-2fa-enforced' : 'sign-in-2fa-optional',
        formFieldValues: {
          userType: role,
        },
      },
    };
  },
  twoFactorSetupLoaded: (locationOfAction) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'setup-2fa',
        locationOfAction,
      },
    };
  },
  twoFactorSetupClick: (elementName, scaEnforced = false) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName,
        locationOfAction: 'sign-in-flow',
        eventType: scaEnforced ? 'sign-in-2fa-enforced' : 'sign-in-2fa-optional',
      },
    };
  },
};

export const CUSTOMER_ROLES_PERMISSIONS_EVENTS = {
  CREATE_NEW_ROLE_VIA_CARD: buildNewRoleClickedEvent('card'),
  CREATE_NEW_ROLE_VIA_BUTTON: buildNewRoleClickedEvent('button'),
  addPermissionsToNewRole: buildPermissionEvent('create-new-role'),
  addPermissionsToExistentRole: buildPermissionEvent('edit-new-role'),
  assignPeopleToNewRole: buildAssignedPeopleEvent('create-new-role'),
  assignPeopleToExistentRole: buildAssignedPeopleEvent('edit-new-role'),
  CANCEL_NEW_ROLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'cancel',
      elementType: 'button',
      locationOfAction: 'create-new-role-permissions',
    },
  },
  USER_MORE_ACTIONS_CARD_CLICK: buildUserMoreActionClick,
  USERS_LIST_MEMBER_ACTION_CLICK: buildUserActionClick({
    elementName: 'more-actions',
    elementType: 'button',
  }),
  USERS_LIST_MEMBER_ACTION_VIEW: buildUserActionClick({
    elementName: 'view-role',
    elementType: 'context-menu',
  }),
  USERS_LIST_MEMBER_ACTION_EDIT: buildUserActionClick({
    elementName: 'edit',
    elementType: 'context-menu',
  }),
  USERS_LIST_MEMBER_ACTION_DELETE: buildUserActionClick({
    elementName: 'delete',
    elementType: 'context-menu',
  }),
  USERS_LIST_MEMBER_DELETE_USER: buildUserActionClick({
    elementName: 'confirm-delete',
    elementType: 'button',
  }),
  USERS_LIST_MEMBER_EDIT_USER: buildUserActionClick({
    elementName: 'save-changes',
    elementType: 'button',
  }),
};

export const EXPENSES_MOBILE_APP_BANNER_EVENTS = {
  OPEN_BANNER: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'mobile-app-banner',
      elementType: 'button',
      locationOfAction: 'expenses',
    },
  },
  VISIT_APPLE_APP_STORE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'apple-app-store',
      elementType: 'link',
      locationOfAction: 'expenses',
    },
  },
  VISIT_GOOGLE_PLAY_STORE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'google-play-store',
      elementType: 'link',
      locationOfAction: 'expenses',
    },
  },
};

export const formEvents = {
  WARNING: {
    name: 'Warning Displayed',
    eventProps: {},
  },
};

export const buildZendeskArticleDrawerOpenEvent = (zendeskArticleUrl, zendeskHelpCenterUrl) => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'side-drawer',
      elementType: 'link',
      elementData: `${zendeskHelpCenterUrl}/${zendeskArticleUrl}`,
    },
  };
};

export const adminEmploymentEvents = {
  EDIT_IN_PLACE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  },
  EDIT_CONTRACT: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
  },
};

/**
 * builds an expense submit event for one-by-one expense processing page
 * @param {Object} data
 * @param {'approved' | 'declined'} action
 * @returns {Object}
 */
export const buildExpenseProcessingEvent = (data, action) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: `expense-${action}`,
    elementType: 'button',
    locationOfAction: 'expenses',
    elementData: data,
  },
});

/**
 * builds an incentive approval event for one-by-one incentive processing page
 * @param {'approvalDuration','countryCode','expenseCategory','legalEntitySlug','reviewedAt','suggestionRank'} data
 * @returns {Object}
 */
export const buildIncentiveProcessingEvent = (data) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'incentive-approved',
    elementType: 'button',
    locationOfAction: 'incentives',
    elementData: data,
  },
});

/**
 * builds a suggestion event interaction in one-by-one expense processing page
 * @param {'expenses|incentives'} location where is the suggestion being used
 * @param {'keyboard|mouse'} method which method was used to select a suggestion
 * @returns {Object}
 */
export const buildSuggestionEvents = (location, method) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'pay-element-suggestion',
    elementType: 'button',
    locationOfAction: location,
    elementData: { method },
  },
});

/**
 * builds an incentives auto mapping event interaction in one-by-one incentives processing page
 * @param {'countryCode','legalEntityType','legalEntitySlug', 'duration', 'count'} data
 * @returns {Object}
 */
export const buildIncentivesAutoMappingEvent = (data) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'incentive-bulk-mapped',
    elementType: 'button',
    locationOfAction: 'incentives',
    elementData: data,
  },
});

/**
 * tracks manual actions (create and edit) related to employee pay elements
 * @param {{legalEntitySlug: string, legalEntityType: string, countryCode: string, employmentSlug: string | undefined}} data Employee pay element related information
 * @param {"created" | "edited"} action Which action is takin place
 * @returns tracking event data structure
 */
export const buildEmployeePayElementEvent = (data, action) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: `employee-pay-element-${action}`,
    elementType: 'button',
    locationOfAction: 'users-payments-deductions',
    elementData: data,
  },
});

/**
 * builds an event for the user clicking on the "Learn more" CTA on the sign-in page
 * @returns {{name: string, eventProps: Object}}
 */
export const buildLoginCTAEvent = () => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'sign-in-cta',
    elementType: 'button',
    locationOfAction: 'sign-in-page',
    elementData: 'learn-more-campaign',
  },
});

/**
 * builds an expense approval/decline event from employer or manager
 * @param {Object} data
 * @param {'approved' | 'declined'} action
 * @returns {{name: string, eventProps: Object}}
 */
export const buildExpenseReviewEvent = (data, action) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: `expense-${action}`,
    elementType: 'button',
    locationOfAction: 'expenses-review',
    elementData: data,
  },
});

/**
 * builds an expense review entry point event
 * @param {Object} data
 * @returns {{name: string, eventProps: Object}}
 */
export const buildExpenseReviewEntryPoint = (data) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'expense-review-entry-point',
    elementType: 'button',
    locationOfAction: 'expenses',
    elementData: data,
  },
});

/**
 * builds an expense bulk approve action event
 * @param {Object} data
 * @returns {{name: string, eventProps: Object}}
 */
export const buildExpenseBulkApproveAction = (count) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'expense-bulk-approve',
    elementType: 'button',
    locationOfAction: 'expenses',
    elementData: { count },
  },
});

/**
 * builds an event when a user duplicates an expense
 * @param {Object} data
 * @returns {{name: string, eventProps: Object}}
 */
export const buildExpenseDuplicateEvent = (data) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'expense-duplicate',
    elementType: 'button',
    locationOfAction: 'expenses',
    elementData: data,
  },
});

/**
 * Builds an event for the create employee pay elements form.
 * @param {Object} formFieldValues
 * @param {Object} locationOfAction
 * @param {String} formName
 */
export const buildEmployeePayElementCreateFormEvent = (
  formFieldValues,
  locationOfAction,
  formName
) => {
  return {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName,
      locationOfAction,
      formFieldValues,
    },
  };
};

/**
 * Builds an event for the edit employee pay elements form.
 * @param {Object} formFieldValues
 * @param {Object} locationOfAction
 */
export const buildEmployeePayElementEditFormEvent = (formFieldValues, locationOfAction) => {
  return {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'edit-pay-elements',
      locationOfAction,
      formFieldValues,
    },
  };
};

/**
 * Builds an event for the deletion of an employee pay element.
 * @param {Object} locationOfAction
 */
export const buildEmployeePayElementDeleteEvent = (locationOfAction) => {
  return {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      elementName: 'delete-pay-element',
      elementType: 'button',
      locationOfAction,
    },
  };
};

/**
 * Builds an onClick event when an employer download payroll report on row click.
 * @returns {{name: string, eventProps: { [key: string]: any }}}
 */
export const buildReportDownloadOnRowClick = () => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'download-g2n-report-row',
      elementType: 'button',
      locationOfAction: 'payroll',
      userType: 'employer',
    },
  };
};

/**
 * Builds an onClick event when an employer download payroll report on row menu click .
 * @returns {{name: string, eventProps: { [key: string]: any }}}
 */
export const buildReportDownloadOnRowMenuClick = () => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'download-g2n-report-row-menu',
      elementType: 'button',
      locationOfAction: 'payroll',
      userType: 'employer',
    },
  };
};

/**
 * Builds an onClick event when an employer download payroll report from a payroll run  .
 * @returns @returns {{name: string, eventProps: { [key: string]: any }}}
 */
export const buildReportDownloadOnPayrollRunClick = () => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'download-g2n-report',
      elementType: 'button',
      locationOfAction: 'payroll-run',
      userType: 'employer',
    },
  };
};

/**
 * Builds an event when users add a data change to a payroll run by clicking on the row action menu.
 * @returns {{name: string, eventProps: Object}}
 */
export const buildAddDataChangeToPayrollRunEvent = () => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'add-to-payroll-run',
      elementType: 'button',
      locationOfAction: 'data-changes',
      userType: 'admin',
    },
  };
};

/**
Builds an event when users bulk add data changes to a payroll run
 * @returns {{name: string, eventProps: Object}}
 */
export const buildBulkAddDataChangesToPayrollRunEvent = () => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'edit-selected',
      elementType: 'button',
      locationOfAction: 'data-changes',
      userType: 'admin',
    },
  };
};

const remoteAPIEventProps = {
  domain: 'remote-api',
};

export const remoteAPIEvents = {
  FORM_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: remoteAPIEventProps,
  },
};

const sidebarEventProps = {
  domain: 'sidebar',
};

export const sidebarEvents = {
  OBJECT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: sidebarEventProps,
  },
};

const navatticEventProps = {
  userType: USER_TYPE.EMPLOYER,
};

export const navatticEvents = {
  OBJECT_CLICKED: {
    name: COMMON_EVENT_NAMES.DEMO_EVENT_COMPLETED,
    eventProps: navatticEventProps,
  },
};

export const formDraftEvents = {
  DRAFT_USED: 'Draft used',
  DRAFT_DISCARDED: 'Draft discarded',
};

/**
 * Builds a "formDraft clicked" event whenever a user uses or discards a saved from draft
 * @param formName String
 * @param actiontype Draft used | Draft discarded
 * @returns {Object}
 */
export const buildFormDraftEvents = (formName, actiontype) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName: 'button',
    formName,
    locationOfAction: 'save-draft-modal',
    action: formDraftEvents[actiontype],
  },
});

const benefitEventProps = { domain: 'benefits' };

export const benefitEvents = {
  CARD_DISPLAYED: {
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: { ...benefitEventProps, locationOfAction: 'benefits' },
  },
  DIRECTORY_CONTENT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      ...benefitEventProps,
      elementName: 'benefits-directory',
      elementType: 'link',
      locationOfAction: 'onboarding',
    },
  },
  OBJECT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      ...benefitEventProps,
      elementType: 'link',
      locationOfAction: 'benefits',
    },
  },
  OBJECT_CLICKED_EMPLOYEE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      ...benefitEventProps,
      elementType: 'link',
      locationOfAction: 'benefits-employee',
    },
  },
  ONBOARDING_CONTINUE_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      ...benefitEventProps,
      elementName: 'benefits-step-continue',
      elementType: 'button',
      locationOfAction: 'add-new-hire-benefits',
    },
  },
  ONBOARDING_GUIDE_URL_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      ...benefitEventProps,
      elementName: 'benefits-overview-guide',
      elementType: 'link',
      locationOfAction: 'add-new-hire-benefits',
    },
  },
  ONBOARDING_TIER_URL_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      ...benefitEventProps,
      elementName: 'benefits-tier-guide',
      elementType: 'link',
      locationOfAction: 'add-new-hire-benefits',
    },
  },
  RENEWAL_CARDS_DISPLAYED: {
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      ...benefitEventProps,
      elementName: 'benefit-renewal-cards',
      locationOfAction: 'benefit-renewals',
    },
  },
  RENEWAL_MODAL_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      ...benefitEventProps,
      locationOfAction: 'benefit-renewal-modal',
    },
  },
};

export const customFieldsEvents = {
  ADD_CUSTOM_FIELD: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'add-custom-field',
      elementType: 'button',
    },
  },
  CUSTOM_FIELD_ADDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
  },
};

export const hireOnboardEvents = {
  SEND_INVITE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {},
  },
};

const talentEventProps = {
  domain: 'talent',
};

export const talentEvents = {
  LEARN_MORE_MODAL_VIEWED: {
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
  },
  CREATE_COMPANY_PROFILE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'create-company-profile',
      locationOfAction: 'talent-welcome-dashboard',
      ...talentEventProps,
    },
  },
  EDIT_COMPANY_PROFILE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'edit-company-profile',
      locationOfAction: 'company-profile',
      ...talentEventProps,
    },
  },
  SAVE_COMPANY_PROFILE: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      elementType: 'button',
      elementName: 'save-company-profile',
      locationOfAction: 'company-profile-form',
      ...talentEventProps,
    },
  },
  PREVIEW_COMPANY_PROFILE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'preview-company-profile',
      locationOfAction: 'company-profile-form',
      ...talentEventProps,
    },
  },
  VIEW_LIVE_COMPANY_PROFILE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'link',
      elementName: 'view-live-company-profile',
      locationOfAction: 'company-profile-form',
      ...talentEventProps,
    },
  },
  LEARN_MORE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'learn-more',
      locationOfAction: 'talent-welcome-dashboard',
      ...talentEventProps,
    },
  },
  CREATE_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'create-new-job',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  IMPORT_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'import-new-job',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  EDIT_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'edit-job',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  SAVE_JOB: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      elementType: 'button',
      elementName: 'save-job',
      locationOfAction: 'job-form',
      ...talentEventProps,
    },
  },
  PUBLISH_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'publish-job',
      locationOfAction: 'job-form',
      ...talentEventProps,
    },
  },
  UNPUBLISH_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'unpublish-job',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  ADVERTISE_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'advertise-job',
      locationOfAction: 'job-form',
      ...talentEventProps,
    },
  },
  STOP_ADVERTISE_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'stop-advertise-job',
      locationOfAction: 'job-form',
      ...talentEventProps,
    },
  },
  CLOSE_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'close-job',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  REOPEN_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'reopen-job',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  DELETE_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'delete-job',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  IMPROVE_JOB_DESCRIPTION_WITH_AI: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'revise-with-ai',
      locationOfAction: 'job-form',
      ...talentEventProps,
    },
  },
  COPY_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'copy-job',
      ...talentEventProps,
    },
  },
  PAY_FOR_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'pay-for-job',
      locationOfAction: 'job-form',
      ...talentEventProps,
    },
  },
  SUBSCRIPTION_CHECKOUT: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'subscription-checkout',
      locationOfAction: 'job-form',
      ...talentEventProps,
    },
  },
  PREVIEW_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'preview-job',
      locationOfAction: 'job-form',
      ...talentEventProps,
    },
  },
  VIEW_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'view-job',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  SEARCH_JOBS: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      tableName: 'jobs',
      action: 'search',
      ...talentEventProps,
    },
  },
  VIEW_LIVE_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'link',
      elementName: 'view-live-job',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  VIEW_CANDIDATES: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'link',
      elementName: 'view-job-candidates',
      locationOfAction: 'talent-dashboard',
      ...talentEventProps,
    },
  },
  DOWNLOAD_CANDIDATE_CV: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'download-candidate-cv',
      locationOfAction: 'talent-candidates',
      ...talentEventProps,
    },
  },
  CONTACT_CANDIDATE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'contact-candidate',
      locationOfAction: 'talent-candidates',
      ...talentEventProps,
    },
  },
  CONTACT_CANDIDATE_CONFIRM: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'contact-candidate-confirm',
      locationOfAction: 'talent-candidates',
      ...talentEventProps,
    },
  },
  MOVE_CANDIDATE_TO_STAGE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'move-candidate-to-stage',
      locationOfAction: 'talent-candidates',
      ...talentEventProps,
    },
  },
  CANDIDATE_BOOKMARKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'bookmark-candidate',
      locationOfAction: 'talent-candidates',
      ...talentEventProps,
    },
  },
  VIEW_CANDIDATE_PROFILE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'view-candidate-profile',
      locationOfAction: 'talent-candidates',
      ...talentEventProps,
    },
  },
  SOURCE_CANDIDATES: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'source-candidates',
      ...talentEventProps,
    },
  },
};

const jobsEventProps = {
  domain: 'jobs',
};

export const jobsEvents = {
  SIGN_UP: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'sign-up',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
  SIGN_UP_ENDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      elementType: 'button',
      elementName: 'sign-up-ended',
      locationOfAction: 'sign-up-form',
      ...jobsEventProps,
    },
  },
  SIGN_IN: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'sign-in',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
  SIGN_IN_ENDED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      elementType: 'button',
      elementName: 'sign-in-ended',
      locationOfAction: 'sign-in-form',
      ...jobsEventProps,
    },
  },
  TOP_BANNER: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'top-banner',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
  DISMISS_TOP_BANNER: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'dismiss-top-banner',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
  POST_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'post-a-job',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
  SORT_JOBS: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      tableName: 'jobs',
      ...jobsEventProps,
    },
  },
  SEARCH_JOBS: {
    name: TABLE_INTERACTION_COMPLETED,
    eventProps: {
      tableName: 'jobs',
      action: 'search',
      ...jobsEventProps,
    },
  },
  SAVE_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'save-job',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
  REPORT_JOB: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      elementName: 'report-job',
      ...jobsEventProps,
    },
  },
  REMOVE_JOB_FROM_FAVORITES: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'remove-job-from-favorites',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
  VIEW_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'view-job',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
  VIEW_COMPANY_PROFILE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'view-company-profile',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
  APPLY_TO_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'apply',
      locationOfAction: 'job-details',
      ...jobsEventProps,
    },
  },
  APPLIED_TO_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'applied',
      locationOfAction: 'job-details',
      ...jobsEventProps,
    },
  },
  NOT_APPLIED_TO_JOB: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'did-not-apply',
      locationOfAction: 'job-details',
      ...jobsEventProps,
    },
  },
  SET_CANDIDATE_PROFILE_VISIBILITY: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'set-profile-visibility',
      locationOfAction: 'jobs',
      ...jobsEventProps,
    },
  },
};

const EMPLOYER_BILLING_TABLE = {
  locationOfAction: 'employer-billing-table',
};
const EMPLOYER_BILLING_WIDGETS = {
  locationOfAction: 'employer-billing-widgets',
};
export const employerBillingEvents = {
  DOWNLOAD_INVOICE_PDF_AT_BILLING_TABLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'download-invoice-pdf',
      elementType: 'button',
      ...EMPLOYER_BILLING_TABLE,
    },
  },
  COPY_INVOICE_NUMBER_AT_BILLING_TABLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'copy-invoice-number',
      elementType: 'button',
      ...EMPLOYER_BILLING_TABLE,
    },
  },
  PAY_INVOICE_AT_BILLING_TABLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'pay-invoice',
      elementType: 'button',
      ...EMPLOYER_BILLING_TABLE,
    },
  },
  CLICK_CONTACT_US_ON_PAYMENT_BADGE_AT_BILLING_TABLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'contact-us-payment-failed',
      elementType: 'link',
      ...EMPLOYER_BILLING_TABLE,
    },
  },
  VIEW_BILLING_UNAPPLIED_CREDIT_WIDGET: {
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      elementName: 'billing-unapplied-credit-widget',
      ...EMPLOYER_BILLING_WIDGETS,
    },
  },
  CLICK_BILLING_WIDGETS_SECTION: (isExpanded) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'billing-widgets-section',
      elementType: 'button',
      elementData: {
        action: isExpanded ? 'expand' : 'collapse',
      },
      ...EMPLOYER_BILLING_WIDGETS,
    },
  }),
  VIEW_BILLING_WIDGETS_SECTION: ({
    numberOfDueInvoices,
    widgetsCurrencies,
    widgetsCurrenciesDisplayed,
  }) => ({
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      elementName: 'billing-widgets-section',
      numberOfDueInvoices,
      widgetsCurrencies,
      widgetsCurrenciesDisplayed,
      ...EMPLOYER_BILLING_WIDGETS,
    },
  }),
  CLICK_BILLING_WIDGET: ({ widgetCurrency }) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'current-balance-widget',
      elementType: 'widget',
      elementData: {
        widgetCurrency,
      },
      ...EMPLOYER_BILLING_WIDGETS,
    },
  }),
  VIEW_BILLING_FEEDBACK_MESSAGE: ({ paymentStatus, numberOfInvoices }) => ({
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      elementName: 'billing-feedback-messages',
      paymentStatus,
      numberOfInvoices,
      ...EMPLOYER_BILLING_WIDGETS,
    },
  }),
  CLICK_BILLING_FEEDBACK_MESSAGE: ({ paymentStatus, actionType }) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'billing-feedback-messages',
      elementData: {
        paymentStatus,
        actionType,
      },
      ...EMPLOYER_BILLING_WIDGETS,
    },
  }),
};

export const privacyNoticeEvents = {
  OBJECT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {},
  },
};

export const rightToWorkEvents = {
  OBJECT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      domain: 'rtw-check-employer',
    },
  },
  FORM_SUBMITTED: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'right-to-work-check-employer',
      locationOfAction: 'right-to-work-check',
      domain: 'rtw-check-employer',
    },
  },
};

export const globalPayrollChangeEvents = {
  OPEN_VIEW_CHANGE_EVENT_DRAWER: ({ payrollRunSlug, isEmployeePayDetailsPage }) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'open-change-events-drawer',
      elementType: 'button',
      locationOfAction: isEmployeePayDetailsPage ? 'employee-pay-details' : 'payroll-run',
      elementData: {
        payrollRunSlug,
      },
    },
  }),
};

export const remoteAIFeedbackEvents = {
  /**
   * @param {Object} props
   * @param {String} props.contentType
   * @param {String} props.contentSlug
   * @param {String} props.question
   *
   */
  THUMBS_UP: ({ contentType, contentSlug, question }) => ({
    name: COMMON_EVENT_NAMES.SURVEY_ACTION_COMPLETED,
    eventProps: {
      surveyName: 'remote-ai-content-feedback',
      surveyId: contentType,
      surveyAction: 'completed',
      platform: 'web',
      response: 'Positive',
      surveyQuestionData: {
        contentSlug,
        question,
      },
    },
  }),
  /**
   * @param {Object} props
   * @param {String} props.contentType
   * @param {String} props.contentSlug
   * @param {String} props.question
   * @param {String} [props.reason]
   * @param {String | null} [props.additionalDetails]
   *
   */
  THUMBS_DOWN: ({ contentType, contentSlug, question, reason, additionalDetails }) => ({
    name: COMMON_EVENT_NAMES.SURVEY_ACTION_COMPLETED,
    eventProps: {
      surveyName: 'remote-ai-content-feedback',
      surveyId: contentType,
      surveyAction: 'completed',
      platform: 'web',
      response: 'Negative',
      surveyQuestionData: {
        contentSlug,
        question,
        reason,
        additionalDetails,
      },
    },
  }),
};

export const workflowsEvents = {
  CREATE_NEW_WORKFLOW: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'create-new-workflow',
      locationOfAction: 'workflows',
    },
  },
  /**
   * @param {string} templateName
   */
  CREATE_NEW_WORKFLOW_FROM_TEMPLATE: (templateName) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'create-new-workflow-from-template',
      locationOfAction: 'workflows',
      elementData: templateName,
    },
  }),
  /**
   * @param {string} templateName
   */
  DISCARD_NEW_WORKFLOW_FROM_TEMPLATE: (templateName) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementType: 'button',
      elementName: 'discard-new-workflow-from-template',
      locationOfAction: 'workflows',
      elementData: templateName,
    },
  }),
  /**
   * @param {'Trigger' | 'Action' | 'Condition'} nodeType
   */
  CONFIGURE_WORKFLOW_STEP: (nodeType) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'configure-workflow-step',
        locationOfAction: 'workflows',
        elementData: nodeType,
      },
    };
  },
  /**
   * @param {'Trigger' | 'Action' | 'Condition | 'ConditionPrompt'} nodeType
   */
  CANCEL_WORKFLOW_STEP: (nodeType) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'cancel-workflow-step',
        locationOfAction: 'workflows',
        elementData: nodeType,
      },
    };
  },
  /**
   * @param {'Trigger' | 'Action' | 'Condition'} nodeType
   */
  DELETE_WORKFLOW_STEP: (nodeType) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'delete-workflow-step',
        locationOfAction: 'workflows',
        elementData: nodeType,
      },
    };
  },
  /**
   * @param {'form' | 'prompt'} conditionType
   */
  SWITCH_CONDITION_TYPE: (conditionType) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'toggle',
        elementName: 'switch-conditional-input-label',
        locationOfAction: 'workflows',
        elementData: conditionType,
      },
    };
  },
  /**
   * @param {{application: string, triggerEvent: string}} data
   */
  SAVE_WORKFLOW_TRIGGER: ({ application, triggerEvent }) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'save-workflow-trigger',
        locationOfAction: 'workflows',
        elementData: {
          step: 'Trigger',
          application,
          triggerEvent,
        },
      },
    };
  },
  /**
   * @param {{{ actionIndex: number; reason: string; placeholders?: string[]; }[]}} validationErrors
   */
  WORKFLOW_VALIDATION_ERROR: ({ validationErrors }) => {
    return {
      name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
      eventProps: {
        elementType: 'error',
        elementName: 'validation-error',
        locationOfAction: 'workflows',
        elementData: {
          validationErrors,
        },
      },
    };
  },
  /**
   * @param {{application: string, actionEvent: string, details: string}}
   */
  SAVE_WORKFLOW_ACTION: ({ application, actionEvent, details }) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'save-workflow-action',
        locationOfAction: 'workflows',
        elementData: {
          step: 'Action',
          application,
          actionEvent,
          details,
        },
      },
    };
  },

  /**
   * We need to have "draft" as part of the status even if we don't use it, otherwise we can't pass a WorkflowStatus entity to the function
   * @param {{status: 'published' | 'paused' | 'archived' | 'unarchived' | 'draft' | 'deleted', workflowName: string | null, workflowDescription: string | null}}
   */
  UPDATE_WORKFLOW_STATUS: ({ status, workflowName, workflowDescription }) => {
    if (status === 'deleted') {
      return {
        name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
        eventProps: {
          elementType: 'button',
          elementName: 'delete-workflow',
          locationOfAction: 'workflows',
          elementData: {
            workflowName,
            workflowDescription,
          },
        },
      };
    }
    if (status === 'published') {
      return {
        name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
        eventProps: {
          elementType: 'button',
          elementName: 'publish-workflow',
          locationOfAction: 'workflows',
          elementData: {
            workflowName,
            workflowDescription,
          },
        },
      };
    }
    if (status === 'paused') {
      return {
        name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
        eventProps: {
          elementType: 'button',
          elementName: 'pause-workflow',
          locationOfAction: 'workflows',
          elementData: {
            workflowName,
            workflowDescription,
          },
        },
      };
    }
    if (status === 'archived') {
      return {
        name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
        eventProps: {
          elementType: 'button',
          elementName: 'archive-workflow',
          locationOfAction: 'workflows',
          elementData: {
            workflowName,
            workflowDescription,
          },
        },
      };
    }

    // We need a default case, which is 'unarchived' in this case
    // Because events cannot return undefined or null
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'unarchive-workflow',
        locationOfAction: 'workflows',
        elementData: {
          workflowName,
          workflowDescription,
        },
      },
    };
  },
  /**
   * @param {{workflowName: string | null, workflowDescription: string | null}}
   */
  OPEN_WORKFLOW_CARD: ({ workflowName, workflowDescription }) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'open-workflow-card',
        locationOfAction: 'workflows',
        elementData: {
          workflowName,
          workflowDescription,
        },
      },
    };
  },
  /**
   * @param {{workflowName: string | null, workflowDescription: string | null}}
   */
  SAVE_WORKFLOW_DETAILS: ({ workflowName, workflowDescription }) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'save-workflow-changes',
        locationOfAction: 'workflows',
        elementData: {
          workflowName,
          workflowDescription,
        },
      },
    };
  },
  /**
   * @param {{path: string, title: string}}
   */
  ADD_PLACEHOLDER_TO_MESSAGE: ({ path, title }) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'placeholders-menu-item',
        elementName: 'data-placeholder',
        locationOfAction: 'workflows',
        elementData: {
          path,
          title,
        },
      },
    };
  },
  /**
   * @param {{prompt: string}}
   */
  VALIDATE_ONLYCONTINUEIF_PROMPT: ({ prompt }) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'condition-prompt-action-form-submit',
        locationOfAction: 'workflows',
        elementData: {
          prompt,
          isValidated: false,
        },
      },
    };
  },
  /**
   * @param {{operation: string, property: string, value: string[]}}
   */
  SAVE_ONLYCONTINUEIF: ({ operation, property, value }) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'condition-action-form-submit',
        locationOfAction: 'workflows',
        elementData: {
          operation,
          property,
          value,
        },
      },
    };
  },
  /**
   * @param {{prompt: string}}
   */
  SAVE_ONLYCONTINUEIF_PROMPT: ({ prompt }) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementType: 'button',
        elementName: 'condition-prompt-action-form-submit',
        locationOfAction: 'workflows',
        elementData: {
          prompt,
          isValidated: true,
        },
      },
    };
  },
  RENDER_SLACK_CHANNEL_FALLBACK_FIELD: {
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      elementType: 'field',
      elementName: 'slack-channel-fallback-field',
      locationOfAction: 'workflows',
    },
  },
};

/**
 * @param {string} elementName
 * @param {string} locationOfAction
 * @param {string} formTitle
 * @param {string} formFieldName
 */
const aiFormAssistantClickEvent = (elementName, locationOfAction, formTitle, formFieldName) => ({
  name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
  eventProps: {
    elementName,
    elementType: 'button',
    locationOfAction,
    formTitle,
    formFieldName,
  },
});

export const aiFormAssistantEvents = {
  /**
   *
   * @param {string} locationOfAction
   * @param {string} formTitle
   * @param {string} formFieldName
   */
  AI_FORM_ASSISTANT_OPENED: (locationOfAction, formTitle, formFieldName) =>
    aiFormAssistantClickEvent('write-with-ai-open', locationOfAction, formTitle, formFieldName),
  /**
   *
   * @param {string} locationOfAction
   * @param {string} formTitle
   * @param {string} formFieldName
   */
  AI_FORM_ASSISTANT_CANCELLED: (locationOfAction, formTitle, formFieldName) =>
    aiFormAssistantClickEvent('write-with-ai-cancel', locationOfAction, formTitle, formFieldName),
  /**
   * @param {string} locationOfAction
   * @param {string} formTitle
   * @param {string} formFieldName
   */
  AI_FORM_ASSISTANT_TRY_AGAIN: (locationOfAction, formTitle, formFieldName) =>
    aiFormAssistantClickEvent(
      'write-with-ai-try-again',
      locationOfAction,
      formTitle,
      formFieldName
    ),
  /**
   * @param {string} locationOfAction
   * @param {string} formTitle
   * @param {string} formFieldName
   */
  AI_FORM_ASSISTANT_USE_THIS: (locationOfAction, formTitle, formFieldName) =>
    aiFormAssistantClickEvent('write-with-ai-use-this', locationOfAction, formTitle, formFieldName),
};

export const offboardingEvents = {
  CREATE_OFFBOARDING_CHECKLIST_VIEWED: {
    name: 'Message Event Delivered',
    eventProps: {
      elementName: 'create-offboarding-checklist',
      locationOfAction: 'offboardings',
    },
  },
  CREATE_OFFBOARDING_CHECKLIST_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'create-offboarding-checklist',
      elementType: 'button',
      locationOfAction: 'offboardings',
    },
  },
  SET_DEFAULT_OFFBOARDING_CHECKLIST_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'set-default-checklist',
      elementType: 'button',
      locationOfAction: 'offboardings',
    },
  },
  GET_OFFBOARDING_HELP_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'get-offboarding-help',
      elementType: 'button',
    },
  },
};

export const selfServiceReset2FAEvents = {
  RESET_STEP_REACHED: (result) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        formName: '2fa-reset',
        result,
      },
    };
  },
  RESET_2FA_CLICK: (locationOfAction) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'reset-2FA',
        locationOfAction,
      },
    };
  },
  START_VERIFICATION_CLICK: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'start-verification',
    },
  },
  SETUP_2FA: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'verify-2fa-success',
      elementName: 'setup-2fa',
    },
  },
  SKIP_TO_DASHBOARD: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'verify-2fa-success',
      elementName: 'skip-to-dashboard',
    },
  },
};

export const accountsSwitchingEvents = {
  ACCOUNT_LINK_REMEMBER_ME: (locationOfAction) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'Remember me',
        locationOfAction,
      },
    };
  },
  ADD_ACCOUNT_CTA_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'add-account-cta',
      elementType: 'button',
      locationOfAction: 'add-account-modal',
    },
  },
  ADD_ACCOUNT_CTA_ACCOUNT_SETTINGS_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'add-account',
      elementType: 'button',
      locationOfAction: 'account-settings',
    },
  },
  REMOVE_ACCOUNT_CTA_ACCOUNT_SETTINGS_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'remove-account',
      elementType: 'button',
      locationOfAction: 'account-settings',
    },
  },
  REMOVE_ACCOUNT_CTA_ACCOUNT_SETTINGS_CONFIRM: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'confirm-remove-account',
      elementType: 'button',
      locationOfAction: 'remove-account-modal',
    },
  },
  ADD_ACCOUNT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'add-account',
      elementType: 'button',
      locationOfAction: 'account-menu',
    },
  },
  ADD_ACCOUNT_CLICKED_ACCOUNT_CHOOSER: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'added-account',
      elementType: 'button',
      locationOfAction: 'account-chooser',
    },
  },
  ACCOUNT_CHOOSER_LOG_IN: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'log-in',
      elementType: 'button',
      locationOfAction: 'account-chooser-login',
    },
  },
  PROFILE_SWITCH_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'switch-profiles',
      elementType: 'button',
      locationOfAction: 'profile-switcher',
    },
  },
  PROFILE_SWITCHER_EXPANDED_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'expand-profile-switcher',
      elementType: 'button',
      locationOfAction: 'account-menu',
    },
  },
  ACCOUNT_SWITCHER_EXPANDED_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'expand-account-switcher',
      elementType: 'button',
      locationOfAction: 'account-menu',
    },
  },
  ACCOUNT_SWITCHER_ACCOUNT_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'switch-accounts',
      elementType: 'button',
      locationOfAction: 'accounts-switcher',
    },
  },
  SWITCH_ACCOUNT_CTA_CLICKED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'switch-account-cta',
      elementType: 'button',
      locationOfAction: 'switch-account-modal',
    },
  },
  ADD_ACCOUNT_FORM_SUBMITTED: (result) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        formName: 'confirm-add-account',
        locationOfAction: 'add-account-modal',
        result,
      },
    };
  },
  ADD_ACCOUNT_FORM_SUBMITTED_ACCOUNT_CHOOSER: (result) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        formName: 'login-to-add-account',
        locationOfAction: 'account-chooser-pw-page',
        result,
      },
    };
  },
  REMOVE_ACCOUNT_FORM_SUBMITTED: (result) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        formName: 'confirm-remove-account',
        locationOfAction: 'remove-account-modal',
        result,
      },
    };
  },
  ADD_ACCOUNT_FORM_SUBMITTED_ACCOUNT_CHOOSER_SSO: {
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'login-to-add-account',
      locationOfAction: 'account-chooser-sso-page',
    },
  },
  SWITCH_ACCOUNT_FORM_SUBMITTED: (result) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        formName: 'confirm-switch-account',
        locationOfAction: 'switch-account-modal',
        result,
      },
    };
  },
};

export const templatedPayrollEvents = {
  TEMPLATE_CREATE_BUTTON: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'template-create-button',
      elementType: 'button',
      locationOfAction: 'templates-table',
    },
  },

  /**
   * @param {Object} formFieldValues
   */
  TEMPLATE_CREATION_FORM: (formFieldValues) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        formName: 'template-creation',
        locationOfAction: 'template-creation-page',
        formFieldValues,
      },
    };
  },

  /**
   * @param {Object} formFieldValues
   */
  TEMPLATE_CONFIGURATION_FORM: (formFieldValues) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        formName: 'template-configuration',
        locationOfAction: 'template-configuration-page',
        formFieldValues,
      },
    };
  },

  /**
   * @param {Object} formFieldValues
   */
  TEMPLATE_SAVE_FORM: (formFieldValues) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        formName: 'template-save',
        locationOfAction: 'template-preview-page',
        formFieldValues,
      },
    };
  },

  /**
   * @param {'template-creation-page' | 'template-configuration-page' | 'template-save-page'} locationOfAction
   */
  TEMPLATE_EXIT_BUTTON: (locationOfAction) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'template-exit-button',
        elementType: 'button',
        locationOfAction,
      },
    };
  },

  /**
   * @param {'exports-table' | 'template-created-success-modal' | 'templates-table-row-action'} locationOfAction
   */
  EXPORT_CREATE_BUTTON: (locationOfAction) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'export-create-button',
        elementType: 'button',
        locationOfAction,
      },
    };
  },

  TEMPLATE_SAVE_SUCCESS_CARD: {
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      title: 'template-save-success-card',
      locationOfAction: 'template-save-page',
    },
  },

  /**
   * @param {'export-filters-page' | 'exports-template-selection-page'} locationOfAction
   */
  EXPORT_DOWNLOAD_EXIT: (locationOfAction) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'export-download-exit',
        elementType: 'button',
        locationOfAction,
      },
    };
  },

  /**
   * @param {Object} formFieldValues
   */
  EXPORT_SAVE_FORM: (formFieldValues) => {
    return {
      name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
      eventProps: {
        formName: 'export-save',
        locationOfAction: 'export-filters-page',
        formFieldValues,
      },
    };
  },

  /**
   * @param {'exports-table' | 'export-filters-page'} locationOfAction
   */
  EXPORT_DOWNLOAD_CSV: (locationOfAction) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'export-download-csv',
        elementType: 'button',
        locationOfAction,
      },
    };
  },

  EXPORT_PREVIEW_FROM_TABLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'export-preview-from-table',
      elementType: 'button',
      locationOfAction: 'exports-table',
    },
  },

  TEMPLATE_DUPLICATE_FROM_TABLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'template-duplicate-from-table',
      elementType: 'button',
      locationOfAction: 'templates-table',
    },
  },

  TEMPLATE_PREVIEW_FROM_TABLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'template-preview-from-table',
      elementType: 'button',
      locationOfAction: 'templates-table',
    },
  },

  TEMPLATE_EDIT_FROM_TABLE: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'template-edit-from-table',
      elementType: 'button',
      locationOfAction: 'templates-table',
    },
  },
};

export const kmAiInPlatformChatbotFeedbackEvents = {
  /**
   * @param {Object} props
   * @param {String} props.contentType
   * @param {String} props.feedbackItem
   * @param {String} props.feedbackItemOrigin
   */
  THUMBS_UP: ({ feedbackItem, feedbackItemOrigin }) => ({
    name: COMMON_EVENT_NAMES.SURVEY_ACTION_COMPLETED,
    eventProps: {
      surveyName: 'remote-ai-chatbot-feedback',
      surveyAction: 'completed',
      platform: 'web',
      response: 'positive',
      formFieldValues: {
        feedbackItem,
        feedbackItemOrigin,
      },
    },
  }),

  /**
   * @param {Object} props
   * @param {String} props.contentType
   * @param {String} props.feedbackItem
   * @param {String} props.feedbackItemOrigin
   *
   */
  THUMBS_DOWN: ({ feedbackItem, feedbackItemOrigin }) => ({
    name: COMMON_EVENT_NAMES.SURVEY_ACTION_COMPLETED,
    eventProps: {
      surveyName: 'remote-ai-chatbot-feedback',
      surveyAction: 'completed',
      platform: 'web',
      response: 'negative',
      formFieldValues: {
        feedbackItem,
        feedbackItemOrigin,
      },
    },
  }),
};

export const kmAiInPlatformChatbot = {
  /**
   * @param {Object} props
   * @param {String} props.locationOfAction
   */
  OPEN: ({ locationOfAction }) => ({
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'remote-ai-chatbot',
      elementType: 'button',
      action: 'open',
      locationOfAction,
    },
  }),

  /**
   * @param {Object} props
   * @param {String} props.locationOfAction
   */
  CLOSE: ({ locationOfAction }) => ({
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: 'remote-ai-chatbot',
      elementType: 'button',
      action: 'close',
      locationOfAction,
    },
  }),

  /**
   * @param {Object} props
   * @param {String} props.message
   */
  SEND_MESSAGE: ({ message }) => ({
    name: 'Bot Summoned',
    eventProps: {
      platform: 'web',
      origin: 'chat-window',
      originText: message,
    },
  }),

  /**
   * @param {Object} props
   * @param {String} props.ticketId
   * @param {String} props.tag
   * @param {String} props.conversationId
   */
  SUBMIT_TICKET: ({ ticketId, tag, conversationId }) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      formName: 'zendesk-ticket',
      formFieldValues: {
        ticketId,
        tag,
        conversationId,
      },
    },
  }),

  /**
   * @param {Object} props
   * @param {String} props.documentTitle
   * @param {String} props.url
   */
  SOURCE_CLICK: ({ documentTitle, url }) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      title: documentTitle,
      content: url,
      locationOfAction: 'chatbot',
    },
  }),

  TERMS_OF_USE_CLICK: () => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'chatbot',
      action: 'terms_of_use',
    },
  }),

  AGENT_HANDOFF: () => ({
    name: 'Support Request Initiated',
    eventProps: {
      locationOfAction: 'chatbot',
      channel: 'native-messaging',
    },
  }),

  HELP_EMAIL_CLICKED: () => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'chatbot',
      action: 'help_email',
    },
  }),

  ATTACHMENT_UPLOADED: (fileInfo) => ({
    name: 'AI Knowledge Bot: Attachment Uploaded',
    eventProps: {
      locationOfAction: 'chatbot',
      action: 'attachment_uploaded',
      elementData: fileInfo,
    },
  }),
};

export const remoteControlPanelEvents = {
  PANEL_OPENED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'remote-control-panel-button',
      elementType: 'button',
      locationOfAction: 'global',
    },
  },
  TAB_SELECTED: (tabName) => ({
    name: COMMON_EVENT_NAMES.NAVIGATION_CLICKED,
    eventProps: {
      elementName: tabName,
      elementType: 'tab',
      locationOfAction: 'remote-control-panel',
      navigationLevel: 'primary',
    },
  }),
};

export const buildTaskGroupItemClickedEvent = ({ checklistURN, taskURN, isTaskCompleted }) => {
  const taskName = taskURN.split(':').pop();
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: taskName,
      elementType: 'link',
      locationOfAction: checklistURN,
      taskId: taskURN,
      isTaskCompleted,
    },
  };
};

export const highResGrossToNetReportEvents = {
  /**
   * @param {'admin-payroll-run-overview' | 'admin-payroll-run-details' } locationOfAction
   * @param {'csv' | 'xlsx'} fileType
   * @param {string} country
   */
  CUSTOMER_G2N_DOWNLOAD_BY_PAYROLL_SPECIALIST: (locationOfAction, fileType, country) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'customer-g2n-download-by-payroll-specialist',
        elementType: 'button',
        locationOfAction,
        fileType,
        country,
      },
    };
  },

  /**
   * @param {'csv' | 'xlsx'} fileType
   * @param {'local' | 'invoice'} reportType
   * @param {string} country
   */
  CUSTOMER_G2N_DOWNLOAD_BY_EMPLOYER: (fileType, reportType, country) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'customer-g2n-download-by-employer',
        elementType: 'button',
        locationOfAction: 'employer-payroll-reports-table',
        fileType,
        reportType,
        country,
      },
    };
  },
};

export const buildPayrollRunFxRatesEvent = ({ country, legalEntityName, payrollRunName }) => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'payroll-run-fx-rates-view-modal',
      elementType: 'button',
      locationOfAction: 'payroll-run-overview',
      country,
      legalEntityName,
      payrollRunName,
    },
  };
};

export const buildPayrollRunSaveFxRatesEvent = ({
  country,
  legalEntityName,
  payrollRunName,
  rates,
}) => {
  return {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'payroll-run-fx-rates-save',
      elementType: 'button',
      locationOfAction: 'payroll-run-fx-rates-modal',
      country,
      legalEntityName,
      payrollRunName,
      rates,
    },
  };
};

export const reconciliationEvents = {
  /**
   * @param {string} country
   * @param {number} employeeCount
   * @param {string} category
   */
  RECONCILIATION_BULK_COMMENT_ADDED: (country, employeeCount, category) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'reconciliation-bulk-comment',
        elementType: 'button',
        locationOfAction: 'reconciliation-bulk-comment-modal',
        country,
        employeeCount,
        category,
      },
    };
  },

  /**
   * @param {string} country
   * @param {number} employeeCount
   */
  RECONCILIATION_BULK_STATUS_UPDATED: (country, employeeCount) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'reconciliation-bulk-status-update',
        elementType: 'button',
        locationOfAction: 'bulk-set-employee-status-button',
        country,
        employeeCount,
      },
    };
  },

  /**
   * @param {string} country
   * @param {number} employeeCount
   * @param {string} category
   */
  RECONCILIATION_COMMENT_ADDED: (country, category) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'reconciliation-single-comment',
        elementType: 'button',
        locationOfAction: 'reconciliation-employee-details-modal',
        country,
        category,
      },
    };
  },

  /**
   * @param {string} country
   * @param {'to_review' | 'ready'} status
   */
  RECONCILIATION_STATUS_UPDATED: (country, status) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'reconciliation-status-update',
        elementType: 'textarea',
        locationOfAction: 'reconciliation-employee-details-modal',
        country,
        status,
      },
    };
  },

  /**
   * @param {string} country
   * @param {string} payrollRunSlug
   */
  RECONCILIATION_EMPLOYEE_VIEW_DETAILS: (country, payrollRunSlug) => {
    return {
      name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
      eventProps: {
        elementName: 'payroll-run-reconciliation-employee-modal',
        elementType: 'button',
        locationOfAction: 'employees-table',
        country,
        payrollRunSlug,
      },
    };
  },
};

export const featureFlagEvents = {
  /**
   * @param {Object} props
   * @param {String} props.flagName
   * @param {Boolean} props.enabled
   * @param {String} props.userId
   * @param {Object} props.attributes
   */
  FEATURE_FLAG_EVALUATED: ({ flagName, enabled, userId, attributes }) => ({
    name: 'Feature Flag Evaluated',
    eventProps: {
      flagName,
      enabled,
      userId,
      attributes,
    },
  }),
};

const recruitSelfServeFlowProps = {
  domain: 'recruit',
  locationOfAction: 'recruit-plan-self-serve-flow',
};

export const recruitSelfServeFlowEvents = {
  FREE_TRIAL_INITIATED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      ...recruitSelfServeFlowProps,
      elementName: 'recruit-trial-started',
      elementType: 'button',
    },
  },
  PAYMENT_SUCCEEDED: {
    name: COMMON_EVENT_NAMES.CONTENT_DISPLAYED,
    eventProps: {
      ...recruitSelfServeFlowProps,
      elementName: 'recruit-plan-purchased',
    },
  },
};

export const complianceHubEvents = {
  COMPLIANCE_HUB_ENTRY_VIEWED: (entry) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'view-compliance-watchtower-entry',
      elementType: 'link',
      locationOfAction: 'compliance-watchtower',
      slug: entry.slug,
      title: entry.change.title,
      country: entry.country.name,
    },
  }),
  COMPLIANCE_HUB_SOURCE_CLICKED: (entrySlug, entryTitle, source) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'follow-compliance-watchtower-source',
      elementType: 'link',
      locationOfAction: 'compliance-watchtower',
      slug: entrySlug,
      title: entryTitle,
      source,
    },
  }),
};

export const hrisPlanEvents = {
  TALK_TO_SALES_CLICKED: ({ locationOfAction }) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'hris-talk-to-sales',
      elementType: 'button',
      locationOfAction,
    },
  }),
  BANNER_LEARN_MORE_CLICKED: ({ locationOfAction }) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'hris-banner-learn-more-link',
      elementType: 'button',
      locationOfAction,
    },
  }),
};

export const orgChartEvents = {
  ADD_REPORTING_LINES: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'add-reporting-lines',
      locationOfAction: 'org-chart',
      elementType: 'button',
    },
  },
};

export const companyFeedbackEvents = {
  FEEDBACK_REPLY_SUBMITTED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'company-feedback',
      elementType: 'button',
      elementName: 'send-company-feedback-response',
    },
  },
  FEEDBACK_CLOSED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'company-feedback',
      elementType: 'button',
      elementName: 'close-company-feedback',
    },
  },
  FEEDBACK_REOPENED: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'company-feedback',
      elementType: 'button',
      elementName: 'reopen-company-feedback',
    },
  },
  VIEW_DETAILS: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      locationOfAction: 'company-feedback',
      elementName: 'view-company-feedback-details',
    },
  },
};

export const stateTaxEvents = {
  STATE_TAX_FORM_OPENED: ({ userIsAdmin }) => ({
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'open-state-tax-form',
      elementType: 'button',
      locationOfAction: 'state-tax-card',
      userType: userIsAdmin ? USER_TYPE.ADMIN : USER_TYPE.EMPLOYER,
    },
  }),
  STATE_TAX_FORM_SUBMITTED: ({ userIsAdmin }) => ({
    name: COMMON_EVENT_NAMES.FORM_SUBMITTED,
    eventProps: {
      formName: 'state-tax-form',
      locationOfAction: 'state-tax-card',
      userType: userIsAdmin ? USER_TYPE.ADMIN : USER_TYPE.EMPLOYER,
    },
  }),
};

export const reportEvents = {
  EXPORT_DATA: {
    name: COMMON_EVENT_NAMES.OBJECT_CLICKED,
    eventProps: {
      elementName: 'download-report-dataset',
      elementType: 'button',
      locationOfAction: 'reports',
    },
  },
};
