import { toast } from '@remote-com/norma';

import type { UserContextUser } from '@/src/components/UserProvider/context';
import { captureException } from '@/src/helpers/captureException';
import type { $TSFixMe } from '@/types';

declare global {
  interface Window {
    Appcues?: {
      identify: (userId: string, properties?: $TSFixMe) => void;
      show: (id: string) => void;
    };
  }
}

export const APPCUES_EXPERIENCE_IDS = {
  HRIS_TRIAL_BANNER_LEARN_MORE: '4d0339f5-9233-45c5-b5c9-71ac2b6465a0',
} as const;

export function identifyAppcuesUser(user?: UserContextUser) {
  // The Appcues SDK is imported via Rudderstack.
  // Find out more about device mode here:
  // https://www.rudderstack.com/docs/destinations/rudderstack-connection-modes/#device-mode
  if (user && window.Appcues) {
    window.Appcues.identify(user.slug);
  }
}

export function showAppcuesExperienceById(
  id: (typeof APPCUES_EXPERIENCE_IDS)[keyof typeof APPCUES_EXPERIENCE_IDS]
) {
  // Ad blocker extensions are the main reason for Appcues not load
  if (!window.Appcues) {
    captureException(
      `window.Appcues not found when trying to load an experience. Experience id: ${id}`
    );
    toast.error({
      title: 'Sorry, something went wrong.',
      description: 'Please try again or contact support for help.',
    });
    return;
  }

  window.Appcues.show(id);
}
