import { useGet } from '@remote-com/data-layer';
import { Box } from '@remote-com/norma';
import { useField } from 'formik';
import { useRef } from 'react';
import styled from 'styled-components';

import type { PayrollRun } from '@/src/api/config/employ/payrollRun.types';
import { PaginatedSearchSelectField } from '@/src/components/Ui/Select/PaginatedSearchSelectField';
import { useUserCan } from '@/src/components/UserCan';
import { getFlagSrc } from '@/src/domains/countries/helpers';
import { PAYROLL_RUN_TYPES_BY_ID } from '@/src/domains/payroll/constants';
import { getPayrollRunEntityName } from '@/src/domains/payroll/employer/helpers';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import { formatFullMonthDayYear } from '@/src/helpers/date';

const PayrollRunFlag = styled.img`
  width: ${({ theme }) => theme.space[6]}px;
`;

export const PayrollRunName = styled(Box)`
  margin: 0 0 0 ${({ theme }) => theme.space[3]}px;
`;

const getPayrollRunOptionLabel = (
  option: PayrollRun.EmployerPayrollRunResponse['data'] | { label: string; slug: string }
) => {
  // 'Not in payroll run' option
  if ('label' in option) return <PayrollRunName>{option.label}</PayrollRunName>;

  const country = option.legalEntity?.address?.country;
  const payrollRunEntityName = getPayrollRunEntityName(option);

  return (
    <Box display="flex" alignItems="flex-start">
      <PayrollRunFlag
        src={getFlagSrc(country?.name) ?? undefined}
        alt={`Flag of ${country?.name}`}
      />
      <PayrollRunName>
        {country?.name} {PAYROLL_RUN_TYPES_BY_ID[option.type]?.label} - {payrollRunEntityName} -{' '}
        {formatFullMonthDayYear(new Date(option.expectedPayoutDate))}
      </PayrollRunName>
    </Box>
  );
};

export default function PayrollRunSelect({
  filters = {},
  description,
  placeholder,
  initialOptions = [],
  ...props
}: {
  filters?: Record<string, unknown>;
  description: string;
  placeholder?: string;
  initialOptions: {
    label: string;
    value: string;
  }[];
  name: string;
}) {
  const { userCan } = useUserCan();
  const userHasPermissions = userCan('read', Resources.employer.payroll);
  const [{ value }] = useField(props.name);
  const initialSlug = useRef(value).current;

  const { isLoading, data: selectedPayrollRun } = useGet('/api/v1/employer/payroll-runs/[slug]', {
    params: { pathParams: { slug: initialSlug } },
    options: {
      select: (response) => response?.data,
      enabled: !!initialSlug && initialSlug !== 'none' && userHasPermissions,
    },
  });

  const defaultOptions = selectedPayrollRun ? [selectedPayrollRun] : [];

  return (
    <PaginatedSearchSelectField
      label="Payroll run"
      placeholder="Select payroll run"
      query={{
        path: '/api/v1/employer/payroll-runs',
        params: {
          queryParams: {
            ...filters,
            sortBy: 'expected_payout_date',
            order: 'desc',
          },
        },
        options: {
          select: (res: PayrollRun.EmployerPayrollRunResponse) =>
            getDataWithRenamedProperty(res, 'payrollRuns'),
          enabled: userHasPermissions,
        },
      }}
      searchQueryAlias="name"
      loadingMessage={() => 'Loading payroll runs…'}
      getOptionLabel={getPayrollRunOptionLabel}
      getOptionValue={(option: PayrollRun.EmployerPayrollRunResponse['data']) => option.slug}
      description={description}
      initialOptions={initialOptions}
      transformValue={(option: PayrollRun.EmployerPayrollRunResponse['data']) => option.slug}
      isControlled
      {...props}
      defaultOptions={defaultOptions}
      isLoadingDefaultOptions={isLoading}
      disableReactSelectSearch
    />
  );
}
