import type { AccountSettingsApi } from '@/src/api/config/employ/accountSettings.types';
import type { MainMenuItem } from '@/src/domains/navigation/main-menu/types';

type FavoriteItem = AccountSettingsApi.FavoriteResponse['data'][number];
export function matchFavoritePath(
  favoritePath: FavoriteItem['path'],
  itemPath: MainMenuItem['href']
) {
  return (
    favoritePath === itemPath || `${favoritePath}/` === itemPath || favoritePath === `${itemPath}/`
  );
}
