import type { UserAccountResponse } from '@/src/components/UserProvider';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { userCan } from '@/src/domains/registration/auth/helpers';

export const adminContractorRootTabs: {
  [key: string]: {
    value: string;
    label: string;
    isTabVisible: (
      user: UserAccountResponse,
      isFeatureFlagEnabled: (flag: string) => boolean
    ) => boolean;
  };
} = {
  CONTRACTOR_INVOICES: {
    value: 'contractor-invoices',
    label: 'Contractor invoices',
    isTabVisible: (user) => userCan('read', Resources.contractor_invoices, user),
  },
  SCHEDULED_INVOICES: {
    value: 'scheduled-invoices',
    label: 'Invoice schedules',
    isTabVisible: (user) => userCan('read', Resources.scheduled_contractor_invoices, user),
  },
  RECURRING_INVOICES: {
    value: 'recurring-invoices',
    label: 'Recurring invoices',
    isTabVisible: (user) => {
      return userCan('read', Resources.recurring_contractor_invoices, user);
    },
  },
  TRANSACTION_RECEIPTS: {
    value: 'transaction-receipts',
    label: 'Transaction receipts',
    isTabVisible: (user) => {
      return userCan('read', Resources.transaction_receipts, user);
    },
  },
  PREPAID_FUNDS: {
    value: 'prepaid-funds',
    label: 'Prepaid funds',
    isTabVisible: (user, isFeatureFlagEnabled) => {
      // TODO: use correct permissions
      const hasPermissions = userCan('read', Resources.transaction_receipts, user);

      const isPrefundingForCMEnabled = isFeatureFlagEnabled?.('prefunding_contractor_management');
      const isPrefundingForCoREnabled = isFeatureFlagEnabled?.('prefunding_contractor_of_record');

      const isFeatureEnabled = isPrefundingForCMEnabled || isPrefundingForCoREnabled;

      return hasPermissions && isFeatureEnabled;
    },
  },
};

export const tabQueryParameter = 'tab';
