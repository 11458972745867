import propTypes from '@styled-system/prop-types';
import type { ReactNode } from 'react';
import type { DefaultTheme } from 'styled-components';
import styled from 'styled-components';
import type {
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
} from 'styled-system';
import {
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
} from 'styled-system';

import type { ComponentProps } from '../../types';
import type { CursorProps, GapProps } from '../../utils/system-constants';
import { columnGap, cursor, gap, rowGap } from '../../utils/system-constants';
import type {
  ColorPropsWithCustomPalette,
  CustomBorderProps,
  CustomShadowProps,
} from '../../utils/types';

type StyledBoxProps = SpaceProps<DefaultTheme, number | 'auto'> &
  ColorPropsWithCustomPalette &
  LayoutProps &
  FlexboxProps &
  GridProps &
  PositionProps &
  CustomBorderProps &
  CustomShadowProps &
  CursorProps &
  GapProps & {
    children?: ReactNode;
  };
const composedStyles = compose(
  space,
  color,
  layout,
  flexbox,
  grid,
  position,
  border,
  cursor,
  shadow,
  columnGap,
  gap,
  rowGap
);

// eslint-disable-next-line remote/norma-prefer-layout
export const Box = styled.div.withConfig<StyledBoxProps>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !composedStyles.propNames?.includes(prop) && defaultValidatorFn(prop),
})`
  ${composedStyles}
`;

export const BoxPropTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.flexbox,
  ...propTypes.grid,
  ...propTypes.position,
  ...propTypes.border,
  ...propTypes.shadow,
  ...propTypes.gap,
};

Box.propTypes = BoxPropTypes;

export type BoxProps = ComponentProps<typeof Box>;
