import { Box, Text } from '@remote-com/norma';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { type TextAreaField } from '@/src/components/Form/DynamicForm/types';
import type { EditorRef } from '@/src/domains/editor/Editor';
import EditorField from '@/src/domains/editor/EditorField';
import { EditorWrapper } from '@/src/domains/editor/styled/EditorWrapper.styled';

const CustomEditorWrapper = styled(Box)`
  .ProseMirror {
    min-height: 152px;
  }
`;

export function RichTextareaField({ description, label, name }: Omit<TextAreaField, 'type'>) {
  const { values, setFieldValue } = useFormikContext();
  const value = get(values, name);

  const editorRef = useRef<EditorRef>(null);

  useEffect(() => {
    const { current } = editorRef;
    if (!current || !current.editor) return;
    current.editor.commands.setContent(value);
  }, [value]);

  return (
    <CustomEditorWrapper>
      <EditorWrapper>
        <EditorField
          // @ts-expect-error this should be string only
          placeholder={label}
          minHeight="152px"
          name={name}
          setValue={(_, val) => {
            setFieldValue(name, val);
          }}
          valueFromApi={value}
          editorRef={editorRef}
        />
      </EditorWrapper>
      {description && (
        <Box px={5}>
          <Text variant="xs" color="grey.600">
            {description}
          </Text>
        </Box>
      )}
    </CustomEditorWrapper>
  );
}
