// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2OutlineLinkAlt = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M7.75 7a5 5 0 1 0 5 5 .75.75 0 0 1 1.5 0 6.5 6.5 0 1 1-6.5-6.5.75.75 0 0 1 0 1.5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M16 6.75A5.25 5.25 0 0 0 10.75 12a.75.75 0 0 1-1.5 0A6.75 6.75 0 1 1 16 18.75a.75.75 0 0 1 0-1.5 5.25 5.25 0 0 0 0-10.5Z" /></svg>;
const ForwardRef = forwardRef(IconV2OutlineLinkAlt);
export { ForwardRef as IconV2OutlineLinkAlt };