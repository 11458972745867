import {
  Box,
  BoxedIcon,
  focusRingOffsetBorder,
  focusVisible,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@remote-com/norma';
import { IconV2OutlineInfo } from '@remote-com/norma/icons/IconV2OutlineInfo';
import type { MouseEvent } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@/src/components/Button';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

import type { TableControlItemUiBaseProps } from './types';

export interface TableControlItemUiProps extends TableControlItemUiBaseProps {
  count?: number;
  isSelected?: boolean;
  isLoading?: boolean;
  onSelected: (id: string) => void;
}

export const CardStyled = styled(Box)<{
  $isSelected?: boolean;
}>`
  display: block;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.grey['200']};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  transition: 0.2s;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.colors.grey['100']};
    border-color: ${({ theme }) => theme.colors.grey['300']};
  }

  ${({ theme }) =>
    focusVisible(css`
      ${focusRingOffsetBorder(theme.colors.brand[600], theme.colors.blank)};
      border-color: ${theme.colors.grey[200]};
    `)}

  /* Apply focus styles when Button inside receives focus */
  &:has(button:focus-visible) {
    ${({ theme }) => css`
      ${focusRingOffsetBorder(theme.colors.brand[600], theme.colors.blank)};
      border-color: ${theme.colors.grey[200]};
    `}
  }

  ${(props) =>
    props.$isSelected &&
    css`
      background-color: ${props.theme.colors.brand['50']};
      border-color: ${props.theme.colors.brand['600']};
    `}
`;

const ButtonStyled = styled(Button)`
  background-color: transparent;
`;

const ButtonNoFocusStyled = styled(Button)`
  &:focus-visible {
    outline: none;
    box-shadow: none !important;
    border: none;
  }
`;

export const TableControlItemUi = ({
  id,
  topLabel,
  count,
  icon,
  tone,
  isSelected,
  isLoading,
  tooltipTextFirstRow,
  tooltipTextSecondRow,
  actionLabel,
  onSelected,
  onActionClick,
  unitLabel = { singular: 'item', plural: 'items' },
  className,
}: TableControlItemUiProps) => {
  const onClickHandler = (e: MouseEvent<HTMLElement>) => {
    const noTextSelected = !window?.getSelection()?.toString();
    if (noTextSelected) {
      onSelected(id);
      e.preventDefault();
    }
  };

  return (
    <CardStyled
      data-dd-action-name={`${id}-control`}
      onClick={onClickHandler}
      $isSelected={isSelected}
      className={className}
    >
      <Stack width="100%" height="100%" direction="row" alignItems="center" px={5} py={4} gap={3}>
        <BoxedIcon
          Icon={icon}
          tone={tone}
          size="lg"
          appearance={isSelected ? 'subtle' : 'default'}
        />
        <Stack flex="1 1 auto">
          <ButtonNoFocusStyled onClick={onClickHandler} variant="raw">
            {topLabel && (
              <Box display="flex" gap={2} alignItems="center">
                <Text variant="smMedium" color="grey.700">
                  {topLabel}
                </Text>
                {tooltipTextFirstRow && (
                  <Tooltip label={tooltipTextFirstRow}>
                    <IconV2OutlineInfo aria-label="info" width="12px" height="12px" />
                  </Tooltip>
                )}
              </Box>
            )}
            {isLoading ? (
              <Box mt={4}>
                <Skeleton variant="rect" width={85} height={16} />
              </Box>
            ) : (
              <Box display="flex" gap={2} alignItems="center">
                <Text variant="lgMedium" color="grey.900">
                  {count ?? '-'}
                </Text>
                <Text variant="smMedium">
                  {typeof unitLabel === 'string'
                    ? unitLabel
                    : getSingularPluralUnit(
                        count ?? 0,
                        unitLabel.singular,
                        unitLabel.plural,
                        false,
                        false
                      )}
                </Text>
                {tooltipTextSecondRow && (
                  <Tooltip label={tooltipTextSecondRow}>
                    <IconV2OutlineInfo aria-label="info" width="12px" height="12px" />
                  </Tooltip>
                )}
              </Box>
            )}
          </ButtonNoFocusStyled>
        </Stack>
        {count !== 0 && !isLoading && onActionClick && isSelected && (
          <ButtonStyled
            variant="outline"
            size="xs"
            id={`${id}-action`}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              onActionClick();
            }}
          >
            {actionLabel}
          </ButtonStyled>
        )}
      </Stack>
    </CardStyled>
  );
};
