import styled, { css } from 'styled-components';

import { IconChevronDown } from '../../icons/build/IconChevronDown';
import { Box } from '../../layout';
import { focusVisible, focusRingOffsetBorder } from '../common.styled';
import { Text } from '../text';

export type SizeProps = {
  size?: 'default' | 'compact';
};

export type ChevronPositionProps = {
  chevronPosition?: 'default' | 'inline';
};

type SummaryProps = SizeProps;
type SummaryInsideProps = ChevronPositionProps;
type SummaryContentProps = ChevronPositionProps;

export const ChevronDown = styled(IconChevronDown)`
  fill: var(--colors-irisBlue);
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease;
`;

/* Workaround as Safari fails to respect flex alignment in summary element */
export const SummaryInside = styled(Box)<SummaryInsideProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ chevronPosition }) =>
    chevronPosition === 'inline' &&
    css`
      justify-content: start;
    `}
`;

export const Summary = styled.summary<SummaryProps>`
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => `${theme.space[5]}px ${theme.space[3]}px`};
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};

  ::marker,
  ::-webkit-details-marker {
    display: none;
  }

  details[open] > & {
    border-bottom: none;
    ${ChevronDown} {
      transform: rotate(180deg);
    }
  }

  ${focusVisible(css`
    ${focusRingOffsetBorder('var(--colors-irisBlue)')};
  `)};

  ${({ size }) =>
    size === 'compact' &&
    css`
      border: none;
      padding: 0;
    `}
`;

export const SummaryContent = styled(Text).attrs({
  variant: 'smMedium',
  pr: 20,
})<SummaryContentProps>`
  ${({ chevronPosition }) =>
    chevronPosition === 'inline' &&
    css`
      padding-right: 5px;
      justify-content: start;
    `}
`;

export const Details = styled.details`
  flex: 1;
`;

export const DetailsContent = styled(Box).attrs({
  pb: 0,
  variant: 'sm',
  color: 'lynch',
})`
  box-sizing: border-box;
  details[open] > & {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};

    ${({ size }) =>
      size === 'compact' &&
      css`
        border: none;
      `}
  }
`;
