// @ts-nocheck
// DO NOT EDIT! Generated by compress.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconTextUnderline = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M7 3a1 1 0 0 0-2 0v7a7 7 0 1 0 14 0V3a1 1 0 1 0-2 0v7a5 5 0 1 1-10 0V3ZM4 20a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2H4Z" /></svg>;
const ForwardRef = forwardRef(IconTextUnderline);
export { ForwardRef as IconTextUnderline };