import { getClientBuildManifest } from 'next/dist/client/route-loader';
import { isDynamicRoute } from 'next/dist/shared/lib/router/utils';
import { getRouteRegex } from 'next/dist/shared/lib/router/utils/route-regex';

/**
 * Checks if a page exists in the client build manifest.
 *
 * This function is required for production builds of Next JS single page applications with dynamic routes.
 * Without it, the server will return a 404 error on the initial load of dynamic routes because it cannot match the URL slug on the server side.
 * This function serves as a workaround by replacing the URL when the 404 page renders.
 *
 * @param asPath - The path to check for existence.
 * @returns A promise that resolves to a boolean that indicates whether the page exists.
 */
export async function pageExists(asPath: string) {
  const { sortedPages } = await getClientBuildManifest();
  const asPathSanitized = asPath === '/' ? asPath : asPath.replace(/\/$/, '').split('?')[0];
  return (
    sortedPages.includes(asPathSanitized) ||
    sortedPages.some((page) => isDynamicRoute(page) && getRouteRegex(page).re.test(asPathSanitized))
  );
}
