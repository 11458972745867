import merge from 'lodash/merge';
import type { MutateOptions, UseMutationResult } from 'react-query';
import type { PartialDeep, Primitive } from 'type-fest';

// Removes keys from `TObject` that have an empty object as a value.
type StripEmptyObjectKeys<TObject> = {
  [Property in keyof TObject as TObject[Property] extends Record<string, never>
    ? never
    : Property]: TObject[Property];
};
type DefinedPrimitive = Exclude<Primitive, undefined>;
type ExcludePropertyDefinedIn<TObject, TProperty> = TProperty extends keyof TObject
  ? TObject[TProperty] extends DefinedPrimitive
    ? never
    : TProperty
  : TProperty;

// Recursively collects properties of `TFull` that are not present or are undefined
// in `TPartial`.
type RequireMissingDeep<TFull, TPartial> = StripEmptyObjectKeys<{
  [Property in keyof TFull as ExcludePropertyDefinedIn<
    TPartial,
    Property
  >]: Property extends keyof TPartial
    ? RequireMissingDeep<TFull[Property], TPartial[Property]>
    : TFull[Property];
}>;

// Intersection of `RequireMissingDeep` with `PartialDeep` is needed to support passing
// parameters that were already present in `defaultParams` to override them.
type Complements<TFull, TPartial> = PartialDeep<TFull> & RequireMissingDeep<TFull, TPartial>;

/**
 * Wraps a `useMutation` hook with some default params. It essentially
 * merges whatever is present on `defaultParams` with the provided params
 * on either `mutate` or `mutateAsync`.
 *
 * Params provided to those functions are merged "on top" of `defaultParams`.
 */
export default function useMutationWithDefaultParams<
  TData,
  TError,
  TVariables,
  TContext,
  TDefaultParams extends PartialDeep<TVariables>
>(mutation: UseMutationResult<TData, TError, TVariables, TContext>, defaultParams: TDefaultParams) {
  return {
    ...mutation,
    mutate: (
      params: Complements<TVariables, TDefaultParams>,
      options?: MutateOptions<TData, TError, TVariables, TContext>
    ) => mutation.mutate(merge(defaultParams, params) as TVariables, options),
    mutateAsync: (
      params: Complements<TVariables, TDefaultParams>,
      options?: MutateOptions<TData, TError, TVariables, TContext>
    ) => mutation.mutateAsync(merge(defaultParams, params) as TVariables, options),
  };
}
