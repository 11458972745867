import { Stack } from '@remote-com/norma';
import { type Editor } from '@tiptap/core';
import type { FunctionComponent } from 'react';
import { useCallback, useState } from 'react';

import { composeFieldText } from '@/src/components/Form/DynamicForm/composeFields';
import { DynamicFormModal } from '@/src/components/Modal/DynamicFormModal';
import {
  getInlineStylesTools,
  getListStylesTools,
  getHeadingStylesTools,
} from '@/src/domains/editor/helpers';
import {
  ToolbarWrapper,
  ToolbarContainer,
  VerticalSpacer,
  ToggleButton,
} from '@/src/domains/editor/styled/Toolbar.styled';

export const Toolbar = ({
  editor,
  ToolbarAction,
}: {
  editor: Editor;
  ToolbarAction?: FunctionComponent<{ editor: Editor }>;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const previousUrl = editor.getAttributes('link').href;

  const [linkUrl, setLinkUrl] = useState<string>('');
  const [urlText, setUrlText] = useState<string>('');

  const openModal = useCallback(() => {
    setLinkUrl(editor.getAttributes('link').href);
    // @ts-expect-error
    setUrlText(editor.commands.getSelectedText());

    setIsVisible(true);
  }, [editor]);

  const closeModal = useCallback(() => {
    setIsVisible(false);
    setLinkUrl('');
  }, []);

  const saveLink = useCallback(
    (values: Record<string, any>) => {
      if (values.linkUrl) {
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: values.linkUrl, target: '_blank' })
          .run();
        if (values.urlText) {
          const { from } = editor.state.selection;
          editor.commands.deleteSelection();
          editor.commands.insertContentAt(from, values.urlText, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: 'full',
            },
          });
        }
      } else {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
      }
      closeModal();
    },
    [editor, closeModal]
  );

  if (!editor) {
    return null;
  }

  const inlineStylesTools = getInlineStylesTools(editor, openModal);
  const headingStylesTools = getHeadingStylesTools(editor);
  const listStylesTools = getListStylesTools(editor);

  return (
    <ToolbarContainer data-testid="editor-toolbar">
      <Stack direction="row" justifyContent="center" alignItems="center" padding={3} gap={5}>
        <ToolbarWrapper>
          {inlineStylesTools.map(({ style, name, icon, handler }) => (
            <ToggleButton
              key={style}
              Icon={icon}
              label={style}
              variant="ghost"
              size="sm"
              $active={editor.isActive(name)}
              onClick={handler}
            />
          ))}
        </ToolbarWrapper>
        {!!headingStylesTools.length && (
          <>
            <VerticalSpacer />
            <ToolbarWrapper>
              {headingStylesTools.map(({ style, name, level, icon, handler }) => (
                <ToggleButton
                  key={`${style}${level}`}
                  Icon={icon}
                  label={style}
                  variant="ghost"
                  size="sm"
                  $active={editor.isActive(name, { level })}
                  onClick={handler}
                />
              ))}
            </ToolbarWrapper>
          </>
        )}
        <VerticalSpacer />
        <ToolbarWrapper>
          {listStylesTools.map(({ style, name, icon, handler }) => (
            <ToggleButton
              key={style}
              Icon={icon}
              label={style}
              variant="ghost"
              size="sm"
              $active={editor.isActive(name)}
              onClick={handler}
            />
          ))}
        </ToolbarWrapper>
        {ToolbarAction ? (
          <>
            <VerticalSpacer />
            <ToolbarWrapper>
              <ToolbarAction editor={editor} />
            </ToolbarWrapper>
          </>
        ) : null}
      </Stack>
      {isVisible ? (
        <DynamicFormModal
          TODO_MIGRATE_handleOnSubmit="DONE"
          formName="urlForm"
          onCancel={() => setIsVisible(false)}
          modalProps={{
            headerTitle: previousUrl ? 'Edit link' : 'Add link',
          }}
          onSubmit={saveLink}
          fieldValues={{
            urlText,
            linkUrl,
          }}
          fields={[
            composeFieldText({ name: 'urlText', label: 'Link text', required: false }),
            composeFieldText({ name: 'linkUrl', label: 'Enter link URL', required: false }),
          ]}
        />
      ) : null}
    </ToolbarContainer>
  );
};
