import type { WorkScheduleField, WorkScheduleItem } from '@/src/components/Form/DynamicForm/types';

import { daysOfTheWeekLabels, fieldToLabel } from './constants';

const subfields: (keyof WorkScheduleItem)[] = [
  'startTime',
  'endTime',
  'hours',
  'breakDurationMinutes',
];

export function displayFieldInfoBlock({
  field,
  formFieldValue,
  keepFieldName,
}: {
  field: WorkScheduleField;
  formFieldValue?: WorkScheduleItem[];
  keepFieldName: boolean;
}) {
  return {
    title: `${field.label}`,
    ...(keepFieldName ? { name: field.name } : {}),
    value: formFieldValue?.map((workScheduleItem: WorkScheduleItem) => ({
      title: daysOfTheWeekLabels[workScheduleItem.day],
      value: subfields.map((subfield) => ({
        ...(keepFieldName ? { name: subfield } : {}),
        title: fieldToLabel[subfield],
        value: workScheduleItem[subfield],
      })),
    })),
  };
}
