import { nanoid } from 'nanoid';
import getConfig from 'next/config';

import {
  SIGN_UP_ROUTE,
  ONBOARDING_COMPANY_ROUTE,
  ONBOARDING_FREELANCER_ROUTE,
} from '@/src/constants/routes';
import { isDev } from '@/src/helpers/general';

const {
  publicRuntimeConfig: {
    API_BASE_URL_CLIENT,
    REMOTE_AI_API_BASE_URL,
    WS_BASE_URL,
    RUDDERSTACK_DATAPLANE_URL,
    ASSET_PREFIX,
    COMPENSATION_API_BASE_URL,
    TALENT_API_BASE_URL,
    RUDDERSTACK_SERVER_SIDE_COOKIES_BASE_PROXY_URL,
  },
} = getConfig();

export const ALLOWED_MARKETING_ORIGINS = [
  'https://amplify.outbrain.com',
  'https://analytics.tiktok.com',
  'https://*.twitter.com',
  'https://bat.bing.com',
  'https://connect.facebook.net',
  'https://d.adroll.com',
  'https://dev.visualwebsiteoptimizer.com',
  'https://googleads.g.doubleclick.net',
  'https://js.hs-analytics.net',
  'https://js.hs-banner.com',
  'https://js.hs-scripts.com',
  'https://s.adroll.com',
  'https://snap.licdn.com',
  'https://snippet.growsumo.com',
  'https://static.ads-twitter.com',
  'https://tr.outbrain.com',
  'https://www.google-analytics.com',
  'https://www.googleadservices.com',
  'https://www.redditstatic.com',
  'https://*.upsellit.com',
  'https://*.civiccomputing.com',
  'https://client-registry.mutinycdn.com',
  'https://api-v2.mutinyhq.io',

  // linkedin, quora and other marketing pixels
  // https://app.asana.com/0/1199142086456941/1208232872110910/f
  // https://remote-com.slack.com/archives/C07C65Q8XRR/p1725526500089319
  'https://*.linkedin.com',
  'https://p.adsymptotic.com',
  'https://*.linkedin.oribi.io',
  'https://sjs.bizographics.com',
  'https://a.quora.com',
  'https://td.doubleclick.net/',
  'https://js.hsadspixel.net',
  'https://js.hubspot.com',
  'https://partnerlinks.io',

  // unsafe-inline must be used for the pages with Marketing trackers because Google Tag Manager will generate
  // tags with unpredictable hashes
  "'unsafe-inline'",
];

/**
 *  If there is a websocket connection error we fallback to long polling.
 *  The websocket url is transformed into a http url.
 *  We need to add this transformed url to our csp. */
function getLongPollFallbackURL(url = '') {
  if (!url) {
    return '';
  }

  if (url.includes('wss://')) {
    return url.replace('wss://', 'https://');
  }

  // Support local environments
  if (url.includes('ws://')) {
    return url.replace('ws://', 'http://');
  }

  return url;
}

const ALLOWED_CONNECT_ORIGINS = [
  API_BASE_URL_CLIENT,
  REMOTE_AI_API_BASE_URL,
  WS_BASE_URL,
  getLongPollFallbackURL(WS_BASE_URL),
  COMPENSATION_API_BASE_URL,
  TALENT_API_BASE_URL,
  ASSET_PREFIX,
  RUDDERSTACK_SERVER_SIDE_COOKIES_BASE_PROXY_URL,
  RUDDERSTACK_DATAPLANE_URL,
  'https://forms.hscollectedforms.net',
  // Google Analytics.
  'https://*.google.com',
  'https://*.google-analytics.com',
  // Datadog RUM, see https://docs.datadoghq.com/real_user_monitoring/faq/content_security_policy/
  'https://browser-intake-datadoghq.com',
  // Zendesk web widget, see
  // https://developer.zendesk.com/documentation/classic-web-widget-sdks/web-widget/integrating-with-google/csp/
  'https://*.zdassets.com',
  'https://*.zendesk.com',
  'https://*.smooch.io',
  'wss://*.smooch.io',
  'wss://*.zendesk.com',
  'https://*.zopim.com',
  'wss://*.zopim.com',
  'https://*.juro.com',

  // Marketing trackers connect to these
  'https://grsm.io',
  'https://d.adroll.com',
  'https://analytics.tiktok.com',
  'https://stats.g.doubleclick.net',
  'https://*.visualwebsiteoptimizer.com',
  'https://bat.bing.com',
  'https://cdn.employ-assets.remote.com',
  'https://*.getbeamer.com',
  'https://*.rudderstack.com', // Rudderstack >=3 sdk
  'https://*.rudderlabs.com', // Rudderstack <3 sdk
  'https://*.appcues.com',
  'https://*.appcues.net',
  'wss://*.appcues.com',
  'wss://*.appcues.net',
  'https://*.sprig.com',
  'https://*.leandata.com',
  'https://*.civiccomputing.com',
  'https://all-remote-content.cdn.prismic.io',
  'https://remote.com',

  // Smartly (Used for address auto-complete)
  'https://*.api.smarty.com',

  // Clearbit (Use for Talent - verifying companies)
  'https://*.clearbit.com',

  // linkedin, quora and other marketing pixels
  // https://app.asana.com/0/1199142086456941/1208232872110910/f
  // https://remote-com.slack.com/archives/C07C65Q8XRR/p1725526500089319
  // these need to be allowed in connect-src so the scripts work
  'https://*.linkedin.com',
  'https://p.adsymptotic.com',
  'https://*.linkedin.oribi.io',
  'https://sjs.bizographics.com',
  'https://a.quora.com',
  'https://td.doubleclick.net/',
  'https://connect.facebook.net',
  'https://client-registry.mutinycdn.com',
  'https://api-v2.mutinyhq.io',
  'https://googleads.g.doubleclick.net',
  'https://pagead2.googlesyndication.com',
  'https://partnerlinks.io',

  // Recaptcha internally fetches more scripts using the fetch API, which requires connect-src to be allowed.
  'https://*.recaptcha.net',

  // Allows connecting to websockets when running Next.js locally
  // Without this, Safari will not load the site because it does
  // not allow websocket connections by default
  isDev() && 'ws://localhost:3000',
  // Allows connecting to lazy compilation server, it can be any port so we use a wildcard
  isDev() && 'localhost:*',
].filter(Boolean);

const ALLOWED_FONT_ORIGINS = [
  ASSET_PREFIX,
  'https://*.gstatic.com',
  'https://*.getbeamer.com',
  'https://*.leandata.com',
  'https://*.smooch.io',
];

const ALLOWED_STYLES_ORIGINS = [
  ASSET_PREFIX,
  'https://*.getbeamer.com',
  'https://fonts.googleapis.com',
  'https://fonts.google.com',
  'https://*.appcues.com',
  'https://*.appcues.net',
  'https://*.smooch.io',
];

const ALLOWED_MEDIA_ORIGINS = [
  ASSET_PREFIX,
  'https://*.zdassets.com',
  'https://*.upsellit.com',
  'https://*.smooch.io',
];

const ALLOWED_SCRIPT_SOURCE_ORIGINS = [
  ASSET_PREFIX,
  'https://consent.cookiebot.com',
  'https://cdn.segment.com',
  'http://js.hs-scripts.com',
  'https://js.hscollectedforms.net',
  // Google domain is used by reCAPTCHA.
  'https://*.google.com',
  'https://*.recaptcha.net',
  'https://*.gstatic.com',
  'https://*.gstatic.cn',
  // Google Tag Manager is an official Google library that manages tagging users
  // for Google Analytics.
  'https://*.googletagmanager.com',
  // Merge is used for several customer-facing integrations, e.g. BambooHR,
  // see https://www.merge.dev/
  'https://*.merge.dev',
  'https://*.plaid.com',
  'https://*.stripe.com',
  'https://*.zdassets.com',
  'https://*.smooch.io',
  'https://*.zopim.com',
  'https://*.withpersona.com',
  'https://*.trustly.one',
  'https://*.paywithmybank.com',
  'https://*.getbeamer.com',
  'https://*.rudderlabs.com',
  'https://*.appcues.com',
  'https://*.sprig.com',
  'https://*.navattic.com/',
  'https://*.clearbit.com',
  'https://test.js.kota.io',
  'https://js.kota.io',
  'https://*.leandata.com',
];

const ALLOWED_FRAME_ORIGINS = [
  'https://consentcdn.cookiebot.com/',
  'https://content-sheets.googleapis.com/',
  'https://content.googleapis.com/',
  'https://*.google.com',
  // No-script fallback for Google Tag Manager is loaded as a frame:
  'https://*.googletagmanager.com',
  'https://*.merge.dev',
  'https://*.plaid.com',
  'https://*.stripe.com',
  // Certain handbook pages have Loom embeds.
  'https://*.loom.com',
  'https://withpersona.com',
  'https://*.getbeamer.com',
  'https://*.paywithmybank.com',
  'https://*.trustly.one',
  'https://*.remote.com',
  'https://paywithmybank.com',
  'https://*.recaptcha.net',
  'https://*.appcues.com',
  'https://*.appcues.net',
  'https://*.navattic.com/',
  'https://calendly.com/',
  // BuiltFirst Marketplace
  'https://*.builtfirst.com/',
  'https://embed.kota.io',
  'https://test.embed.kota.io',
  'https://*.leandata.com',
  'https://staging.simplyinsured.com',
  'https://www.simplyinsured.com',

  // this origin must be allowed in frame-src so this tracking pixel works.
  // https://app.asana.com/0/1199142086456941/1208232872110910/f
  // https://remote-com.slack.com/archives/C07C65Q8XRR/p1725526500089319
  'https://td.doubleclick.net/',
];

export function generateNonce() {
  const randomString = nanoid(32);
  const buffer = Buffer.from(randomString);
  return buffer.toString('base64');
}

/**
 * @param {string} nonce
 * @param {Object} origins
 * @param {string[]} origins.scriptSrc
 * @returns {string}
 */
export function generateCSPHeader(nonce, origins = { scriptSrc: [] }) {
  const scriptSrc = `'self' 'strict-dynamic' ${nonce ? `'nonce-${nonce}'` : ''} ${[
    ...ALLOWED_SCRIPT_SOURCE_ORIGINS,
    ...origins.scriptSrc,
  ].join(' ')}`;

  const csp = {
    'default-src': `'self' ${ASSET_PREFIX}`,
    'connect-src': `'self' blob: ${ALLOWED_CONNECT_ORIGINS.join(' ')}`,
    'font-src': `'self' ${ALLOWED_FONT_ORIGINS.join(' ')}`,
    // Allow `blob:` for embedded PDF previews.
    'frame-src': `'self' blob: ${ALLOWED_FRAME_ORIGINS.join(' ')}`,
    // Images can be loaded from any origin. This policy is so eased
    // because user avatars are loaded from a number of unknown domains.
    // In addition to that, loading inline images (for example, SVG icons) and
    // blobs (for example, file previews) is also permitted.
    'img-src': '* data: blob:',
    // Note that `script-src-elem` policy is non-standard and not supported by Safari and FF,
    // see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/script-src-elem
    // Additionally, `process.env.NODE_ENV` check is inlined to aid with build-time dead code
    // elimination.
    'script-src':
      process.env.NODE_ENV === 'production'
        ? scriptSrc
        : // `unsafe-eval` has to be allowed in development mode because `eval` is used to
          // provide fastest source map generation, see https://webpack.js.org/configuration/devtool/
          `${scriptSrc} 'unsafe-eval'`,
    // Unfortunately `nonce` attribute is not yet supported fully by `styled-components`,
    // see https://github.com/styled-components/styled-components/issues/2363#issuecomment-895464410
    'style-src': `'self' 'unsafe-inline' ${ALLOWED_STYLES_ORIGINS.join(' ')}`,
    'worker-src': `'self' blob:`,
    'media-src': `'self' ${ALLOWED_MEDIA_ORIGINS.join(' ')}`,
  };

  return Object.entries(csp).reduce((acc, [key, val]) => `${acc} ${key} ${val};`, '');
}

export const shouldShowMarketingCSP = (currentUrl) =>
  [SIGN_UP_ROUTE, ONBOARDING_COMPANY_ROUTE, ONBOARDING_FREELANCER_ROUTE]
    .map((route) => currentUrl.startsWith(route))
    .some((b) => b);
