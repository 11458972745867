import type * as light from './palettes';

/**
 * Colour groups in light are defined with "as const",
 * so it enforces not only the keys but also the values.
 * For dark mode, we only want to follow the keys.
 */
type Same<Type> = {
  [K in keyof Type]: string;
};

export const background: Same<typeof light.background> = {
  base: '#141415',
  subtle: '#1E1E1F',
  surface: '#232325',
};

export const foreground: Same<typeof light.foreground> = {
  onBrand: '#FFFFFF',
};

export const white = '#232325';

export const brand: Same<typeof light.brand> = {
  900: '#141415',
  800: '#9ECBF4',
  700: '#72B3EF',
  600: '#3A95E9',
  500: '#2069DF',
  400: '#1658C3',
  300: '#1249A1',
  200: '#11418E',
  100: '#082047',
  50: '#041023',
};

export const grey: Same<typeof light.grey> = {
  900: '#F9FAFB',
  800: '#F1F2F3',
  700: '#E7E9EB',
  600: '#D5D6D7',
  500: '#A6A6A6',
  400: '#787878',
  300: '#585858',
  200: '#434445',
  100: '#2B2E30',
  50: '#131415',
};

export const red: Same<typeof light.red> = {
  900: '#FBE4E4',
  800: '#F8CECE',
  700: '#F3A5A5',
  600: '#E97777',
  500: '#DB4D4D',
  400: '#C03939',
  300: '#9F2D2D',
  200: '#802828',
  100: '#3A1212',
  50: '#1F0A0A',
};

export const orange: Same<typeof light.orange> = {
  900: '#FBE7DA',
  800: '#F7D1B6',
  700: '#F09A75',
  600: '#EA6A43',
  500: '#E64D1E',
  400: '#CE431C',
  300: '#A93119',
  200: '#872B1D',
  100: '#3F140D',
  50: '#220B07',
};

export const yellow: Same<typeof light.yellow> = {
  900: '#F8ECCD',
  800: '#F2D695',
  700: '#ECBC5D',
  600: '#E6A739',
  500: '#DE8D22',
  400: '#C5671A',
  300: '#A34C1A',
  200: '#833F1D',
  100: '#3F1E0E',
  50: '#211007',
};

export const green: Same<typeof light.green> = {
  900: '#D6F5E1',
  800: '#B0EAC3',
  700: '#7DD89B',
  600: '#4BBC71',
  500: '#26A350',
  400: '#13883A',
  300: '#0E6E2E',
  200: '#0F5627',
  100: '#082B14',
  50: '#051D0D',
};

export const greenLight: Same<typeof light.greenLight> = {
  900: '#E3F5D2',
  800: '#D1EFB4',
  700: '#ABDE78',
  600: '#8DCB50',
  500: '#71AF33',
  400: '#58911F',
  300: '#406E1D',
  200: '#3A561E',
  100: '#1A260D',
  50: '#141E0A',
};

export const purple: Same<typeof light.purple> = {
  900: '#EEEBFC',
  800: '#DFDAFA',
  700: '#C5BCF6',
  600: '#A596EF',
  500: '#886BE7',
  400: '#714ED9',
  300: '#603FC2',
  200: '#5035A2',
  100: '#2B204C',
  50: '#1C1532',
};

export const cyan: Same<typeof light.cyan> = {
  900: '#D4F3F9',
  800: '#AEE7F3',
  700: '#77D6E9',
  600: '#39BCD7',
  500: '#1C9DBE',
  400: '#1B829F',
  300: '#1E6980',
  200: '#235968',
  100: '#112C34',
  50: '#0C1D22',
};

export const blueLight: Same<typeof light.blueLight> = {
  900: '#E3F3FB',
  800: '#C0E5F7',
  700: '#89CEF0',
  600: '#4BB2E5',
  500: '#2499D3',
  400: '#147FB5',
  300: '#126392',
  200: '#145278',
  100: '#0B2D41',
  50: '#061823',
};

export const fuchsia: Same<typeof light.fuchsia> = {
  900: '#F9EAFD',
  800: '#F3D5F9',
  700: '#EBB4F3',
  600: '#DD87EB',
  500: '#CA5ADB',
  400: '#AC3FBA',
  300: '#90319A',
  200: '#762A7E',
  100: '#361339',
  50: '#1D0A1F',
};

export const pink: Same<typeof light.pink> = {
  900: '#F9EAF4',
  800: '#F7D3EB',
  700: '#F1B0DB',
  600: '#E77FC1',
  500: '#DA5AAF',
  400: '#C43E8E',
  300: '#AB2B76',
  200: '#8C2862',
  100: '#3C112A',
  50: '#200916',
};
