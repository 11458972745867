type PermissionsDictionary = {
  [key: string]: { label: string; description: string };
};

export const clustersDictionary: PermissionsDictionary = {
  employees: {
    label: 'Employee Information',
    description: 'User details, pay, employment, time off',
  },
  onboarding: { label: 'Onboarding', description: 'Onboarding stages and status' },
  offboarding: { label: 'Offboarding', description: 'Terminations and resignations management' },
  companies: { label: 'Companies', description: 'Company details, billing, documents' },
  'fraud-controls': { label: 'Fraud controls', description: 'KYB and credit risk reviews' },
  countries: { label: 'Countries', description: 'List, settings, and forms' },
  benefits: {
    label: 'Benefits',
    description: 'Plans, costs, employee enrollment, plus additional permissions',
  },
  contracts: { label: 'Contracts', description: 'All contracts and templates' },
  mobility: {
    label: 'Mobility',
    description: 'Right to work checks for employees',
  },
  'cost-calculator': {
    label: 'Cost Calculator',
    description: 'Estimates for hiring new employees',
  },
  'talent-insights': {
    label: 'Talent insights',
    description: 'Where to hire new employees',
  },
  'knowledge-database-page': {
    label: 'Knowledge database',
    description: 'Country guides information',
  },
  'custom-notifications': {
    label: 'Custom Notifications',
    description: 'Custom notifications management',
  },
  payments: { label: 'Payments', description: 'Pricing, fees, invoices, transactions and events' },
  payroll: { label: 'Payroll', description: 'Payroll runs, inputs, adjustments and calendars' },
  users: {
    label: 'Platform access',
    description: 'Manage users with access to Remote, roles, and permissions',
  },
  talent: {
    label: 'Talent',
    description: 'Remote Talent management',
  },
};

export const permissionsDictionary: PermissionsDictionary = {
  'name-and-pronouns': {
    label: 'Name and Pronouns',
    description: '',
  },
  'administrative-details': {
    label: 'Administrative details',
    description: 'ID/passport, marital status, education',
  },
  'sensitive-personal-details': {
    label: 'Sensitive information',
    description: 'Nationality, date and place of birth, sex',
  },
  'employee-time-off': {
    label: 'Time off',
    description: 'Total, taken, and upcoming days',
  },
  'custom-fields': {
    label: 'Custom fields',
    description: 'Created by admins',
  },
  nationality: {
    label: 'Nationality',
    description: '',
  },
  'date-of-birth': {
    label: 'Date of birth',
    description: '',
  },
  'place-of-birth': {
    label: 'Place of birth',
    description: '',
  },
  sex: {
    label: 'Sex',
    description: '',
  },
  'marital-status': {
    label: 'Marital status',
    description: '',
  },
  'tax-information': {
    label: 'Tax information',
    description: '',
  },
  'id-information': {
    label: 'ID information',
    description: '',
  },
  'communication-information': {
    label: 'Communication information',
    description: '',
  },
  'right-to-work': {
    label: 'Right to work',
    description: 'Eligibility, check status, visa/permit info',
  },
  address: {
    label: 'Address',
    description: 'Street, city, postal code, country',
  },
  'emergency-contact-information': {
    label: 'Emergency contact',
    description: 'Name, relationship, email, phone',
  },
  'personal-documents': {
    label: 'Personal documents',
    description: 'Name, status, type, date uploaded',
  },
  'employee-technical-information': {
    label: 'User details',
    description: 'User id, invitation url, role, status, login, reset 2FA, unlock account',
  },
  'technical-information': {
    label: 'Technical information',
    description: '',
  },
  'employment-and-contract-information': {
    label: 'Employment',
    description: 'Company, manager, department, status',
  },
  'employment-requests': {
    label: 'Requests',
    description: 'In-app requests from employees or employers',
  },
  'payment-details': {
    label: 'Payment information',
    description: 'Account details, currency, billing address',
  },
  salary: {
    label: 'Salary',
    description: '',
  },
  'company-information': {
    label: 'Company information',
    description: '',
  },
  'payroll-and-payslips': {
    label: 'Payslips',
    description: 'Amounts, statuses, and dates issued ',
  },
  'employee-expenses': {
    label: 'Expenses',
    description: 'Requested and approved',
  },
  expenses: {
    label: 'Expenses',
    description: 'Expenses management and approval',
  },
  'time-off': {
    label: 'Time off',
    description: 'Total, taken, and upcoming days',
  },
  events: {
    label: 'Events',
    description: 'History of changes to a profile',
  },
  notes: {
    label: 'Notes',
    description: 'Manually entered by admins',
  },
  onboarding: {
    label: 'Onboarding',
    description: 'Onboarding stages and status',
  },
  'onboarding-case': {
    label: 'Onboarding Case',
    description: "Manage Employees' onboarding",
  },
  'onboarding-specialists-management': {
    label: 'Onboarding Specialist management',
    description: 'Manage Onboarding Specialists',
  },
  offboarding: {
    label: 'Offboarding',
    description: 'Offboarding processes management',
  },
  'offboarding-completions': {
    label: 'Offboarding completion',
    description: 'Completion of offboarding processes',
  },
  'offboarding-payroll': {
    label: 'Offboarding payroll',
    description: 'Offboarding processes management related to payroll',
  },
  'general-company-information': {
    label: 'General information',
    description: 'Name, status, Remote entity',
  },
  'company-documents': {
    label: 'Documents',
    description: 'Name, type, date',
  },
  'company-products': {
    label: 'Company products',
    description: 'Manage company products',
  },
  'company-users-and-permissions': {
    label: 'User and permissions',
    description: 'Company admin users management',
  },
  'company-notes': {
    label: 'Company notes',
    description: 'Internal admin notes',
  },
  'company-technical-information': {
    label: 'Authentication',
    description: 'Manage company authentication settings',
  },
  'company-vat-information': {
    label: 'VAT information',
    description: 'Manage company tax information',
  },
  'payments-and-payroll-information': {
    label: 'Payment and Payroll information',
    description: 'Payment information, transactions and settings',
  },
  'eor-payout': {
    label: 'EOR Payout',
    description: 'Payment file, H2H, EOR Payout',
  },
  'freeze-controls': {
    label: 'Freeze controls',
    description: 'Company and collection setting fraud controls.',
  },
  'kyb-reviews': {
    label: 'KYB reviews',
    description: 'KYB status management',
  },
  'credit-risk': {
    label: 'Credit risk',
    description: 'Credit risk management',
  },
  'countries-list-and-settings': {
    label: 'Country settings',
    description: 'Details and benefits management',
  },
  'country-forms': {
    label: 'Country forms',
    description: 'Country Forms preview and editor',
  },
  benefits: {
    label: 'Benefits',
    description: 'Plans, costs, employee enrollment, plus additional permissions',
  },
  contracts: {
    label: 'Contracts',
    description: 'Employment agreements, amendments and their documents',
  },
  'contract-templates': {
    label: 'Contract Templates',
    description: 'Templates for document creation',
  },
  mobility: {
    label: 'Mobility',
    description: 'Right to work checks for employees (only for Mobility team)',
  },
  'cost-calculator': {
    label: 'Cost Calculator',
    description: 'Estimates for hiring new employees',
  },
  'talent-insights': {
    label: 'Talent insights',
    description: 'Where to hire new employees',
  },
  'knowledge-database-page': {
    label: 'Guides and Facts',
    description: 'Country hiring values',
  },
  'knowledge-database-fact-schemas': {
    label: 'Facts Schemas',
    description: 'Values configuration (JSON Schemas)',
  },
  'custom-notifications': {
    label: 'Custom Notifications',
    description: 'Custom notifications management',
  },
  pricing: {
    label: 'Pricing',
    description: 'Country and product pricing',
  },
  'inter-company-invoices': {
    label: 'Inter-Company',
    description: 'Inter-company management',
  },
  'employment-fees': {
    label: 'Employment fees',
    description: 'View the employment fee for each employee',
  },
  'off-payslip-costs': {
    label: 'Off-Payslip costs',
    description: 'Off-payslip costs management',
  },
  billing: {
    label: 'Billing',
    description: '',
  },
  'employee-invoices': {
    label: 'Customer invoices (old)',
    description: '',
  },
  contractors: {
    label: 'Contractors',
    description: 'Contractor payments',
  },
  'payment-events': {
    label: 'Payment events',
    description: 'Who has paid, what, when and how',
  },
  payroll: {
    label: 'Payroll',
    description: 'Payroll runs management',
  },
  'data-exports': {
    label: 'Data Exports',
    description: 'Data exports management',
  },
  'payroll-billables': {
    label: 'Payroll Billables',
    description: 'Billables creation from output pay elements',
  },
  'payroll-output': {
    label: 'Payroll output',
    description: 'Payroll outputs review and approval',
  },
  'payroll-run-force-actions': {
    label: 'Payroll Run force actions',
    description: 'Payroll Run force-create and force-update',
  },
  'payroll-calendars': {
    label: 'Payroll calendars',
    description: 'Payroll calendar visibility and management',
  },
  'payroll-payments': {
    label: 'Payroll payments',
    description: 'Payroll payments management',
  },
  'employee-pay-elements': {
    label: 'Employee Pay Items',
    description: 'Employee Pay Item and Legal Entity Pay Item management',
  },
  'data-export-templates': {
    label: 'Data Export Templates',
    description: 'Data export templates management',
  },
  payslips: {
    label: 'Payslips',
    description: 'Payslips review and approval',
  },
  'payslip-parsing-rules': {
    label: 'Payslip Parsing Rules',
    description: 'Change and review payslip parsing rules',
  },
  'payroll-expenses': {
    label: 'Expenses',
    description: 'Expenses management and approval',
  },
  'payroll-time-off': {
    label: 'Time off',
    description: 'Time off management and approval',
  },
  'persisted-config-settings': {
    label: 'Persisted Config Settings',
    description: 'Manage admins with access to config settings',
  },
  'time-tracking': {
    label: 'Time Tracking',
    description: 'Timesheet and overtime management',
  },
  adjustments: {
    label: 'Adjustments',
    description: 'Adjustments management',
  },
  incentives: {
    label: 'Incentives',
    description: 'Incentives review and management',
  },
  users: {
    label: 'Platform users',
    description: 'View list of users with access to the Remote platform',
  },
  roles: {
    label: 'Roles',
    description: 'Configure and manage roles and permissions',
  },
  'health-checks': {
    label: 'Health & Safety',
    description: 'Health & safety management',
  },
  'cost-approval-requests': {
    label: 'Cost approval requests',
    description: 'Request employer approvals for additional costs.',
  },
  'legal-entities': {
    label: 'Legal Entities',
    description: 'Manage Legal Entities settings',
  },
  'team-plans-management': {
    label: 'Team Plans Management',
    description: 'Manage company Team Plans, including updating dates for accepted Team Plans.',
  },
  'job-listings': {
    label: 'Job listings',
    description: 'Manage Remote Talent job listings',
  },
  metrics: {
    label: 'Metrics',
    description: 'View Remote Talent metrics, company usage and job listings',
  },
  payments: {
    label: 'Payments',
    description: 'Mange Remote Talent payments',
  },
  'annual-plans-management': {
    label: 'Annual Plans',
    description: 'Manage annual plans permissions',
  },
  'pricing-plans-management': {
    label: 'Pricing Plans',
    description: 'Manage all pricing plans permissions',
  },
  'cash-application-specialist': {
    label: 'Cash Application Specialist',
    description: 'Manage Cash App dashboard',
  },
  'remote-paid-services': {
    label: 'Additional service costs',
    description: 'Allows to manage additional service costs',
  },
  'user-impersonation': {
    label: 'Log in as customer',
    description: 'Directly troubleshoot customer issues',
  },
  'billable-items': {
    label: 'Billable Items',
    description: 'Allows to manage billable items',
  },
  'job-catalog': {
    label: 'Job Catalog',
    description: 'Manage a database of jobs available at your company',
  },
};

export const customerPermissionsDictionary: PermissionsDictionary = {
  team: {
    label: 'Team',
    description: 'Access and manage all team member information.',
  },
  'team-restricted': {
    label: 'Team (restricted)',
    description:
      'Access and manage team member information excluding sensitive personal details. Home address, tax information, payment information, administrative details, mobile phone number, personal email address and contractor invoices are restricted.',
  },
  'onboarding-new-hires': {
    label: 'Onboard new hires',
    description: 'Add new employees and contractors and manage their onboarding process',
  },
  'time-off-management': {
    label: 'Manage time off requests',
    description: 'Approve and decline time off requests',
  },
  'time-off': {
    label: 'Add time off to calendars',
    description: "Add time off to your team's calendars",
  },
  'leave-policies': {
    label: 'Leave policies',
    description: 'Leave policies management',
  },
  'time-tracking': {
    label: 'Time Tracking',
    description: 'Timesheet and overtime management',
  },
  projects: {
    label: 'Projects',
    description: 'Can create and configure a project, assign a lead and project members',
  },
  'project-lead': {
    label: 'Project management/lead',
    description:
      'Can be assigned as a project lead, can assign members to projects and view associated contractor invoices',
  },
  'incentives-management': {
    label: 'Incentive management',
    description: 'Manage incentive pay for your employees',
  },
  'expense-management': {
    label: 'Expense management',
    description: 'Review expense requests',
  },
  'requests-review': {
    label: 'Requests review',
    description: 'Review all your team’s requests and their status',
  },
  'payroll-and-payments': {
    label: 'Payroll and payments',
    description: "Manage your company's payments and invoices",
  },
  'contractor-rate': {
    label: 'Contractor rate',
    description: 'Manage contractor billing rate',
  },
  'compliance-hub': {
    label: 'Compliance watchtower',
    description: 'Access to the Compliance watchtower for keeping up to date with regulations',
  },
  'contract-document-sign-by-procuration': {
    label: 'Manage contractor agreements',
    description:
      'Create, assign and sign contractor agreements (including on behalf of other signatories)',
  },
  'pay-contractors': {
    label: 'Contractor invoices',
    description: 'Manage and pay contractor invoices',
  },
  'contractor-invoices-view': {
    label: 'View invoices',
    description: 'View all contractor invoices',
  },
  'contractor-invoices-approval': {
    label: 'Approve invoices',
    description: 'Approve invoices from contractor and company',
  },
  'contractor-invoices-payment': {
    label: 'Pay invoices',
    description: 'Pay invoices from contractor and company',
  },
  'billable-contractor-activities': {
    label: 'Billable activities',
    description: "Monitor your contractors' billable activities",
  },
  'scheduled-contractor-invoices': {
    label: 'Invoice schedules',
    description: 'Manage invoice schedules on behalf of contractors',
  },
  'company-management': {
    label: 'Company management',
    description:
      "Manage your company's information, team plan, settings, roles, workflows, and integrations",
  },
  'cost-calculator': {
    label: 'Cost calculator',
    description: 'Estimate the cost to hire an employee in a particular country',
  },
  'talent-insights': {
    label: 'Talent insights',
    description: 'Where to hire new employees',
  },
  'compensation-explorer': {
    label: 'Salary explorer',
    description: 'Compare employee compensation to the market median',
  },
  'anonymous-feedback-management': {
    label: 'Anonymous Feedback',
    description: 'Reviews anonymous feedback from employees at your company',
  },
  'offboarding-approvals': {
    label: 'Offboarding requests',
    description: 'Approve and reject offboarding requests',
  },
  'manage-jobs': {
    label: 'Job postings',
    description: "Manage your company's job postings",
  },
  'manage-candidates': {
    label: 'Candidates',
    description: 'Discover and manage candidates',
  },
  'performance-review-cycles': {
    label: 'Set up performance review cycles',
    description: 'Create and edit performance review cycles',
  },
  'performance-review-submissions': {
    label: 'Performance review submissions',
    description: 'Manage the status of performance review assessments',
  },
  'performance-review-submission-content': {
    label: 'Performance review submission responses',
    description: 'Access to view completed self-reflections and manager reflections',
  },
  'performance-manage-teams': {
    label: 'Manage team members',
    description:
      'Ability to view team members included in performance reviews and submit manager reflections.',
  },
  'view-equity': {
    label: 'View Equity',
    description: 'View and/or manage equity for direct and indirect reports',
  },
  'reporting-library-hr-reports': {
    label: 'HR Reporting',
    description:
      'Access reports & insights into workforce distribution, organizational structure, and manager-to-employee ratios. This permission needs to combined with either Team or Team (restricted).',
  },
  'reporting-library-payroll-reports': {
    label: 'Payroll Reporting',
    description:
      'Access Payroll related reports, including salary expenses, deductions, benefits, and tax data.',
  },
  'reporting-library-billing-reports': {
    label: 'Billing Reporting',
    description: 'Access billing reports, including year-to-date consolidated invoices.',
  },
  'reporting-library-contractor-reports': {
    label: 'Contractor Reporting',
    description: 'Access contractor related reports',
  },
  'job-catalog': {
    label: 'Job Catalog',
    description: 'Manage a database of jobs available at your company',
  },
};

export const customerClustersDictionary: PermissionsDictionary = {
  people: {
    label: 'People',
    description: 'Manage your global team',
  },
  'performance-management': {
    label: 'Performance management',
    description: 'Manage the performance of your team',
  },
  projects: {
    label: 'Projects',
    description: ' ',
  },
  tools: {
    label: 'Tools',
    description: 'Additional hiring resources',
  },
  contractors: {
    label: 'Contractors',
    description: 'Manage your contractors',
  },
  recruit: {
    label: 'Recruit',
    description: 'Manage your jobs and access recruiting tools',
  },
  'reporting-and-analytics': {
    label: 'Reporting & Analytics',
    description: 'Access to company reporting and analytics',
  },
};
