import type { GetResponse } from '@remote-com/data-layer';
import { createContext, useContext } from 'react';

import { useUserContext } from '@/src/components/UserProvider';
import { matchFavoritePath } from '@/src/domains/userSettings/helpers';

import { useGetUserSettingsFavorites } from './hooks/useGetUserSettingsFavorites';

type UserSettingsFavoritesContextType = {
  userSettingsFavorites?: GetResponse<'/api/v1/account/settings/favorites'>['data'];
  isLoadingUserSettingsFavorites: boolean;
  userSettingsFavoritesError: unknown;
  isPathFavorited: (path: string) => boolean;
  getFavoriteSlug: (path: string) => string | undefined;
};

const UserSettingsFavoritesContext = createContext<UserSettingsFavoritesContextType | undefined>(
  undefined
);

type UserSettingsFavoritesProviderProps = {
  children: React.ReactNode;
};

export function UserSettingsFavoritesProvider({ children }: UserSettingsFavoritesProviderProps) {
  const { user } = useUserContext();
  const {
    data: userSettingsFavorites,
    isLoading: isLoadingUserSettingsFavorites,
    error: userSettingsFavoritesError,
  } = useGetUserSettingsFavorites({ options: { enabled: !!user } });

  const isPathFavorited = (path: string) => {
    if (!userSettingsFavorites) {
      return false;
    }
    return userSettingsFavorites.some((favorite) => matchFavoritePath(favorite.path, path));
  };

  const getFavoriteSlug = (path: string) => {
    if (!userSettingsFavorites) {
      return undefined;
    }
    return userSettingsFavorites.find((favorite) => matchFavoritePath(favorite.path, path))?.slug;
  };

  return (
    <UserSettingsFavoritesContext.Provider
      value={{
        userSettingsFavorites,
        isLoadingUserSettingsFavorites,
        userSettingsFavoritesError,
        isPathFavorited,
        getFavoriteSlug,
      }}
    >
      {children}
    </UserSettingsFavoritesContext.Provider>
  );
}

export function useUserSettingsFavorites() {
  const context = useContext(UserSettingsFavoritesContext);

  if (context === undefined) {
    throw new Error('useUserSettingsFavorites must be used within a UserSettingsFavoritesProvider');
  }

  return context;
}
