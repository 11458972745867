import { trackEvent } from '@remote-com/analytics';
import { Box, Spinner, toast } from '@remote-com/norma';
import { IconArrowRight } from '@remote-com/norma/icons/IconArrowRight';
import { IconV2DuotoneTimesFull } from '@remote-com/norma/icons/IconV2DuotoneTimesFull';
import { useRouter } from 'next/router';
import { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import type { API } from '@/src/api/config/api.types';
import { Button } from '@/src/components/Button';
import type { ColumnType } from '@/src/components/Table/types.ts';
import { SMART_SEARCH_ISSUE, PROMPT_SMART_SEARCH } from '@/src/domains/smart-search/events';
import { useSmartSearch } from '@/src/domains/smart-search/hooks';

type Filters = API.RemoteAi.GenerateSmartSearchFilter.Response['data']['filters'];

type SmartSearchProps = {
  apiPath: string;
  setAllFilters?: (filters: Filters) => void;
  transformFilters: (filters: Filters) => Filters;
  columns: Array<ColumnType>;
  setGlobalFilter?: (filter: string) => void;
  /**
   * What field or fields are searchable by the table's global search (eg: "name", "name and email", "slug")
   */
  globalSearchFields?: string;
  placeholder?: string;
  tableName: string;
  existingValue?: string;
  setExistingValue?: (s: string) => void;
};

const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  padding: 8px 14px;
  padding-right: 48px;
  font-size: 14px;
  min-width: 200px;
  width: 700px;
  max-width: 460px;

  transition: all 0.5s ease-in-out, border-color 0.2s ease-in-out;

  &[data-wider='true'] {
    max-width: 700px;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey[400]};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.grey[900]};
    outline: none;
  }

  // Only show the clear button when the input has something
  // and the user focuses or hovers on the input
  &:focus,
  &:hover {
    &:not(:placeholder-shown) + button {
      opacity: 100%;
      pointer-events: initial;
    }
  }

  // Hide the default "clear" button on chromium browsers
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

const StyledButton = styled(Button).attrs({ variant: 'raw' })`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${({ theme }) => theme.colors.brand[600]};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  padding: 4px;
  display: flex;

  svg {
    height: 16px;
    width: 16px;
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const StyledClear = styled(Button).attrs({ variant: 'raw' })`
  display: flex;
  position: absolute;
  top: 7px;
  right: 36px;
  height: 20px;
  width: 20px;

  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;

  path:first-child {
    fill: ${({ theme }) => theme.colors.grey[300]};
  }
  path:last-child {
    fill: ${({ theme }) => theme.colors.grey[800]};
  }
`;

export default function SmartSearch({
  apiPath,
  setAllFilters,
  transformFilters,
  columns,
  setGlobalFilter,
  globalSearchFields,
  placeholder = 'What are you looking for?',
  tableName,
  existingValue,
  setExistingValue = (s: string) => s,
}: SmartSearchProps) {
  const { pathname } = useRouter();
  const [userQuery, setUserQuery] = useState(existingValue || '');
  const {
    isLoading,
    isError,
    run: runSmartSearch,
    status,
  } = useSmartSearch({
    apiPath,
    columns,
    setFilters: (filters) => setAllFilters?.(transformFilters(filters)),
    setGlobalSearch: setGlobalFilter,
    globalSearchFields,
  });

  useLayoutEffect(() => {
    if (isError) {
      toast.error('An error occurred, some results might be missing.');
    }
  }, [isError]);

  const handleSmartSearchResults = () => {
    if (status === 'error') {
      toast.error(`There's been an issue applying desired view.`);
      trackEvent(SMART_SEARCH_ISSUE({ pathname }));
      return;
    }
    if (status === 'success') {
      toast.success('Filters applied to the current view.');
    } else {
      toast.success(
        'Some queries might not be applicable to the current view. Please apply them manually.'
      );
    }
  };

  const handlePressEnter = async () => {
    trackEvent(PROMPT_SMART_SEARCH({ pathname }));

    if (!userQuery.trim()) {
      toast.error('Please enter a valid input query.');
      return;
    }

    await runSmartSearch(userQuery);

    handleSmartSearchResults();
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
    }
    if (e.key === 'Enter') {
      handlePressEnter();
    }
  };

  const handleClear = () => {
    setExistingValue('');
    setUserQuery('');
    const input = document.getElementById(`smart-search-${tableName}`) as HTMLInputElement;
    if (input) {
      input.value = '';
    }

    // Clear the filters
    if (setAllFilters) {
      setAllFilters([]);
    }
  };

  return (
    <Box position="relative">
      <StyledInput
        type="search"
        placeholder={placeholder}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          setUserQuery(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        id={`smart-search-${tableName}`}
        defaultValue={existingValue}
        data-wider={userQuery.length > 50}
      />
      <StyledClear onClick={handleClear}>
        <IconV2DuotoneTimesFull />
      </StyledClear>
      <StyledButton disabled={isLoading} onClick={handlePressEnter}>
        {isLoading ? <Spinner size="12px" color="brand.600" /> : <IconArrowRight />}
      </StyledButton>
    </Box>
  );
}
