import { paymentStatus } from '@/src/domains/payments/constants';

export const OUTSTANDING_PAYMENT_TIMELINE_EVENTS = {
  outstanding_payment_created: 'outstanding_payment_created',
  outstanding_payment_paid_in: 'outstanding_payment_paid_in',
  outstanding_payment_pay_out_in_progress: 'outstanding_payment_pay_out_in_progress',
  outstanding_payment_paid_out: 'outstanding_payment_paid_out',
};

export const PAYABLE_STATUSES = [
  paymentStatus.PAY_IN_FAILED,
  paymentStatus.CREATED,
  paymentStatus.DISPUTED,
];

export const PAYMENT_INITIATED_STATUSES = [
  paymentStatus.PROCESSING,
  paymentStatus.COLLECTING,
  paymentStatus.MARKED_COLLECTING,
  paymentStatus.PAID_IN,
  paymentStatus.COMPLETED,
];
