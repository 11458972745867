import { Accordion, Box } from '@remote-com/norma';
import type { JSXElementConstructor, ReactElement } from 'react';
import styled from 'styled-components';

import type { ColumnType } from '@/src/components/Table/types';

import { TableControlHeader } from './TableControlHeader';
import { TableControlItem } from './TableControlItem';
import type {
  TableControlItemConfig,
  TableStateControlProps,
  TableControlTitleObject,
} from './types';

export const TableControlWrapper = styled(Box).attrs({
  mb: 4,
  padding: 5,
  backgroundColor: 'blank',
  borderRadius: 'md',
  mt: 4,
})``;

export const TableControlItemsWrapper = styled(Box).attrs({
  display: 'grid',
  // Generate equal width columns, but as soon as the screen is too narrow
  // to fit 320px per widget button -- wrap to the next line.
  gridTemplateColumns: 'repeat(auto-fill, minmax(345px, 1fr))',
  gap: 4,
  mt: 4,
})``;

export interface TableControlProps extends TableStateControlProps {
  title: TableControlTitleObject | ReactElement<any, string | JSXElementConstructor<any>>;
  tableControlItemConfigs: TableControlItemConfig[];
  columns: ColumnType[];
}

export const TableControl = ({
  title,
  tableControlItemConfigs,
  tableState,
  setAllFilters,
  columns,
  setSortBy,
}: TableControlProps) => {
  return (
    <TableControlWrapper>
      <Accordion open size="compact" summary={<TableControlHeader title={title} />}>
        <TableControlItemsWrapper>
          {tableControlItemConfigs.map((itemConfig) => (
            <TableControlItem
              key={itemConfig.id}
              {...itemConfig}
              tableState={tableState}
              setAllFilters={setAllFilters}
              columns={columns}
              setSortBy={setSortBy}
            />
          ))}
        </TableControlItemsWrapper>
      </Accordion>
    </TableControlWrapper>
  );
};
