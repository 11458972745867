import { Box } from '@remote-com/norma';
import type { Editor as EditorCoreType } from '@tiptap/core';
import { EditorContent, useEditor } from '@tiptap/react';
import type { EditorOptions } from '@tiptap/react';
import { forwardRef, useImperativeHandle, useRef, useEffect, type FunctionComponent } from 'react';
import type { CSSProperties } from 'styled-components';
import styled, { css } from 'styled-components';

import { TiptapExtensions } from '@/src/domains/editor/extensions';

import { Toolbar } from './components/Toolbar';

export const StyledEditorContainer = styled(Box)<{
  $autoHeight?: boolean;
  $variant: 'outline' | 'ghost';
  $minHeight?: CSSProperties['minHeight'];
  $maxWidth?: CSSProperties['maxWidth'];
  $hasError?: boolean;
}>`
  position: relative;
  display: inline-block;
  min-height: ${({ $minHeight }) => $minHeight};
  ${({ $autoHeight }) =>
    $autoHeight &&
    css`
      &,
      > div,
      > div > .tiptap {
        height: inherit;
      }
    `};
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth};
  border: ${({ theme, $variant }) =>
    $variant === 'outline' ? `1px solid ${theme.colors.grey[400]}` : 'none'};
  border-radius: 12px;
  overflow: hidden;

  ${({ $hasError }) =>
    $hasError &&
    css`
      background-color: ${({ theme }) => theme.colors.negative.background};
      border-color: ${({ theme }) => theme.colors.negative.foreground};
    `}
`;

export type EditorProps = {
  autoHeight?: boolean;
  showToolbar: boolean;
  editorOptions?: Partial<EditorOptions>;
  variant?: 'outline' | 'ghost';
  minHeight?: string;
  maxWidth?: string;
  hasError?: boolean;
  placeholder?: string;
  ToolbarAction?: FunctionComponent<{ editor: EditorCoreType }>;
};

export type EditorRef = {
  editor: EditorCoreType | null;
  focus: EditorCoreType['commands']['focus'];
};

export const Editor = forwardRef<EditorRef, EditorProps>(
  (
    {
      autoHeight = false,
      showToolbar,
      editorOptions,
      variant = 'outline',
      minHeight = '500px',
      maxWidth = '768px',
      hasError,
      placeholder,
      ToolbarAction,
    },
    ref
  ): JSX.Element => {
    const editor = useEditor({
      extensions: TiptapExtensions(placeholder),
      ...editorOptions,
      editorProps: {
        ...editorOptions?.editorProps,
        attributes: {
          'data-testid': 'tiptap-editor',
          ...editorOptions?.editorProps?.attributes,
        },
      },
    });

    const doDeferredFocus = useRef<null | {
      params: Parameters<EditorCoreType['commands']['focus']>;
    }>(null);
    useImperativeHandle(
      ref,
      () => ({
        /**
         * The editor instance.
         * @see https://tiptap.dev/docs/editor/api/editor#introduction
         */
        editor,
        /**
         * Focus the editor. If the editor is not initialized yet, it will be deferred until it's ready.
         * The deferred focus exists as a workaround for `autofocus` being broken in TipTap 2.2+. See https://github.com/ueberdosis/tiptap/issues/5005.
         * Focusing the editor at any other time should not need this functionality. This can be removed when the bug is fixed.
         */
        focus: (...args) => {
          if (editor) {
            editor.commands.focus(...args);
          } else {
            doDeferredFocus.current = { params: args };
          }
          return true; // focus() command always returns true.
        },
      }),
      [editor]
    );

    useEffect(() => {
      if (editor && doDeferredFocus.current) {
        editor.commands.focus(...doDeferredFocus.current.params);
        doDeferredFocus.current = null;
      }
    }, [editor]);

    return (
      <StyledEditorContainer
        $autoHeight={autoHeight}
        $variant={variant}
        $minHeight={minHeight}
        $maxWidth={maxWidth}
        $hasError={hasError}
      >
        {showToolbar && editor ? <Toolbar editor={editor} ToolbarAction={ToolbarAction} /> : null}
        <EditorContent editor={editor} />
      </StyledEditorContainer>
    );
  }
);
