import styled, { createGlobalStyle, css } from 'styled-components';

import { Button } from '../button';
import { Spinner } from '../spinner';
import { Text } from '../text';

const toastCSS = css`
  body .toast {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 40px 16px 16px;
    align-items: flex-start;
    position: relative;

    background: ${({ theme }) => theme.colors.blank};
    box-shadow: ${({ theme }) => theme.shadows.small};
    box-sizing: unset;
    border-radius: ${({ theme }) => theme.borderRadius.md};
    border: 1px solid ${({ theme }) => theme.colors.grey[200]};
    min-width: 400px;

    div[role='status'] {
      ${({ theme }) => theme.typography.sm};
      margin-left: ${({ theme }) => theme.space[3]}px;
      margin-top: 2px;
      justify-content: flex-start;
      flex-direction: column;
    }

    svg,
    ${Spinner} {
      margin-top: 2px;
      flex-shrink: 0;
    }

    &.toast-success {
      > svg {
        fill: ${({ theme }) => theme.colors.green[600]};
      }
    }

    &.toast-error {
      > svg {
        fill: ${({ theme }) => theme.colors.red[400]};
      }
    }

    &.toast-loading {
      ${Spinner} {
        color: ${({ theme }) => theme.colors.brand[600]};
        margin: 0;
      }
    }
  }
`;

export const ToastCSS = createGlobalStyle`${toastCSS}`;

export const ButtonClose = styled(Button).attrs({ variant: 'raw' })`
  padding: 0;
  border: none;
  appearance: none;
  background: transparent;
  top: ${({ theme }) => theme.space[4]}px;
  right: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.colors.grey[600]};
  position: absolute;
`;

export const ToastTitle = styled(Text).attrs({ variant: 'smMedium', color: 'grey.900' })``;

export const ToastDescription = styled(Text).attrs({ variant: 'sm', color: 'grey.700' })``;
