import type { WeekDay } from '../types';

export const MINUTES_IN_HOUR = 60;
export const MINUTES_IN_DAY = 1440;
export const NUMBER_OF_DIGITS_PER_TIME_SEGMENT = 2;

export const DAYS_OF_THE_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

export const daysOfTheWeekLabels: Record<WeekDay, string> = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
};

export const FALLBACK_SCHEDULE = {
  startTime: '',
  endTime: '',
  breakDurationMinutes: 0,
  hours: 0,
  isFallback: true,
};

export const SCHEDULE_TYPES = {
  FLEXIBLE: 'flexible',
  BUSINESS_HOURS: 'core_business_hours',
  FIXED: 'fixed_hours',
} as const;

type FieldToLabelType = {
  [key: string]: string;
};

export const fieldToLabel: FieldToLabelType = {
  day: 'Day',
  startTime: 'Start time',
  endTime: 'End time',
  breakDurationMinutes: 'Break duration (in minutes)',
  hours: 'Hours',
};
