import { Box, Skeleton, Stack, Text } from '@remote-com/norma';
import type { JSXElementConstructor, ReactElement } from 'react';

import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

import type { TableControlTitleObject } from './types';

type TableControlHeaderProps = {
  title: TableControlTitleObject | ReactElement<any, string | JSXElementConstructor<any>>;
};

export const TableControlHeader = ({ title }: TableControlHeaderProps) => {
  if (title && typeof title === 'object' && 'count' in title) {
    if (title.isLoading) {
      return (
        <Box mt={4}>
          <Skeleton variant="rect" width={85} height={16} />
        </Box>
      );
    }

    return (
      <Stack gap={2} alignItems="center" direction="row">
        <Text variant="lgMedium" color="grey.900">
          {title.count ?? '-'}
        </Text>
        <Text variant="lgMedium" color="grey.500">
          {getSingularPluralUnit(
            title.count || 0,
            title.singularLabel,
            title.pluralLabel,
            false,
            false
          )}
        </Text>
      </Stack>
    );
  }

  if (React.isValidElement(title)) return title;

  return null;
};
